<template>
    <div class="flex flex--column flex--center__vert content">
        <h1 class="mar--top__8 font--s5">Warum OKourse?</h1>
        <h2 class="color--grey mar--bottom__4">Überzeuge dich selbst und schaue dir den Vergleich zu anderen Alternativen an.</h2>
        <div class="comparison flex flex--column b-radius--5 card bgColor--white pad--2 mar--bottom__8 overflowAuto--hor">
            <div class="grid comparison--row pad--2 b-radius--5">
                <div></div>
                <div><img class="height--2" src="../../../assets/SVG3.svg" /></div>
                <div class="font--s2 font--w700 flex flex--end__vert">Kajabi</div>
                <div class="font--s2 font--w700 flex flex--end__vert">Thinkific</div>
                <div class="font--s2 font--w700 flex flex--end__vert">Elopage</div>
                <div class="font--s2 font--w700 flex flex--end__vert">Teach:able</div>
                <div class="font--s2 font--w700 flex flex--end__vert">Podia</div>
            </div>
            <div class="grid comparison--row comparison--row__pad b-radius--5">
                <div class="font--s16 font--w700 pad--right__2">Preis/Monat</div>
                <div class="font--s16 font--w700">ab 19€</div>
                <div class="comparison--value">ab 119€</div>
                <div class="comparison--value">ab 39€</div>
                <div class="comparison--value">ab 49€</div>
                <div class="comparison--value">ab 39€</div>
                <div class="comparison--value">ab 39€</div>
            </div>
            <div class="grid comparison--row comparison--row__pad b-radius--5">
                <div class="font--s16 font--w700 pad--right__2">Kostenloser Plan</div>
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
            </div>
            <div class="grid comparison--row comparison--row__pad b-radius--5">
                <div class="font--s16 font--w700 pad--right__2">Integration in eigene Apps und Webseiten</div>
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
            </div>
            <div class="grid comparison--row comparison--row__pad b-radius--5">
                <div class="font--s16 font--w700 pad--right__2">Unbegrenzte Kursinhalte</div>
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
            </div>
            <div class="grid comparison--row comparison--row__pad b-radius--5">
                <div class="font--s16 font--w700 pad--right__2">Unbegrenzte Kursmitglieder</div>
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
            </div>
            <div class="grid comparison--row comparison--row__pad b-radius--5">
                <div class="font--s16 font--w700 pad--right__2">Kurszertifikate</div>
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
            </div>
            <div class="grid comparison--row comparison--row__pad b-radius--5">
                <div class="font--s16 font--w700 pad--right__2">Integration von Zahlungsanbietern</div>
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
            </div>
            <div class="grid comparison--row comparison--row__pad b-radius--5">
                <div class="font--s16 font--w700 pad--right__2">Eigener Zahlungsanbieter</div>
                <i class="ion-md-close-circle color--danger font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
                <i class="ion-md-checkmark-circle color--success font--s3 lineHeight--1" />
            </div>
        </div>
        <div class="flex flex--column flex--center__vert mar--bottom__8">
            <div class="font--s3 font--w700 font--center lineHeight--12 mar--bottom__2">
                Kursverwaltung und -hosting as a Serivce
            </div>
            <div class="font--s16 font--center width--max__80rem mar--bottom__2">
                Mit OKourse als Kursplattform, lassen wir dir alle Freiheiten die du benötigst um kreativ zu sein. Auf Wunsch geben wir dir nicht mal vor wo deine Kunden Ihre Kurse überhaupt schauen.
                Da du unsere öffentlichen Schnittstellen verwenden kannst, hast du die Kursinhalte im Handumdrehen auch in deine eigenen Website oder App integriert.
                Zusätzlich überlassen wir dir die Wahl, welchen Zahlungsdienstleister du verwenden möchtest und bieten dir die entsprechenden Integrationen an.
            </div>
            <AccentButton @onClick="$router.push('/dashboard')" :filled="true" title="Jetzt selbst testen"/>
        </div>
    </div>
</template>
<script>
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    export default {
        name: 'Comparison',
        components: {AccentButton},
    }
</script>
