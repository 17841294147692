<template>
  <transition name="fade" mode="out-in">
    <div class="fullScreen" :key="'content'" v-if="init">
      <Header />
      <transition name="fade-move" mode="out-in">
        <router-view />
      </transition>
      <a :href="`https://neon-software.typeform.com/to/CiOC9ugy#link=${link}&editorId=${user._id}`" target="_blank" class="fixed right--2 bottom--2 feedback bgColor--primaryLight flex flex--center circle">
        <i class="ion-md-alert font--s5 color--white" />
      </a>
    </div>
    <div class="flex flex--center width--100 height--total__100" v-if="!init" :key="'loader'">
      <loader :loading="true" :size="'15px'" :color="'#114B5F'" />
    </div>
  </transition>
</template>

<script>
import Header from '@/components/reusable/3_Other/Header';
export default {
  name: 'MainLoader',
  components: {Header},
  data() {
    return {
      init: false,
      link: window.location.href,
    };
  },
  computed: {
    token() {
      return this.$store.getters.getToken;
    },
    user() {
      /* eslint-disable */
      console.log(this.$store.getters.getUser)
      return this.$store.getters.getUser
    }
  },
  async mounted() {
    this.$store.dispatch('retrieveErrorCodes');
    this.$store.dispatch('refreshToken', true).then(() => {
      this.init = true;
      this.$forceUpdate();
      this.$store.dispatch('retrieveOwnEditor');
    }).catch(() => {
      console.log('Refresh Error');
      this.$router.push('/login');
      this.init = true;
    });
  },
}
</script>
<style lang="scss" scoped>
.feedback {
  width: 50px;
  height: 50px;

}
</style>
