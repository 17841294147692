<template>
    <div class="flex flex--column flex--center__vert overflowAuto">
        <div class="courses--wrapper">
            <ActionHeader back-title="Webhooks" title="Endpunkt" @onBack="$router.push('/dashboard/organizations/' + $route.params.organizationID + '/webhooks')">
                <ActionButton icon="ion-md-trash" light="true" @onClick="deleteEndpoint" />
            </ActionHeader>
            <div class="flex flex--column pad--2">
                <div class="flex mar--bottom__2">
                    <AccentButton title="Test-Webhook senden" @onClick="testEndpoint" />
                </div>
                <div class="form--label">URL</div>
                <div class="font--s16 mar--bottom__2">
                    {{ selectedWebhook.url || '' }}
                </div>
                <div class="form--label">Beschreibung</div>
                <div class="font--s16 mar--bottom__2">
                    {{ selectedWebhook.description || '' }}
                </div>
                <div class="form--label">Passwort</div>
                <div class="font--s16 mar--bottom__2 flex flex--center__vert">
                    <ActionButton icon="ion-md-eye" class="mar--right__1" @onClick="showPassword = !showPassword"/>
                    <div v-if="showPassword">
                        {{ selectedWebhook.password || '' }}
                    </div>
                    <div v-if="!showPassword" class="flex">
                        <div class="width--05 height--05 circle bgColor--greyDark mar--right__1" />
                        <div class="width--05 height--05 circle bgColor--greyDark mar--right__1" />
                        <div class="width--05 height--05 circle bgColor--greyDark mar--right__1" />
                        <div class="width--05 height--05 circle bgColor--greyDark mar--right__1" />
                        <div class="width--05 height--05 circle bgColor--greyDark mar--right__1" />
                        <div class="width--05 height--05 circle bgColor--greyDark mar--right__1" />
                        <div class="width--05 height--05 circle bgColor--greyDark mar--right__1" />
                        <div class="width--05 height--05 circle bgColor--greyDark mar--right__1" />
                    </div>
                </div>
                <div class="form--label">Events</div>
                <div class="font--s16 font--w700 mar--bottom__2 flex">
                    <div v-for="type in selectedWebhook.eventTypes" :key="type" class="mar--right__1">
                        {{ type }}
                    </div>
                </div>
                <div class="form--label">Logs</div>
                <div class="members--list flex flex--column width--100 b-radius--5 overflowHidden" v-if="logs.length">
                    <div class="members--member flex border--bottom__light clickable pad--1"
                         v-for="log in logs" :key="log._id" @click="selectLog(log)">
                        <div class="flex flex--center mar--right__1">
                            <div class="flex pad--05 b-radius--5 color--white"
                            :class="[ log.events[0].statusCode === 200 ? 'bgColor--successDark' : 'bgColor--greyDark']">
                                {{ log.events[0].statusCode }}
                            </div>
                        </div>
                        <div class="flex flex--center__vert mar--right__1" >
                            <div class="font--s14">
                                {{ log.payload.eventType }}
                            </div>
                        </div>
                        <div class="flex--grow" />
                        <div class="flex flex--center font--s14">
                            {{ moment(log.events[0].dateTried).format('DD.MM.YYYY - HH:mm') }} Uhr
                        </div>
                    </div>
                </div>
                <p v-if="!logs.length">
                    Es gibt zu diesem Endpunkt noch keine Logs.
                </p>
                <div class="flex flex--space__hor mar--top__1">
                  <ActionButton light="true" icon="ion-ios-arrow-back" @onClick="backward"/>
                  <div class="font--s16">Seite {{ page }}</div>
                  <ActionButton light="true" icon="ion-ios-arrow-forward"  @onClick="forward"/>
                </div>
            </div>
        </div>
        <transition name="fade">
            <Modal title="Event Log" @toggle="showLogDetails = false" v-if="showLogDetails">
                <div class="pad--25 flex flex--column flex--grow height--100 flex--column flex--center overflowAuto">
                    <div class="flex flex--column width--100">
                        <AccentButton title="Erneut senden" @onClick="resendEvent" class="mar--bottom__2" />
                        <div class="flex flex--column border--bottom__light pad--1"
                             v-for="instance in event.events" :key="instance._id">

                          <div class="flex mar--bottom__1">
                            <div class="flex flex--center mar--right__1">
                              <div class="flex pad--05 b-radius--5 color--white"
                                   :class="[ instance.statusCode === 200 ? 'bgColor--successDark' : 'bgColor--error']">
                                {{ instance.statusCode }}
                              </div>

                            </div>
                            <div class="font--s16">{{ instance.message}}</div>
                            <div class="flex--grow" />
                          </div>
                          <div class="flex flex--center__vert mar--bottom__1">
                            <div class="font--s16 font--w700 mar--right__1">Date</div>

                            <div class="font--s16 color--grey">
                              {{ moment(instance.dateTried).format('DD.MM.YYYY - HH:mm') }} Uhr
                            </div>
                          </div>
                          <div class="flex">
                            <div class="font--s16 font--w700 mar--right__1">Body</div>
                            <div class="font--s16 color--grey">
                              {{ event.payload.data }}
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </transition>
    </div>
</template>
<script>
    import ActionHeader from "@/components/views/4_Page/ActionHeader";
    import ActionButton from "@/components/reusable/1_Buttons/ActionButton";
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    import Modal from "@/components/reusable/2_Modals/Modal";
    export default {
        name: 'WebhookDetails',
        components: {Modal, AccentButton, ActionButton, ActionHeader},
        data() {
            return {
                showLogDetails: false,
                showPassword: false,
                selectedLog: null,
                page: 1,
            }
        },
        computed: {
            logs() {
                /* eslint-disable */
                console.log(this.$store.getters.getLogs);
                return this.$store.getters.getLogs;
            },
            selectedWebhook() {
                console.log(this.webhooks);
                const selected = this.webhooks.filter((el) => el._id === this.$route.params.endpointID);
                return selected && selected.length ? selected[0] : {};
            },
            webhooks() {
                return this.$store.getters.getWebhooks;
            },
            event() {
                /* eslint-disable */
                console.log(this.logs);
                console.log(this.selectedLog);
                console.log(this.logs.find(el => el._id === this.selectedLog));
                return this.logs.find(el => el._id === this.selectedLog);
            },
        },
        methods: {
          forward() {
            if (this.logs.length >= 10) {
              this.page+= 1;
            }
          },
          backward() {
            if (this.page > 1) {
              this.page-= 1;
            }
          },
            selectLog(log) {
                this.selectedLog = log._id;
                this.showLogDetails = !this.showLogDetails;
            },
            testEndpoint() {
                this.$store.dispatch('testEndpoint', {
                    organizationID: this.$route.params.organizationID,
                    endpointID: this.$route.params.endpointID,
                });
            },
            deleteEndpoint() {
                this.$store.dispatch('deleteEndpoint', {
                    organizationID: this.$route.params.organizationID,
                    endpointID: this.$route.params.endpointID,
                });
                this.$router.push('/dashboard/organizations/' + this.$route.params.organizationID + '/webhooks');
            },
            resendEvent() {
                this.$store.dispatch('resendEvent', {
                    organizationID: this.$route.params.organizationID,
                    endpointID: this.$route.params.endpointID,
                    eventID: this.selectedLog,
                });
            }
        },
      watch: {
          page(newVal) {
            if (newVal) {
              this.$store.dispatch('retrieveLogs', {
                organizationID: this.$route.params.organizationID,
                endpointID: this.$route.params.endpointID,
                start: (this.page - 1) * 10,
                end: this.page * 10,
              });
            }
          },
      },
        mounted() {
            if (!this.webhooks || !this.webhooks.length) {
                this.$store.dispatch('retrieveEndpoints', this.$route.params.organizationID);
            }
            if (!this.eventTypes || !this.eventTypes.length) {
                this.$store.dispatch('retrieveEventTypes');
            }
            this.$store.dispatch('retrieveLogs', {
                organizationID: this.$route.params.organizationID,
                endpointID: this.$route.params.endpointID,
                start: 0,
                end: 10,
            });
        }
    }
</script>
