<template>
    <div class="height--100 width--100 flex flex--center mar--top__4">
        <div class="flex flex--column flex--center width--50">
            <img :src="src" class="width--50 mar--bottom__2"/>
            <div class="scoped-text font--s2 mar--bottom__2">
                {{text}}
            </div>
            <AccentButton :title="buttonTitle" @onClick="$emit('onButtonClick')"/>
        </div>
    </div>
</template>
<script>
    import AccentButton from '@/components/reusable/1_Buttons/AccentButton'
    import defaultImage from '../../assets/SVG6.svg'

    export default {
        name: 'NoDataAction',
        components: {AccentButton},
        props: {
            text: {
                type: String,
                default: 'Du hast noch keine Daten angelegt.'
            },
            buttonTitle: {
                type: String,
                default: 'Jetzt Daten anlegen'
            },
            src: {
                type: String,
                default: defaultImage
            }
        },
    }
</script>

<style scoped lang="scss">
.scoped-text {
    text-align: center;
}
</style>