<template>
    <div class="flex flex--column flex--center__vert">
        <div class="font--s25 font--w700 width--100 font--center">
            Wähle einen Plan aus:
        </div>
        <div class="grid organizations--plan__wrapper mar--bottom__4">
            <div :key="plan._id" class="flex flex--center pad--2 width--100 height--100" v-for="plan in filteredPlans">
                <div :class="{'organizations--plan__selected': selected === plan.position, 'organizations--plan__recommended': 2 === plan.position}"
                     @click="selected = plan.position"
                     class="card card--move organizations--plan bgColor--white flex--space__hor">
                    <div class="font--w700 font--s2 mar--bottom__1 color--primaryLight">
                        {{ plan.nickname.split(' ')[0] }}
                    </div>
                    <div class="width--100 border--bottom__light mar--bottom__2"/>
                    <div class="font--s5 font--w700 lineHeight--1">
                        {{ plan.type === 'year' ? Math.round((plan.amount / 12) / 100) : (plan.amount / 100) }}€
                    </div>
                    <div class="font--s1 mar--bottom__2">
                        /Monat
                    </div>
                    <ul class="flex flex--column font--s16 mar--bottom__1">
                        <li :key="ind + point"
                            class="noDec flex flex--center__vert lineHeight--12"
                            v-for="(point, ind) in plan.sellingPoints">
                            <i class="ion-md-checkmark font--s25 color--primaryLight mar--right__1"/>
                            <div>
                                {{ point }}
                            </div>
                        </li>
                    </ul>
                    <!--                    <div class="width&#45;&#45;100 font&#45;&#45;s16 font&#45;&#45;w700 flex flex&#45;&#45;center pad&#45;&#45;05 bgColor&#45;&#45;primaryLight b-radius&#45;&#45;5 color&#45;&#45;white"-->
                    <!--                         v-if="plan.position === 2">-->
                    <!--                        Empfohlen-->
                    <!--                    </div>-->
                </div>
            </div>
        </div>

        <div class="width--100 border--bottom__light mar--bottom__2"/>
        <div class="flex flex--center__vert flex--space__hor width--100 mar--bottom__2">
            <div class="font--w700 font--s2 mar--right__2">
                Abrechnungszeitraum:
            </div>
            <div class="width--10 flex">
                <ToggleButton :value="yearly" @toggleValue="yearly = !yearly" false="Jährlich"
                              true="Monatlich"/>
            </div>
        </div>
        <div class="flex flex--center__vert flex--space__hor width--100 mar--bottom__2">
            <div class="font--w700 font--s2 mar--right__2">
                Geschäftskunde:
            </div>
            <div class="width--10 flex">
                <ToggleButton :value="business" @toggleValue="business = !business" false="Ja"
                              true="Nein"/>
            </div>
        </div>
        <div class="width--100 mar--bottom__2" v-if="organization.role === 'Creator'">
            <AccentButton :disable="!this.selected"
                          @onClick="next"
                          secondary="true"
                          :loading="loading"
                          title="Weiter"/>
        </div>
        <transition name="fade">
            <Modal @toggle="showBillingAddress = !showBillingAddress" title="Rechnungsadresse"
                   v-if="showBillingAddress">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <label class="form--label" for="billing_name">Name</label>
                    <input class="form--input form--input__text" id="billing_name"
                           type="text"
                           v-focus
                           v-model.trim="billingAddress.name"
                    />
                    <label class="form--label" for="billing_line1">Adresszeile 1</label>
                    <input class="form--input form--input__text" id="billing_line1"
                           type="text"
                           v-model.trim="billingAddress.line1"
                    />
                    <label class="form--label" for="billing_line2">Adresszeile 2</label>
                    <input class="form--input form--input__text" id="billing_line2"
                           type="text"
                           v-model.trim="billingAddress.line2"
                    />
                    <label class="form--label" for="billing_postal">Postleitzahl</label>
                    <input class="form--input form--input__text" id="billing_postal"
                           type="text"
                           v-model.trim="billingAddress.postal_code"
                    />
                    <label class="form--label" for="billing_city">Stadt</label>
                    <input class="form--input form--input__text" id="billing_city"
                           type="text"
                           v-model.trim="billingAddress.city"
                    />
                    <label class="form--label" for="billing_city">Land</label>
                    <v-select
                        :value="billingAddress.country | codeTranslation"
                        :options="countryCodes"
                        :clearable="false"
                        placeholder="Land"
                        label="label"
                        @input="({ code }) => (billingAddress.country = code)"
                    />
                    <label class="form--label" for="billing_tax">Umsatzsteuernummer</label>
                    <input class="form--input form--input__text mar--bottom__2" id="billing_tax"
                           type="text"
                           v-model.trim="billingAddress.taxId"
                    />
                    <AccentButton :filled="true" :full-width="true" :loading="loading"
                                  :title="!this.billingAddress.name ? 'Ohne Rechnungsadresse fortfahren' : 'Mit Rechnungsadresse fortfahren'"
                                  @onClick="updateAddress" ref="validate"
                                  tabindex="0"
                    />
                </div>
            </Modal>
        </transition>
    </div>
</template>
<script>
    import ToggleButton from "@/components/reusable/1_Buttons/ToggleButton";
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    import Modal from "@/components/reusable/2_Modals/Modal";
    const countries = require('i18n-iso-countries')
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'

    export default {
        name: 'SelectPlan',
        components: {Modal, AccentButton, ToggleButton, vSelect},
        data() {
            return {
                yearly: true,
                business: true,
                selected: null,
                stripe: null,
                showBillingAddress: false,
                loading: false,
                billingAddress: {},
            };
        },
        filters: {
          codeTranslation(val) {
            return countries.getName(val, 'de')
          },
        },
        methods: {
            async next() {
                if (this.business || this.yearly) {
                    this.showBillingAddress = true;
                } else {
                    this.startPayment()
                }
            },
            async updateAddress() {
                let success = true;
                if (this.billingAddress.name) {
                    this.loading = true;
                    await this.$store.dispatch('updateBillingAddress', {
                        organizationID: this.$route.params.organizationID,
                        data: this.billingAddress,
                    }).catch(() => {
                        success = false
                    });
                    this.loading = false;
                }
                if (success) {
                    this.startPayment();
                }
            },
            async startPayment() {
                this.loading = true;
                /* eslint-disable */
                this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
                const session = await this.$store.dispatch('createSubscription', {
                    organizationID: this.$route.params.organizationID,
                    data: {
                        priceId: this.filteredPlans.find((el) => el.position === this.selected).id,
                        urlParams: {
                            cancel: `dashboard/organizations/${this.$route.params.organizationID}/plan`,
                            success: `dashboard/organizations/${this.$route.params.organizationID}/plan/success`,
                        },
                    },
                });
                console.log(session);
                if (session) {
                    this.stripe.redirectToCheckout({
                        sessionId: session,
                    }).then((result) => {
                        console.log(result.error.message);
                    });
                }
                this.loading = false;
            }
        },
        computed: {
          countryCodes() {
            const countryMapping = []
            countries.registerLocale(require('i18n-iso-countries/langs/de.json'))
            const codes = countries.getAlpha2Codes()
            for (const [key] of Object.entries(codes)) {
              countryMapping.push({
                label: countries.getName(key, 'de'),
                code: key,
              })
            }
            return countryMapping
          },
            currentBillingAddress() {
                return this.$store.getters.getBillingAddress;
            },
            plans() {
                return this.$store.getters.getPlans;
            },
            filteredPlans() {
                if (this.plans) {
                    return this.plans.filter((el) => this.yearly ? el.type === 'year' : el.type === 'month');
                }
                return [];
            },
            organization() {
                return this.$store.getters.getOrganizationById(this.$route.params.organizationID)
            },
            me() {
                return this.$store.getters.getUser;
            },
        },
        watch: {
            currentBillingAddress(newVal) {
                console.log(newVal);
                this.billingAddress = newVal;
            },
        },
        mounted() {
            this.$store.dispatch('retrievePlans');
            this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
            this.$store.dispatch('fetchBillingAddress', {
                organizationID: this.$route.params.organizationID,
            });
        },
    };
</script>
