<template>
    <div class="width--100 height--100 flex flex--column flex--center__hor" >
        <div class="font--s14 mar--bottom__2">
            Aus Sicherheitsgründen benötigst du für dieses Login den Code, welchen wir dir gerade per E-Mail zugesendet haben.
        </div>
        <div class="font--w700 font--s2 mar--bottom__2">
            Passcode eingeben
        </div>
        <div class="flex mar--bottom__2">
            <input type="text"
                   id="0"
                   maxlength="1"
                   v-model="passcode[0]"
                   @input="focusNext(1)"
                   class="form--input form--input__text mar--right__1" />
            <input type="text"
                   id="1"
                   maxlength="1"
                   v-model="passcode[1]"
                   @input="focusNext(2)"
                   class="form--input form--input__text mar--right__1" />
            <input type="text"
                   id="2"
                   maxlength="1"
                   v-model="passcode[2]"
                   @input="focusNext(3)"
                   class="form--input form--input__text mar--right__1" />
            <input type="text"
                   id="3"
                   maxlength="1"
                   v-model="passcode[3]"
                   @input="focusNext(4)"
                   class="form--input form--input__text mar--right__1" />
            <input type="text"
                   id="4"
                   maxlength="1"
                   v-model="passcode[4]"
                   @input="focusNext(5)"
                   class="form--input form--input__text mar--right__1" />
            <input type="text"
                   id="5"
                   maxlength="1"
                   v-model="passcode[5]"
                   @input="login"
                   class="form--input form--input__text" />
        </div>
        <AccentButton title="Anmelden" :full-width="true" @onClick="login" :disable="passcode.includes('')" :loading="loading"/>
    </div>
</template>
<script>
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    export default {
        name: 'Passcode',
        components: {AccentButton},
        props: ['loginEmail', 'loginPassword', 'stayLogged'],
        data() {
            return {
                passcode: ['', '', '', '', '', ''],
                loading: false,
            }
        },
        methods: {
            login() {
                this.loading = true;
                this.$store.dispatch('login', {
                    email: this.loginEmail,
                    password: this.loginPassword,
                    rememberLogin: this.stayLogged,
                    secret: this.passcode.join(''),
                }).then((res) => {
                    if (res.status >= 200 && res.status < 300) {
                        this.$router.push('/dashboard')
                    }
                    this.loading = false;
                });
            },
            focusNext(id) {
                document.getElementById(id).focus();
            }
        }
    }
</script>
