<template>
    <label class="toggleButton relative width--100 flex flex--space__hor flex--center__vert clickable overflowHidden">
        <input
                type="checkbox"
                class="absolute opacity--0"
                @change="changeHandler"
                :checked="value"/>
        <div class="toggleButton--slider absolute b-radius--5 clickable" :class="{'toggleButton--slider__equal': same}"></div>
        <div class="toggleButton--answer width--50 color--white font--center font--s14">{{ this.false }}</div>
        <div class="toggleButton--answer width--50 color--white font--center font--s14">{{ this.true }}</div>
    </label>
</template>
<script>
    export default {
        name: 'ToggleButton',
        props: ['value', 'false', 'true', 'same'],
        methods: {
            changeHandler() {
                this.$emit('toggleValue');
            },
        },
    };
</script>
