<template>
    <transition name="fade" mode="out-in">
        <div class="width--100 height--100 flex flex--column flex--center__hor" v-if="!passcodeRequired" :key="'form'">
            <div class="font--s2 font--w700 mar--bottom__2">
                Anmelden
            </div>
            <div class="mar--bottom__2">
                <label class="form--label" for="login_email">
                  E-Mail
                </label>
                <input type="text" id="login_email" class="form--input form--input__text mar--bottom__1"
                       :class="{'form--input__error': this.$v.loginEmail.$error}" v-model="$v.loginEmail.$model"/>
                <label class="form--label" for="login_password">
                    Passwort
                </label>
                <input type="password" id="login_password" class="form--input form--input__text"
                       :class="{'form--input__error': this.$v.loginPassword.$error}" v-model="$v.loginPassword.$model" @keyup.enter="login"/>
            </div>
            <div class="flex flex--center__vert width--100 mar--bottom__2">
                <div class="auth--stay b-radius--5 mar--right__1 clickable flex flex--center"
                     @click="toggleStayLogged"
                     :class="{'border--dark': stayLogged, 'border--light': !stayLogged}">
                    <transition name="fade">
                        <i class="ion-md-checkmark font--s2" v-if="stayLogged"/>
                    </transition>
                </div>
                <div class="font--s16 clickable" @click="toggleStayLogged">
                    Angemeldet bleiben
                </div>
            </div>
            <div class="flex flex--column flex--center">
                <AccentButton title="Anmelden" :full-width="true" @onClick="login" :disable="$v.$invalid" />
                <div class="font--s14 mar--top__1 clickable" @click="resetPass">
                    Passwort vergessen?
                </div>
            </div>
        </div>
        <Passcode v-if="passcodeRequired"
                  :login-email="loginEmail"
                  :login-password="loginPassword"
                  :stay-logged="stayLogged"
                  :key="'passcode'"/>
    </transition>
</template>
<script>
    import AccentButton from '@/components/reusable/1_Buttons/AccentButton';
    import {required, email} from 'vuelidate/lib/validators';
    import Passcode from "@/components/views/0_Authentication/Passcode";

    export default {
        name: 'Login',
        components: {Passcode, AccentButton},
        data() {
            return {
                loginEmail: null,
                loginPassword: null,
                stayLogged: true,
                passcodeRequired: false,
            };
        },
        validations: {
            loginEmail: {
                required,
                email,
            },
            loginPassword: {
                required,
            }
        },
        methods: {
            login() {
                if (!this.$v.$invalid) {
                    this.$store.dispatch('login', {
                        email: this.loginEmail,
                        password: this.loginPassword,
                        rememberLogin: this.stayLogged,
                    }).then((res) => {
                        if (res.status >= 200 && res.status < 300) {
                            this.$router.push('/dashboard')
                        } else if (res.status === 401 && res.data.msg === 5) {
                            this.passcodeRequired = true;
                        }
                    });
                } else {
                    this.$v.$touch();
                    this.$store.commit('addToast', {
                        type: 'error',
                        title: 'Bitte gib deine korrekten Login Daten an'
                    })
                }
            },
            toggleStayLogged() {
                this.stayLogged = !this.stayLogged;
            },
            resetPass() {
                if (this.$v.loginEmail.$invalid) {
                    this.$v.loginEmail.$touch();
                    this.$store.commit('addToast', {
                        type: 'warning',
                        title: 'Bitte gib die E-Mail ein mit der du bei OKourse angemeldet bist.'
                    })
                } else {
                    this.$store.dispatch('forgotPassword', {
                        email: this.loginEmail,
                    }).then(() => {
                        this.$store.commit('addToast', {
                            type: 'success',
                            title: 'Bitte schau in deine Mails',
                        })
                    })
                }
            },
        },
    };
</script>
