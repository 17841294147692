<template>
    <div class="auth flex flex--column flex--center height--total__100">
        <div class="auth--container grid relative mar--2">
            <div class="auth--container__header width--100 height--100 flex--center bgColor--primary">
                <img class="height--2 clickable" src="../../../assets/SVG4.svg" @click="$router.push('/')"/>
            </div>
            <div class="width--100 height--100 flex flex--column auth--container__element">
                <transition name="fade" mode="out-in">
                    <Login v-if="loginMode" class="pad--25" :key="'loginForm'"/>
                    <div v-else :key="'loginPlaceholder'"/>
                </transition>
            </div>
            <div class="width--100 height--100 flex flex--column  auth--container__element">
                <transition name="fade" mode="out-in">
                    <Register v-if="!loginMode" class="pad--25" :key="'registerForm'"/>
                    <div v-else :key="'registerPlaceholder'"/>
                </transition>
            </div>
            <div class="auth--slider height--100 absolute flex--column flex--center__vert flex--space__hor pad--25"
                 :class="{'auth--slider__active': loginMode}">
                <img class="height--2 clickable" src="../../../assets/SVG4.svg"  @click="$router.push('/')"/>
                <div class="auth--slider__illustration flex flex--center relative circle">
                    <img src="../../../assets/SVG1.svg" />
                </div>
                    <div class="flex flex--column flex--center__vert" v-if="loginMode">
                        <div class="font--s16">
                            Noch keinen Account?
                        </div>
                        <div class="font--s16 font--w700 clickable" @click="loginMode = !loginMode">
                            Jetzt Account erstellen.

                        </div>
                    </div>
                    <div class="flex flex--column flex--center__vert" v-else>
                        <div class="font--s16">
                            Schon einen Account?
                        </div>
                        <div class="font--s16 font--w700 clickable" @click="loginMode = !loginMode">
                            Jetzt anmelden.

                        </div>
                    </div>
            </div>
        </div>
        <div class="auth--container__footer flex--center mar--top__2">
            <transition name="fade" mode="out-in">
                <div class="flex flex--column flex--center__vert" v-if="loginMode" :key="'register'">
                    <div class="font--s16">
                        Noch keinen Account?
                    </div>
                    <div class="font--s16 font--w700 clickable" @click="loginMode = !loginMode">
                        Jetzt Account erstellen.

                    </div>
                </div>
                <div class="flex flex--column flex--center__vert" v-else :key="'login'">
                    <div class="font--s16">
                        Schon einen Account?
                    </div>
                    <div class="font--s16 font--w700 clickable" @click="loginMode = !loginMode">
                        Jetzt anmelden.

                    </div>
                </div>
            </transition>
        </div>
        <div class="absolute top--2">
            <IconButton title="Zurück zur Startseite" icon="ion-md-arrow-back" @onClick="$router.push('/')"/>
        </div>
    </div>
</template>
<script>
    import Login from '@/components/views/0_Authentication/Login';
    import Register from '@/components/views/0_Authentication/Register';
    import IconButton from "@/components/reusable/1_Buttons/IconButton";
    export default {
        name: 'AuthenticationLoader',
        components: {IconButton, Register, Login},
        data() {
            return {
                loginMode: true,
            };
        },
        async mounted() {
            await this.$store.dispatch('retrieveErrorCodes')
        }
    };
</script>
