<template>
  <div class="flex flex--column">
    <div class="line" v-if="!first" />
    <div class="card pad--2 flex flex--column">
      <h2 class="lineHeight--12">{{ getTitle() }}</h2>
      <div class="font--s14 color--grey">{{ moment(item.entryDate).format('DD.MM.YYYY - HH:mm') + ' Uhr' }} durch {{ getPerson() }}</div>
      <div class="lineHeight--12 font--s16" v-if="item.offer">{{ item.offer.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HistoryItem',
  props: ['item', 'first'],
  data() {
    return {
      types: ['userCreated', ]
    }
  },
   methods: {
    getTitle() {
      switch (this.item.type) {
        case 'userCreated':
          return 'OKourse beigetreten';
        case 'offerAccessRemoved':
          return 'Zugriff verloren';
        case 'offerAccessGranted':
          return 'Zugriff erhalten';
        default:
          return 'Unbekanntes Ereignis';
      }
    },
     getPerson() {
       switch (this.item.type) {
         case 'userCreated':
           return this.item.additionalInformation.addedBy;
         case 'offerAccessRemoved':
           return this.item.additionalInformation.removedBy;
         case 'offerAccessGranted':
           return this.item.additionalInformation.addedBy;
         default:
           return 'Unbekanntes Ereignis';
       }
     }
   }
}
</script>

<style lang="scss" scoped>
  .line {
    margin-left: 2rem;
    height: 40px;
    width: 2px;
    background-color: lightgrey;
  }
</style>