<template>
    <div class="clickable font--s14 font--w700 accentButton flex flex--center color noDec"
         :class="{
             'accentButton--secondary': secondary,
             'accentButton--filled': filled,
             'width--100': fullWidth,
             'accentButton--danger': danger,
             'accentButton--disabled': disable || !!disableCounter,
         }"
         @click="handleClick"
         @keyup.enter="handleClick">
        <transition name="fade" mode="out-in">
            <div v-if="!this.loading" key="title">
                {{ title }}
            </div>
            <div v-else class="absolute" key="loader">
                <loader :loading="true" :color="filled ? '#fff' : '#114B5F'" :size="'10px'"/>
            </div>
        </transition>
    </div>
</template>
<script>
    export default {
        name: 'AccentButton',
        props: ['title', 'filled', 'fullWidth', 'danger', 'disable', 'loading', 'secondary'],
        data() {
            return {
                disableCounter: 0
            }
        },
        methods: {
            handleClick(e) {
                if (!this.disable && !this.disableCounter) {
                    this.$emit('onClick', e)
                }
            },
            incValidateCounter() {
                this.disableCounter += 1
            },
            decValidateCounter() {
                this.disableCounter -= 1
            }
        }
    };
</script>


