<template>
    <div class="width--100 flex flex--column">
        <div class="flex width--100 pad--2 flex--center__vert relative">
            <input type="text" class="form--input__text mar--right__1" placeholder="Suchen..."/>
            <ActionButton :light="true" icon="ion-md-swap" :rotate="true" @onClick="toggleMainPopup"
                          v-click-outside="closeMainPopup"/>
            <transition name="fade">
                <div class="absolute popup--anchor" v-if="showMainPopup">
                    <PopUp>
                        <div class="popup--item pad--2 clickable font--s16">
                            Hinzugefügt am
                        </div>
                        <div class="popup--item pad--2 clickable font--s16">
                            Alphabetisch
                        </div>
                    </PopUp>
                </div>
            </transition>
        </div>
        <div class="pad--2">
            <NoDataAction v-if="!courses.instructors.length" @onButtonClick="$emit('addInstructor')"
                          text="Du hast noch keine Instruktoren erstellt." button-title="Jetzt Instruktor erstellen"
            />
            <div v-else class="members--list flex flex--column width--100 b-radius--5 overflowHidden">
                <div class="members--member grid border--bottom__light clickable"
                     @click.self="toggleEditInstructorModal(instructor)"
                     :key="instructor._id"
                     v-for="instructor in courses.instructors">
                    <div class="flex flex--center__vert pad--2"
                         @click.self="toggleEditInstructorModal(instructor)">
                        <i class="ion-md-contact mar--right__1 font--s25 lineHeight--1"/>
                        <div class="font--s14">
                            {{ instructor.firstName }} {{ instructor.lastName }}
                        </div>
                    </div>
                    <div class="flex flex--center__vert pad--2"
                         @click.self="toggleEditInstructorModal(instructor)">
                        <div class="font--s14">
                            {{ instructor.email }}
                        </div>
                    </div>
                    <div class="flex flex--center__vert flex--end__hor pad--1">
                        <ActionButton icon="ion-md-trash" :light="true" @onClick="toggleConfirmationModal(instructor)"/>
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <Modal title="Instruktordetails" @toggle="toggleEditInstructorModal" v-if="showEditInstructorModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <label for="instructor_email" class="form--label">Email</label>
                    <input type="text" id="instructor_email" class="form--input form--input__text"
                           v-model="instructor.email" v-focus/>
                    <label for="instructor_firstname" class="form--label">Vorname</label>
                    <input type="text" id="instructor_firstname" class="form--input form--input__text"
                           v-model="instructor.firstName"/>
                    <label for="instructor_lastname" class="form--label">Nachname</label>
                    <input type="text" id="instructor_lastname" class="form--input form--input__text mar--bottom__2"
                           v-model="instructor.lastName"/>
                    <label for="member_notes" class="form--label">Notizen</label>
                    <textarea id="member_notes" class="form--input form--input__textArea mar--bottom__2" rows="5"
                    v-model="instructor.notes"/>
                    <AccentButton :full-width="true" :filled="true" title="Änderungen speichern" @onClick="updateInstructor"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <ConfirmationModal title="Willst du diesen Instruktor wirklich löschen?" content="Alle Kurse werden deaktiviert."
                               @confirm="deleteInstructor" @reject="toggleConfirmationModal" v-if="showConfirmationModal"/>
        </transition>
    </div>
</template>
<script>
    import ActionButton from '@/components/reusable/1_Buttons/ActionButton';
    import PopUp from '@/components/reusable/2_Modals/PopUp';
    import Modal from '@/components/reusable/2_Modals/Modal';
    import AccentButton from '@/components/reusable/1_Buttons/AccentButton';
    import NoDataAction from "../../reusable/NoDataAction";
    import _ from 'lodash';
    import ConfirmationModal from '@/components/reusable/2_Modals/ConfirmationModal';

    export default {
        name: 'InstructorsList',
        components: {ConfirmationModal, AccentButton, Modal, PopUp, ActionButton, NoDataAction},
        data() {
            return {
                showEditInstructorModal: false,
                showMainPopup: false,
                showConfirmationModal: false,
                instructorEmail: null,
                instructorFirstName: null,
                instructorLastName: null,
                instructor: null,
            };
        },
        computed: {
            courses() {
                return this.$store.getters.getCourses;
            },
        },
        methods: {
            toggleEditInstructorModal(val) {
                if (val) {
                    this.instructor = _.cloneDeep(val);
                }
                this.showEditInstructorModal = !this.showEditInstructorModal;
            },
            toggleConfirmationModal(val) {
                if (val) {
                    this.instructor = _.cloneDeep(val);
                }
                this.showConfirmationModal = !this.showConfirmationModal;
            },
            toggleMainPopup() {
                this.showMainPopup = !this.showMainPopup;
            },
            closeMainPopup() {
                this.showMainPopup = false;
            },
            updateInstructor() {
                this.$store.dispatch('updateInstructor', {
                    organizationID: this.$route.params.organizationID,
                    instructorID: this.instructor._id,
                    instructor: {
                        firstName: this.instructor.firstName,
                        lastName: this.instructor.lastName,
                        email: this.instructor.email,
                        notes: this.instructor.notes,
                    },
                }).then(this.toggleEditInstructorModal)
            },
            deleteInstructor() {
                this.$store.dispatch('removeInstructor', {
                    organizationID: this.$route.params.organizationID,
                    instructorID: this.instructor._id,
                }).then(this.toggleConfirmationModal)
            },
        }
    };
</script>
