<template>
    <div class="paymentCard paymentCard__shine mar--bottom__2">
        <div class="paymentCard__card">
            <div class="font--w700 font--s2">{{ method.card.brand.toUpperCase() }}</div>
            <div class="paymentCard__actionField">
                <i
                        class="ion-md-create space--right__medium paymentCard__actionIcon">
                </i>
                <i class="ion-md-trash paymentCard__actionIcon"></i>
            </div>
        </div>
        <div class="paymentCard__number">
            <div class="paymentCard__numberField">
                <i class="ion-ios-medical"></i>
                <i class="ion-ios-medical"></i>
                <i class="ion-ios-medical"></i>
                <i class="ion-ios-medical"></i>
            </div>
            <div class="paymentCard__numberField">
                <i class="ion-ios-medical"></i>
                <i class="ion-ios-medical"></i>
                <i class="ion-ios-medical"></i>
                <i class="ion-ios-medical"></i>
            </div>
            <div class="paymentCard__numberField">
                <i class="ion-ios-medical"></i>
                <i class="ion-ios-medical"></i>
                <i class="ion-ios-medical"></i>
                <i class="ion-ios-medical"></i>
            </div>
            <div class="font-w700 font--s25">{{ method.card.last4 }}</div>
        </div>
        <div class="paymentCard__card">
            <div>{{ method.billing_details.name }}</div>
            <div>{{
                ('0' + method.card.exp_month).slice(method.card.exp_month.length - 2) }}
                /
                {{ method.card.exp_year.toString().slice(method.card.exp_year.toString().length - 2) }}</div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'CreditCard',
        props: ['method'],
    }
</script>
