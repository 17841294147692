<template>
    <div class="flex flex--column flex--center__vert overflowAuto width--100">
        <div class="course--wrapper">
            <ActionHeader back-title="Alle Vorlagen" title="Modern OKourse" @onBack="$router.push(`/dashboard/organizations/${$route.params.organizationID}/theme`)">
            </ActionHeader>
            <div class="card pad--2 width--100 height--100 flex flex--column themeGrid--full mar--bottom__2">
                <div class="font--s2 font--w700">
                    Farben
                </div>
                <div class="color--grey font--s14 font--center mar--bottom__1">
                    Die Primärfarbe wird für Knöpfe und wichtige Elemente wie den Emails verwendet. <br />
                    Die Akzentfarbe wird verwendet um überschriften und andere kleinere Elemente abzuheben.
                </div>
                <div class="flex flex--column flex--center flex--grow">
                    <div class="flex flex--center__vert mar--bottom__1">
                        <div class="font--s16 mar--right__1">
                            Primärfarbe:
                        </div>
                        <div class="colorPicker b-radius--5 border--light clickable" :style="{'backgroundColor': cacheTheme.primary}"
                             @click="toggleEditPrimary"/>
                    </div>
                    <div class="flex flex--center__vert">
                        <div class="font--s16 mar--right__1">
                            Akzentfarbe:
                        </div>
                        <div class="colorPicker b-radius--5 border--light clickable" :style="{'backgroundColor': cacheTheme.accent}"
                             @click="toggleEditAccent"/>
                    </div>
                </div>
            </div>
            <div class="grid themeGrid width--100 mar--bottom__2">
                <div class="card pad--2 width--100 height--100">
                    <div class="font--s2 font--w700">
                        Logo
                    </div>
                    <div class="pad--2 flex flex--column flex--center">
                        <UploadThumbnail id="course_img" v-if="!theme.bannerLogo.link" @file-uploaded="uploadLogo" aspect-ratio="1:1"/>
                        <img class="width--100 max--width__40 mar--bottom__2" v-if="theme.bannerLogo.link" :src="`${URL}/${theme.bannerLogo.link}`" />
                        <AccentButton title="Logo löschen" full-width="true" @onClick="removeLogo" v-if="theme.bannerLogo.link"/>
                    </div>
                </div>
                <div class="card pad--2 width--100 height--100">
                    <div class="font--s2 font--w700">
                        Favicon
                    </div>
                    <div class="pad--2 flex flex--column flex--center">
                        <div class="color--grey font--s14 font--center mar--bottom__1" v-if="!theme.faviconLogo.link">
                            Verwende am besten einen Favicon generator um ein kleines (16x16px o. 32x32px) Bild deines Logos zu erstellen.
                        </div>
                        <UploadThumbnail id="course_icon" v-if="!theme.faviconLogo.link" @file-uploaded="uploadFavicon" aspect-ratio="1:1"/>
                        <img class="max--width__40 height--3 mar--bottom__2" v-if="theme.faviconLogo.link" :src="`${URL}/${theme.faviconLogo.link}`" />
                        <AccentButton title="Favicon löschen" full-width="true" @onClick="removeFavicon" v-if="theme.faviconLogo.link"/>
                    </div>
                </div>
                <div class="card pad--2 width--100 height--100 themeGrid--full">
                    <div class="flex flex--space__hor mar--bottom__2">
                        <div class="font--s2 font--w700">
                            Aktionsleiste
                        </div>
                        <ActionButton icon="ion-md-add" accent="true" @onClick="toggleCreatAction"/>
                    </div>
                    <div class="flex flex--column flex--center">
                        <div class="font--s16" v-if="!cacheTheme.actions.length">
                            Du hast noch keine Aktionen definiert.
                        </div>
                        <div class="grid themeGrid--actions width--100 mar--bottom__2" v-for="action in cacheTheme.actions" :key="action._id">
                            <div class="font--s16 font--w700">
                                {{ action.title }}
                            </div>
                            <div class="font--s16">
                                {{ action.link }}
                            </div>
                            <div class="flex flex--end__hor">
                                <ActionButton light="true" icon="ion-md-trash" @onClick="updateThemeActions(action._id)"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card pad--2 width--100 height--100 themeGrid--full">
                  <div class="flex flex--space__hor mar--bottom__2">
                    <div class="font--s2 font--w700">
                      Bibliothekslayout
                    </div>
                  </div>
                  <div class="flex flex--column flex--center mar--bottom__1">
                    <v-select :clearable="false"
                              id="library_layout"
                              :options="layoutOptions"
                              label="label"
                              transition="fade"
                              class="width--100 flex--grow pad--right__1"
                              :value="layoutOptions.find((el) => el.value === cacheTheme.libraryLayout)"
                              @input="(el) => cacheTheme.libraryLayout = el.value"/>
                  </div>
                  <div class="flex flex--end__hor">
                    <AccentButton title="Speichern" :filled="true" :loading="isLoading" @onClick="updateLayout"/>
                  </div>
                </div>
                <div class="card pad--2 width--100 height--100 themeGrid--full">
                    <div class="flex flex--space__hor mar--bottom__2">
                        <div class="font--s2 font--w700">
                            Login Infotext
                        </div>
                    </div>
                  <div class="flex flex--column flex--center mar--bottom__1">
                    <textarea class="form--input form--input__textArea" v-model="cacheTheme.info" />
                  </div>
                    <div class="flex flex--end__hor">
                        <AccentButton title="Speichern" :filled="true" :loading="isLoading" @onClick="updateInfo"/>
                    </div>
                </div>
              <div class="card pad--2 width--100 height--100 themeGrid--full">
                <div class="flex flex--space__hor mar--bottom__2">
                  <div class="font--s2 font--w700">
                    Rechtstexte (Links)
                  </div>
                </div>
                <label for="lega_imprint" class="form--label">
                  Impressum
                </label>
                <div class="flex flex--column flex--center mar--bottom__1">
                  <input id="lega_imprint" type="text" class="form--input form--input__text" v-model="cacheTheme.legal.imprint" />
                </div>
                <label for="lega_data" class="form--label">
                  Datenschutzerklärung
                </label>
                <div class="flex flex--column flex--center mar--bottom__1">
                  <input id="lega_data" type="text" class="form--input form--input__text" v-model="cacheTheme.legal.privacy" />
                </div>
                <label for="legal_agb" class="form--label">
                  Geschäftsbedingungen
                </label>
                <div class="flex flex--column flex--center mar--bottom__1">
                  <input id="legal_agb" type="text" class="form--input form--input__text" v-model="cacheTheme.legal.termsOfUse" />
                </div>
                <div class="flex flex--end__hor">
                  <AccentButton title="Speichern" :filled="true" :loading="isLoading" @onClick="updateLegal"/>
                </div>
              </div>
            </div>
        </div>
        <transition name="fade">
            <Modal title="Neue Aktion" v-if="showCreateAction" @toggle="toggleCreatAction">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <label for="action_title" class="form--label">Titel</label>
                    <input class="form--input__text" id="action_title" v-model="actionTitle"/>
                    <label for="action_link" class="form--label">Link</label>
                    <input class="form--input__text mar--bottom__2" id="action_link" v-model="actionLink"/>
                    <AccentButton title="Aktion Hinzufügen" full-width="true" filled="true" @onClick="updateThemeActions(false)"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <Modal title="Primärfarbe" v-if="showEditPrimary" @toggle="toggleEditPrimary">
                <div class="pad--25 flex flex--grow flex--column flex--center">
                    <div class="mar--bottom__2">
                        <Chrome :value="cacheTheme.primary || '#ffffff'" @input="updatePrimary" />
                    </div>
                    <AccentButton title="Änderungen Speichern" @onClick="updateThemePrimary"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <Modal title="Akzentfarbe" v-if="showEditAccent" @toggle="toggleEditAccent">
                <div class="pad--25 flex flex--grow flex--column flex--center">
                    <div class="mar--bottom__2">
                        <Chrome :value="cacheTheme.accent || '#ffffff'" @input="updateAccent" />
                    </div>
                    <AccentButton title="Änderungen Speichern" @onClick="updateThemeAccent"/>
                </div>
            </Modal>
        </transition>
    </div>
</template>
<script>
    import ActionHeader from '@/components/views/4_Page/ActionHeader';
    import ActionButton from '@/components/reusable/1_Buttons/ActionButton';
    import UploadThumbnail from '@/components/reusable/3_Other/UploadThumbnail';
    import Modal from '@/components/reusable/2_Modals/Modal';
    import AccentButton from '@/components/reusable/1_Buttons/AccentButton';
    import vSelect from 'vue-select'
    import { Chrome } from 'vue-color';
    import _ from 'lodash';
    export default {
        name: 'CustomizeCourseTheme',
        components: {AccentButton, Modal, UploadThumbnail, ActionButton, ActionHeader, Chrome, vSelect},
        data() {
            return {
                isLoading: false,

                showCreateAction: false,
                showEditPrimary: false,
                showEditAccent: false,

                actionTitle: null,
                actionLink: null,

                selectedLayout: {'label': 'Raster', 'value': 'grid'},

                layoutOptions: [
                  {
                    'label': 'Raster',
                    'value': 'grid'
                  },
                  {
                    'label': 'Liste',
                    'value': 'list'
                  },
                ],

                cacheTheme: {
                    actions: [],
                    accent: '#ffffff',
                    primary: '#ffffff',
                    info: null,
                    libraryLayout: 'grid',
                    legal: {},
                }
            };
        },
        computed: {
            course() {
                return this.$store.getters.getCourseById(this.$route.params.courseID)
            },
            courses() {
                return this.$store.getters.getCourses;
            },
            theme() {
              /* eslint-disable */
              console.log(this.$store.getters.getTheme);
                return this.$store.getters.getTheme ||{bannerLogo: {}, faviconLogo: {}};
            },
            URL() {
                return this.$store.getters.getURL;
            }
        },
        methods: {
            toggleCreatAction() {
                this.showCreateAction = !this.showCreateAction;
            },
            toggleEditPrimary() {
                this.showEditPrimary = !this.showEditPrimary;
            },
            toggleEditAccent() {
                this.showEditAccent = !this.showEditAccent;
            },
            updateThemePrimary() {
                this.$store.dispatch('updateTheme', {
                    organizationID: this.$route.params.organizationID,
                    theme: {
                        primary: this.cacheTheme.primary
                    },
                }).then(() => {
                    this.showEditPrimary = false;
                });
            },
            updateThemeAccent() {
                this.$store.dispatch('updateTheme', {
                    organizationID: this.$route.params.organizationID,
                    theme: {
                        accent: this.cacheTheme.accent
                    },
                }).then(() => {
                    this.showEditAccent = false;
                });
            },

          updateLayout() {
            this.isLoading = true;
            let success = true;
            this.$store.dispatch('updateTheme', {
              organizationID: this.$route.params.organizationID,
              theme: {
                libraryLayout: this.cacheTheme.libraryLayout
              },
            }).catch(() => {
              success = false;
            }).then(() => {
              if (success) {
                this.$store.commit('addToast', {
                  type: 'success',
                  title: 'Layout erfolgreich gespeichert'
                });
              } else {
                this.$store.commit('addToast', {
                  type: 'warning',
                  title: 'LAyout konnte nicht gespeichert werden',
                });
              }
              this.isLoading = false;
            });
          },
            updateInfo() {
                this.isLoading = true;
                let success = true;
                this.$store.dispatch('updateTheme', {
                    organizationID: this.$route.params.organizationID,
                    theme: {
                        info: this.cacheTheme.info
                    },
                }).catch(() => {
                    success = false;
                }).then(() => {
                    if (success) {
                        this.$store.commit('addToast', {
                            type: 'success',
                            title: 'Infotext erfolgreich gespeichert'
                        });
                    } else {
                        this.$store.commit('addToast', {
                            type: 'warning',
                            title: 'Infotext konnte nicht gespeichert werden',
                        });
                    }
                    this.isLoading = false;
                });
            },
          updateLegal() {
            this.isLoading = true;
            let success = true;
            this.$store.dispatch('updateTheme', {
              organizationID: this.$route.params.organizationID,
              theme: {
                legal: this.cacheTheme.legal
              },
            }).catch(() => {
              success = false;
            }).then(() => {
              if (success) {
                this.$store.commit('addToast', {
                  type: 'success',
                  title: 'Rechtstexte erfolgreich gespeichert'
                });
              } else {
                this.$store.commit('addToast', {
                  type: 'warning',
                  title: 'Rechtstexte konnte nicht gespeichert werden',
                });
              }
              this.isLoading = false;
            });
          },
            uploadLogo(files) {
                const file = files[0];
                const formData = new FormData();
                formData.append('File', file, file.name);
                this.$store.dispatch('uploadLogo', {
                    organizationID: this.$route.params.organizationID,
                    logo: formData,
                })
            },
            uploadFavicon(files) {
                const file = files[0];
                const formData = new FormData();
                formData.append('File', file, file.name);
                this.$store.dispatch('uploadFavicon', {
                    organizationID: this.$route.params.organizationID,
                    logo: formData,
                })
            },
            removeLogo() {
                this.$store.dispatch('removeLogo', this.$route.params.organizationID);
            },
            removeFavicon() {
                this.$store.dispatch('removeFavicon', this.$route.params.organizationID);
            },
            updateThemeActions(id) {
                if (id) {
                    this.cacheTheme.actions = this.cacheTheme.actions.filter(el => el._id !== id);
                } else {
                    this.cacheTheme.actions.push({
                        title: this.actionTitle,
                        link: this.actionLink,
                    });
                }
                const cleaned = _.cloneDeep(this.cacheTheme.actions).map((el) => {
                    return {
                        title: el.title,
                        link: el.link,
                    };
                });
                this.$store.dispatch('updateTheme', {
                    organizationID: this.$route.params.organizationID,
                    theme: {
                        actions: cleaned
                    },
                }).then(() => {
                    this.showCreateAction = false;
                });
            },
            updatePrimary(val) {
                this.cacheTheme.primary = val.hex;
            },
            updateAccent(val) {
                this.cacheTheme.accent = val.hex;
            },
        },
        watch: {
            theme(newVal) {
                this.cacheTheme = { ...this.cacheTheme, ...newVal };
            },
        },
        mounted() {
            this.$store.dispatch('retrieveTheme', this.$route.params.organizationID);
        }
    };
</script>
