<template>
    <div class="flex flex--column flex--center__vert overflowAuto">
        <div class="courses--wrapper">
            <div class="flex flex--center__vert lineHeight--1 font--s2 clickable" @click="$router.push(`/dashboard/organizations/${$route.params.organizationID}/settings`)">
                <i class="ion-ios-arrow-back mar--right__1 pad--left__1" />
                <div>
                    Alles Kurse
                </div>
            </div>
            <div class="flex flex--space__hor flex--center__vert courses--title pad--1 mar--bottom__2">
                <div class="font--s3 font--w700 lineHeight--12">
                    Instruktoren
                </div>
                <div class="flex relative">
                    <div class="mar--right__1">
                        <ActionButton icon="ion-md-add" :accent="true" @onClick="toggleCreateInstructorModal" />
                    </div>
                </div>
            </div>
            <InstructorsList @addInstructor="toggleCreateInstructorModal"/>
        </div>
        <transition name="fade">
            <Modal title="Neuer Instruktor" @toggle="toggleCreateInstructorModal" v-if="showCreateInstructorModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <label for="instructor_email" class="form--label">Email</label>
                    <input type="text" id="instructor_email" class="form--input form--input__text" v-model="instructorEmail"/>
                    <label for="instructor_firstname" class="form--label">Vorname</label>
                    <input type="text" id="instructor_firstname" class="form--input form--input__text" v-model="instructorFirstName"/>
                    <label for="instructor_lastname" class="form--label">Nachname</label>
                    <input type="text" id="instructor_lastname" class="form--input form--input__text mar--bottom__2" v-model="instructorLastName"/>
                    <AccentButton :full-width="true" :filled="true" title="Instruktor erstellen" @onClick="createInstructor"/>
                </div>
            </Modal>
        </transition>
    </div>
</template>
<script>
    import Modal from '@/components/reusable/2_Modals/Modal';
    import AccentButton from '@/components/reusable/1_Buttons/AccentButton';
    import ActionButton from '@/components/reusable/1_Buttons/ActionButton';
    import InstructorsList from '@/components/views/7_Instructors/InstrcutorsList';
    export default {
        name: 'InstructorsLoader',
        components: {InstructorsList, ActionButton, AccentButton, Modal},
        data() {
            return {
                showCreateInstructorModal: false,
                instructorEmail: null,
                instructorFirstName: null,
                instructorLastName: null,
            };
        },
        methods: {
            forward(url) {
                this.$router.push(`${this.$route.path}/${url}`);
            },
            toggleCreateInstructorModal() {
                this.showCreateInstructorModal = !this.showCreateInstructorModal;
            },
            createInstructor() {
                this.$store.dispatch('createInstructor', {
                    organizationID: this.$route.params.organizationID,
                    instructor: {
                        email: this.instructorEmail,
                        firstName: this.instructorFirstName,
                        lastName: this.instructorLastName,
                    },
                }).then(() => {
                    this.toggleCreateInstructorModal();
                    this.instructorEmail = null;
                    this.instructorFirstName = null;
                    this.instructorLastName = null;
                });
            },
        },
        mounted() {
            this.$store.dispatch('retrieveCourses', this.$route.params.organizationID);
        }
    };
</script>
