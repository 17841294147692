<template>
    <div class="pad--1 mar--bottom__1">
        <NoDataAction v-if="!chapters.length" @onButtonClick="addChapter"
                      text="Du hast noch keine Kapitel erstellt." button-title="Jetzt Kapitel erstellen"
        />
        <div v-else class="course--structure pad--2">
            <div class="flex flex--center__vert flex--space__hor pad--1">
                <div class="font--s2">Kapitel</div>
                <div class="flex">
                    <ActionButton class="mar--right__1" icon="ion-md-add" :accent="true" @onClick="addChapter"
                                  v-if="organization.role === 'Creator' || organization.role === 'Editor'"/>
                    <ActionButton @onClick="toggleExpandAllChapters" light="true"
                                  :icon="isAnyChapterExpanded ? 'ion-md-contract' : 'ion-md-expand'"
                    />
                </div>
            </div>
            <transition-group class="course--structure__grid grid pad--1" v-if="chapters.length">
                <Chapter :dragged-el="draggedEl"
                         :id="chapter._id"
                         :index="ind"
                         @addPage="(id) => $emit('addPage', id)"
                         @editPage="(el) => forward(`chapters/${chapter._id}/pages/${el}`)"
                         @editChapter="$emit('editChapter', chapter)"
                         @deleteChapter="$emit('deleteChapter', chapter._id)"
                         @newChapterPosition="setNewChapterPosition(ind)"
                         @newPagePosition="(el) => setNewPagePosition(el, ind)"
                         @dragChapter="handleDragChapter(chapter, ind)"
                         @dragPage="(el) => handleDragPage(el, ind)"
                         @onDragEnd="handleDragEnd"
                         v-for="(chapter, ind) in chapters"
                         :key="chapter._id"
                         :pages="chapter.pages"
                         :title="chapter.title"
                         :timeToAccess="chapter.timeToAccess"
                         :oldParent="oldParent"
                />
            </transition-group>
            <div class="flex flex--center font--s2 pad--2 color--grey" v-if="!chapters.length">
                Du hast noch keine Kapitel erstellt.
            </div>
        </div>
    </div>
</template>
<script>
    import Chapter from '@/components/reusable/3_Other/Chapter';
    import {reorderArray, transformMovePageOrChapterRequest} from '@/services/helpers';
    import ActionButton from '@/components/reusable/1_Buttons/ActionButton';
    import _ from 'lodash';
    import NoDataAction from "../../reusable/NoDataAction";

    export default {
        name: 'CourseStructure',
        components: {ActionButton, Chapter, NoDataAction},
        data() {
            return {
                newChapterPosition: null,
                oldParent: null,
                draggedEl: null,
                draggedInd: null,
                newPosition: null,

            };
        },
        computed: {
            chapters() {
                return this.$store.getters.getCourseDetails || [];
            },
            courses() {
                return this.$store.getters.getCourses || [];
            },
            course() {
                return this.$store.getters.getCourseById(this.$route.params.courseID)
            },
            isAnyChapterExpanded() {
                return this.$store.getters.isAnyChapterExpanded
            },
            organization() {
                return this.$store.getters.getOrganizationById(this.$route.params.organizationID)
            }
        },
        methods: {
            addChapter() {
                this.$emit('addChapter')
            },
            toggleExpandAllChapters() {
                this.$store.commit('toggleExpandAllChapters')
            },
            forward(url) {
                this.$router.push(`${this.$route.path}/${url}`);
            },
            setNewChapterPosition(val) {
                if (val !== this.draggedInd && this.draggedEl.type === 'Chapter') {
                    this.$store.commit('setCourseDetails', reorderArray(_.cloneDeep(this.chapters), this.draggedInd, val));
                    this.draggedInd = val;
                }
            },
            setNewPagePosition(val, chapter) {
                if (this.draggedEl.type === 'Page') {
                    let newChapters = _.cloneDeep(this.chapters);
                    if (this.oldParent !== chapter) {
                        newChapters[this.oldParent].pages = newChapters[this.oldParent].pages.filter((el) => el._id !== this.draggedEl._id);
                        if (!newChapters[chapter].pages.length) {
                            newChapters[chapter].pages.push(this.draggedEl);
                        } else {
                            newChapters[chapter].pages.splice(val, 0, this.draggedEl);
                        }
                        this.oldParent = chapter;
                        this.draggedInd = val;
                    } else {
                        newChapters[chapter].pages = reorderArray(newChapters[chapter].pages.slice(), this.draggedInd, val);
                        this.draggedInd = val;
                    }
                    this.$store.commit('setCourseDetails', newChapters);
                }
            },
            handleDragChapter(chapter, ind) {
                this.draggedInd = ind;
                this.draggedEl = chapter;
            },
            handleDragPage(page, ind) {
                this.draggedInd = page.ind;
                this.draggedEl = page.page;
                this.oldParent = ind;
            },
            handleDragEnd() {
                this.draggedEl = null;
                this.draggedInd = null;
                this.oldParent = null;
                this.updateChapterOrder();
            },

            updateChapterOrder: _.throttle(function () {
                this.$store.dispatch('updateChapterOrder', {
                    organizationID: this.$route.params.organizationID,
                    courseID: this.$route.params.courseID,
                    chapters: transformMovePageOrChapterRequest(_.cloneDeep(this.chapters)),
                });
            }, 1000, {trailing: false}),
        },
    };
</script>
