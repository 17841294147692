import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ClickOutside from 'vue-click-outside';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import VueQuillEditor from 'vue-quill-editor'
import Toast from '@/components/reusable/2_Modals/Toast';
import {focus, required} from '@/services/directives.js'
import Vuelidate from 'vuelidate';
import moment from 'moment';
import 'video.js/dist/video-js.css'
// import VueMatomo from 'vue-matomo'
import VueAnalytics from 'vue-analytics';

Vue.config.productionTip = false;


Vue.use(VueQuillEditor, /* { default global options } */);
Vue.use(Vuelidate);
// Vue.use(VueMatomo, {
//     router: router,
//     host: 'https://analytics.neon.dev',
//     siteId: 3,
// });
Vue.use(VueAnalytics, {
    id: 'UA-184396070-2',
    router
});

Vue.directive('ClickOutside', ClickOutside);
Vue.directive('focus', focus);
Vue.directive('required', required);

Vue.component('loader', PulseLoader);
Vue.component('toast', Toast);

Vue.prototype.moment = moment;

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
