<template>
    <div class="flex flex--column flex--center__vert overflowAuto">
        <div class="courses--wrapper">
            <div class="flex flex--center__vert lineHeight--1 font--s2 clickable"
                 @click="$route.path.split('/')[4] === 'members' ? $router.push(`/dashboard/organizations/${$route.params.organizationID}/settings`) : $router.push(`/dashboard/organizations/${$route.params.organizationID}/courses/${$route.params.courseID}`)">
                <i class="ion-ios-arrow-back mar--right__1 pad--left__1" />
                <div>
                    {{ $route.path.split('/')[4] === 'members' ? 'Alle Kurse' : 'Kursübersicht'}}
                </div>
            </div>
            <div class="flex flex--space__hor flex--center__vert courses--title pad--1 mar--bottom__2">
                <div class="flex flex--center__vert">
                    <div class="font--s3 font--w700 lineHeight--12 mar--right__2">
                        Kursmitglieder
                    </div>
                    <div class="font--s2 color--grey lineHeight--12">
                        {{ title }}
                    </div>
                </div>
                <div class="flex relative">
<!--                    <div class="mar&#45;&#45;right__1" v-if="$route.path.split('/')[4] !== 'members'">-->
<!--                        <ActionButton icon="ion-md-cloud-upload" :light="true" @onClick="toggleImportMemberModal"-->
<!--                                      v-if="organization.role === 'Creator' || organization.role === 'Editor'"/>-->
<!--                    </div>-->
                    <div class="mar--right__1">
                        <ActionButton icon="ion-md-add" :accent="true" @onClick="toggleCreateMemberModal" />
                    </div>
                </div>
            </div>
            <MemberList />
        </div>
        <transition name="fade">
            <Modal title="Neuer Nutzer" @toggle="toggleCreateMemberModal" v-if="showCreateMemberModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <label for="member_firstName" class="form--label">Vorname</label>
                    <input type="text" id="member_firstName" class="form--input form--input__text" v-model="memberFirstName" v-focus/>
                    <label for="member_lastName" class="form--label">Nachname</label>
                    <input type="text" id="member_lastName" class="form--input form--input__text" v-model="memberLastName"/>
                    <label for="member_email" class="form--label">Email</label>
                    <input type="text" id="member_email" class="form--input form--input__text mar--bottom__2" v-model="memberEmail"/>
                    <AccentButton :full-width="true" :filled="true" :loading="loading" title="Nutzer erstellen" @onClick="createMember"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <Modal title="Nutzerliste importieren" @toggle="toggleImportMemberModal" v-if="showImportMemberModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <label for="member_list" class="form--label">Nutzerliste (csv)</label>
                    <div class="font--s14 color--grey">
                        firstName;lastName;email <br />
                        Max;Mustermann;max.mustermann@gmail.com <br />
                    </div>
                    <UploadThumbnail id="member_list" @file-uploaded="cacheList" :cached-thumb-name="cachedListName" :file="true"/>
                    <label for="list_delimiter" class="form--label">Separator</label>
                    <div class="font--s14 color--grey">
                        Der Separator ist das Symbol welches in der CSV-Datei die Informationen von einander trennt.
                    </div>
                    <input maxlength="1" type="text" id="list_delimiter" class="form--input form--input__text mar--bottom__2" v-model="delimiter"/>
                    <div class="flex flex--space__hor">
                        <div class="form--label">Kurs Willkommensnachricht</div>
                        <div class="checkbox circle border--light clickable mar--right__1 flex flex--center"
                             @click="() => { customMail = !customMail; $forceUpdate(); }"
                             :class="{'checkbox--filled': customMail}">
                            <i class="ion-ios-checkmark color--white font--s2 lineHeight--1" v-if="customMail"/>
                        </div>
                    </div>
                    <div class="font--s14 mar--bottom__2 color--grey">
                        Wenn Kurs Willkommensnachrichten eingeschaltet sind, bekommen die Nutzer für jeden Kurs eine separate Willkommensmail.
                    </div>
                    <label for="mail_subject" class="form--label">E-Mail Betreff</label>
                    <input type="text" id="mail_subject" class="form--input form--input__text mar--bottom__2" v-model="mailSubject"/>
                    <label for="mail" class="form--label">E-Mail (Html)</label>
                    <div class="font--s14 color--grey">
                        {{ htmlRequirements }}
                    </div>
                    <div class="mar--bottom__2 width--max__50vw overflowAuto">
                        <quill-editor ref="myTextEditor"
                                      id="mail"
                                      class="max--height__50vh"
                                      v-model="mail"
                                      :options="editorOptions"
                        />
                    </div>
                    <AccentButton :full-width="true" :filled="true" :loading="loading" title="Nutzer hochladen" @onClick="uploadList"/>
                </div>
            </Modal>
        </transition>
    </div>
</template>
<script>
    import Modal from '@/components/reusable/2_Modals/Modal';
    import AccentButton from '@/components/reusable/1_Buttons/AccentButton';
    import ActionButton from '@/components/reusable/1_Buttons/ActionButton';
    import MemberList from '@/components/views/5_Members/MembersList';
    import _ from "lodash";
    import UploadThumbnail from "@/components/reusable/3_Other/UploadThumbnail";
    export default {
        name: 'CoursesOverview',
        components: {UploadThumbnail, MemberList, ActionButton, AccentButton, Modal},
        data() {
            return {
                showCreateMemberModal: false,
                showImportMemberModal: false,

                htmlRequirements: 'Verpflichtend: {{password}}, Optional: {{firstName}} und {{lastName}}',

                cachedList: new FormData(),
                cachedListName: null,
                cachedMailName: null,
                delimiter: null,
                customMail: false,
                mailSubject: null,
                mail: '',
                editorOptions: {
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline', 'blockquote'],
                            [{'header': 1}, {'header': 2}],
                            [{'list': 'ordered'}, {'list': 'bullet'}],
                            [{'script': 'sub'}, {'script': 'super'}],
                            ['clean', 'link'],
                        ]
                    }
                },

                memberFirstName: null,
                memberLastName: null,
                memberEmail: null,
                memberPassword: null,

                loading: false,
            };
        },
        computed: {
            courses() {
                return _.cloneDeep(this.$store.getters.getCourses.courses);
            },
            title() {
                if (this.$route.params.courseID != null && this.courses.length) {
                    let course = this.courses.find((el) => el._id === this.$route.params.courseID);
                    return course ? course.title : 'Alle Kurse';
                }
                return 'Alle Kurse';
            },
            organization() {
                return this.$store.getters.getOrganizationById(this.$route.params.organizationID)
            }
        },
        methods: {
            forward(url) {
                this.$router.push(`${this.$route.path}/${url}`);
            },
            toggleCreateMemberModal() {
                this.showCreateMemberModal = !this.showCreateMemberModal;
            },
            toggleImportMemberModal() {
                this.showImportMemberModal = !this.showImportMemberModal;
            },
            createMember() {
                this.loading = true;
                let success = true;
                this.$store.dispatch('createMember', {
                    organizationID: this.$route.params.organizationID,
                    member: {
                        email: this.memberEmail,
                        firstName: this.memberFirstName,
                        lastName: this.memberLastName,
                    },
                }).catch(() => {
                    success = false;
                    this.$store.commit('addToast', {
                        type: 'warning',
                        title: 'Bitte überprüfen sie ihre Eingaben.',
                    });
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000);
                }).then(() => {
                    if (success) {
                        this.toggleCreateMemberModal();
                        this.memberFirstName = null;
                        this.memberLastName = null;
                        this.memberEmail = null;
                        this.memberPassword = null;
                        this.loading = false;
                    }
                });
            },
            cacheList(fileList) {
                const formData = new FormData();
                formData.append('File', fileList[0], fileList[0].name);
                this.cachedListName = fileList[0].name;
                this.cachedList = formData;
            },
            async uploadList() {
                if (!this.cachedList) {
                    this.$store.commit('addToast', {
                        type: 'warning',
                        title: 'Bitte lade ein gültiges CSV hoch.'
                    });
                    return;
                }
                if (!this.mailSubject || this.mailSubject === '') {
                    this.$store.commit('addToast', {
                        type: 'warning',
                        title: 'Bitte gib einen Betreff ein.'
                    });
                    return;
                }
                if (!this.mail || !this.mail.includes('{{password}}')) {
                    this.$store.commit('addToast', {
                        type: 'warning',
                        title: 'Bitte lade ein gültiges Html hoch.'
                    });
                    return;
                }
                /* eslint-disable */
                console.log(this.mail);
                this.cachedList.append('emailTemplate', this.mail);
                this.cachedList.append('subject', this.mailSubject);
                this.cachedList.append('sendUserEmailCourseAdded', this.customMail);
                this.cachedList.append('delimiter', this.delimiter);
                await this.$store.dispatch('importMembers', {
                    organizationID: this.$route.params.organizationID,
                    courseID: this.$route.params.courseID,
                    data: this.cachedList,
                });
                this.cachedList = null;
                this.cachedListName = null;
                this.subject = null;
                this.delimiter = null;
            },
        },
        async mounted() {
            await this.$store.dispatch('retrieveOrganizations');
        }
    };
</script>
