export default {
    setAuthenticated(state) {
      state.isAuthenticated = true
    },
    setToken(state, token) {
        state.token = token;
    },
    setErrorCodes(state, errorCodes) {
        state.errorCodes = errorCodes
    },
    setUser(state, user) {
        state.user = user;
    },
    setDetailedMember(state, member) {
        state.detailedMember = member;
    },
    setCustomerPortal(state, url) {
        state.customerPortal = url;
    },
    setOrganizations(state, organizations) {
        state.organizations = organizations;
    },
    setMethods(state, methods) {
        state.methods = methods;
    },
    setSubscriptions(state, subscriptions) {
        state.subscriptions = subscriptions;
    },
    setBillingAddress(state, address) {
        state.billingAddress = address;
    },
    setCourses(state, courses) {
        state.courses = courses;
    },
    setCourseDetails(state, details) {
        state.courseDetails = details;
    },
    setMembers(state, members) {
        state.members = members;
    },
    setMemberCount(state, count) {
        state.memberCount = count;
    },
    setEditors(state, editors) {
        state.editors = editors;
    },
    setApiTokens(state, tokens) {
        state.apiTokens = tokens;
    },
    setTheme(state, theme) {
        state.theme = theme;
    },
    setCertificate(state, certificate) {
        state.certificate = certificate;
    },
    setCertificateDetails(state, details) {
        state.certificateDetails = details;
    },
    addToast(state, toast) {
        state.toasts.push({
            id: Date.now().toString(),
            type: toast.type,
            title: toast.title,
        });
    },
    deleteToast(state, toastID) {
        state.toasts = state.toasts.filter(el => el.id !== toastID);
    },
    setPlans(state, plans) {
        state.plans = plans;
    },
    setInvoices(state, invoices) {
        state.invoices = invoices;
    },
    setOffers(state, offers) {
        state.offers = offers;
    },
    setWebhooks(state, webhooks) {
        state.webhooks = webhooks;
    },
    setEventTypes(state, types) {
        state.eventTypes = types;
    },
    setLogs(state, logs) {
        state.logs = logs;
    },

    toggleExpandChapter(state, {chapterID, expand}) {
        const index = state.expandedChapters.findIndex(id => id === chapterID)
        if (index === -1) { // is not Expanded
            state.expandedChapters.push(chapterID)
        } else if (!expand){
            state.expandedChapters.splice(index, 1)
        }
    },
    toggleExpandAllChapters(state) {
        if (state.expandedChapters.length > 0) { // at least one Expanded
            state.expandedChapters = []
        } else {
            state.expandedChapters = state.courseDetails.map(el => el._id)
        }
    }
}
