<template>
    <div class="flex flex--column flex--center__vert overflowAuto width--100">
        <div class="course--wrapper">
            <ActionHeader :back-title="course.title" title="Startseite bearbeiten"
                          @onBack="$router.push(`/dashboard/organizations/${$route.params.organizationID}/courses/${$route.params.courseID}`)">
                <accent-button class="margin" @onClick="updateCourse" filled="true" title="Speichern" :loading="loading" />
            </ActionHeader>
            <div class="form--label">Banner</div>
            <div class="flex flex--column flex--center__vert" v-if="course.headerImage.link">
                <div class="max--width__40">
                    <RawImage :link="course.headerImage.link"/>
                </div>
                <div class="max--width__40">
                    <AccentButton title="Banner Löschen" danger="true" @onClick="deleteBanner"  v-if="course.headerImage.link"/>
                </div>
            </div>
            <div class="mar--bottom__2"  v-if="!course.headerImage.link">
                <UploadThumbnail @file-uploaded="cacheThumb" :cached-thumb-name="cachedThumbName" aspect-ratio="16:9"/>
            </div>
            <div class="flex flex--column width--100">
                <label for="welcome_title" class="form--label">Willkommenstitel</label>
                <input id="welcome_title" class="form--input__text mar--bottom__2" v-model="welcomeTitle"/>
                <div class="form--label">Willommenstext</div>
                <div class="mar--bottom__1">
                    <quill-editor ref="myTextEditor"
                                  v-model="welcomeDescription"
                                  :options="editorOptions"
                    />
                </div>
<!--                <textarea id="welcome_text" class="form&#45;&#45;input__textArea height&#45;&#45;10 mar&#45;&#45;bottom__2" v-model="welcomeDescription"/>-->
                <div class="flex flex--space__hor">
                    <div class="form--label">Werbetafeln</div>
                    <ActionButton accent="true" icon="ion-md-add" @onClick="showUploadModal = true"/>
                </div>
                <div v-if="course.linkElements && course.linkElements.length" class="grid itemGrid pad--2">
                    <div class="itemGrid--slot flex flex--center" v-for="ad in course.linkElements" :key="ad.link">
                        <div class="card card--move width--100 height--100 clickable itemGrid--inner grid">
                            <div class="width--100 height--100 max--height__50vh overflowHidden relative flex flex--center">
                                <RawImage :link="ad.thumbnail.link" class="width--100"/>
                            </div>
                            <div class="flex flex--column flex--space__hor pad--2">
                              <div>
                                <div class="font--s2 font--w700 mar--bottom__1" v-if="ad.link && ad.link !== ''">
                                  {{ ad.link }}
                                </div><div class="font--s14 font--w700 mar--bottom__2" v-if="ad.description && ad.description !== ''">
                                {{ ad.description }}
                              </div>
                              </div>


                                <AccentButton danger="true" title="Tafel löschen" @onClick="deleteAd(ad._id)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mar--top__2" />
        </div>
        <transition name="fade">
            <modal v-if="showUploadModal" title="Werbetafel hinzufügen" @toggle="showUploadModal = false">
                <div class="flex flex--column width--100 pad--2">
                    <div class="">
                        <UploadThumbnail @file-uploaded="cacheAd" :cached-thumb-name="cachedAdName" aspect-ratio="1:1"/>
                    </div>
                    <label for="ad_link" class="form--label">Link</label>
                    <input id="ad_link" class="form--input form--input__text mar--bottom__2" v-model="adLink" placeholder="https://www.google.com" />
                  <label for="ad_description" class="form--label">Text</label>
                  <input id="ad_description" class="form--input form--input__text mar--bottom__2" v-model="adDescription" />
                    <AccentButton title="Hinzufügen" :disable="!cachedAd " @onClick="uploadAd" filled="true" :loading="loading"/>
                </div>
            </modal>
        </transition>
    </div>
</template>
<script>
    import ActionHeader from '@/components/views/4_Page/ActionHeader';
    import UploadThumbnail from '@/components/reusable/3_Other/UploadThumbnail';
    import ActionButton from '@/components/reusable/1_Buttons/ActionButton';
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    import RawImage from "@/components/reusable/3_Other/RawImage";
    import Modal from "@/components/reusable/2_Modals/Modal";

    export default {
        name: 'CourseWelcome',
        components: {Modal, RawImage, AccentButton, ActionButton, UploadThumbnail, ActionHeader},
        data() {
            return {
                loading: false,

                welcomeTitle: null,
                welcomeDescription: null,
                editorOptions: EDITOR_OPTIONS,

                cachedThumb: null,
                cachedThumbName: null,

                cachedAd: null,
                cachedAdName: null,

                adLink: null,
                adDescription: null,

                showUploadModal: false,
            };
        },
        computed: {
            course() {
                return this.$store.getters.getCourseById(this.$route.params.courseID)
            },
            courses() {
                return this.$store.getters.getCourses;
            },
        },
        methods: {
            async updateCourse() {
                this.loading = true;
                await this.$store.dispatch('updateCourse', {
                    organizationID: this.$route.params.organizationID,
                    courseID: this.$route.params.courseID,
                    course: {
                        welcomeText: this.welcomeDescription,
                        welcomeTitle: this.welcomeTitle,
                    },
                }).then(async () => {
                   if (this.cachedThumb) {
                       await this.uploadThumb();
                   }
                });
                this.loading = false;
                this.$router.go(-1);
            },
            cacheThumb(fileList) {
                const formData = new FormData();
                formData.append('File', fileList[0], fileList[0].name);
                this.cachedThumb = formData;
                this.cachedThumbName = fileList[0].name;
            },
            async uploadThumb() {
                await this.$store.dispatch('uploadCourseHeader', {
                    organizationID: this.$route.params.organizationID,
                    courseID: this.$route.params.courseID,
                    header: this.cachedThumb,
                });
                this.cachedThumb = null;
                this.cachedThumbName = null;
            },
            cacheAd(fileList) {
                const formData = new FormData();
                formData.append('File', fileList[0], fileList[0].name);
                this.cachedAd = formData;
                this.cachedAdName = fileList[0].name;
            },
            async uploadAd() {
                this.loading = true;
                this.cachedAd.append('link', `${this.adLink}`);
                if (this.adDescription && this.adDescription !== '') {
                  this.cachedAd.append('description', `${this.adDescription}`);
                }
                await this.$store.dispatch('uploadCourseAd', {
                    organizationID: this.$route.params.organizationID,
                    courseID: this.$route.params.courseID,
                    ad: this.cachedAd,
                });
                this.loading = false;
                this.cachedAd = null;
                this.cachedAdName = null;
                this.adLink = null;
                this.adDescription = null;
                this.showUploadModal = false;
            },
            deleteBanner() {
                this.$store.dispatch('deleteCourseHeader', {
                    organizationID: this.$route.params.organizationID,
                    courseID: this.$route.params.courseID,
                });
            },
            deleteAd(id) {
                this.$store.dispatch('deleteCourseAd', {
                    organizationID: this.$route.params.organizationID,
                    courseID: this.$route.params.courseID,
                    adID: id,
                });
            },
        },
        watch: {
            course(newVal) {
                if (newVal) {
                    this.welcomeDescription = newVal.welcomeText;
                    this.welcomeTitle = newVal.welcomeTitle;
                }
            },
        },
        async mounted() {
            await this.$store.dispatch('retrieveCourses', this.$route.params.organizationID);
            this.welcomeDescription = this.course.welcomeText;
            this.welcomeTitle = this.course.welcomeTitle;
        }
    };


    const EDITOR_OPTIONS = {
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'blockquote'],
                [{'header': 1}, {'header': 2}],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],
                ['clean', 'link'],
            ]
        }
    }
</script>
