<template>
    <div :class="{'width--100': fullWidth, 'iconButton--filled': filled, 'iconButton--disabled': disable}"
         @click="disable ? null : $emit('onClick')"
         class="clickable font--w700 iconButton flex flex--center">
        <transition name="fade" mode="out-in">
            <div class="flex flex--center" :key="'content'" v-if="!loading">
                <i :class="icon" class="mar--right__1 font--s2 lineHeight--1"/>
                {{ title }}
            </div>
            <div class="absolute" :key="'loader'" v-if="loading">
                <loader :loading="true" color="#fff" :size="'10px'"/>
            </div>
        </transition>
    </div>
</template>
<script>
    export default {
        name: 'IconButton',
        props: ['title', 'icon', 'fullWidth', 'filled', 'loading', 'disable']
    }
</script>
