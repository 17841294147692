<template>
  <div class="modal fixed flex flex--center" >
    <div class="modal--backdrop fixed opacity--5 flex flex--center width--100 height--100" @click.self="toggleBackdrop"/>
    <div class="modal--container__small b-radius--5 flex flex--column">
      <div class="pad--25 flex flex--grow flex--column flex--center__hor">
        <div class="font--s2 font--w700 mar--bottom__2">{{ title }}</div>
        <div class="font--s16 mar--bottom__2">{{ content }}</div>
        <div class="flex">
          <AccentButton title="Nein" @onClick="$emit('reject')" :full-width="true" />
          <AccentButton :danger="true" title="Ja" @onClick="$emit('confirm')" :full-width="true" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import AccentButton from '@/components/reusable/1_Buttons/AccentButton';
  export default {
    name: 'ConfirmationModal',
    components: {AccentButton},
    props: ['title', 'content'],
    computed: {
    },
    methods: {
      toggleBackdrop() {
        this.$emit('toggle');
      },
    },
  };
</script>
