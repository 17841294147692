<template>
    <div class="header fixed flex flex--center">
        <div class="header--wrapper relative flex flex--center__vert flex--space__hor">
            <img class="height--2 clickable" src="../../../assets/SVG4.svg" @click="$router.push('/dashboard')" />
            <i class="ion-md-contact clickable font--s5 color--white" @click="toggleMainPopup" v-click-outside="closeMainPopup"/>
        </div>
        <transition name="fade">
            <div class="absolute popup--anchor" v-if="showMainPopup">
                <PopUp :posX="posX" :posY="posY">
                    <div class="popup--item pad--2 clickable font--s16" @click="$router.push('/dashboard/settings')">
                        Einstellungen
                    </div>
                    <div class="popup--item pad--2 clickable font--s16 color--danger" @click="logout">
                        Abmelden
                    </div>
                </PopUp>
            </div>
        </transition>
    </div>
</template>
<script>
    import PopUp from '@/components/reusable/2_Modals/PopUp';
    export default {
        name: 'Header',
        components: {PopUp},
        data() {
            return {
                posX: null,
                posY: null,

                showMainPopup: false,
            };
        },
        methods: {
            toggleMainPopup(e) {
                this.posX = e.clientX;
                this.posY = e.clientY;
                this.showMainPopup = !this.showMainPopup;
            },
            closeMainPopup() {
                this.showMainPopup = false;
            },
            async logout() {
                await this.$store.dispatch('logout');
                this.$router.push('/login');
            },
        },
    };
</script>
