import OrganizationsOverview from '@/components/views/1_Organizations/OrganizationsOverview';
import CoursesOverview from '@/components/views/2_Courses/CoursesOverview';
import CourseLoader from '@/components/views/3_Course/CourseLoader';
import PageLoader from '@/components/views/4_Page/PageLoader'
import AuthenticationLoader from '@/components/views/0_Authentication/AuthenticationLoader';
import MembersLoader from '@/components/views/5_Members/MembersLoader';
import MainLoader from '@/components/views/MainLoader';
import EditorsLoader from '@/components/views/6_Editors/EditorsLoader';
import InstructorsLoader from '@/components/views/7_Instructors/InstructorsLoader';
import CourseThemes from '@/components/views/3_Course/CourseThemes';
import CustomizeCourseTheme from '@/components/views/3_Course/CustomizeCourseTheme';
import CourseWelcome from '@/components/views/3_Course/CourseWelcome';
import SettingsLoader from '@/components/views/8_Settings/SettingsLoader';
import LandingLoader from "@/components/views/LandingLoader";
import ResetLoader from "@/components/views/ResetLoader";
import Home from "@/components/views/9_Landing/Home";
import Information from "@/components/views/9_Landing/Information";
import Contact from "@/components/views/9_Landing/Contact";
import Imprint from "@/components/views/9_Landing/Imprint";
import SelectPlan from "@/components/views/1_Organizations/SelectPlan";
import DataProtection from "@/components/views/9_Landing/DataProtection";
import PaymentMethods from "@/components/views/1_Organizations/PaymentMethods";
import PaymentLoader from "@/components/views/1_Organizations/PaymentLoader";
import Checkout from "@/components/views/1_Organizations/Checkout";
import PaymentSuccess from "@/components/views/1_Organizations/PaymentSuccess";
import OrganizationAbos from "@/components/views/1_Organizations/OrganizationAbos";
import OrganizationPaymentMethods from "@/components/views/1_Organizations/OrganizationPaymentMethods";
import Invoices from "@/components/views/1_Organizations/Invoices";
import AGB from "@/components/views/9_Landing/AGB";
import Certificates from "@/components/views/9_Landing/Certificates";
import CoursesSettings from "@/components/views/2_Courses/CoursesSettings";
import Offers from "@/components/views/2_Courses/Offers";
import Comparison from "@/components/views/9_Landing/Comparison";
import Webhooks from "@/components/views/2_Courses/Webhooks";
import WebhookDetails from "@/components/views/2_Courses/WebhookDetails";
import MemberDetails from "@/components/views/5_Members/MemberDetails";

export default [
    {
        path: '/',
        component: LandingLoader,
        children: [
            {
                path: '',
                component: Information,
            },
            {
                path: 'information',
                component: Home,
            },
            {
                path: 'comparison',
                component: Comparison,
            },
            {
                path: 'contact',
                component: Contact,
            },
            {
                path: 'imprint',
                component: Imprint,
            },
            {
                path: 'data',
                component: DataProtection,
            },
            {
                path: 'agb',
                component: AGB,
            },
            {
                path: 'certificates/:certificateID',
                component: Certificates,
            }
        ]
    },
    {
        path: '/login',
        component: AuthenticationLoader,
    },
    {
        path: '/passwords/reset/:secret',
        component: ResetLoader,
    },
    {
        path: '/dashboard',
        component: MainLoader,
        children: [
            {
                path: '',
                component: OrganizationsOverview,
            },
            {
                path: 'settings',
                component: SettingsLoader,
            },
            {
                path: 'abos',
                component: OrganizationAbos,
            },
            {
                path: 'methods',
                component: OrganizationPaymentMethods,
            },
            {
                path: 'invoices',
                component: Invoices,
            },
            {
                path: 'organizations/:organizationID',
                component: CoursesOverview,
            },
            {
                path: 'organizations/:organizationID/settings',
                component: CoursesSettings,
            },
            {
                path: 'organizations/:organizationID/webhooks',
                component: Webhooks,
            },
            {
                path: 'organizations/:organizationID/webhooks/:endpointID',
                component: WebhookDetails,
            },
            {
                path: 'organizations/:organizationID/offers',
                component: Offers,
            },
            {
                path: 'organizations/:organizationID/plan',
                component: PaymentLoader,
                children: [
                    {
                        path: '',
                        component: SelectPlan,
                    },
                    {
                        path: 'success',
                        component: PaymentSuccess,
                    },
                    {
                        path: ':planID/address',
                        component: PaymentMethods,
                    },
                    {
                        path: ':planID/payment',
                        component: PaymentMethods,
                    },
                    {
                        path: ':planID/:paymentID/checkout',
                        component: Checkout,
                    }
                ],
            },
            {
                path: 'organizations/:organizationID/members',
                component: MembersLoader,
            },
            {
                path: 'organizations/:organizationID/members/:memberID',
                component: MemberDetails,
            },
            {
                path: 'organizations/:organizationID/editors',
                component: EditorsLoader,
            },
            {
                path: 'organizations/:organizationID/instructors',
                component: InstructorsLoader,
            },
            {
                path: 'organizations/:organizationID/theme',
                component: CourseThemes,
            },
            {
                path: 'organizations/:organizationID/theme/:themeID',
                component: CustomizeCourseTheme,
            },
            {
                path: 'organizations/:organizationID/courses/:courseID',
                component: CourseLoader,
            },
            {
                path: 'organizations/:organizationID/courses/:courseID/welcome',
                component: CourseWelcome,
            },
            {
                path: 'organizations/:organizationID/courses/:courseID/members',
                component: MembersLoader,
            },
            {
                path: 'organizations/:organizationID/courses/:courseID/members/:memberID',
                component: MemberDetails,
            },
            {
                path: 'organizations/:organizationID/courses/:courseID/chapters/:chapterID/pages/:pageID',
                component: PageLoader
            },
        ],
    },
    {
        path: '*',
        redirect: '/',
    },
]
