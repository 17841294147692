<template>
    <div class="flex flex--column flex--center__vert width--100">
        <div class="content flex flex--column font--s2">
            <h1 class="mar--top__4">
                Impressum
            </h1>
            <h2 class="mar--bottom__2">
                Angaben gemäß §5 TMG
            </h2>

            <h3>
                NEON Software Solutions GmbH
            </h3>
            <p>
                Burgsdorfstr. 5
            </p>
            <p>
                13353 Berlin
            </p>
            <p>
                Deutschland
            </p>
            <p class="mar--bottom__2">
                +49 152 56457016
            </p>

            <p>
                St.-IdNr: DE327944700
            </p>
            <p class="mar--bottom__2">
                Registernummer: HRB: 213873 B | Amtsgericht Charlottenburg
            </p>

            <h2>
                Geschäftsführung:
            </h2>
            <p class="mar--bottom__2">
                Niklas Stemberg, Leon Stephan
            </p>

            <h2>
                Kontakt:
            </h2>
            <p>
                E-Mail: hello@neon.dev
            </p>
            <p class="mar--bottom__2">
                Tel: +49 152 56457016
            </p>

            <h2 class="mar--bottom__1">
                Disclaimer
            </h2>
            <h3>
                Haftung für Links
            </h3>
            <p class="mar--bottom__2">
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
                Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
                Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden
                zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt
                der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
                konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden
                wir derartige Links umgehend entfernen.
            </p>
            <h3>
                Urheberrecht
            </h3>
            <p class="mar--bottom__4">
                Die Inhalte dieser Website unterliegen dem Urheberrecht, sofern nicht anders gekennzeichnet, und dürfen nicht
                ohne vorherige schriftliche Zustimmung von NEON Software Solutions weder als Ganzes noch in Teilen verbreitet,
                verändert oder kopiert werden. Die auf dieser Website eingebundenen Bilder dürfen nicht ohne vorherige
                schriftliche Zustimmung von NEON Software Solutions verwendet werden. Auf den Websites enthaltene Bilder
                unterliegen teilweise dem Urheberrecht Dritter. Soweit die Inhalte auf dieser Seite nicht vom Betreiber
                erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
                gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </p>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Imprint',
    };
</script>
