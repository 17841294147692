<template>
    <div class="flex flex--column flex--center__vert overflowAuto width--100">
        <div class="course--wrapper">
            <ActionHeader back-title="Einstellungen" title="Designvorlagen" @onBack="$router.push(`/dashboard/organizations/${$route.params.organizationID}/settings`)">
            </ActionHeader>
            <div class="flex flex--column width--100">
                <div class="flex flex--space__hor flex--center__vert width--100 mar--bottom__2">
                    <div class="checkbox checkbox--filled circle border--light clickable mar--right__1">
                    </div>
                    <div class="font--s16 flex--grow">
                        Modern OKourse
                    </div>
                    <AccentButton title="Anpassen" @onClick="forward('dbsnq5zafgq5z')"/>
                </div>
<!--                <div class="flex flex&#45;&#45;space__hor flex&#45;&#45;center__vert width&#45;&#45;100">-->
<!--                    <div class="checkbox circle border&#45;&#45;light clickable mar&#45;&#45;right__1">-->
<!--                    </div>-->
<!--                    <div class="font&#45;&#45;s16 flex&#45;&#45;grow">-->
<!--                        Dark OKourse-->
<!--                    </div>-->
<!--                    <AccentButton title="Anpassen" />-->
<!--                </div>-->
            </div>
        </div>
    </div>
</template>
<script>
    import ActionHeader from '@/components/views/4_Page/ActionHeader';
    import AccentButton from '@/components/reusable/1_Buttons/AccentButton';
    export default {
        name: 'CourseThemes',
        components: {AccentButton, ActionHeader},
        computed: {
            course() {
                return this.$store.getters.getCourseById(this.$route.params.courseID)
            },
            courses() {
                return this.$store.getters.getCourses;
            },
        },
        methods: {
            forward(url) {
                this.$router.push(`${this.$route.path}/${url}`);
            },
        },
    };
</script>
