<template>
    <div class="flex flex--center__hor certificate--background width--100 height--100"
         :style="{ backgroundImage: background}">
        <div class="flex flex--column content certificate--content">
            <div class="flex flex--column flex--center width--100 height--100 pad--4">
<!--                <div class="flex flex&#45;&#45;column flex&#45;&#45;center height&#45;&#45;100 width&#45;&#45;100">-->
<!--                    <div class="card b-radius&#45;&#45;5 flex flex&#45;&#45;column pad&#45;&#45;4 bgColor&#45;&#45;white width&#45;&#45;90">-->
<!--                        <div class="font&#45;&#45;s16 color&#45;&#45;grey">-->
<!--                            Hiermit bestätigen wir, dass-->
<!--                        </div>-->
<!--                        <div class="font&#45;&#45;s3 font&#45;&#45;w700 color&#45;&#45;primaryDark">-->
<!--                            Max Mustermann-->
<!--                        </div>-->
<!--                        <div class="font&#45;&#45;s16 color&#45;&#45;grey">-->
<!--                            am-->
<!--                        </div>-->
<!--                        <div class="font&#45;&#45;s2 font&#45;&#45;w700 color&#45;&#45;primaryDark">-->
<!--                            26.06.2020-->
<!--                        </div>-->
<!--                        <div class="font&#45;&#45;s16 color&#45;&#45;grey">-->
<!--                            den Kurs-->
<!--                        </div>-->
<!--                        <div class="font&#45;&#45;s25 font&#45;&#45;w700 color&#45;&#45;primaryDark">-->
<!--                            Leinenführungskurs-->
<!--                        </div>-->
<!--                        <div class="font&#45;&#45;s16 color&#45;&#45;grey mar&#45;&#45;bottom__2">-->
<!--                            abgeschlossen hat.-->
<!--                        </div>-->
<!--                        <AccentButton title="Zertifikat herunterladen" />-->
<!--                    </div>-->
<!--                </div>-->
                <div class="font--s3 color--white mar--bottom__2">
                    Herzlichen Glückwunsch!
                </div>
                <pdf :src="`${URL}/certifications/${$route.params.certificateID}`" class="certificate--image mar--bottom__2"/>
                <div class="card bgColor--white b-radius--5 flex clickable pad--1 flex--center__vert">
                    <ActionButton light="true" icon="ion-ios-copy" class="mar--right__1" @onClick.stop.prevent="copyLink"/>
                    <div class="font--s16">
                        {{ domain + '/certificates/' + $route.params.certificateID }}
                    </div>
                    <input class="pad--1 font--s16 width--100"
                           id="testing-code"
                           type="hidden"
                           :value="domain + '/certificates/' + $route.params.certificateID" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import pdf from 'vue-pdf';
    import ActionButton from "@/components/reusable/1_Buttons/ActionButton";
    export default {
        name: 'Certificates',
        components: {ActionButton, pdf },
        data() {
            return {
                domain: process.env.VUE_APP_DOMAIN_FRONTEND,
                testingCode: '1234',
            };
        },
        computed: {
            certificateDetails() {
                return this.$store.getters.getCertificateDetails;
            },
            background() {
                if (this.certificateDetails && this.certificateDetails.organization.theme.primary && this.certificateDetails.organization.theme.accent) {
                    return `linear-gradient(45deg, ${this.certificateDetails.organization.theme.primary}, ${this.certificateDetails.organization.theme.accent}`
                }
                return 'linear-gradient(45deg, rgba($color-primary, 1), rgba($color-secondary, 1)';
            },
            URL() {
                return this.$store.getters.getURL;
            }
        },
        methods: {
            copyLink () {
                let testingCodeToCopy = document.querySelector('#testing-code');
                testingCodeToCopy.setAttribute('type', 'text') ;   // 不是 hidden 才能複製
                testingCodeToCopy.select();

                try {
                    let successful = document.execCommand('copy');
                    if (successful) {
                        this.$store.commit('addToast', {
                            type: 'success',
                            title: 'Link kopiert',
                        });
                    }
                } catch (err) {
                    // eslint-disable-next-line
                    console.log(err);
                }
                testingCodeToCopy.setAttribute('type', 'hidden');
                window.getSelection().removeAllRanges()
            },
        },
        mounted() {
            this.$store.dispatch('retrieveCertificateDetails', this.$route.params.certificateID);
        },
    }
</script>
