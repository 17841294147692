<template>
    <div class="width--100 flex flex--column">
        <div class="flex width--100 pad--2 flex--center__vert">
            <input type="text" class="form--input__text mar--right__1" placeholder="Suchen..." v-model="searchString" @input="debounceInput"/>
            <ActionButton :light="true" icon="ion-md-swap" :rotate="true" @onClick="toggleMainPopup"
                          v-click-outside="closeMainPopup"/>
            <transition name="fade">
                <div class="absolute popup--anchor" v-if="showMainPopup">
                    <PopUp :posX="posX" :posY="posY">
                        <div class="popup--item pad--2 clickable font--s16">
                            Hinzugefügt am
                        </div>
                        <div class="popup--item pad--2 clickable font--s16">
                            Alphabetisch
                        </div>
                    </PopUp>
                </div>
            </transition>
        </div>
        <div class="flex flex--center font--s16 font--w700" v-if="this.$route.params.courseID == null && !loading">
            {{ memberCount }} Treffer
        </div>
        <div class="pad--2" v-if="!loading">
            <div class="members--list flex flex--column width--100 b-radius--5 overflowHidden" v-if="members.length">
                <div class="members--member grid border--bottom__light clickable" @click.self="toggleEditMemberModal(member)"
                     v-for="member in members" :key="member._id">
                    <div class="flex flex--center__vert pad--2" @click.self="toggleEditMemberModal(member)">
                        <i class="ion-md-contact mar--right__1 font--s25 lineHeight--1"/>
                        <div class="font--s14">
                            {{ member.firstName }} {{ member.lastName }}
                        </div>
                    </div>
                    <div class="flex flex--center__vert pad--2" @click.self="toggleEditMemberModal(member)">
                        <div class="font--s14">
                            {{ member.email }}
                        </div>
                    </div>
                    <div class="flex flex--center__vert flex--end__hor pad--1" @click.self="toggleEditMemberModal(member)"
                         v-if="organization.role === 'Creator' || organization.role === 'Editor'">
                        <ActionButton icon="ion-md-trash" :light="true" @onClick="() => { memberCache = member; showDeleteModal = true;}" @click.prevent/>
                    </div>
                </div>
            </div>
            <div class="height--100 width--100 flex flex--center mar--top__2" v-if="!members.length">
                <div class="flex flex--column flex--center width--50">
                    <img src="../../../assets/SVG6.svg" class="width--50 mar--bottom__2"/>
                    <div class="font--s2 mar--bottom__2">
                        Du hast noch keine Kursmitglieder.
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex--center pad--4" v-if="loading">
            <loader :loading="true" color="#114B5F" :size="'1rem'"/>
        </div>
        <div class="flex flex--center font--s16 font--w700 mar--bottom__2" v-if="!loading">
            <div class="mar--right__1">
                <ActionButton icon="ion-ios-arrow-back" @onClick="navigateMembers(false)"/>
            </div>
            {{ (page - 1) * 50 }} bis {{ page * 50}}
            <div class="mar--left__1">
                <ActionButton icon="ion-ios-arrow-forward" @onClick="navigateMembers(true)"/>
            </div>
        </div>
        <transition name="fade">
            <Modal title="Nutzerdetails" @toggle="toggleEditMemberModal" v-if="showEditMemberModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <label for="member_firstName" class="form--label">Vorname</label>
                    <input type="text" id="member_firstName" class="form--input form--input__text"
                           v-model="member.firstName"/>
                    <label for="member_lastName" class="form--label">Nachname</label>
                    <input type="text" id="member_lastName" class="form--input form--input__text"
                           v-model="member.lastName"/>
                    <label for="member_email" class="form--label">E-Mail</label>
                    <input type="text" id="member_email" class="form--input form--input__text" v-model="member.email"/>
                    <div class="form--label">Angebote</div>
                    <div class="flex flex--column width--100">
                        <div class="flex flex--center__vert width--100 mar--bottom__1">
                            <div class="flex--grow">
                                <v-select :clearable="false"
                                          :options="offers"
                                          id="chapter"
                                          label="title"
                                          transition="fade"
                                          v-model="newOffer"/>
                            </div>
                            <div class="mar--right__1" />
                            <ActionButton icon="ion-md-add" :accent="newOffer" :light="!newOffer" @onClick="newOffer ? addOffer() : null"/>
                        </div>
                        <div class="flex flex--center__vert" v-if="newOffer">
                            <input type="text" id="offer_order" class="form--input form--input__text mar--right__1" placeholder="Bestellnummer" v-model="orderID"/>
                            <input type="text" id="offer_key" class="form--input form--input__text mar--right__1" placeholder="Produktschlüssel" v-model="externalID"/>
                            <div class="flex flex--center__vert lineHeight--1">
                                <div class="mar--right__1">Voller Zugang</div>
                                <div class="checkbox circle border--light clickable mar--right__1 flex flex--center"
                                     @click="() => { fullAccess = !fullAccess; $forceUpdate(); }"
                                     :class="{'checkbox--filled': fullAccess}">
                                    <i class="ion-ios-checkmark color--white font--s2 lineHeight--1" v-if="fullAccess"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex--column mar--bottom__1 overflowAuto">
                        <div class="grid userCourseGrid pad--1 border--bottom__light font--s16" v-for="offer in member.offers" :key="offer._id">
                            <div class="flex flex--center__vert lineHeight--1 mar--right__1">
                                {{ offer.title }}
                            </div>
                            <div class="flex flex--center__vert lineHeight--1 mar--right__1">
                                <div class="mar--right__1">Voller Zugang</div>
                                <div class="checkbox circle border--light clickable mar--right__1 flex flex--center"
                                     @click="() => { offer.fullAccess = !offer.fullAccess; $forceUpdate(); }"
                                     :class="{'checkbox--filled': offer.fullAccess}">
                                    <i class="ion-ios-checkmark color--white font--s2 lineHeight--1" v-if="offer.fullAccess"/>
                                </div>
                            </div>
                            <div>
                                <ActionButton icon="ion-md-close" light="true" @onClick="removeCourse(offer)"/>
                            </div>
                        </div>
                    </div>
<!--                    <label for="member_notes" class="form&#45;&#45;label">Notizen</label>-->
<!--                    <textarea id="member_notes" class="form&#45;&#45;input form&#45;&#45;input__textArea mar&#45;&#45;bottom__2" rows="5" v-model="member.notes"/>-->
                    <AccentButton :full-width="true" :filled="true" title="Änderungen speichern"
                                  @onClick="updateMember"/>
                </div>
            </Modal>
        </transition>
<!--        <transition name="fade">-->
<!--            <ConfirmationModal title="Möchtest du die E-Mail ändern?" @toggle="showConfirmationModal = false" v-if="showConfirmationModal"-->
<!--                content="Bist du dir sicher dass du die E-Mail Adresse des Nutzers verändern möchtest?"-->
<!--                @confirm="updateMember(true)" @reject="() => { this.showConfirmationModal = false; this.showEditMemberModal = false}" />-->
<!--        </transition>-->
        <transition name="fade">
            <ConfirmationModal title="Möchtest du den Nutzer löschen?" @toggle="showDeleteModal = false" v-if="showDeleteModal"
                               content="Bist du dir sicher dass du den Nutzer löschen möchtest? Alle Kurse und Zugänge gehen verloren."
                               @confirm="deleteMember" @reject="showDeleteModal = false" />
        </transition>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    import ActionButton from '@/components/reusable/1_Buttons/ActionButton';
    import PopUp from '@/components/reusable/2_Modals/PopUp';
    import Modal from '@/components/reusable/2_Modals/Modal';
    import AccentButton from '@/components/reusable/1_Buttons/AccentButton';
    import _ from 'lodash';
    import {handleEsc} from "../../../services/mixins";
    import ConfirmationModal from "@/components/reusable/2_Modals/ConfirmationModal";

    export default {
        name: 'MemberList',
        components: {ConfirmationModal, AccentButton, Modal, PopUp, ActionButton, vSelect},
        mixins: [handleEsc],
        data() {
            return {
                showEditMemberModal: false,
                showConfirmationModal: false,
                showDeleteModal: false,
                showMainPopup: false,

                posX: null,
                posY: null,
                loading: true,

                searchString: '',
                member: null,
                memberCache: null,
                newOffer: null,
                orderID: null,
                externalID: null,
                fullAccess: false,
                page: 1,
            };
        },
        computed: {
            memberCount() {
                return this.$store.getters.getMemberCount;
            },
            members() {
                /* eslint-disable */
                console.log(this.$store.getters.getMembers);
                return this.$store.getters.getMembers;
            },
            courses() {
                return _.cloneDeep(this.$store.getters.getCourses.courses);
            },
            course() {
                if (this.$route.params.courseID != null) {
                    return this.$store.getters.getCourseById(this.$route.params.courseID);
                }
                return null;
            },
            organization() {
                return this.$store.getters.getOrganizationById(this.$route.params.organizationID)
            },
            offers() {
                console.log(this.$store.getters.getOffers);
                return this.$store.getters.getOffers;
            },
        },
        methods: {
            handleEsc() {
                this.showEditMemberModal = false
            },
            toggleEditMemberModal(val) {
              console.log(val);
              this.$router.push(this.$route.path + '/' + val._id);
                // this.member = _.cloneDeep(val);
                // this.memberCache = _.cloneDeep(val);
                // this.showEditMemberModal = !this.showEditMemberModal;
            },
            toggleMainPopup(e) {
                this.posX = e.clientX;
                this.posY = e.clientY;

                this.showMainPopup = !this.showMainPopup;
            },
            closeMainPopup() {
                this.showMainPopup = false;
            },
            async deleteMember() {
                await this.$store.dispatch('deleteMember', {
                    organizationID: this.$route.params.organizationID,
                    memberID: this.memberCache._id,
                });
                this.showDeleteModal = false;
            },
            addOffer() {
                console.log(this.newOffer);
                console.log(this.member.offers);
                if (this.newOffer) {
                    if (this.member.offers.findIndex((el) => el._id === this.newOffer._id) >= 0) {
                        this.newOffer = null;
                        this.$store.commit('addToast', {
                            type: 'warning',
                            title: 'Der Nutzer hat bereits Zugriff auf dieses Angebot',
                        });
                    } else {
                        this.member.offers.push({
                            id: this.newOffer._id,
                            title: this.newOffer.title,
                            externalId: this.externalID,
                            orderId: this.orderID,
                            joinDate: new Date().toISOString(),
                            fullAccess: this.fullAccess,
                        });
                        this.newOffer = null;
                        this.externalID = null;
                        this.orderID = null;
                    }
                }
            },
            removeCourse(offer) {
                this.member.offers = this.member.offers.filter((el) => el._id !== offer._id);
            },
            debounceInput: _.debounce(function (e) {
                this.searchMembers();
            }, 1000),
            async searchMembers() {
                this.loading = true;
                if (this.$route.params.courseID != null) {
                    if (this.searchString == null || this.searchString === '') {
                        await this.$store.dispatch('retrieveCourseMembers', {
                            organizationID: this.$route.params.organizationID,
                            range: {
                                start: (this.page - 1) * 50,
                                end: this.page * 50,
                            },
                            courseID: this.$route.params.courseID,
                        });
                    } else {
                        await this.$store.dispatch('searchCourseMembers', {
                            organizationID: this.$route.params.organizationID,
                            data: {
                                start: (this.page - 1) * 50,
                                end: this.page * 50,
                                searchValue: this.searchString,
                            },
                            courseID: this.$route.params.courseID,
                        });
                    }
                } else {
                    if (this.searchString == null || this.searchString === '') {
                        await this.$store.dispatch('retrieveMembers', {
                            organizationID: this.$route.params.organizationID,
                            range: {
                                start: (this.page - 1) * 50,
                                end: this.page * 50,
                            },
                        });
                    } else {
                        await this.$store.dispatch('searchMembers', {
                            organizationID: this.$route.params.organizationID,
                            data: {
                                start: (this.page - 1) * 50,
                                end: this.page * 50,
                                searchValue: this.searchString,
                            },
                        });
                    }
                }
                this.loading = false;
            },
            navigateMembers(forward) {
                if (forward) {
                    if (this.members.length >= 50) {
                        this.page++;
                        if (this.$route.params.courseID != null) {
                            this.$store.dispatch('retrieveCourseMembers', {
                                organizationID: this.$route.params.organizationID,
                                range: {
                                    start: (this.page - 1) * 50,
                                    end: this.page * 50,
                                },
                                courseID: this.$route.params.courseID,
                            });
                        } else {
                            this.$store.dispatch('retrieveMembers', {
                                organizationID: this.$route.params.organizationID,
                                range: {
                                    start: (this.page - 1) * 50,
                                    end: this.page * 50,
                                },
                            });
                        }
                    }
                } else {
                    if (this.page > 1) {
                        this.page--;
                        if (this.$route.params.courseID != null) {
                            this.$store.dispatch('retrieveCourseMembers', {
                                organizationID: this.$route.params.organizationID,
                                range: {
                                    start: (this.page - 1) * 50,
                                    end: this.page * 50,
                                },
                                courseID: this.$route.params.courseID,
                            });
                        } else {
                            this.$store.dispatch('retrieveMembers', {
                                organizationID: this.$route.params.organizationID,
                                range: {
                                    start: (this.page - 1) * 50,
                                    end: this.page * 50,
                                },
                            });
                        }
                    }
                }
            },
            async updateMember(confirmed) {
                if (this.member.email !== this.memberCache.email && !confirmed) {
                    this.showConfirmationModal = true;
                } else {
                    this.isLoading = true;
                    await this.memberCache.offers.forEach((el) => {
                        if (this.member.offers.findIndex((el2) => el2._id === el._id) < 0) {
                            this.$store.dispatch('deleteMemberOffer', {
                                organizationID: this.$route.params.organizationID,
                                memberID: this.member._id,
                                offerID: el._id,
                            })
                        }
                    });
                    if (this.member.offers.length) {
                        await this.member.offers.forEach((el) => {
                            if (this.memberCache.offers.findIndex((el2) => el2._id === el._id) >= 0) {
                                this.$store.dispatch('updateMemberOffer', {
                                    organizationID: this.$route.params.organizationID,
                                    memberID: this.member._id,
                                    offerID: el._id,
                                    data: {
                                        fullAccess: el.fullAccess,
                                    }
                                })
                            } else {
                                this.$store.dispatch('addMemberOffer', {
                                    organizationID: this.$route.params.organizationID,
                                    memberID: this.member._id,
                                    data: {
                                        joinDate: el.joinDate,
                                        customFullAccess: el.fullAccess,
                                        orderId: el.orderId,
                                        externalId: el.externalId ? el.externalId : el.id,
                                    }
                                })
                            }
                        })
                    }
                    let success = true;
                    await this.$store.dispatch('updateMember', {
                        organizationID: this.$route.params.organizationID,
                        memberID: this.member._id,
                        data: {
                            firstName: this.member.firstName !== this.memberCache.firstName ? this.member.firstName : undefined,
                            lastName: this.member.lastName !== this.memberCache.lastName ? this.member.lastName : undefined,
                            email: this.member.email !== this.memberCache.email ? this.member.email : undefined,
                            // notes: this.member.notes,
                        },
                    }).catch(() => {
                        success = false;
                        this.$store.commit('addToast', {
                            type: 'warning',
                            title: 'Bitte überprüfen sie ihre Eingaben.',
                        });
                        setTimeout(() => {
                            this.loading = false;
                        }, 1000);
                    }).then(() => {
                        if (success) {
                            this.toggleEditMemberModal();
                            this.showConfirmationModal = false;
                            this.loading = false;
                            this.searchString = '';
                        }
                    });
                }
            },
        },
        async mounted() {
            await this.$store.dispatch('retrieveCourses', this.$route.params.organizationID);
            await this.$store.dispatch('retrieveOrganizations');
            await this.$store.dispatch('retrieveOffers', this.$route.params.organizationID);
            if (this.$route.params.courseID != null) {
                await this.$store.dispatch('retrieveCourseMembers', {
                    organizationID: this.$route.params.organizationID,
                    range: {
                        start: 0,
                        end: 50,
                    },
                    courseID: this.$route.params.courseID,
                });
            } else {
                await this.$store.dispatch('retrieveMembers', {
                    organizationID: this.$route.params.organizationID,
                    range: {
                        start: 0,
                        end: 50,
                    },
                });
            }
            this.loading = false;
        },
    };
</script>
<style lang="scss">

</style>
