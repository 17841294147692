<template>
    <footer class="footer pad--3 flex flex--center width--100">
        <div class="footer--contet flex flex--space__hor flex--start__vert">
            <div class="flex flex--column">
                <img src="../../../assets/SVG3.svg">
                <div class="font--s16 color--grey">
                    © 2020 — All Rights Reserved
                </div>
            </div>
            <div class="flex">
                <div class="flex flex--column mar--right__2">
                    <div class="font--w700 font--s14 color--grey mar--bottom__2 letterSpace--1">
                        ENTWICKLER
                    </div>
                    <a href="https://okourse.stoplight.io/" target="_blank" class="noDec font--s14 color--grey mar--bottom__1">
                        Dokumentation
                    </a>
                    <div class="font--s14 color--grey mar--bottom__1">
                        API-Referenz
                    </div>
                    <a href="https://okourse.statuspage.io/" target="_blank" class="noDec font--s14 color--grey mar--bottom__1 flex relative flex--center__vert">
                        <div class="mar--right__1">Status</div>
                        <div class="width--05 height--05 circle" :class="[status === 'operational' ? 'bgColor--success' : 'bgColors--warning']"></div>
                    </a>
                </div>
                <div class="flex flex--column">
                    <div class="font--w700 font--s14 color--grey mar--bottom__2 letterSpace--1">
                        UNTERNEHMEN
                    </div>
                    <router-link class="font--s14 color--grey mar--bottom__1 noDec clickable"
                                 @click.native="linkNavigation"
                                 to="/contact">
                        Kontaktinformationen
                    </router-link>
                    <router-link class="font--s14 color--grey mar--bottom__1 noDec clickable"
                                 @click.native="linkNavigation"
                                 to="/data">
                        Datenschutz
                    </router-link>
                    <router-link class="font--s14 color--grey mar--bottom__1 noDec clickable"
                                 @click.native="linkNavigation"
                                 to="/agb">
                        Nutzungsbedingungen
                    </router-link>
                    <router-link class="font--s14 color--grey mar--bottom__1 noDec clickable"
                                 @click.native="linkNavigation"
                                 to="/imprint">
                        Impressum
                    </router-link>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
    export default {
        name: 'Footer',
        data() {
            return {
                status: 'operational',
            }
        },
        mounted() {
            /* eslint-disable */
            let sp = new StatusPage.page({ page : 'h3nq3l2w5rw2' });
            sp.summary({
                success : (data) => {
                    this.status = data.components[0].status;
                }
            });
        },
        methods: {
            linkNavigation() {
                document.getElementById('main').scrollTop = 0;
            },
        },
    };
</script>
