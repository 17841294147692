<template>
    <div class="flex flex--column flex--center__vert width--100">
        <div class="content flex flex--column font--s16 mar--bottom__2">
            <article class="page sans" id="284e0477-615c-4511-a592-36b8eb6c88fb">
                <header><h1 class="page-title mar--top__4">Nutzungsbedingungen</h1></header>
                <div class="page-body"><p class="" id="5fc4cd7c-bfed-4f8d-894b-5b6945275b4a">
                </p>
                    <h2 class="" id="3f91fc70-cc68-485a-a2ff-c31351fdfeb2"><strong>Nutzungsbedingungen</strong></h2>
                    <h2 class="" id="be24d9e7-b51a-4981-8d5e-3abffa2973e3"><strong>1. Geltungsbereich,
                        Änderungen</strong></h2>
                    <p class="" id="862f06c3-da93-4c02-951f-6264a1faf875">1.1 Die NEON Software Solutions GmbH
                        (nachfolgend „NEON“ genannt) stellt unter <a
                                href="https://okourse.com/">https://okourse.com/</a> eine Plattform für Onlinekurse zur
                        Verfügung (nachfolgend „Plattform“ genannt). Der Zugang zur Plattform ist kostenlos, jedoch kann
                        auch bei kostenpflichtiger Nutzung Zugang zu weiteren Inhalten erworben werden.</p>
                    <p class="" id="d7eed82d-7a26-4d1b-9c1e-faaf13de06fb">1.2 Nähere Angaben zur NEON kann hier gefunden
                        werden: <a href="https://neon.dev/imprint">https://neon.dev/imprint</a><a
                                href="https://doguniversity.de/imprint/">.</a></p>
                    <p class="" id="10b500d5-7831-4827-a169-9c7201bccda7">1.3 Die vorliegenden Nutzungsbedingungen
                        regeln die Zurverfügungstellung der Dienste durch NEON und die Nutzung dieser Dienste durch Dich
                        als ordnungsgemäß angemeldeten Teilnehmer.</p>
                    <p class="" id="f26cc11b-a3c4-4049-9b1d-5d5dd7ee9bbd">1.4 NEON behält sich vor, diese
                        Nutzungsbedingungen jederzeit mit Wirksamkeit auch innerhalb der bestehenden
                        Vertragsverhältnisse zu ändern. Über derartige Änderungen wird NEON Dich mindestens 30
                        Kalendertage vor dem geplanten Inkrafttreten der Änderungen in Kenntnis setzen. Sofern Du nicht
                        innerhalb von 30 Tagen ab Zugang der Mitteilung widersprichst und die Inanspruchnahme der
                        Dienste auch nach Ablauf der Widerspruchsfrist fortsetzt, so gelten die Änderungen ab
                        Fristablauf als wirksam vereinbart. Im Falle Deines Widerspruchs wird der Vertrag zu den
                        bisherigen Bedingungen fortgesetzt. In der Änderungsmitteilung wird NEON Dich auf Dein
                        Widerspruchsrecht und auf die Folgen hinweisen.</p>
                    <h2 class="" id="0008d163-0d1f-4fe0-a855-cc0a2f5f29ae"><strong>2. Nutzung der Plattform</strong>
                    </h2>
                    <p class="" id="625ec936-1f49-464d-9b3a-bffb4ba7e07b">2.1.1.1 Die Nutzung der Plattform setzt Deine
                        Anmeldung als Teilnehmer voraus. Ein Anspruch auf die Zulassung besteht nicht. NEON ist
                        berechtigt, Zulassungsanträge ohne Angabe von Gründen nicht anzunehmen bzw. zurückzuweisen.</p>
                    <p class="" id="f2b4da2a-463e-46d3-9bb3-091fb3b29f6d">2.1.1.2 Um zur Nutzung der Plattform
                        zugelassen zu werden, musst Du die im Anmeldevorgang erfragten Daten angeben. Mit Eingabe der
                        Daten und anschließendem Klick auf die Fläche „Registrieren“ beantragst Du die Zulassung zur
                        Plattform.</p>
                    <p class="" id="2a1faf69-4fd0-42b8-8b9b-ac07d97b86af">2.1.1.3 Eine Speicherung der
                        Vertragsbestimmungen durch NEON erfolgt nicht.</p>
                    <p class="" id="1e7edd88-009f-45bc-9207-cdce93353c85">2.1.1.4 Der Vertragsschluss erfolgt in
                        deutscher Sprache.</p>
                    <h2 class="" id="3e1f946b-004d-4433-ac29-db57a44b4a21"><strong>2.1.2 Laufzeit, Kündigung</strong>
                    </h2>
                    <p class="" id="16c51a83-6b8f-4836-8164-1978400f066e">2.1.2.1 Du kannst den Nutzungsvertrag
                        jederzeit mit sofortiger Wirkung und ohne Angaben von Gründen kündigen. Hast Du auch einen
                        Abonnement-Vertrag („Starter“, &quot;Creator&quot;, &quot;Business&quot;) abgeschlossen, muss
                        zunächst das Abonnement gekündigt werden.</p>
                    <p class="" id="00977f4e-97b2-495b-b9fa-0ceb5fe9c5d0">2.1.2.2 NEON kann den Nutzungsvertrag mit
                        einer Frist von zwei Wochen kündigen.</p>
                    <p class="" id="0b6d4f83-e490-4856-85be-41f70a5e5f80">2.1.2.3 Das Recht zur fristlosen Kündigung aus
                        wichtigem Grund bleibt unberührt.</p>
                    <p class="" id="d0ca5281-1ac6-449b-9664-f468924bea67">2.1.2.4 Die Kündigung bedarf zu ihrer
                        Wirksamkeit der Textform (E-Mail reicht aus).</p>
                    <p class="" id="b629e249-8458-4f0b-983c-a4593a747aa0">2.1.2.5 NEON ist berechtigt, mit Ablauf von 30
                        Tagen nach Wirksamwerden der Kündigung und nach Ablauf etwaiger gesetzlicher
                        Aufbewahrungsfristen sämtliche im Rahmen Deiner Teilnahme entstandenen Daten zu löschen.</p>
                    <h2 class="" id="9d24b480-eaa0-4c5a-a7d1-3083fbcc185a"><strong>2.1.3 Kosten</strong></h2>
                    <p class="" id="37dd045f-8778-4a6d-a4eb-4da4133d3c9a">Die Nutzung der Plattform ist, vorbehaltlich
                        der nur für kostenpflichtige Nutzung (Ziffer 3) verfügbaren Inhalte, kostenlos.</p>
                    <h2 class="" id="6db68688-566e-40a7-8685-878b2120cc0d"><strong>3. Kostenpflichtige Nutzung</strong>
                    </h2>
                    <p class="" id="69991477-e7a0-41a4-95f1-aaa567e413a6">Bestimmte Inhalte der Plattform sind nur gegen
                        Bezahlung nutzbar. Hierfür stehen Dir zwei Möglichkeiten zur Verfügung:</p>
                    <p class="" id="5902daaa-d16c-402a-a4cc-243c7f055587">a) Du kaufst („Starter“, &quot;Creator&quot;,
                        &quot;Business&quot;). Das ist ein Abonnement, das dir Zugriff auf einen Großteil der Videos der
                        Plattform während der Abonnement-Laufzeit gibt (ausgenommen sind einige Inhalte der Premium
                        Kurse).</p>
                    <p class="" id="0e6fcf51-00bd-48b4-9683-8881e2019ccc">a) Du kaufst einen ganzen Kurs. Dabei handelt
                        es sich um kein Abonnement, welches gekündigt werden muss.</p>
                    <h2 class="" id="4ea2f052-6c1c-436c-a3c0-efa4e9a9f34b"><strong>3.1.1 Vertragsschluss</strong></h2>
                    <p class="" id="1e290751-f19e-4e5c-b338-a22d58737635">3.1.1.1 Der Vertrag über den Erwerb von
                        („Starter“, &quot;Creator&quot;, &quot;Business&quot;) oder eines Kurses kommt durch Angebot und
                        Annahme zustande.</p>
                    <p class="" id="b5230f62-4ac2-4ee8-a92f-f39f8e3d3d32">3.1.1.2 Vor Deinem 18. Geburtstag benötigst Du
                        zum Abschluss die Einwilligung Deines gesetzlichen Vertreters.</p>
                    <p class="" id="5d68ccb4-6856-4792-8dd9-939d8a5bd694">3.1.1.4 Das Angebot auf Erwerb von („Starter“,
                        &quot;Creator&quot;, &quot;Business&quot;) oder eines Kurses gibst Du ab, indem Du nach Eingabe
                        der geforderten Daten auf den Button „Jetzt kaufen“ klickst. Zuvor hast Du Gelegenheit, die
                        eingegebenen Daten in einem Bestätigungsfenster zu überprüfen und erforderlichenfalls zu
                        korrigieren.</p>
                    <p class="" id="16e67eda-7764-4177-b19b-1f7cfcdef4e1">3.1.1.5 Unmittelbar nach Abschicken der Daten
                        bestätigen wir Dir den Erhalt Deines Angebots. Diese Bestätigung stellt noch nicht die Annahme
                        Deines Angebots dar (dazu Ziffer 3.1.6).</p>
                    <p class="" id="f9afac9f-f909-43bb-8437-63faecfbfa53">3.1.1.6 NEON ist frei darin, Dein Angebot
                        anzunehmen. Wenn NEON das Angebot annimmt, erhältst Du von NEON eine E-Mail. Die E-Mail stellt
                        die Annahme Deines Angebots auf Erwerb von („Starter“, &quot;Creator&quot;, &quot;Business&quot;)
                        dar. Der Annahme per E-Mail steht es gleich, wenn NEON Dir den Zugang zu den kostenpflichtigen
                        Inhalten der Plattform freischaltet. Mit Erhalt der E-Mail bzw. mit Freischaltung der
                        kostenpflichtigen Inhalte ist der Vertrag über den Erwerb des Abonnements zwischen NEON und Dir
                        zustande gekommen.</p>
                    <p class="" id="9dc5d209-a285-41ae-a6b3-135d8bc1b42b">3.1.1.7 In der E-Mail, mit der NEON die
                        Annahme erklärt (Ziffer 3.1.5), sind die vorliegenden Nutzungsbedingungen nochmals beigefügt.
                        Eine Speicherung der Vertragsbestimmungen durch NEON erfolgt nicht.</p>
                    <p class="" id="056bc099-f421-4080-88eb-cc685ea997fb">3.1.1.8 Der Vertragsschluss erfolgt in
                        deutscher Sprache.</p>
                    <h2 class="" id="a88f89ff-61aa-42b4-9d2a-7158f5adccd9"><strong>Widerrufsbelehrung</strong></h2>
                    <h2 class="" id="9908c8e3-2ea0-4856-91bf-2d45befd81b2"><strong>Widerrufsrecht</strong></h2>
                    <p class="" id="df6835bd-ddaf-4b0c-8536-79c3808e3b8c">Sie haben das Recht, binnen vierzehn Tagen
                        ohne Angabe von Gründen diesen Vertrag zu widerrufen.</p>
                    <p class="" id="a9501ff5-84e1-494b-a5f2-82f338ed687c">Die Widerrufsfrist beträgt vierzehn Tage ab
                        dem Tag des Vertragsabschlusses.</p>
                    <p class="" id="23d95af4-aec2-43b2-86c0-d136df3ee1cf">Um Ihr Widerrufsrecht auszuüben, müssen Sie
                        uns, NEON Software Solutions GmbH, Burgsdorfstr. 5, 13353 Berlin, support@neon.dev, mittels
                        einer eindeutigen Erklärung (z.B. eine E-Mail) über Ihren Entschluss, diesen Vertrag zu
                        widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das
                        jedoch nicht vorgeschrieben ist.</p>
                    <p class="" id="5acd1b88-1530-4362-993c-83143abb1697">Zur Wahrung der Widerrufsfrist reicht es aus,
                        dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist
                        absenden.</p>
                    <h2 class="" id="cc4ae6a1-8d9c-4e86-912d-3425b951c01d"><strong>Folgen des Widerrufs</strong></h2>
                    <p class="" id="2485e3df-1666-41e3-a421-d4a2cc248c12">Wenn Sie diesen Vertrag widerrufen, haben wir
                        Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
                        Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der
                        Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich
                        und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren
                        Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe
                        Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit
                        Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser
                        Rückzahlung Entgelte berechnet.</p>
                    <p class="" id="99295784-ef7e-4289-a9a0-c61eb4a92077">3.1.2.2 Dein Widerrufsrecht erlischt, wenn Du
                        ausdrücklich zugestimmt hast, dass NEON mit der Ausführung des Vertrags vor Ablauf der
                        Widerrufsfrist beginnt, und Du Deine Kenntnis davon bestätigt hast, dass Du durch Deine
                        Zustimmung mit Beginn der Vertragsausführung Dein Widerrufsrecht verlierst.</p>
                    <p class="" id="29d9cdbc-6dd1-4f75-ae6d-5f2f74097c4b">3.1.2.3 Zur Ausübung Deines Widerrufsrechts
                        kannst Du das im Anhang dieser Nutzungsbedingungen beigefügte Widerrufs-Musters verwenden.</p>
                    <h2 class="" id="5341fa63-ddb6-4759-9dd8-25aa285c7399"><strong>3.1.3 Dienstangebot,
                        Verfügbarkeit</strong></h2>
                    <p class="" id="ac215990-0322-4b6c-beab-dd53ebabbc2e">3.1.3.1 NEON stellt Dir auf der Plattform
                        verschiedene Inhalte wie z.B. Beiträge, Bild- und Tondokumente, Informationen und sonstige
                        Inhalte (nachfolgend zusammenfassend „Inhalte“ genannt) zur Verfügung. Inhalt und Umfang der
                        Dienste bestimmen sich nach den jeweiligen vertraglichen Vereinbarungen, im Übrigen nach den
                        jeweils aktuell auf der Plattform verfügbaren Funktionalitäten.</p>
                    <p class="" id="1c69c369-e1b7-49dd-945a-658a52f2ffab">3.1.3.2 NEON erstrebt in seinem
                        Verantwortungsbereich eine Verfügbarkeit von 95% im Jahresmittel. Nicht in die Berechnung der
                        Verfügbarkeit fallen die regulären Wartungsfenster der Plattform.</p>
                    <p class="" id="2955e7e5-a5a0-4656-9f33-9cc265eb7b0a">3.1.3.3 Im Übrigen besteht ein Anspruch auf
                        die Nutzung der auf der Plattform verfügbaren Dienste nur im Rahmen der technischen und
                        betrieblichen Möglichkeiten von NEON. NEON bemüht sich um eine möglichst unterbrechungsfreie
                        Nutzbarkeit seiner Dienste. Jedoch können durch technische Störungen (wie z.B. Unterbrechung der
                        Stromversorgung, Hardware- und Softwarefehler, technische Probleme in den Datenleitungen)
                        zeitweilige Beschränkungen oder Unterbrechungen auftreten.</p>
                    <h2 class="" id="67f82291-a92e-4d63-8230-5725bc9b52c8"><strong>3.1.4 Informationen zu Funktionsweise
                        und Interoperabilität der Okourse-Plattform</strong></h2>
                    <p class="" id="90276c6a-bac6-40c3-adbb-14bc8e3c2b7a">3.1.4.1 Die Inhalte werden in deutscher
                        Sprache veröffentlicht.</p>
                    <p class="" id="10a9e761-713f-430b-a091-dee51bc43af4">3.1.4.2 Du kannst die Inhalte hauptsächlich in
                        dem Land ansehen, in dem Du Dein Konto erstellt hat, und nur in geografischen Regionen, in denen
                        NEON seinen Dienst anbietet und die jeweiligen Inhalte lizenziert hat. Die verfügbaren Inhalte
                        können sich je nach Region unterscheiden.</p>
                    <p class="" id="5e4740fd-5aa6-4f7b-b3ec-b62c3c51ca56">3.1.4.3 Das Inhalte-Angebot und die Plattform
                        werden regelmäßig aktualisiert.</p>
                    <p class="" id="0cac6753-70fe-4f2a-962d-04865a9128b6">3.1.4.4 Das Plattform-Angebot kann auf allen
                        üblichen Endgeräten (PC, Smartphone, iPad etc.) und mit allen üblichen Betriebs- und
                        Browsersystemen, mit Ausnahme von Windows Phone. Der Einsatz einer speziellen Software zum
                        Ansehen der Inhalte ist nicht erforderlich.</p>
                    <p class="" id="ed94145d-50d8-4711-98d6-0a0cedb08562">3.1.4.5 Die Plattform-Inhalte werden online
                        per Streaming angeboten, so dass die Nutzung der Plattform eine Internetverbindung erfordert.
                        Die Abspieldauer der einzelnen Videos variiert; sie beträgt üblicherweise zwischen etwa 4 und 60
                        Minuten. Einzelne Inhalte können im pdf-Format heruntergeladen werden, um sie offline ansehen zu
                        können.</p>
                    <p class="" id="4909f5e6-8308-429d-8a64-5f8e3155191a">3.1.4.6 Die Darstellungsqualität der Inhalte
                        kann je nach verwendetem Endgerät variieren und ist zudem abhängig von weiteren Faktoren wie
                        Aufenthaltsort oder verfügbare Bandbreite der Internetverbindung. Die Verfügbarkeit von
                        HD-Bildqualität ist abhängig von Deinem Internetdienst und der Leistungsfähigkeit Deines Geräts.
                        Die standardmäßigen Wiedergabe-Einstellungen über Mobilfunknetze schließen Inhalte in HD aus.
                        Die Mindestgeschwindigkeit zum Schauen von Lernvideos in Standardauflösung beträgt 0,5 Mbit/s.
                        Jedoch wird für eine bessere Bildqualität eine schnellere Verbindung empfohlen. Für den Empfang
                        von HD-Inhalten (per Definition eine Auflösung von mindestens 720p) wird eine
                        Download-Geschwindigkeit von mindestens 5,0 Mbit/s empfohlen. Für den Empfang von Ultra-HD- und
                        HDR-Inhalten (per Definition eine Auflösung von mindestens 1080p) wird eine
                        Download-Geschwindigkeit von mindestens 25,0 Mbit/s empfohlen.</p>
                    <h2 class="" id="2939a076-ac05-46b6-84a3-500bed1f32fb"><strong>3.1.5 Preise,
                        Zahlungsbedingungen</strong></h2>
                    <p class="" id="00094be8-439c-4f69-ad9f-93c9ac62189c">3.1.5.1 Es gelten jeweils die zum Zeitpunkt
                        des Vertragsschlusses auf der Plattform angegebenen Preise. Diese verstehen sich einschließlich
                        gesetzlicher Umsatzsteuer.</p>
                    <p class="" id="f0e02f7c-68ce-41eb-a4aa-486be5079a1d">3.1.5.2 Du kannst per Lastschrifteinzug,
                        Sofortüberweisung, Kreditkarte (Mastercard und Visa) oder in der App über iTunes zahlen.</p>
                    <p class="" id="4db1095b-127e-49f6-9280-3503e5769d9b">3.1.5.3 Im Falle des Erwerbs eines Abonnements
                        bei monatlicher Zahlweise ist der monatliche Zahlbetrag jeweils zum Beginn eines Vertragsmonats,
                        erstmals mit Abschluss des Vertrags über den Erwerb des Abonnements fällig. (Beispiel: Wurde der
                        Vertrag am 15. April abgeschlossen, sind die Zahlungen jeweils am 15. eines Monats fällig,
                        erstmals am 15. April.).</p>
                    <p class="" id="44684cf8-7884-4c8a-911a-99d79d21ae72">3.1.5.4 Aufrechnungs- oder
                        Zurückbehaltungsrechte stehen dir nur insoweit zu, als Dein Anspruch rechtskräftig festgestellt
                        oder unbestritten ist. Dies gilt nicht im Hinblick auf Ansprüche, die Dir aufgrund Ausübung
                        Deines Widerrufsrechts (Ziffer 3.2) gegen NEON zustehen.</p>
                    <p class="" id="6ee45608-57d8-42fb-b12c-d9e227785bd3">3.1.5.5 NEON kann den Preis für den Erwerb der
                        Abonnements nach billigem Ermessen der Entwicklung der Kosten anpassen, die für die
                        Preisberechnung maßgeblich sind. Eine Preisanpassung kommt in Betracht, wenn sich z.B. die
                        Kosten für die Erstellung und/oder Vermarktung der auf der Plattform angebotenen Lerninhalte
                        verändern oder Änderungen von rechtlichen Rahmenbedingungen zu einer geänderten Kostensituation
                        führen. NEON wird bei der Ausübung des billigen Ermessens die jeweiligen Zeitpunkte einer
                        Preisänderung so wählen, dass Kostensenkungen nicht nach für den Kunden ungünstigeren Maßstäben
                        Rechnung getragen werden als Kostenerhöhungen, also Kostensenkungen mindestens in gleichem
                        Umfang preiswirksam werden wie Kostenerhöhungen.</p>
                    <h2 class="" id="1b1cc371-de69-4faa-8b9f-d7eaafe60072"><strong>3.1.6 Laufzeit, Kündigung</strong>
                    </h2>
                    <p class="" id="9d93047b-a35c-4ebf-949b-bea954a9d8e1">3.1.6.1 Sofern Du einen Vertrag über
                        („Starter“, &quot;Creator&quot;, &quot;Business&quot;) abgeschlossen hast, beginnt dieser mit
                        Vertragsabschluss und läuft für unbestimmte Zeit. Du kannst ihn jederzeit ohne Angabe von
                        Gründen mit einer Frist von einem Tag zum Ablauf des laufenden Vertrags-Monats kündigen.
                        Beispiel: Der Monatsvertrag hat am 15. April begonnen. Dann kannst Du ihn bis einschließlich 14.
                        Mai täglich mit Wirkung zum 15. Mai kündigen. Ab 15. Mai kannst Du dann nächstmöglich zum 15.
                        Juni kündigen.</p>
                    <p class="" id="c51cdcf0-f830-47ab-a40d-a13960f0fe92">3.1.6.2 Mit Beendigung des Vertrags endet das
                        Recht der Nutzung der kostenpflichtigen Inhalte.</p>
                    <p class="" id="73a62ba7-bef6-4e58-8f7b-9a8df3f8ed55">3.1.6.3 Das Recht zur fristlosen Kündigung aus
                        wichtigem Grund bleibt unberührt.</p>
                    <p class="" id="721bba06-bada-417c-aac4-47aaabb3885d">3.1.6.4 Die Kündigung bedarf zu ihrer
                        Wirksamkeit der Textform (E-Mail oder Abmeldung auf dem Portal bzw. in iTunes reicht aus).</p>
                    <p class="" id="30fdc6a5-79f2-43a5-b248-a384c2e1ce70">3.1.6.5 Die Kündigung lässt den
                        Nutzungsvertrag (Ziffer 2.1) unberührt. Dieser muss, wenn gewünscht, zusätzlich gekündigt
                        werden.</p>
                    <h2 class="" id="cef7b5a3-2baa-4eaa-9503-87653f61a58f"><strong>4. Übergreifende (für die kostenlose
                        Plattformnutzung, Ziffer 2, und die kostenpflichtige Plattformnutzung, Ziffer 3, geltende)
                        Bestimmungen</strong></h2>
                    <h2 class="" id="ed7f280c-9d13-4496-9558-fac00877ae29"><strong>4.1.1 Aktualisierung der
                        Teilnehmerdaten</strong></h2>
                    <p class="" id="d7b5c120-2d4b-4255-a4bc-2cf90fe62f4e">Du bist verpflichtet, Deine Daten
                        (einschließlich Kontaktdaten) aktuell zu halten. Tritt eine Änderung der angegebenen Daten ein,
                        so hast Du die Angaben unverzüglich auf dem Portal in Deinen persönlichen Einstellungen zu
                        korrigieren.</p>
                    <h2 class="" id="7f5fca2b-529b-40d8-9831-a2e41b0810b2"><strong>4.1.2 Sicherung der
                        Zugangsdaten</strong></h2>
                    <p class="" id="47d848d9-5b77-4e5a-8922-9a503b46790e">4.1.2.1 Du hast die Zugangsdaten zur Plattform
                        einschließlich des Passworts sind geheim zu halten und unbefugten Dritten nicht zugänglich zu
                        machen.</p>
                    <p class="" id="6777bb0e-3f74-4d81-a49c-c9843d2613cd">4.1.2.2 Steht zu befürchten, dass unbefugte
                        Dritte von Deinen Zugangsdaten Kenntnis erlangt haben oder erlangen werden, ist NEON
                        unverzüglich zu informieren.</p>
                    <h2 class="" id="34249e76-436f-4423-b690-048df603a4b6"><strong>4.1.3 Änderungen der Dienste</strong>
                    </h2>
                    <p class="" id="f8de5d65-d427-4306-9b45-0acb26ac117a">NEON ist jederzeit berechtigt, auf der
                        Plattform unentgeltlich bereitgestellte Dienste zu ändern, neue Dienste unentgeltlich oder
                        entgeltlich verfügbar zu machen und die Bereitstellung unentgeltlicher Dienste einzustellen.
                        NEON wird hierbei jeweils auf Deine berechtigten Interessen Rücksicht nehmen.</p>
                    <h2 class="" id="1a731357-ff42-4775-94ce-4d9ddf32546f"><strong>4.1.4 Nutzung der
                        Plattform-Inhalte</strong></h2>
                    <p class="" id="a36dc99d-6167-4911-8500-e8c3a0275afe">4.1.4.1 Die auf der Plattform verfügbaren
                        Inhalte sind überwiegend geschützt durch das Urheberrecht oder durch sonstige Schutzrechte und
                        stehen jeweils im Eigentum von NEON, der anderen Teilnehmer oder sonstiger Dritter, welche die
                        jeweiligen Inhalte zur Verfügung gestellt haben.</p>
                    <p class="" id="cb5dccdc-1a22-4c56-a50b-f53fe8771f16">4.1.4.2 Du darfst diese Inhalte lediglich
                        gemäß diesen Nutzungsbedingungen sowie im auf der Plattform vorgegebenen Rahmen nutzen.</p>
                    <p class="" id="2743f7e9-796c-4ef1-abbf-529092e87d83">4.1.4.3 Soweit nicht in diesen
                        Nutzungsbedingungen oder auf der Plattform eine weitergehende Nutzung ausdrücklich erlaubt oder
                        durch eine entsprechende Funktionalität (z.B. Download-Button) ermöglicht wird,</p>
                    <ul class="bulleted-list" id="ff155251-074e-41fc-922a-6a4ef1f65ace">
                        <li>darfst Du die Inhalte der Plattform ausschließlich für persönliche Zwecke online abrufen und
                            anzeigen. Dieses Nutzungsrecht ist auf die Dauer Deiner vertragsgemäßen Teilnahme an der
                            Plattform beschränkt;
                        </li>
                    </ul>
                    <ul class="bulleted-list" id="827bbeb3-68bb-4ac8-854c-426a5f0643e4">
                        <li>ist es Dir untersagt, die auf der Plattform verfügbaren Inhalte ganz oder teilweise zu
                            bearbeiten, zu verändern, zu übersetzen, vorzuzeigen oder vorzuführen, zu veröffentlichen,
                            auszustellen, zu vervielfältigen oder zu verbreiten. Ebenso ist es untersagt,
                            Urhebervermerke, Logos und sonstige Kennzeichen oder Schutzvermerke zu entfernen oder zu
                            verändern.
                        </li>
                    </ul>
                    <p class="" id="41d59640-04fa-4c10-b9da-3e96dec1cd9e">4.1.4.4 Zum Herunterladen von Inhalten
                        („Download“) sowie zum Ausdrucken von Inhalten bist Du nur berechtigt, soweit eine Möglichkeit
                        zum Download bzw. zum Ausdrucken auf dem Portal als Funktionalität (z.B. mittels eines
                        Download-Buttons) zur Verfügung steht.</p>
                    <p class="" id="b47a4d0f-ed12-4016-a538-eb4deeb59128">4.1.4.5 An den von Dir ordnungsgemäß
                        heruntergeladenen bzw. ausgedruckten Inhalten erhältst Du jeweils ein zeitlich unbefristetes und
                        nicht ausschließliches Nutzungsrecht für die Nutzung zu eigenen, nichtkommerziellen Zwecken.</p>
                    <p class="" id="6035a6b9-ad6b-44fd-81a5-f568b988435c">4.1.4.6 Deine zwingenden gesetzlichen Rechte
                        (einschließlich der Vervielfältigung zum privaten und sonstigen eigenen Gebrauch nach § 53 UrhG)
                        bleiben unberührt.</p>
                    <h2 class="" id="b96e9637-64ff-4552-9438-b1ae6bfa6920"><strong>4.1.5 Verbotene Aktivitäten</strong>
                    </h2>
                    <p class="" id="06eb14d6-e9f2-4700-8b4e-5fb0693cc812">4.1.5.1 Die auf der Plattform verfügbaren
                        Dienste sind ausschließlich für die nichtkommerzielle Nutzung durch die Teilnehmer bestimmt.
                        Jede Nutzung für oder im Zusammenhang mit kommerziellen Zwecken ist Dir untersagt.</p>
                    <p class="" id="d427056d-e706-45cc-8cdf-5bbc677d7a76">4.1.5.2 Jegliche Aktivitäten auf bzw. im
                        Zusammenhang mit der Plattform sind untersagt, die gegen geltendes Recht verstoßen, Rechte
                        Dritter verletzen, die Funktionalität der Plattform einschränken können oder gegen die
                        Grundsätze des Jugendschutzes verstoßen.</p>
                    <p class="" id="80898869-5685-4406-a56a-98f8b3e6d8a8">4.1.5.3 Jeder Nutzer darf sich nur ein
                        Nutzerkonto auf der Plattform einrichten. Mehrere Accounts je Nutzer sind untersagt.</p>
                    <h2 class="" id="a54fe058-90e2-4a3a-bf15-9092b638890c"><strong>4.1.6 Sperrung des Zugangs</strong>
                    </h2>
                    <p class="" id="4aac6dc0-f2d1-4b3f-9181-5829d5173e28">4.1.6.1 NEON kann Deinen Zugang zur Plattform
                        vorübergehend oder dauerhaft sperren, wenn konkrete Anhaltspunkte vorliegen, dass Du gegen diese
                        Nutzungsbedingungen und/oder geltendes Recht verstößt oder verstoßen hast, oder wenn NEON ein
                        sonstiges berechtigtes Interesse an der Sperrung hat. Bei der Entscheidung über eine Sperrung
                        wird NEON Deine berechtigten Interessen angemessen berücksichtigen.</p>
                    <p class="" id="efb6eed0-f520-45da-8541-1c0ab9b8fab7">4.1.6.2 Im Falle der vorübergehenden bzw.
                        dauerhaften Sperrung sperrt NEON Deine Zugangsberechtigung und benachrichtigt Dich hierüber per
                        E-Mail.</p>
                    <p class="" id="d553060d-25a0-496c-836d-f099a6484ef3">4.1.6.3 Im Falle einer vorübergehenden
                        Sperrung reaktiviert NEON nach Ablauf der Sperrzeit die Zugangsberechtigung und benachrichtigt
                        Dich hierüber per E-Mail. Eine dauerhaft gesperrte Zugangsberechtigung kann nicht
                        wiederhergestellt werden. Dauerhaft gesperrte Personen sind von der Teilnahme an der Plattform
                        dauerhaft ausgeschlossen und dürfen sich nicht erneut anmelden.</p>
                    <h2 class="" id="8a0cb98b-a937-495d-b79b-572cc81e287b"><strong>4.1.7 Haftung</strong></h2>
                    <h3 class="" id="87a00097-7988-4485-bfbc-4f9cb16f6907">4.1.7.1.1 Kostenlose Dienste</h3>
                    <p class="" id="1651986a-6dd0-4b30-bc9a-833554ef0642">Sollte Dir durch die Nutzung von auf der
                        Plattform unentgeltlich zur Verfügung gestellten Diensten (einschließlich des Abrufs von
                        kostenlosen Inhalten) ein Schaden entstehen, so haftet NEON nur, soweit Dein Schaden aufgrund
                        der vertragsgemäßen Nutzung der unentgeltlichen Inhalte und/oder Dienste entstanden ist, und nur
                        bei Vorsatz (einschließlich Arglist) und grober Fahrlässigkeit von NEON.</p>
                    <h3 class="" id="4d25ed38-d5ce-4bcf-a7cf-d130b6d03337">4.1.7.1.2 Kostenpflichtige Dienste</h3>
                    <p class="" id="d6710bc5-c91f-4e9d-a9d0-57e11a855f0d">Bei Schäden durch Nutzung von entgeltlichen
                        Diensten auf Der Plattform haftet NEON auf Schadensersatz im Rahmen der Verschuldenshaftung bei
                        Vorsatz und grober Fahrlässigkeit. Bei einfacher Fahrlässigkeit haftet NEON vorbehaltlich eines
                        milderen Haftungsmaßstabs nach gesetzlichen Vorschriften (z.B. für Sorgfalt in eigenen
                        Angelegenheiten) nur</p>
                    <p class="" id="35be3da3-148d-487a-9ed7-3ea8730da284">a) für Schäden aus der Verletzung des Lebens,
                        des Körpers oder der Gesundheit,</p>
                    <p class="" id="b0ea6c25-bfe9-4fc6-9263-618dc97e36e7">b) für Schäden aus der nicht unerheblichen
                        Verletzung einer wesentlichen Vertragspflicht (Verpflichtung, deren Erfüllung die ordnungsgemäße
                        Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner
                        regelmäßig vertraut und vertrauen darf); in diesem Fall ist die Haftung jedoch auf den Ersatz
                        des vorhersehbaren, typischerweise eintretenden Schadens begrenzt.</p>
                    <p class="" id="42ab0d63-e2fa-47b5-a8a6-ba1159064208">Die vorstehenden Haftungsbeschränkungen gelten
                        auch bei Pflichtverletzungen durch bzw. zugunsten von Personen, deren Verschulden NEON nach
                        gesetzlichen Vorschriften zu vertreten hat. Sie gelten nicht, soweit NEON einen Mangel arglistig
                        verschwiegen oder eine Beschaffenheitsgarantie übernommen hat sowie für Ansprüche nach dem
                        Produkthaftungsgesetz.</p>
                    <h2 class="" id="c64b418e-c424-4034-942c-f02f2e28f300"><strong>4.1.8 Datenschutzhinweis</strong>
                    </h2>
                    <p class="" id="e97e99cd-99cd-4c5a-9156-91aa37e88cba">NEON beachtet den Datenschutz. Die
                        Einzelheiten kannst Du unserer Datenschutzerklärung unter <a href="https://okourse.com/data">https://okourse.com/data</a>
                        entnehmen.</p>
                    <h3 class="" id="023e2fa2-81ae-48f5-8ef6-d3fab03518ee">4.1.9 Anwendbares Recht, Gerichtsstand,
                        Alternative Streitbeilegung</h3>
                    <p class="" id="aa55cf80-7687-4120-9766-82ff3f840f12">4.1.9.1 Für diese Nutzungsbedingungen und die
                        Vertragsbeziehung zwischen NEON und Dir gilt das Recht der Bundesrepublik Deutschland unter
                        Ausschluss internationalen Einheitsrechts, insbesondere des UN-Kaufrechts. Die gesetzlichen
                        Vorschriften zur Beschränkung der Rechtswahl und zur Anwendbarkeit zwingender Vorschriften
                        insbes. des Staates, in dem der Kunde als Verbraucher seinen gewöhnlichen Aufenthalt hat,
                        bleiben unberührt.</p>
                    <p class="" id="12f21548-28f6-40ac-9fb2-003b73931f7a">4.1.9.2 Ausschließlicher – auch
                        internationaler - Gerichtsstand für alle sich aus dem Vertragsverhältnis unmittelbar oder
                        mittelbar ergebenden Streitigkeiten ist, soweit eine solche Gerichtsstandsvereinbarung zulässig
                        ist, der Geschäftssitz von NEON.</p>
                    <p class="" id="dd0c7113-871a-420e-be1e-c2e5e5094375">4.1.9.3 NEON weist darauf hin, dass Du neben
                        dem ordentlichen Gerichtsweg auch die Möglichkeit einer außergerichtlichen Beilegung von
                        Streitigkeiten hast. Einzelheiten finden sich in Verordnung (EU) Nr. 524/2013 und unter der
                        Internetadresse <a
                                href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a>. Wir
                        weisen nach § 36 VSBG darauf hin, dass wir nicht verpflichtet sind, an einem außergerichtlichen
                        Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                    <p class="" id="fa535748-f4b6-482f-b911-1ef8e58e682f"><strong>Stand dieser Nutzungsbedingungen:
                        09.01.2021</strong></p>
                    <h2 class="" id="4e713cce-2ea7-4753-af69-62a78f7c8b00"><strong>Muster-Widerrufsformular</strong>
                    </h2>
                    <p class="" id="fcf85c8f-2601-4ddf-8326-1f0b740d1dd1">(Wenn Sie den Vertrag widerrufen wollen, dann
                        füllen Sie bitte dieses Formular aus und senden Sie es zurück.)</p>
                    <p class="" id="21b653e5-9647-4dc1-8b83-762b50dd2436">An NEON Software Solutions GmbH, Burgsdorfstr.
                        5, 13353 Berlin, support@neon.dev:</p>
                    <ul class="bulleted-list" id="340c0f0d-932e-45b2-812b-71ce32fc7039">
                        <li>Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf
                            der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)
                        </li>
                    </ul>
                    <ul class="bulleted-list" id="020f1e39-08b2-49aa-a0bc-8517f7d69bba">
                        <li>Bestellt am (*)/erhalten am (*)</li>
                    </ul>
                    <ul class="bulleted-list" id="87d7a2d2-ed0c-487b-a064-41cdfd7b221c">
                        <li>Name des/der Verbraucher(s)</li>
                    </ul>
                    <ul class="bulleted-list" id="41498a19-fa40-40c0-97c4-85c1f6aac427">
                        <li>Anschrift des/der Verbraucher(s)</li>
                    </ul>
                    <ul class="bulleted-list" id="5c2c4538-6cf0-4c2f-8c47-b861c3bccc6c">
                        <li>Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</li>
                    </ul>
                    <ul class="bulleted-list" id="ab77994e-101b-4bb3-8c30-27fdf27443b7">
                        <li>Datum</li>
                    </ul>
                    <ul class="bulleted-list" id="86f9bb28-91b4-4a90-afc5-30320ad1f4a1">
                        <li>--</li>
                    </ul>
                    <p class="" id="f413392a-0b70-4f65-aece-4ee4eaa7409c">(*) Unzutreffendes streichen.</p></div>
            </article>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'AGB',
    };
</script>
<style lang="scss" scoped>
    p {
        margin-bottom: 0.5rem;
        line-height: 1.4;
    }
    h2 {
        margin-top: 2rem;
    }
    li {
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-left: 2rem;
    }
</style>

