<template>
    <div class="flex flex--column flex--center__vert overflowAuto">
        <div class="organizations--wrapper">
            <div class="flex flex--space__hor flex--center__vert organizations--title pad--1 mar--bottom__2">
                <div class="font--s3 font--w700 lineHeight--12">
                    Organisationen
                </div>
                <div class="flex">
                    <ActionButton icon="ion-md-add" :accent="true" @onClick="toggleModal"/>
                    <div class="mar--right__1" />
<!--                    <ActionButton icon="ion-md-more" :light="true" @onClick="toggleMainPopup"-->
<!--                                  v-click-outside="closeMainPopup"/>-->
                </div>
            </div>
            <div class="grid itemGrid pad--2" v-if="organizations.length">
                <div class="itemGrid--slot flex flex--center" v-for="org in organizations" :key="org.organization._id">
                    <div class="card card--move width--100 height--100 clickable itemGrid--inner grid"
                         @click="org.organization.subscription.subscriptionId ?
                         forward(`/dashboard/organizations/${org.organization._id}`) :
                         forward(`/dashboard/organizations/${org.organization._id}/plan`)">
                        <div class="relative itemGrid--image">
                            <div class="absolute top--0 flex flex--center height--100 overflowHidden">
                                <div class="width--100 height--100 overflowHidden flex flex--center">
                                    <RawImage :link="org.organization.thumbnail.link" :auth="true" />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex--column pad--1">
                            <div class="font--s2 font--w700 mar--bottom__1">
                                {{ org.organization.title }}
                            </div>
                            <p>
                                {{ org.organization.description }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <NoDataAction v-if="!organizations.length" @onButtonClick="toggleModal"
            text="Du hast noch keine Organisationen erstellt." button-title="Jetzt Organisation erstellen"
            />
        </div>
        <transition name="fade">
            <Modal title="Neue Organisation" @toggle="resetModal" v-if="showModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <label for="organization_title" class="form--label">Name</label>
                    <input type="text" id="organization_title"
                           class="form--input form--input__text"
                           v-model.trim="orgTitle"
                           v-focus v-required
                    />
                    <label for="organization_description" class="form--label">Beschreibung</label>
                    <textarea id="organization_description" class="form--input form--input__textArea" rows="5"
                              v-model.trim="orgDescription"/>
                    <label for="organization_img" class="form--label">Thumbnail</label>
                    <UploadThumbnail id="organization_img" @file-uploaded="cacheThumb" :cached-thumb-name="cachedThumbName" aspect-ratio="16:9"/>
                    <AccentButton :full-width="true" :filled="true" title="Organisation erstellen"
                                  @onClick="createOrganization"
                                  tabindex="0" ref="validate" :loading="loading"
                    />
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <div class="absolute popup--anchor" v-if="showMainPopup">
                <PopUp :posX="posX" :posY="posY">
                    <div class="popup--item pad--2 clickable font--s16" @click="forward('/dashboard/abos')">
                        Meine Abonnements
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="forward('/dashboard/methods')">
                        Meine Zahlungsdaten
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="forward('/dashboard/invoices')">
                        Meine Rechnungen
                    </div>
                </PopUp>
            </div>
        </transition>
    </div>
</template>
<script>
    import Modal from '@/components/reusable/2_Modals/Modal'
    import AccentButton from '@/components/reusable/1_Buttons/AccentButton'
    import ActionButton from '@/components/reusable/1_Buttons/ActionButton'
    import RawImage from '@/components/reusable/3_Other/RawImage'
    import {handleEsc} from "../../../services/mixins";
    import UploadThumbnail from "../../reusable/3_Other/UploadThumbnail";
    import NoDataAction from "../../reusable/NoDataAction";
    import PopUp from "@/components/reusable/2_Modals/PopUp";

    export default {
        name: 'OrganizationsOverview',
        components: {PopUp, NoDataAction, RawImage, ActionButton, AccentButton, Modal, UploadThumbnail},
        mixins: [handleEsc],
        data() {
            return {
                posX: null,
                posY: null,

                showMainPopup: false,
                showModal: false,
                orgTitle: '', // required
                orgDescription: null,
                cachedThumb: null,
                cachedThumbName: null,
                loading: false,
            }
        },
        computed: {
            organizations() {
                return this.$store.getters.getOrganizations;
            }
        },
        methods: {
            forward(url) {
                this.$router.push(url)
            },
            toggleModal() {
                this.showModal = !this.showModal
            },
            closeMainPopup() {
                this.showMainPopup = false;
            },
            toggleMainPopup(e) {
                this.posX = e.clientX;
                this.posY = e.clientY;

                this.showMainPopup = !this.showMainPopup;
            },
            resetModal() {
                this.showModal = false;
                this.orgTitle = '';
                this.orgDescription = null;
                this.loading = false
            },
            handleEsc() {
                this.resetModal()
            },
            async createOrganization() {
                this.loading = true
                const id = await this.$store.dispatch('createOrganization', {
                    title: this.orgTitle,
                    description: this.orgDescription,
                });
                if (this.cachedThumb) this.uploadThumb(id);
                this.resetModal();
            },
            cacheThumb(fileList) {
                const formData = new FormData();
                formData.append('File', fileList[0], fileList[0].name);
                this.cachedThumb = formData;
                this.cachedThumbName = fileList[0].name;
            },
            async uploadThumb(id) {
                await this.$store.dispatch('uploadOrganizationThumbnail', {
                    organizationID: id,
                    thumbnail: this.cachedThumb,
                });
                this.cachedThumb = null;
                this.cachedThumbName = null;
            },
        },
        mounted() {
            this.$store.dispatch('retrieveOrganizations');
        },
    }
</script>
