<template>
    <div class="flex flex--center">
        <div class="settings--wrapper">
            <ActionHeader title="Einstellungen" back-title="Zurück" @onBack="$router.push(`/dashboard`)">
                <ActionButton icon="ion-md-more" light="true" @onClick="toggleMainPopup" v-click-outside="closeMainPopup"/>
            </ActionHeader>
            <div class="flex flex--column">
                <div class="flex flex--column mar--left__1 font--s2">
                    <div class="font--s14 color--grey lineHeight--1">
                        Vorname
                    </div>
                    <div class="font--w700 lineHeight--12 mar--bottom__1">
                        {{me.firstName}}
                    </div>
                </div>
                <div class="flex flex--column mar--left__1 font--s2">
                    <div class="font--s14 color--grey lineHeight--1">
                        Nachname
                    </div>
                    <div class="font--w700 lineHeight--12 mar--bottom__1">
                        {{ me.lastName }}
                    </div>
                </div>
                <div class="flex flex--column mar--left__1 font--s2">
                    <div class="font--s14 color--grey lineHeight--1">
                      E-Mail
                    </div>
                    <div class="font--w700 lineHeight--12 mar--bottom__1">
                        {{ me.email }}
                    </div>
                </div>
                <div class="flex flex--column mar--left__1 font--s2">
                    <div class="font--s14 color--grey lineHeight--1">
                        Passwort
                    </div>
                    <div class="font--w700 lineHeight--12 mar--bottom__1">
                        **********
                    </div>
                </div>
                <div class="flex flex--column mar--left__1 font--s2">
                    <div class="font--s14 color--grey lineHeight--1">
                        Mitglied seit
                    </div>
                    <div class="font--w700 lineHeight--12 mar--bottom__1">
                        {{ moment(me.createdAt).format('DD.MM.YYYY') }}
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div class="absolute popup--anchor" v-if="showMainPopup">
                <PopUp :posX="posX" :posY="posY">
                    <div class="popup--item pad--2 clickable font--s16" @click="toggleEditInfo">
                        Informationen ändern
                    </div>
                    <div class="popup--item pad--2 clickable font--s16 color--danger" @click="resetPass">
                        Passwort zurücksetzen
                    </div>
                    <div class="popup--item pad--2 clickable font--s16 color--danger" @click="showDeleteModal = true">
                        Account Löschen
                    </div>
                </PopUp>
            </div>
        </transition>
        <transition name="fade">
            <Modal title="Informationen" @toggle="toggleEditInfo" v-if="showEditInfo">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <label for="firstname" class="form--label">Vorname</label>
                    <input type="text" id="firstname"
                           class="form--input form--input__text"
                           v-model="firstName"
                           v-required
                    />
                    <label for="lastname" class="form--label">Nachname</label>
                    <input type="text" id="lastname"
                           class="form--input form--input__text"
                           v-model="lastName"
                           v-required
                    />
                    <label for="email" class="form--label">Email</label>
                    <input type="text" id="email"
                           class="form--input form--input__text mar--bottom__2"
                           v-model="email"
                           v-required
                    />
                    <AccentButton :full-width="true" :filled="true" title="Änderungen Speichern"
                                  ref="validate" tabindex="0" :loading="loading" @onClick="updateUserInfo(false)"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <Modal title="Account Löschung" @toggle="showDeleteModal = false" v-if="showDeleteModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <div class="font--s14 color--grey width--max__50vw">
                        Bitte hab Verständnis, dass wir die Löschung deines Accounts nocheinmal überprüfen müssen, damit auch alle deine Daten gelöscht werden.
                        Normalerweise dauert die Löschung weniger als 24h.
                    </div>
                    <label for="reason" class="form--label">Grund</label>
                    <div class="mar--bottom__2">
                        <v-select :clearable="false"
                                  :options="reasons"
                                  id="reason"
                                  transition="fade"
                                  v-model="reason"/>
                    </div>
                    <label for="description" class="form--label">Beschreibung</label>
                    <textarea id="description"
                           class="form--input form--input__textArea mar--bottom__2"
                           v-model="description"
                           v-required
                    />
                    <AccentButton :full-width="true" :filled="true" title="Antrag abschicken"
                                  ref="validate" tabindex="0" :loading="loading" @onClick="sendMail"/>
                </div>
            </Modal>
        </transition>
        <transition namde="fade">
            <ConfirmationModal title="E-Mail wirklich ändern?"
                               v-if="showEmailChange"
                               @confirm="updateUserInfo(true)"
                               @reject="showEmailChange = false"
                               content="Du bist dabei deine E-Mail zu ändern. Bitte prüfe ob es sich tatsächlich um deine richtige Email handelt." />
        </transition>
    </div>
</template>
<script>
    import ActionHeader from '@/components/views/4_Page/ActionHeader';
    import ActionButton from '@/components/reusable/1_Buttons/ActionButton';
    import PopUp from '@/components/reusable/2_Modals/PopUp';
    import Modal from '@/components/reusable/2_Modals/Modal';
    import AccentButton from '@/components/reusable/1_Buttons/AccentButton';
    import ConfirmationModal from "@/components/reusable/2_Modals/ConfirmationModal";
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    export default {
        name: 'SettingsLoader',
        components: {ConfirmationModal, AccentButton, Modal, PopUp, ActionButton, ActionHeader, vSelect},
        data() {
            return {
                posX: null,
                posY: null,

                showMainPopup: false,
                showEditInfo: false,
                showEmailChange: false,
                showDeleteModal: false,

                firstName: '',
                lastName: '',
                email: '',
                description: null,
                reasons: [
                    'Besserer Anbieter',
                    'Unzufrieden',
                    'Keinen Bedarf',
                    'Sonstiges'
                ],
                reason: null,

                loading: false,
            };
        },
        computed: {
            me() {
                return this.$store.getters.getUser;
            },
        },
        methods: {
            sendMail() {
                if (!this.description || !this.reason) {
                    this.$store.commit('addToast', {
                        type: 'warning',
                        title: 'Bitte gib einen Grund an.'
                    });
                    return;
                }
                let success = true;
                this.$store.dispatch('sendAuthMail', {
                    body: this.description,
                    subject: this.reason,
                }).catch(() => {
                    success = false;
                }).then(() => {
                    if (success) {
                        this.$store.commit('addToast', {
                            type: 'success',
                            title: 'Wir werden uns in Kürze bei dir melden.'
                        });
                        this.showDeleteModal = false;
                    }
                })
            },
            toggleMainPopup(e) {
                this.posX = e.clientX;
                this.posY = e.clientY;

                this.showMainPopup = !this.showMainPopup;
            },
            closeMainPopup() {
                this.showMainPopup = false;
            },
            toggleEditInfo() {
                this.showEditInfo = !this.showEditInfo;
            },
            resetPass() {
                this.$store.dispatch('forgotPassword', {
                    email: this.me.email,
                }).then(() => {
                    this.$store.commit('addToast', {
                        type: 'success',
                        title: 'Schau in deine Mails',
                    })
                })
            },
            async updateUserInfo(overwrite) {
                if (this.email !== this.me.email && !overwrite) {
                    this.showEmailChange = true;
                } else {
                    this.showEmailChange = false;
                    this.loading = true;
                    await this.$store.dispatch('updateOwnEditor', {
                        firstName: this.firstName !== this.me.firstName ? this.firstName : undefined,
                        lastName: this.lastName !== this.me.lastName ? this.lastName : undefined,
                        email: this.email !== this.me.email ? this.email : undefined,
                    }).then(() => {
                        this.showEditInfo = false;
                    });
                    this.loading = false;
                }
            },
        },
        async mounted() {
            await this.$store.dispatch('retrieveOwnEditor');
            this.firstName = this.me.firstName;
            this.lastName = this.me.lastName;
            this.email = this.me.email;
        }
    };
</script>
