<template>
  <div  class="flex flex--column flex--center__vert overflowAuto">
    <div class="courses--wrapper">
      <ActionHeader back-title="Alle Mitglieder" :title="member.firstName + ' ' + member.lastName" @onBack="$router.go(-1)" >
        <div class="flex">
          <ActionButton icon="ion-md-trash" light="true" @onClick="showDeleteModal = true" class="mar--right__1" v-if="organization.role === 'Creator' || organization.role === 'Editor'" />
          <ActionButton icon="ion-md-create" accent="true" @onClick="showEditMemberModal = true" />
        </div>
      </ActionHeader>
      <div class="flex">
        <div class="font--s2 mar--1 color--grey transition clickable" :class="{'members--select': selectedTab === 0}" @click="selectedTab = 0">
          Allgemein
        </div>
        <div class="font--s2 mar--1 color--grey transition clickable" :class="{'members--select': selectedTab === 1}" @click="selectedTab = 1">
          Kurse
        </div>
        <div class="font--s2 mar--1 color--grey transition clickable" :class="{'members--select': selectedTab === 2}" @click="selectedTab = 2">
          Historie
        </div>
      </div>
      <div>
        <transition name="fade-move" mode="out-in">
          <div class="flex flex--column pad--1" :key="0" v-if="selectedTab === 0">
            <div class="grid grid--col__2">
              <div class="card pad--2 flex flex--column" v-if="!member.firstLogin">
                <div class="flex flex--space__hor">
                  <div class="font--s16">Beigetreten am:</div>
                  <div class="font--s16 font--w700">{{ moment(member.createdAt).format('DD.MM.YYYY')}}</div>
                </div>
              </div>
              <div class="card pad--2 flex flex--column">
                <div class="flex flex--space__hor">
                  <div class="font--s16">Bereits eingeloggt:</div>
                  <div class="font--s16 font--w700">{{ member.firstLogin ? 'Nein' : 'Ja'}}</div>
                </div>
              </div>
              <div class="card pad--2 flex flex--column" v-if="!member.firstLogin">
                <div class="flex flex--space__hor">
                  <div class="font--s16">Letzter Login:</div>
                  <div class="font--s16 font--w700">{{ moment(member.lastLogin).format('DD.MM.YYYY - HH:mm') + ' Uhr'}}</div>
                </div>
              </div>
              <div class="card pad--2 flex flex--column">
                <div class="flex flex--space__hor">
                  <div class="font--s16">Logins (erfolgreich):</div>
                  <div class="font--s16 font--w700">{{ member.successfulLoginCount }}</div>
                </div>
              </div>
              <div class="card pad--2 flex flex--column">
                <div class="flex flex--space__hor">
                  <div class="font--s16">Logins (fehlgeschlagen):</div>
                  <div class="font--s16 font--w700">{{ member.failedLoginAttempts }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex--column pad--1" :key="1" v-if="selectedTab === 1">
            <div class="form--label">Neues Angebot</div>
            <div class="flex flex--column width--100 mar--bottom__2">
              <div class="flex flex--center__vert width--100 mar--bottom__1">
                <div class="flex--grow">
                  <v-select :clearable="false"
                            :options="offers"
                            id="chapter"
                            label="title"
                            transition="fade"
                            v-model="newOffer"/>
                </div>
                <div class="mar--right__1" />
                <ActionButton icon="ion-md-add" :accent="newOffer" :light="!newOffer" @onClick="newOffer ? addOffer() : null"/>
              </div>
              <div class="flex flex--center__vert" v-if="newOffer">
                <input type="text" id="offer_order" class="form--input form--input__text mar--right__1" placeholder="Bestellnummer" v-model="orderID"/>
                <input type="text" id="offer_key" class="form--input form--input__text mar--right__1" placeholder="Produktschlüssel" v-model="externalID"/>
                <div class="flex flex--center__vert lineHeight--1">
                  <div class="mar--right__1">Voller Zugang</div>
                  <div class="checkbox circle border--light clickable mar--right__1 flex flex--center"
                       @click="() => { fullAccess = !fullAccess; $forceUpdate(); }"
                       :class="{'checkbox--filled': fullAccess}">
                    <i class="ion-ios-checkmark color--white font--s2 lineHeight--1" v-if="fullAccess"/>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex--center__vert flex--space__hor border--bottom__light pad--bottom__1">
              <div class="form--label">Vorhandene Angebote</div>
              <AccentButton title="Speichern" filled="true" :loading="isLoading" @onClick="updateMember" />
            </div>
            <div class="flex flex--column mar--bottom__1 pad--1 overflowAuto">
              <div class="flex flex--column font--s16 card mar--bottom__1" v-for="offer in cachedMember.offers" :key="offer._id">
                <div class="grid userCourseGrid pad--1">
                  <div class="flex flex--column flex--center__hor lineHeight--1 mar--right__1 font--w700 font--s2">
                    {{ offer.title }}
                    <div v-if="offer.orderId" class="font--s1 font--w500 color--grey lineHeight--12">Bestellnr: {{ offer.orderId }}</div>
                  </div>
                  <div class="flex flex--center__vert lineHeight--1 mar--right__1">
                    <div class="mar--right__1">Voller Zugang</div>
                    <div class="checkbox circle border--light clickable mar--right__1 flex flex--center"
                         @click="() => { offer.fullAccess = !offer.fullAccess; $forceUpdate(); }"
                         :class="{'checkbox--filled': offer.fullAccess}">
                      <i class="ion-ios-checkmark color--white font--s2 lineHeight--1" v-if="offer.fullAccess"/>
                    </div>
                  </div>
                  <div>
                    <ActionButton icon="ion-md-close" light="true" @onClick="removeCourse(offer)"/>
                  </div>
                </div>
                <div class="flex flex--column mar--left__2 pad--bottom__1 font--s16" v-if="offer.courses">
                  <div v-for="(course, ind) in offer.courses" :key="course._id" class="flex flex--column">
                    {{ind + 1}}. {{ course.title }}
                    <div class="font--s16 color--grey flex flex--center__vert mar--left__2 mar--bottom__1" v-if="course.certificationPossible">
                      <div class="mar--right__1">
                        Zertifikat vorhanden
                      </div>
                      <ActionButton icon="ion-md-trash" @onClick="deleteCertificate(course)"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex--column pad--top__2 pad--bottom__2" :key="2" v-if="selectedTab === 2">
            <div v-for="(item, ind) in member.history" :key="item._id">
              <HistoryItem :item="item" :first="ind === 0" />
            </div>
          </div>
        </transition>
      </div>
    </div>



    <transition name="fade">
      <Modal title="Nutzerdetails" @toggle="showEditMemberModal = !showEditMemberModal" v-if="showEditMemberModal">
        <div class="pad--25 flex flex--grow flex--column flex--center__hor">
          <label for="member_firstName" class="form--label">Vorname</label>
          <input type="text" id="member_firstName" class="form--input form--input__text"
                 v-model="cachedMember.firstName"/>
          <label for="member_lastName" class="form--label">Nachname</label>
          <input type="text" id="member_lastName" class="form--input form--input__text"
                 v-model="cachedMember.lastName"/>
          <label for="member_email" class="form--label">E-Mail</label>
          <input type="text" id="member_email" class="form--input form--input__text mar--bottom__1" v-model="cachedMember.email"/>
          <div class="flex flex--space__hor flex--center__vert lineHeight--1 mar--bottom__2">
            <div class="mar--right__1 font--s16 font--w700">Nutzer gesperrt</div>
              <div class="checkbox circle border--light clickable mar--right__1 flex flex--center"
                   @click="() => { cachedMember.locked = !cachedMember.locked; $forceUpdate(); }"
                   :class="{'checkbox--filled': cachedMember.locked}">
                <i class="ion-ios-checkmark color--white font--s2 lineHeight--1" v-if="cachedMember.locked"/>
            </div>
          </div>
          <AccentButton :full-width="true" :filled="true" title="Änderungen speichern" class="mar--bottom__1" :loading="isLoading"
                        @onClick="updateMember"/>
          <div class="flex">
            <div>
              <AccentButton title="Passwort zurücksetzen" @onClick="resetPassword" />
            </div>
          </div>
        </div>
      </Modal>
    </transition>

    <transition name="fade">
      <ConfirmationModal title="Möchtest du die E-Mail ändern?" @toggle="showConfirmationModal = false" v-if="showConfirmationModal"
                         content="Bist du dir sicher dass du die E-Mail Adresse des Nutzers verändern möchtest?"
                         @confirm="updateMember(true)" @reject="() => { this.showConfirmationModal = false; this.showEditMemberModal = false}" />
    </transition>
    <transition name="fade">
      <ConfirmationModal title="Möchtest du den Nutzer löschen?" @toggle="showDeleteModal = false" v-if="showDeleteModal"
                         content="Bist du dir sicher dass du den Nutzer löschen möchtest? Alle Kurse und Zugänge gehen verloren."
                         @confirm="deleteMember" @reject="showDeleteModal = false" />
    </transition>
  </div>
</template>

<script>
import ActionHeader from "@/components/views/4_Page/ActionHeader";
import ActionButton from "@/components/reusable/1_Buttons/ActionButton";
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'
import Modal from "@/components/reusable/2_Modals/Modal";
import ConfirmationModal from "@/components/reusable/2_Modals/ConfirmationModal";
import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
import _ from 'lodash';
import HistoryItem from "@/components/reusable/3_Other/HistoryItem";
export default {
  name: 'MemberDetails',
  components: {HistoryItem, AccentButton, ConfirmationModal, Modal, ActionButton, ActionHeader, vSelect},
  props: ['user'],
  data() {
    return {
      selectedTab: 0,
      cachedMember: {},
      newOffer: null,
      orderID: null,
      externalID: null,
      fullAccess: false,

      showConfirmationModal: false,
      showDeleteModal: false,
      showEditMemberModal: false,

      isLoading: false,
    }
  },
  computed: {
    offers() {
      return this.$store.getters.getOffers;
    },
    member() {
      return this.$store.getters.getDetailedMember;
    },
    organization() {
      return this.$store.getters.getOrganizationById(this.$route.params.organizationID)
    },
  },
  methods: {
    async updateMember(confirmed) {
      if (this.member.email !== this.cachedMember.email && !confirmed) {
        this.showConfirmationModal = true;
      } else {
        this.isLoading = true;
        await this.member.offers.forEach((el) => {
          if (this.cachedMember.offers.findIndex((el2) => el2._id === el._id) < 0) {
            this.$store.dispatch('deleteMemberOffer', {
              organizationID: this.$route.params.organizationID,
              memberID: this.member._id,
              offerID: el._id,
            })
          }
        });
        if (this.cachedMember.offers.length) {
          await this.cachedMember.offers.forEach((el) => {
            /* eslint-disable */
            console.log(this.member.offers.findIndex((el2) => el2._id === el._id));
            if (this.member.offers.findIndex((el2) => el2._id === el._id) >= 0) {
              this.$store.dispatch('updateMemberOffer', {
                organizationID: this.$route.params.organizationID,
                memberID: this.member._id,
                offerID: el._id,
                data: {
                  fullAccess: el.fullAccess,
                }
              })
            } else {
              this.$store.dispatch('addMemberOffer', {
                organizationID: this.$route.params.organizationID,
                memberID: this.member._id,
                data: {
                  joinDate: el.joinDate,
                  customFullAccess: el.fullAccess,
                  orderId: el.orderId ? el.orderId : undefined,
                  externalId: el.externalId ? el.externalId : el._id,
                }
              })
            }
          })
        }
        let success = true;
        await this.$store.dispatch('updateMember', {
          organizationID: this.$route.params.organizationID,
          memberID: this.member._id,
          data: {
            firstName: this.member.firstName !== this.cachedMember.firstName ? this.cachedMember.firstName : undefined,
            lastName: this.member.lastName !== this.cachedMember.lastName ? this.cachedMember.lastName : undefined,
            email: this.member.email !== this.cachedMember.email ? this.cachedMember.email : undefined,
            locked: this.member.locked !== this.cachedMember.locked ? this.cachedMember.locked : undefined,
            // notes: this.member.notes,
          },
        }).catch(() => {
          success = false;
          this.$store.commit('addToast', {
            type: 'warning',
            title: 'Bitte überprüfen sie ihre Eingaben.',
          });
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        }).then(async () => {
          if (success) {
            await this.$store.dispatch('retrieveDetailedMember', {
              organizationID: this.$route.params.organizationID,
              memberID: this.$route.params.memberID,
            });
            this.cachedMember = _.cloneDeep(this.member);
            this.showEditMemberModal = false;
            this.showConfirmationModal = false;
            this.isLoading = false;
            this.searchString = '';
          }
        });
      }
    },
    deleteCertificate(course) {
      /* eslint-disable */
      console.log(course);
      this.$store.dispatch('removeCertificate', {
        organizationID: this.$route.params.organizationID,
        courseID: course._id,
        memberID: this.member._id,
      }).then(() => {
        this.$store.commit('addToast', {
          type: 'success',
          title: 'Zertifikat gelöscht',
        })
      });
    },
    addOffer() {
      if (this.newOffer) {
        /* eslint-disable */
        console.log(this.newOffer);
        console.log(this.cachedMember.offers);
        if (this.cachedMember.offers.findIndex((el) => el._id === this.newOffer._id) >= 0) {
          this.newOffer = null;
          this.$store.commit('addToast', {
            type: 'warning',
            title: 'Der Nutzer hat bereits Zugriff auf dieses Angebot',
          });
        } else {
          this.cachedMember.offers.push({
            _id: this.newOffer._id,
            title: this.newOffer.title,
            externalId: this.externalID,
            orderId: this.orderID,
            joinDate: new Date().toISOString(),
            fullAccess: this.fullAccess,
          });
          this.newOffer = null;
          this.externalID = null;
          this.orderID = null;
        }
      }
    },
    removeCourse(offer) {
      this.cachedMember.offers = this.cachedMember.offers.filter((el) => el._id !== offer._id);
    },
    async deleteMember() {
      await this.$store.dispatch('deleteMember', {
        organizationID: this.$route.params.organizationID,
        memberID: this.memberCache._id,
      });
      this.showDeleteModal = false;
    },
    async resetPassword() {
      let succes = true;
      await this.$store.dispatch('forgotMemberPassword', {
        email: this.member.email,
        organizationID: this.$route.params.organizationID,
      }).catch(() => {
        succes = false;
        this.$store.commit('addToast', {
          type: 'warning',
          title: 'Zurücksetzen fehlgeschlagen'
        });
      });
      if (succes) {
        this.$store.commit('addToast', {
          type: 'success',
          title: 'Die Email wurde verschickt'
        });
        this.showEditMemberModal = false;
      }
    }
  },
  async mounted() {
    this.$store.dispatch('retrieveOrganizations');
    this.$store.dispatch('retrieveOffers', this.$route.params.organizationID);
    await this.$store.dispatch('retrieveDetailedMember', {
      organizationID: this.$route.params.organizationID,
      memberID: this.$route.params.memberID,
    });
    this.cachedMember = _.cloneDeep(this.member);
  }
}
</script>