<template>
    <div class="flex flex--column">
        <div class="flex">
            <div class="flex font--s16 pad--left__1 lineHeight--12 clickable" @click="$emit('onBack')">
                <i class="ion-ios-arrow-back mar--right__1"/>
                <div class="flex">
                    {{backTitle}}
                </div>
            </div>
        </div>
        <div class="flex flex--space__hor flex--center__vert courses--title pad--1 mar--bottom__2">
            <div class="font--s3 font--w700 lineHeight--12">{{title}}</div>
            <slot>

            </slot>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'ActionHeader',
        props: {
            backTitle: {
                type: String,
                default: 'All Chapters'
            },
            title: {
                type: String,
                default: ''
            },
        }
    }
</script>
