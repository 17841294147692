<template>
    <div class="flex flex--column flex--center__vert overflowAuto">
        <div class="courses--wrapper">
            <div class="flex flex--center__vert lineHeight--1 font--s2 clickable" @click="$router.push(`/dashboard/organizations/${$route.params.organizationID}/settings`)">
                <i class="ion-ios-arrow-back mar--right__1 pad--left__1" />
                <div>
                    Alle Kurse
                </div>
            </div>
            <div class="flex flex--space__hor flex--center__vert courses--title pad--1 mar--bottom__2">
                <div class="font--s3 font--w700 lineHeight--12">
                    Organisationsmitglieder
                </div>
                <div class="flex relative">
                    <div class="mar--right__1">
                        <ActionButton icon="ion-md-add" :accent="true" @onClick="toggleInviteEditorModal" v-if="organization.role === 'Creator'"/>
                    </div>
                </div>
            </div>
            <EditorsList />
        </div>
        <transition name="fade">
            <Modal title="Neues Organisationsmitglied" @toggle="toggleInviteEditorModal" v-if="showInviteEditorModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <label for="member_firstName" class="form--label">Email</label>
                    <input type="text" id="member_firstName" class="form--input form--input__text mar--bottom__2" v-model="editorEmail"/>
                    <AccentButton :full-width="true" :filled="true" title="Mitglied einladen" @onClick="inviteEditor"/>
                </div>
            </Modal>
        </transition>
    </div>
</template>
<script>
    import Modal from '@/components/reusable/2_Modals/Modal';
    import AccentButton from '@/components/reusable/1_Buttons/AccentButton';
    import ActionButton from '@/components/reusable/1_Buttons/ActionButton';
    import EditorsList from '@/components/views/6_Editors/EditorsList';
    export default {
        name: 'EditorsLoader',
        components: {EditorsList, ActionButton, AccentButton, Modal},
        data() {
            return {
                showInviteEditorModal: false,
                editorEmail: null,
            };
        },
        computed: {
            organization() {
                return this.$store.getters.getOrganizationById(this.$route.params.organizationID)
            },
        },
        methods: {
            forward(url) {
                this.$router.push(`${this.$route.path}/${url}`);
            },
            toggleInviteEditorModal() {
                this.showInviteEditorModal = !this.showInviteEditorModal;
            },
            inviteEditor() {
                this.$store.dispatch('inviteEditor', {
                    organizationID: this.$route.params.organizationID,
                    editor: {
                        email: this.editorEmail,
                        role: 'Editor',
                    },
                }).then(() => {
                    this.toggleInviteEditorModal();
                    this.editorEmail = null;
                });
            },
        },
        mounted() {
            this.$store.dispatch('retrieveEditors', this.$route.params.organizationID);
        },
    };
</script>
