<template>
    <div class="flex flex--column flex--center__vert overflowAuto">
        <div class="organizations--wrapper">
            <ActionHeader back-title="Alle Organisationen" title="Abonnements" @onBack="$router.push('/dashboard')" />
            <transition name="fade" mode="out-in">
                <div class="height--total__70 width--100 flex flex--center" v-if="isLoading">
                    <loader :loading="true" color="#114B5F" :size="'1rem'"/>
                </div>
                <div class="flex flex--column" v-if="!isLoading && subscriptions.active.length">
                    <div class="relative width--100 pad--3 border--bottom__light flex flex--column" v-for="sub in subscriptions.active" :key="sub._id">
                        <div class="absolute top--2 right--2">
                            <div class="relative">
                                <ActionButton icon="ion-md-more" light="true" @onClick="(e) => toggleMainPopup({e, id: sub})"
                                              v-click-outside="closeMainPopup"/>
                            </div>
                        </div>
                        <div class="flex mar--bottom__1 font--s2 font--w700 color--danger" v-if="sub.cancelAtPeriodEnd">
                            Zum {{ moment(sub.currentPeriodEnd * 1000).format('DD.MM.YYYY') }} gekündigt
                        </div>
                        <div class="flex mar--bottom__1 font--s2 font--w700 color--danger" v-if="!sub.cancelAtPeriodEnd && sub.metadata.downgrade">
                            Zum {{ moment(sub.currentPeriodEnd * 1000).format('DD.MM.YYYY') }} auf niedrigeren Plan gewechselt
                        </div>
                        <div class="grid grid--col__2 mar--bottom__2">
                            <div class="font--s16 flex flex--column lineHeight--1 mar--right__2">
                                <div class="font--s14 color--grey">
                                    Abotyp
                                </div>
                                <div class="font--w700 font--s2">
                                    {{ sub.plan.nickname }}
                                </div>
                            </div>
                            <div class="font--s16 flex flex--column lineHeight--1 mar--right__2">
                                <div class="font--s14 color--grey">
                                    Organisation
                                </div>
                                <div class="font--w700 font--s2">
                                    {{ organization(sub.metadata.organization).organization.title }}
                                </div>
                            </div>
                            <div class="font--s16 flex flex--column lineHeight--1 mar--right__2">
                                <div class="font--s14 color--grey">
                                    Status
                                </div>
                                <div class="font--w700 font--s2">
                                    {{ sub.status === 'active' ? 'Aktiv' : sub.status === 'past_due' ? 'Verzug' : 'Inaktiv' }}
                                </div>
                            </div>
                        </div>
                        <div class="grid grid--col__2 mar--bottom__2 mar--bottom__2">
                            <div class="font--s16 flex flex--column lineHeight--1 mar--right__2">
                                <div class="font--s14 color--grey">
                                    Abrechnung
                                </div>
                                <div class="font--s2">
                                    {{ sub.plan.interval === 'year' ? 'Jährlich' : 'Monatlich' }}
                                </div>
                            </div>
                            <div class="font--s16 flex flex--column lineHeight--1 mar--right__2">
                                <div class="font--s14 color--grey">
                                    Monatlicher Betrag
                                </div>
                                <div class="font--s2">
                                    {{ sub.plan.interval === 'year' ? (sub.plan.amount / 12) / 100 : sub.plan.amount / 100 }}€
                                </div>
                            </div>
                            <div class="font--s16 flex flex--column lineHeight--1 mar--right__2">
                                <div class="font--s14 color--grey">
                                    Gesamtbetrag
                                </div>
                                <div class="font--s2">
                                    {{ sub.plan.amount / 100 }}€
                                </div>
                            </div>
                        </div>
                        <div class="grid grid--col__2 mar--bottom__2">
                            <div class="font--s16 flex flex--column lineHeight--1 mar--right__2">
                                <div class="font--s14 color--grey">
                                    Startdatum
                                </div>
                                <div class="font--s2">
                                    {{ moment(sub.startDate * 1000).format('DD.MM.YYYY') }}
                                </div>
                            </div>
                            <div class="font--s16 flex flex--column lineHeight--1 mar--right__2">
                                <div class="font--s14 color--grey">
                                    Abrechnungsperiode
                                </div>
                                <div class="font--s2">
                                    {{ moment(sub.currentPeriodStart * 1000).format('DD.MM.YYYY') }} - {{ moment(sub.currentPeriodEnd * 1000).format('DD.MM.YYYY') }}
                                </div>
                            </div>
                            <div class="font--s16 flex flex--column lineHeight--1 mar--right__2">
                                <div class="font--s14 color--grey">
                                    Nächste Zahlung
                                </div>
                                <div class="font--s2">
                                    {{ sub.cancelAtPeriodEnd ? 'Gekündigt' : `in ${moment(sub.currentPeriodEnd * 1000).diff(new Date(), 'days')} Tagen` }}
                                </div>
                            </div>
                        </div>
                        <div class="grid grid--col__2 mar--bottom__2">
                            <div class="font--s16 flex flex--column lineHeight--1 mar--right__2">
                                <div class="font--s14 color--grey">
                                    Rechnungsadressat
                                </div>
                                <div class="font--s2">
                                    {{ methodById(sub.paymentMethod).billing_details.name }}
                                </div>
                            </div>
                            <div class="font--s16 flex flex--column lineHeight--1 mar--right__2">
                                <div class="font--s14 color--grey">
                                    Zahlungsart
                                </div>
                                <div class="font--s2">
                                    {{ methodById(sub.paymentMethod).card.brand.toUpperCase() }} - {{ methodById(sub.paymentMethod).card.last4 }}
                                </div>
                            </div>
                            <div class="font--s16 flex flex--column lineHeight--1 mar--right__2">
                                <div class="font--s14 color--grey">
                                    Rechnungsadresse
                                </div>
                                <div class="font--s2 lineHeight--12">
                                    <div v-if="methodById(sub.paymentMethod).billing_details.address.line1">
                                        {{ methodById(sub.paymentMethod).billing_details.address.line1 }} <br/>
                                    </div>
                                    <div v-if="methodById(sub.paymentMethod).billing_details.address.line2">
                                        {{ methodById(sub.paymentMethod).billing_details.address.line2 }} <br/>
                                    </div>
                                    <div v-if="methodById(sub.paymentMethod).billing_details.address.postal_code">
                                        {{methodById(sub.paymentMethod).billing_details.address.postal_code }} {{ methodById(sub.paymentMethod).billing_details.address.city }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NoDataAction v-if="!subscriptions.active.length  && !isLoading" @onButtonClick="this.$('/dashboard')"
                              text="Du hast noch keine Abonnements." button-title="Zu meinen Organisationen"
                />
            </transition>
        </div>
        <transition name="fade">
            <Modal title="Abo wechseln" @toggle="showChangeAbo = false" v-if="showChangeAbo">
                <div class="pad--25 flex flex--grow height--100 flex--column flex--center overflowAuto">
                    <p class="mar--bottom__2 color--grey">
                        Der Wechsel von einem niedrigeren Abo zu einem höheren Abo oder von einem monatlichen Abo zu einem jährlichen Abo, wird sofort durchgeführt. Anderenfalls erfolgt der Wechsel erst zum Ende der vorhergehenden Laufzeit.
                    </p>
                    <div class="width--10 flex mar--bottom__2">
                        <ToggleButton :value="yearly" @toggleValue="yearly = !yearly" false="Jährlich" true="Monatlich" same="true"/>
                    </div>
                    <div class="grid organizations--plan__wrapper width--100 mar--bottom__2">
                        <div class="width--100 height--100 flex flex--center pad--2" v-for="plan in filteredPlans" :key="plan._id">
                            <div class="card card--move organizations--plan bgColor--white"
                                 :class="{'organizations--plan__selected': selectedPlan === plan.position, 'organizations--plan__recommended': 2 === plan.position}"
                                 @click="selectedPlan = plan.position">
                                <div class="font--w700 font--s2 mar--bottom__1">
                                    {{ plan.nickname.split(' ')[0] }}
                                </div>
                                <div class="font--s5 lineHeight--1">
                                    {{ plan.type === 'year' ? (plan.amount / 12) / 100 : (plan.amount / 100) }}€
                                </div>
                                <div class="font--s16 mar--bottom__1">
                                    /Monat
                                </div>
                                <ul class="flex flex--column font--s16 mar--bottom__1">
                                    <li class="noDec flex flex--center__vert lineHeight--12" v-for="(point, ind) in plan.sellingPoints" :key="ind + point">
                                        <i class="ion-md-checkmark font--s25 color--primaryLight mar--right__1" />
                                        <div>
                                            {{ point }}
                                        </div>
                                    </li>
                                </ul>
                                <div class="width--100 font--s16 font--w700 flex flex--center pad--05 bgColor--primaryLight b-radius--5 color--white" v-if="plan.id === selectedAbo.plan.id">
                                    Dein aktuelles Abo
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                    <AccentButton :full-width="true" :filled="true" :title="selectedPlan ? changeText : 'Wähle ein Abo'"
                                  :loading="isLoading"
                                  :disable="!selectedPlan" @onClick="switchAbo(filteredPlans[selectedPlan - 1])"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <Modal title="Zahlungsart ändern" @toggle="showChangeMethod = false" v-if="showChangeMethod">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <div class="flex flex--center__vert">
                        <div class="mar--right__1">
                            <ActionButton icon="ion-ios-arrow-back" v-if="methods.length > 2" @onClick="navigate(false)"/>
                        </div>
                        <div class="flex mar--bottom__2 overflowAuto--hor" id="scroll--payments">
                            <div class="card clickable pad--3 b-radius--5 mar--2 bgColor--white"
                                 :class="{'card--selected__outline': selected === method.id}"
                                 v-for="method in methods" :key="method.id">
                                <CreditCard :method="method" />
                                <div class="font--s2 font--w700">
                                    Rechnungsadresse
                                </div>
                                <div class="font--s16">
                                    {{ method.billing_details.name }}
                                </div>
                                <div class="font--s16">
                                    {{ method.billing_details.address.line1 }}
                                </div>
                                <div class="font--s16">
                                    {{ method.billing_details.address.line2 }}
                                </div>
                                <div class="font--s16 mar--bottom__2">
                                    {{ method.billing_details.address.postal_code }} {{ method.billing_details.address.city }}
                                </div>
                                <div class="width--100">
                                    <AccentButton :title="selected === method.id ? 'Nicht mehr auswählen' : 'Auswählen'" @onClick="selected = selected === method.id ? null : method.id"/>
                                </div>
                            </div>
                        </div>
                        <div class="mar--left__1">
                            <ActionButton icon="ion-ios-arrow-forward" v-if="methods.length > 2" @onClick="navigate(true)"/>
                        </div>
                    </div>
                    <AccentButton :full-width="true" :filled="true" :title="selected ? 'Änderungen Speichern' : 'Neue Zahlungsart auswählen'" :disable="!selected" @onClick="changeMethod"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <ConfirmationModal title="Möchtest du das Abo wirklich löschen?"
                               @reject="showCancelAbo = false"
                               @confirm="cancelAbo"
                               v-if="showCancelAbo"
                               content="Sobald du das Abo gelöscht hast wird die zugeordnete Organisation zum Ende der Laufzeit deaktiviert" />
        </transition>
        <transition name="fade">
            <div class="absolute popup--anchor" v-if="showMainPopup">
                <PopUp :posX="posX" :posY="posY">
                    <div class="popup--item pad--2 clickable font--s16" @click="showChangeAbo = !showChangeAbo">
                        Abo wechseln
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="showChangeMethod = !showChangeMethod">
                        Zahlunsart ändern
                    </div>
                    <div class="popup--item pad--2 clickable font--s16 color--danger"
                         v-if="!selectedAbo.cancelAtPeriodEnd"
                         @click="showCancelAbo = !showCancelAbo">
                        Abo kündigen
                    </div>
                    <div class="popup--item pad--2 clickable font--s16 color--danger"
                         v-if="selectedAbo.cancelAtPeriodEnd"
                         @click="revokeCancelation">
                        Kündigung zurücknehmen
                    </div>
                </PopUp>
            </div>
        </transition>
    </div>
</template>
<script>
    import ActionHeader from "@/components/views/4_Page/ActionHeader";
    import ActionButton from "@/components/reusable/1_Buttons/ActionButton";
    import PopUp from "@/components/reusable/2_Modals/PopUp";
    import ConfirmationModal from "@/components/reusable/2_Modals/ConfirmationModal";
    import Modal from "@/components/reusable/2_Modals/Modal";
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    import NoDataAction from "@/components/reusable/NoDataAction";
    import CreditCard from "@/components/reusable/3_Other/CreditCard";
    import ToggleButton from "@/components/reusable/1_Buttons/ToggleButton";
    import moment from 'moment';
    export default {
        name: 'OrganizationAbos',
        components: {
            ToggleButton,
            CreditCard, NoDataAction, AccentButton, Modal, ConfirmationModal, PopUp, ActionButton, ActionHeader},
        data() {
            return {
                posX: null,
                posY: null,
                isLoading: false,

                selected: null,
                selectedAbo: null,
                selectedPlan: null,

                yearly: true,

                showMainPopup: false,
                showChangeAbo: false,
                showChangeMethod: false,
                showCancelAbo: false,
            };
        },
        computed: {
            subscriptions() {
                /* eslint-disable */
                console.log(this.$store.getters.getSubscriptions);
                return this.$store.getters.getSubscriptions;
            },
            organizations() {
                return this.$store.getters.getOrganizations();
            },
            methods() {
                return this.$store.getters.getMethods;
            },

            plans() {
                return this.$store.getters.getPlans;
            },
            filteredPlans() {
                if (this.plans) {
                    return this.plans.filter((el) => this.yearly ? el.type === 'year' : el.type === 'month');
                }
                return [];
            },
            changeText() {
                if (this.selectedPlan) {
                    if (this.filteredPlans[this.selectedPlan - 1].id === this.selectedAbo.plan.id) {
                        return 'Dein aktuelles Abo';
                    }
                    const currentPlan = this.plans.find(el => el.id === this.selectedAbo.plan.id);
                    console.log(this.selectedAbo);
                    console.log(currentPlan);
                    if (this.selectedPlan >= currentPlan.position && (this.filteredPlans[this.selectedPlan - 1].type === this.selectedAbo.plan.type || this.selectedAbo.plan.type === 'year' )) {
                        return 'Jetzt wechseln'
                    }
                    return `Zum ${moment(this.selectedAbo.currentPeriodEnd * 1000).format('DD.MM.YYYY')} wechseln`;
                }
            },
        },
        methods: {
            forward(url) {
                this.$router.push(url)
            },
            organization(id) {
                return this.$store.getters.getOrganizationById(id);
            },
            toggleMainPopup({ e, id}) {
                this.posX = e.clientX;
                this.posY = e.clientY;

                this.selectedAbo = id;

                setTimeout(() => {
                    this.showMainPopup = true;
                }, 0)
            },
            closeMainPopup() {
                this.showMainPopup = false;
            },
            methodById(id) {
                if (this.methods) {
                    return this.methods.find((el) => el.id === id);
                }
                return {};
            },
            async cancelAbo() {
                await this.$store.dispatch('deleteSubscription', {
                    organizationID: this.selectedAbo.metadata.organization,
                    data: {
                        value: true,
                    }
                });
                this.showCancelAbo = false;
            },
            async switchAbo(plan) {
                if (this.selectedPlan) {
                    if (this.filteredPlans[this.selectedPlan - 1].id === this.selectedAbo.plan.id) {
                        this.showChangeAbo = false;
                        return;
                    }
                    this.isLoading = true;
                    console.log(plan);
                    await this.$store.dispatch('upgradeSubscription', {
                        organizationID: this.selectedAbo.metadata.organization,
                        data: {
                            planId: plan.id,
                            paymentMethod: this.selectedAbo.paymentMethod,
                        }
                    });
                    this.isLoading = false;
                    this.showChangeAbo = false;
                }
            },
            async revokeCancelation() {
                await this.$store.dispatch('deleteSubscription', {
                    organizationID: this.selectedAbo.metadata.organization,
                    data: {
                        value: false,
                    }
                });
            },
            async changeMethod() {
                this.isLoading = true;
                this.showChangeMethod = false;
                await this.$store.dispatch('changeMethodOfSubscription', {
                    paymentMethod: this.selected,
                    subscriptionId: this.selectedAbo.subscriptionId,
                });
                this.isLoading = false;
            },
        },
        async mounted() {
            this.isLoading = true;
            await this.$store.dispatch('retrieveOrganizations');
            await this.$store.dispatch('retrieveSubscriptions');
            await this.$store.dispatch('retrieveMethods');
            await this.$store.dispatch('retrievePlans');
            this.isLoading = false;
        },
    };
</script>
