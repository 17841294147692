const KEYUP = 'keyup';
const KEY_ESCAPE = 'Escape'

function isEsc(e) {
    if (e && e.key === KEY_ESCAPE) this.handleEsc()
}

export const handleEsc = {
    mounted() {
        window.addEventListener(KEYUP, isEsc.bind(this))
    },
    destroyed() {
        window.removeEventListener(KEYUP, isEsc.bind(this))
    }
}
