<template>
    <div class="width--100 flex flex--column">
        <div class="flex width--100 pad--2 flex--center__vert relative">
            <input type="text" class="form--input__text mar--right__1" placeholder="Suchen..."/>
            <ActionButton :light="true" icon="ion-md-swap" :rotate="true" @onClick="toggleMainPopup" v-click-outside="closeMainPopup"/>
            <transition name="fade">
                <div class="absolute popup--anchor" v-if="showMainPopup">
                    <PopUp>
                        <div class="popup--item pad--2 clickable font--s16">
                            Hinzugefügt am
                        </div>
                        <div class="popup--item pad--2 clickable font--s16">
                            Alphabetisch
                        </div>
                    </PopUp>
                </div>
            </transition>
        </div>
        <div class="pad--2">
            <div class="members--list flex flex--column width--100 b-radius--5 overflowHidden">
                <div class="members--member grid border--bottom__light clickable"
                     @click.self="organization.role === 'Creator' ? toggleEditEditorModal(editor) : null" v-for="editor in editors" :key="editor.editor._id">
                    <div class="flex flex--center__vert pad--2">
                        <i class="ion-md-contact mar--right__1 font--s25 lineHeight--1" @click.self="organization.role === 'Creator' ? toggleEditEditorModal(editor) : null"/>
                        <div class="font--s14" @click.self="organization.role === 'Creator' ? toggleEditEditorModal(editor) : null">
                            {{ editor.editor.firstName }} {{ editor.editor.lastName }}
                        </div>
                    </div>
                    <div class="flex flex--center__vert pad--2" @click.self="organization.role === 'Creator' ? toggleEditEditorModal(editor) : null">
                        <div class="font--s14">
                            {{ editor.editor.email }}
                        </div>
                    </div>
                    <div class="flex flex--center__vert flex--end__hor pad--1">
                        <ActionButton icon="ion-md-trash" :light="true" @onClick="toggleConfirmationModal(editor)" v-if="organization.role === 'Creator'"/>
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <Modal title="Nutzerdetails" @toggle="toggleEditEditorModal(editor)" v-if="showEditEditorModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <label class="form--label">Rolle</label>
                    <v-select :clearable="true"
                              :options="roleOptions"
                              id="member_role"
                              v-model="editor.role"/>
                    <label for="member_notes" class="form--label">Notizen</label>
                    <textarea id="member_notes" class="form--input form--input__textArea mar--bottom__2" rows="5"/>
                    <AccentButton :full-width="true" :filled="true" title="Änderungen speichern" @onClick="updateEditorRole"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <ConfirmationModal v-if="showConfirmationModal"
                               title="Möchten sie das Mitglied wirklich entfernen?"
                               content="Diese Person kann danach nicht mehr auf Organisationsinhalte zugreifen."
                               @confirm="removeEditor"
                               @toggle="toggleConfirmationModal"
                               @reject="toggleConfirmationModal"/>
        </transition>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    import ActionButton from '@/components/reusable/1_Buttons/ActionButton';
    import PopUp from '@/components/reusable/2_Modals/PopUp';
    import Modal from '@/components/reusable/2_Modals/Modal';
    import AccentButton from '@/components/reusable/1_Buttons/AccentButton';
    import _ from 'lodash';
    import ConfirmationModal from '@/components/reusable/2_Modals/ConfirmationModal';
    export default {
        name: 'EditorsList',
        components: {ConfirmationModal, AccentButton, Modal, PopUp, ActionButton, vSelect},
        data() {
            return {
                showEditEditorModal: false,
                showMainPopup: false,
                showConfirmationModal: false,
                roleOptions: ['Creator', 'Editor', 'Support'],
                editor: null,
            };
        },
        computed: {
            editors() {
                return this.$store.getters.getEditors;
            },
            organization() {
                return this.$store.getters.getOrganizationById(this.$route.params.organizationID)
            },
        },
        methods: {
            toggleEditEditorModal(val) {
                this.editor = _.cloneDeep(val);
                this.showEditEditorModal = !this.showEditEditorModal;
            },
            toggleMainPopup() {
                this.showMainPopup = !this.showMainPopup;
            },
            toggleConfirmationModal(val) {
                this.editor = val;
                this.showConfirmationModal = !this.showConfirmationModal;
            },
            closeMainPopup() {
                this.showMainPopup = false;
            },
            updateEditorRole() {
                this.$store.dispatch('updateEditorRole', {
                    organizationID: this.$route.params.organizationID,
                    editorID: this.editor.editor._id,
                    role: {
                        role: this.editor.role,
                    },
                }).then(this.toggleEditEditorModal);
            },
            removeEditor() {
                this.$store.dispatch('removeEditor', {
                    organizationID: this.$route.params.organizationID,
                    editorID: this.editor.editor._id,
                }).then(this.toggleConfirmationModal);
            }
        }
    };
</script>
