<template>
    <div class="flex flex--column">
        <div class="flex flex--space__hor">
            <div class="font--s25 font--w700">
                Deine Zahlungsarten
            </div>
            <ActionButton icon="ion-md-add" accent="true" @onClick="createNew"/>
        </div>
        <div class="flex flex--center__vert">
            <div class="mar--right__1">
                <ActionButton icon="ion-ios-arrow-back" v-if="methods.length > 2" @onClick="navigate(false)"/>
            </div>
            <div class="flex mar--bottom__2 overflowAuto--hor" id="scroll--payments">
                <div class="card clickable pad--3 b-radius--5 mar--2 bgColor--white"
                     :class="{'card--selected__outline': selected === method.id}"
                     v-for="method in methods" :key="method.id">
                    <div class="paymentCard paymentCard__shine mar--bottom__2">
                        <div class="paymentCard__card">
                            <div class="font--w700 font--s2">{{ method.card.brand.toUpperCase() }}</div>
                            <div class="paymentCard__actionField">
                                <i
                                        class="ion-md-create space--right__medium paymentCard__actionIcon">
                                </i>
                                <i class="ion-md-trash paymentCard__actionIcon"></i>
                            </div>
                        </div>
                        <div class="paymentCard__number">
                            <div class="paymentCard__numberField">
                                <i class="ion-ios-medical"></i>
                                <i class="ion-ios-medical"></i>
                                <i class="ion-ios-medical"></i>
                                <i class="ion-ios-medical"></i>
                            </div>
                            <div class="paymentCard__numberField">
                                <i class="ion-ios-medical"></i>
                                <i class="ion-ios-medical"></i>
                                <i class="ion-ios-medical"></i>
                                <i class="ion-ios-medical"></i>
                            </div>
                            <div class="paymentCard__numberField">
                                <i class="ion-ios-medical"></i>
                                <i class="ion-ios-medical"></i>
                                <i class="ion-ios-medical"></i>
                                <i class="ion-ios-medical"></i>
                            </div>
                            <div class="font-w700 font--s25">{{ method.card.last4 }}</div>
                        </div>
                        <div class="paymentCard__card">
                            <div>{{ method.billing_details.name }}</div>
                            <div>{{
                                ('0' + method.card.exp_month).slice(method.card.exp_month.length - 2) }}
                                /
                                {{ method.card.exp_year.toString().slice(method.card.exp_year.toString().length - 2) }}</div>
                        </div>
                    </div>
                    <div class="font--s2 font--w700">
                        Rechnungsadresse
                    </div>
                    <div class="font--s16">
                        {{me.firstName}} {{me.lastName}}
                    </div>
                    <div class="font--s16">
                        {{ method.billing_details.address.line1 }}
                    </div>
                    <div class="font--s16">
                        {{ method.billing_details.address.line2 }}
                    </div>
                    <div class="font--s16 mar--bottom__2">
                        {{ method.billing_details.address.postal_code }} {{ method.billing_details.address.city }}
                    </div>
                    <div class="width--100">
                        <AccentButton :title="selected === method.id ? 'Nicht mehr auswählen' : 'Auswählen'" @onClick="selected = selected === method.id ? null : method.id"/>
                    </div>
                </div>
            </div>
            <div class="mar--left__1">
                <ActionButton icon="ion-ios-arrow-forward" v-if="methods.length > 2" @onClick="navigate(true)"/>
            </div>
        </div>
        <AccentButton :title="selected ? 'Mit Zahlung fortfahren' : 'Wähle eine Zahlungsart'" filled="true" @onClick="$router.push(`${selected}/checkout`)" :disable="!selected"/>
        <div class="mar--bottom__2" />
    </div>
</template>
<script>
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    import ActionButton from "@/components/reusable/1_Buttons/ActionButton";
    export default {
        name: 'PaymentMethods',
        components: {ActionButton, AccentButton},
        data() {
            return {
                selected: null,
                stripe: null,
            };
        },
        computed: {
            me() {
                return this.$store.getters.getUser;
            },
            methods() {
                return this.$store.getters.getMethods;
            }
        },
        methods: {
            navigate(forward) {
                if (forward) {
                    document.getElementById('scroll--payments').scrollLeft += 400;
                } else {
                    document.getElementById('scroll--payments').scrollLeft -= 400;
                }
            },

            async createNew() {
                /* eslint-disable */
                this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
                const session = await this.$store.dispatch('retrieveSetupSession', {
                    cancelURL: `${process.env.VUE_APP_DOMAIN_FRONTEND}/dashboard/organizations/${this.$route.params.organizationID}/plan`,
                    successURL: `${process.env.VUE_APP_DOMAIN_FRONTEND}/dashboard/organizations/${this.$route.params.organizationID}/plan/${this.$route.params.planID}/payment`,
                });
                if (session) {
                    this.stripe.redirectToCheckout({
                        // Make the id field from the Checkout Session creation API response
                        // available to this file, so you can provide it as parameter here
                        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                        sessionId: session,
                    }).then((result) => {
                        // If `redirectToCheckout` fails due to a browser or network
                        // error, display the localized error message to your customer
                        // using `result.error.message`.
                        console.log(result.error.message);
                    });
                }
            },
        },
    };
</script>
