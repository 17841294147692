export const reorderArray = (array, oldInd, newInd) => {
    const moveEl = array[oldInd];
    array.splice(oldInd, 1);
    if (newInd >= array.length) {
        array.push(moveEl);
    } else {
        array.splice(newInd, 0, moveEl);
    }
    return array;
};

export const transformMovePageOrChapterRequest = (chapterPagesArray) => {
    let answerArray = [];
    chapterPagesArray.forEach(chapter => {
        let transformedChapter = {};
        transformedChapter._id = chapter._id;

        let transformedPages = [];
        chapter.pages.forEach(page => {
            transformedPages.push({_id: page._id})
        });
        transformedChapter.pages = transformedPages;
        answerArray.push(transformedChapter);
    });
    return answerArray
};

export const delay = ms => new Promise(res => setTimeout(res, ms));