<template>
    <div class="page">
        <ActionHeader :back-title="chapter.title" :title="title"
                      @onBack="$router.push(`/dashboard/organizations/${$route.params.organizationID}/courses/${$route.params.courseID}`)">
            <accent-button class="margin" @onClick="update" filled="true" title="Speichern" :loading="loading"
                           ref="validate"
            />
        </ActionHeader>
        <article class="page--grid mar--bottom__2 font--s16">

            <main class="flex--start__vert">
                <div v-if="page.pageType === PageTypeEnum.ASSIGNMENT && !assignments" class="page--structure">

                    <div class="flex flex--center__hor">
                        <icon-button v-if="!assignments"
                                     @onClick="addAssignments"
                                     class="mar--1" icon="ion-md-add" title="Multiple Choice Quiz"/>
                    </div>
                </div>

                <div class="page--structure">
                    <!-- title -->
                    <h3>Details</h3>
                    <label class="form--label mar--top__2" for="title">Titel</label>
                    <input class="form--input__text"
                           id="title"
                           v-model.trim="title"
                           type="text"
                           placeholder="Title"
                           v-required

                    >
                    <div class="form--label mar--top__2">Text</div>
                    <div>
                        <quill-editor ref="myTextEditor"
                                      v-model="body"
                                      :options="editorOptions"
                        />
                    </div>
                </div>

                <div v-if="page.pageType === PageTypeEnum.VIDEO" class="page--structure">
                    <h3 class="mar--bottom__1">Video</h3>

                    <div v-if="!videoStatus" class="flex flex--center__hor">
                        <icon-button @onClick="$emit('openUploadModal')"
                                     class="mar--1" icon="ion-md-add" title="Video"/>
                    </div>
                    <div v-if="videoStatus === VideoStatusEnum.PROCESSING" class="flex flex--center flex--column">
                        <loader :loading="true" color="#114B5F" :size="'2rem'"/>
                        Video wird verarbeitet...
                    </div>
                    <div v-if="videoStatus === VideoStatusEnum.DONE" class="pad--top__1">
                        <video-js id="video"
                                  class="video-js vjs-big-play-centered page--video bottom--2 flex flex--center width--100"
                                  ref="videoPlayer"
                                  :poster="page.thumbnail.link && page.thumbnail.link.includes('pages') ? URL + '/' + page.thumbnail.link : null"
                                  preload="auto"
                                  data-setup="{}"
                                  controls />
                    </div>
<!--                    <div v-if="videoStatus === VideoStatusEnum.DONE" v-html="videoHtml"-->
<!--                         class="page&#45;&#45;video pad&#45;&#45;bottom__2 flex flex&#45;&#45;center">-->
<!--                    </div>-->

                    <!-- upload thumbnails-->
                    <div v-if="videoStatus === VideoStatusEnum.DONE">
                        <h4 class="mar--bottom__1">Upload Thumbnail</h4>
                        <form enctype="multipart/form-data"
                              class="form--input__file mar--bottom__2 mar--top__1 flex flex--center flex--column relative b-radius--5 pad--2">
                            <input type="file" accept="image/*" id="video_thumbnail"
                                   class="clickable width--100 height--100 opacity--0 absolute"
                                   @change="uploadThumbnail"/>
                            <transition name="fade" mode="out-in">
                                <div v-if="!this.thumbnailLoading" class="flex flex--column flex--center">
                                    <i class="ion-md-cloud-upload font--s3 lineHeight--12"/>
                                    <div class="font--s16">Bild hochladen</div>
                                    <div class="font--s14 font--w700">16:9 wird empfohlen</div>
                                </div>
                            </transition>
                            <transition name="fade" mode="out-in">
                                <div v-if="this.thumbnailLoading" class="flex flex--column flex--center">
                                    <loader :loading="true" color="#114B5F" :size="'1rem'"/>
                                    <div class="font--s16">Bild wird hochladen...</div>
                                </div>
                            </transition>
                        </form>
                    </div>
                    <accent-button v-if="videoStatus === VideoStatusEnum.DONE"
                                   @onClick="$emit('openConfirmDeleteVideo')"
                                   danger="true" title="Video löschen"/>
                </div>
                <div class="page--structure assignment" v-if="page.pageType === PageTypeEnum.ASSIGNMENT && assignments">
                    <h3 class="mar--bottom__1">Quiz</h3>
                    <div>
                        <div v-for="(question, index) in assignments" :key="index"
                             class="pad--bottom__1 mar--bottom__2"
                        >
                            <div class="hidden--enable flex flex--center__vert">
                                <input class="form--input__text" type="text"
                                       placeholder="Question"
                                       v-model.trim="question.title"
                                       v-focus @keyup.enter="addAnswer(question)"
                                >
                                <i class="hidden remove ion-md-close font--s25 lineHeight--12 clickable color--grey"
                                   @click="removeQuestion(index)"
                                />
                            </div>
                            <ul class="mar--top__1">
                                <li v-for="(answer, index) in question.answers" :key="index"
                                    class="flex flex--center__vert mar--top__1"
                                    :class="{ 'hidden--enable': index !== 0}"
                                >
                                    <div>
                                        <action-button class="mar--right__1"
                                                       @onClick="answer.correct = !answer.correct"
                                                       :icon="answer.correct
                                                           ? 'ion-md-checkbox-outline'
                                                           : 'ion-md-square-outline'"
                                        />
                                    </div>
                                    <div class="flex flex--center__vert width--100">
                                        <input class="form--input__text "
                                               placeholder="Answer"
                                               type="text"
                                               v-model.trim="answer.text"
                                               @keyup.enter="addAnswer(question)"
                                               v-focus
                                        >
                                        <i class="hidden remove ion-md-close font--s25 lineHeight--12 clickable color--grey"
                                           @click="removeAnswer(question, index)"
                                        />
                                    </div>
                                </li>
                            </ul>
                            <div class="width--100 flex flex--center mar--top__1">
                                <action-button class="" icon="ion-md-add" light="true"
                                               @onClick="addAnswer(question, index)"
                                />
                            </div>
                        </div>
                        <icon-button title="Question" icon="ion-md-add" @onClick="addQuestion"/>
                    </div>
                </div>

                <div class="page--structure assignment" v-if="page.pageType === PageTypeEnum.AD">
                    <h3>Banner</h3>
                    <UploadThumbnail id="organization_img" @file-uploaded="cacheThumb" :cached-thumb-name="cachedThumbName"/>
                    <AccentButton :full-width="true" :filled="true" title="Banner Hochladen"
                                  @onClick="uploadThumb"
                                  tabindex="0" ref="validate" :loading="loading"
                    />
                </div>

                <div class="page--structure assignment" v-if="page.pageType === PageTypeEnum.AD">
                    <h3>Aktionsknopf</h3>
                    <label for="ad_action" class="form--label">Text</label>
                    <input id="ad_action" class="form--input form--input__text mar--bottom__1" />
                    <label for="ad_link" class="form--label">Link</label>
                    <input id="ad_link" class="form--input form--input__text" />
                </div>
            </main>

            <aside class="flex--start__vert">
                <div class="page--structure">
                    <h3 class="mar--bottom__1">Status</h3>
                    <div>
                        <input type="radio" id="published" :value="true" v-model="publish">
                        <label class="mar--left__1" for="published">Veröffentlicht</label>
                    </div>
                    <div>
                        <input type="radio" id="draft" :value="false" v-model="publish">
                        <label class="mar--left__1" for="draft">Entwurf</label>
                    </div>
                    <div class="font--s1 mar--top__1 color--grey">
                        Kurs "{{course.title}}" ist {{course.publish ? '' :
                        'NICHT'}} veröffentlicht.
                    </div>
                </div>
                <div class="page--structure">
                    <h3 class="mar--bottom__1">Dauer</h3>
                    <div class="flex flex--end__vert">
                        <input class="form--input form--input__text mar--right__1" type="number" v-model="duration"/>
                        <div>
                            min
                        </div>
                    </div>
                </div>
                <div class="page--structure">
                    <h3>Downloads</h3>
                    <div class="form--input__file mar--bottom__2 flex flex--center flex--column relative b-radius--5 pad--2">
                        <div v-if="downloadsLoading" class="width--100 flex flex--center flex--column">
                            <loader :loading="true" color="#114B5F" :size="'1rem'"/>
                            <div class="mar--top__2 font--s2">Uploading Files...</div>
                        </div>
                        <div v-else>
                            <input type="file" multiple @change="addDownloads($event)" ref="fileUpload"
                                   class="clickable width--100 height--100 opacity--0 absolute"/>
                            <div class="flex flex--center flex--column"><i
                                    class="ion-md-cloud-upload font--s3 lineHeight--12"/>
                                <div class="font--s16">Dateien hochladen</div>
                            </div>
                        </div>
                    </div>


                    <div class="flex flex--column">
                        <div class="downloads--row hidden--enable flex flex--space__hor flex--center__vert pad--05 border--bottom__light overflowHidden"
                             v-for="(item, index) in downloads"
                             :key="index"
                        >
                            <div class="flex flex--center__vert mar--right__1">
                                <div class="downloads--filetype">{{getFileType(item.mimeType)}}</div>
                                <div class="pad--left__1 pad--right__1 font--s14 color--grey">{{item.filename}}</div>
                            </div>
                            <div class="flex">
                                <i v-if="item.uploaded" class="ion-md-checkmark font--s25 lineHeight--12 color--grey"/>
                                <i v-else class="ion-md-alert font--s25 lineHeight--12 color--danger"/>
                                <i class="hidden remove ion-md-close font--s25 lineHeight--12 clickable color--grey mar--left__1"
                                   @click="removeDownload(index, item.id)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page--structure">
                    <accent-button @onClick="$emit('openConfirmDeletePage')"
                                   danger="true" title="Lektion löschen"
                    />
                </div>
            </aside>
        </article>
    </div>
</template>

<script>
    import 'vue-select/dist/vue-select.css';
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import {quillEditor} from 'vue-quill-editor' // https://github.surmon.me/vue-quill-editor/
    import IconButton from '../../reusable/1_Buttons/IconButton';
    import AccentButton from "../../reusable/1_Buttons/AccentButton";
    import ActionButton from "../../reusable/1_Buttons/ActionButton";
    import ActionHeader from "./ActionHeader";
    import {PageTypeEnum, QuestionTypeEnum, VideoStatusEnum} from "../../../services/enums";
    import UploadThumbnail from "@/components/reusable/3_Other/UploadThumbnail";
    import videojs from 'video.js';
    import '@videojs/http-streaming';
    import 'videojs-contrib-quality-levels';
    import 'videojs-hls-quality-selector'

    export default {
        name: 'Page',
        components: {UploadThumbnail, ActionHeader, AccentButton, ActionButton, IconButton, quillEditor},
        data() {
            return {
                loading: false,
                thumbnailLoading: false,
                downloadsLoading: false,

                title: '',
                body: null,
                editorOptions: EDITOR_OPTIONS,
                publish: false,
                downloads: [],
                assignments: null,
                duration: null,
                accessTime: null,

                cachedThumb: null,
                cachedThumbName: null,


                videoStatus: null,
                videoHtml: null,
                player: null,

                PageTypeEnum: PageTypeEnum,
                VideoStatusEnum: VideoStatusEnum,
            }
        },
        computed: {
            page() {
                /* eslint-disable */
              console.log(this.$store.getters.getPageById(this.$route.params.chapterID, this.$route.params.pageID));
                return this.$store.getters.getPageById(this.$route.params.chapterID, this.$route.params.pageID) || {
                    _id: '0',
                };
            },
            chapter() {
                return this.$store.getters.getChapterById(this.$route.params.chapterID)
            },
            course() {
                return this.$store.getters.getCourseById(this.$route.params.courseID)
            },
            courseDetails() {
                return this.$store.getters.getCourseDetails;
            },
            URL() {
                return this.$store.getters.getURL;
            },
        },
        methods: {
            async uploadThumbnail(event) {
                this.thumbnailLoading = true
                const file = event.target.files[0]
                const formData = new FormData()
                formData.append('File', file, file.name)
                await this.$store.dispatch('updateVideoThumbnail', {
                    params: this.$route.params,
                    formData
                }).then(() => {
                    this.thumbnailLoading = false;
                    this.$router.go(0);
                });
                this.thumbnailLoading = false;
            },
            cacheThumb(fileList) {
                const formData = new FormData();
                formData.append('File', fileList[0], fileList[0].name);
                this.cachedThumb = formData;
                this.cachedThumbName = fileList[0].name;
            },
            async uploadThumb(id) {
                await this.$store.dispatch('uploadAdBanner', {
                    organizationID: this.$route.params.organizationID,
                    courseID: id,
                    thumbnail: this.cachedThumb,
                });
                this.cachedThumb = null;
                this.cachedThumbName = null;
            },
            async addDownloads(event) {
                this.downloadsLoading = true
                const files = [...event.target.files]
                const uploadedFiles = await Promise.all(files.map(async (file) => {
                    const formData = new FormData()
                    formData.append('File', file, file.name)
                    const uploaded = await this.$store.dispatch('uploadDownload', {
                        params: this.$route.params,
                        formData
                    })
                    return Promise.resolve({filename: file.name, mimeType: file.type, uploaded})
                }))
                this.downloads = [...this.downloads, ...uploadedFiles]
                this.downloadsLoading = false

                // remove file path store in value so that same file can be uploaded again (after it has been removed)
                // this.$refs.fileUpload.value = null
            },
            getFileType(mimeType) {
                return mimeType.split('/').pop()
            },
            removeDownload(indexToRemove, id) {
                this.downloads = this.downloads.filter((el, index) => indexToRemove !== index)
                this.$store.dispatch('deleteDownload', {params: this.$route.params, id})
            },
            addAssignments() {
                this.assignments = [getEmptyMCQuestion()]
            },
            addQuestion() {
                this.assignments.push(getEmptyMCQuestion())
            },
            removeQuestion(index) {
                this.assignments = this.assignments.filter((el, idx) => index !== idx)
            },
            addAnswer(question) {
                question.answers.push(getEmptyMCAnswer())
            },
            removeAnswer(question, index) {
                question.answers = question.answers.filter((el, idx) => index !== idx)
            },
            cleanAssignmentsBeforeUpdate() {
                if (!this.assignments) return;
                const noEmptyAnswers = this.assignments.map(question => {
                    return {...question, answers: question.answers.filter(answer => answer.text !== '')}
                });
                this.assignments = noEmptyAnswers.filter(question => question.title !== '' && question.answers.length > 0)
            },
            async update() {
                this.loading = true;
                this.cleanAssignmentsBeforeUpdate();

                if (this.videoId) {
                    const videoData = {
                        title: this.title
                    };
                    this.$store.dispatch('updateVideo', {
                        videoId: this.videoId,
                        videoData
                    })
                }
                const page = {
                    title: this.title,
                    description: this.body,
                    publish: this.publish,
                    assignments: this.assignments,
                    duration: this.duration,
                };
                await this.$store.dispatch('updatePage', {
                    params: this.$route.params,
                    page
                });
                setTimeout(() => {
                    this.loading = false
                }, 1000)

            },
            preview() {
                // TODO: concept, implementation
            }
        },
        watch: {
            async page(newData) {
                if (!newData) return;
                this.title = newData.title;
                this.body = newData.description;
                this.publish = newData.publish;
                this.duration = newData.duration;
                const newAssignments = JSON.parse(JSON.stringify(newData.assignments));
                // removes unwanted attribute _id from answers
                this.assignments = newAssignments.map(question => {
                    return {
                        title: question.title,
                        type: question.type,
                        // eslint-disable-next-line no-unused-vars
                        answers: question.answers.map(({_id, ...keepAttributes}) => keepAttributes)
                    }
                })

                this.downloads = newData.downloads.map(dl => ({
                    id: dl._id,
                    filename: dl.name,
                    mimeType: dl.mimeType,
                    uploaded: true
                }))

                if (newData.video) {
                    const {status} = newData.video
                    this.videoStatus = status
                    if (status === VideoStatusEnum.DONE) {
                        try {
                            this.videoHtml = await this.$store.dispatch('retrieveVideoHtml', newData.video.online.link)
                            /* eslint-disable */
                            console.log(this.videoHtml);
                        } catch (e) {
                            this.videoStatus = VideoStatusEnum.PROCESSING
                        }
                    }
                }
            }
        },
        async mounted() {
            window.HELP_IMPROVE_VIDEOJS = false;
            if (!this.page) return;
            this.title = this.page.title;
            this.body = this.page.description;
            this.publish = this.page.publish;
            this.duration = this.page.duration;
            const newAssignments = JSON.parse(JSON.stringify(this.page.assignments));
            // removes unwanted attribute _id from answers
            this.assignments = newAssignments.map(question => {
                return {
                    title: question.title,
                    type: question.type,
                    // eslint-disable-next-line no-unused-vars
                    answers: question.answers.map(({_id, ...keepAttributes}) => keepAttributes)
                }
            })

            this.downloads = this.page.downloads.map(dl => ({
                id: dl._id,
                filename: dl.name,
                mimeType: dl.mimeType,
                uploaded: true
            }))

            if (this.page.video) {
                const {status} = this.page.video
                this.videoStatus = status
                console.log('State after Mounted:');
                console.log(status);
                if (status === VideoStatusEnum.DONE) {
                    try {
                        this.videoHtml = await this.$store.dispatch('retrieveVideoHtml', this.page.video.online.link)
                        const res = await this.$store.dispatch('retrieveVideoDetails', '/videos/' + this.page.video.online.id.split('/')[2]);
                        console.log(res);
                        console.log(this.videoHtml);
                        this.player = videojs(this.$refs.videoPlayer, this.playerOptions, function onPlayerReady(player) {
                            console.log('onPlayerReady', this);
                        });
                        this.player.src({
                            src: res.hls.link,
                            type: 'application/x-mpegURL',
                            withCredentials: false,
                        });
                        this.player.hlsQualitySelector({
                            displayCurrentQuality: true,
                        });
                    } catch (e) {
                        // this.videoStatus = VideoStatusEnum.PROCESSING
                    }
                }
            }
        }
    }
    const EDITOR_OPTIONS = {
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'blockquote'],
                [{'header': 1}, {'header': 2}],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],
                ['clean', 'link'],
            ]
        }
    }
    const getEmptyMCAnswer = () => ({text: '', correct: false})
    const getEmptyMCQuestion = () => ({
        title: '',
        type: QuestionTypeEnum.MULTIPLE_CHOICE,
        answers: []
    })
</script>
