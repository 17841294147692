<template>
    <div class="width--100 height--100 flex flex--column flex--center__hor">
        <div class="font--s2 font--w700 mar--bottom__2">
            Registrieren
        </div>
        <div class="mar--bottom__2">
            <label class="form--label" for="login_firstName">
                Vorname
            </label>
            <input type="text" id="login_firstName" class="form--input form--input__text mar--bottom__1"
                   :class="{'form--input__error': this.$v.registerFirstName.$error}"
                   v-model="$v.registerFirstName.$model"/>
            <label class="form--label" for="login_lastName">
                Nachname
            </label>
            <input type="text" id="login_lastName" class="form--input form--input__text mar--bottom__1"
                   :class="{'form--input__error': this.$v.registerLastName.$error}"
                   v-model="$v.registerLastName.$model"/>

            <label class="form--label" for="login_email">
              E-Mail
            </label>
            <input type="text" id="login_email" class="form--input form--input__text mar--bottom__1"
                   :class="{'form--input__error': this.$v.registerEmail.$error}"
                   v-model="$v.registerEmail.$model"/>

            <label class="form--label" for="login_password">
                Passwort
            </label>
            <div class="flex relative mar--bottom__2">
                <input :type="showPass ? 'text' : 'password'" id="login_password" class="form--input form--input__text"
                       :class="{'form--input__error': this.$v.registerPassword.$error}"
                       v-model="$v.registerPassword.$model"/>
                <div class="absolute right--0">
                    <ActionButton icon="ion-ios-eye" @onClick="showPass = !showPass"/>
                </div>
            </div>
            <div class="flex flex--center__vert width--100 mar--bottom__2">
                <div class="auth--stay b-radius--5 mar--right__1 clickable flex flex--center"
                     @click="consent = !consent"
                     :class="{'border--dark': consent, 'border--light': !consent}">
                    <transition name="fade">
                        <i class="ion-md-checkmark font--s2" v-if="consent"/>
                    </transition>
                </div>
                <div class="font--s14 clickable lineHeight--12 width--100" @click="consent = !consent">
                    Ich habe die <span class="font--w700" @click.prevent="openDataProtection">Datenschutzerklärung</span> sowie die <span class="font--w700" @click.prevent="openAGB">AGBs</span> zur Kenntnis genommen und erkläre mich damit einverstanden.
                </div>
            </div>
        </div>
        <AccentButton title="Registrieren" :full-width="true" @onClick="register" :disable="$v.$invalid || !consent"/>
    </div>
</template>
<script>
    import AccentButton from '@/components/reusable/1_Buttons/AccentButton';
    import {email, required, minLength} from "vuelidate/lib/validators";
    import ActionButton from "@/components/reusable/1_Buttons/ActionButton";

    export default {
        name: 'Register',
        components: {ActionButton, AccentButton},
        data() {
            return {
                showPass: false,
                registerFirstName: null,
                registerLastName: null,
                registerEmail: null,
                registerPassword: null,
                consent: false,
            };
        },
        validations: {
            registerFirstName: {
                required
            },
            registerLastName: {
                required
            },
            registerEmail: {
                required,
                email,
            },
            registerPassword: {
                required,
                minLength: minLength(8),
                containsUppercase: function(value) {
                  return /[A-Z]/.test(value)
                },
                containsLowercase: function(value) {
                  return /[a-z]/.test(value)
                },
                containsNumber: function(value) {
                  return /[0-9]/.test(value)
                },
            }
        },
        computed: {
            token() {
                return this.$store.getters.getToken;
            }
        },
        methods: {
            openDataProtection() {
                window.open(process.env.VUE_APP_DOMAIN_FRONTEND + '/data');
            },
            openAGB() {
                window.open(process.env.VUE_APP_DOMAIN_FRONTEND + '/agb');
            },
            register() {
                if (!this.$v.$invalid) {
                    this.$store.dispatch('register', {
                        firstName: this.registerFirstName,
                        lastName: this.registerLastName,
                        email: this.registerEmail,
                        password: this.registerPassword,
                        passwordCheck: this.registerPassword,
                    }).then(() => {
                        if (this.token !== null) {
                            this.$router.push('/dashboard');
                        }
                    })
                } else if (this.$v.registerFirstName.$invalid || this.$v.registerLastName.$invalid) {
                    this.$v.$touch();
                    this.$store.commit('addToast', {
                        type: 'error',
                        title: 'Bitte gebe deine vollständigen Daten zur Registrierung an'
                    })
                } else if (this.$v.registerEmail.$invalid) {
                    this.$v.$touch();
                    this.$store.commit('addToast', {
                        type: 'error',
                        title: 'Bitte gebe deine korrekte E-Mail Adresse an'
                    })
                } else if (this.$v.registerPassword.$invalid) {
                    this.$v.$touch();
                    this.$store.commit('addToast', {
                        type: 'error',
                        title: 'Dein Passwort muss min. 8 Zeichen haben und Zahlen oder Sonderzeichen enthalten'
                    })
                } else {
                    this.$v.$touch();
                    this.$store.commit('addToast', {
                        type: 'error',
                        title: 'Bitte gebe deine vollständigen Daten zur Registrierung an'
                    })
                }
            },
        },
    };
</script>
