<template>
    <div class="width--100 flex flex--column flex--center__vert overflowAuto" id="main">
        <LandingHeader />
        <transition name="fade" mode="out-in">
            <router-view />
        </transition>
        <Footer />
    </div>
</template>
<script>
    import LandingHeader from "@/components/reusable/3_Other/LandingHeader";
    import Footer from "@/components/reusable/3_Other/Footer";
    export default {
        name: 'LandingLoader',
        components: {Footer, LandingHeader},
    };
</script>
