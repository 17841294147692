<template>
    <!-- eslint-disable -->
    <!--    <transition name="fade">-->
    <div class="width--100 height--100 grid chapter overflowHidden relative"
         :id="`chapter-${id}`"
         @dragend="handleDragEnd"
         @dragenter="handleDragChapterEnter"
         @dragstart="(e) => {
                if (organization.role !== 'Creator' && organization.role !== 'Editor') return;
                handleDragStart(e);
         }">
        <div class="width--100 height--100 absolute bgColor--primary left--0 opacity--5 front"
             v-if="draggedChapter"/>
        <div class="chapter--header pad--2 flex flex--center__vert flex--space__hor font--s16 clickable border--bottom__light"
             @click.self="toggleChapter">
            <div class="flex flex--center__vert">
                <i class="ion-ios-reorder mar--right__2 movable font--s25 lineHeight--1"
                   @mousedown="handleMouseDown(`chapter-${id}`)" @mouseup="handleMouseUp(`chapter-${id}`)"/>
                <div class="chapter--title flex lineHeight--12">
                    <div @click.self="toggleChapter">
                        {{ title }}
                    </div>
                    <div class="height--1 b-radius--5 pad--05 bgColor--primaryLight flex flex--center__vert mar--left__1" v-if="timeToAccess">
                        <i class="ion-md-timer font--s14 color--white lineHeight--1 color--white" />
                        <div v-if="timeToAccess" class="mar--left__05 font--s14 color--white lineHeight--1">
                            {{ timeToAccess ? `${Math.round(timeToAccess / 86400000)} Tag${ timeToAccess > 86400000 ? 'e' : ''}` : '' }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex--center__vert" @click.self="toggleChapter">
                <div class="flex mar--right__1">
                    <ActionButton icon="ion-md-create" :light="true" @onClick="$emit('editChapter')"
                                  v-if="organization.role === 'Creator' || organization.role === 'Editor'"/>
                </div>
                <div class="flex mar--right__1">
                    <ActionButton icon="ion-md-trash" :light="true" @onClick="$emit('deleteChapter')"
                                  v-if="organization.role === 'Creator' || organization.role === 'Editor'"/>
                </div>
                <div class="flex mar--right__2">
                    <ActionButton icon="ion-md-add" :accent="true" @onClick="$emit('addPage', id)"
                                  v-if="organization.role === 'Creator' || organization.role === 'Editor'"/>
                </div>
                <i class="ion-ios-arrow-forward font--s3 lineHeight--1" :class="{'rotate--90': !isExpanded}"
                   @click.self="toggleChapter"/>
            </div>
        </div>
        <!-- page -->
        <div class="flex flex--space__hor flex--center__vert pad--2 border--bottom__light relative"
             v-for="(page, ind) in pages"
             :key="page._id"
             :id="`page-${page._id}`"
             v-if="isExpanded"
             @dragend="handleDragEnd"
             @dragenter="handleDragPageEnter(`page-${page._id}`, ind)"
             @dragstart="(e) => {
                 if (organization.role !== 'Creator' && organization.role !== 'Editor') return;
                 handleDragStart(e, page, ind);
             }">
            <div class="width--100 height--100 absolute bgColor--primary left--0 opacity--5"
                 v-if="draggedEl && draggedEl._id === `${page._id}`"/>
            <div class="flex">
                <i class="ion-ios-reorder mar--right__2 movable font--s25 lineHeight--1"
                   @mousedown="handleMouseDown(`page-${page._id}`)" @mouseup="handleMouseUp(`page-${page._id}`)"/>
                <div class="font--s14">
                    {{ page.title }}
                </div>
            </div>

            <div class="flex flex--center__vert">
              <i v-if="!page.publish" class="ion-md-eye-off font--s14 mar--right__1 tooltip--draft color--warning"/>
              <i v-else class="ion-md-eye font--s14 mar--right__1 tooltip--draft color--success"/>
              <div v-if="!page.publish" class="font--s14 font--w700 color--warning mar--right__1">
                Entwurf
              </div>
              <div v-else class="font--s14 font--w700 color--success mar--right__1">
                Öffentlich
              </div>
                <ActionButton icon="ion-md-create" :light="true" @click.native.middle="middleClick(page._id)" @onClick="$emit('editPage', page._id)"
                              v-if="organization.role === 'Creator' || organization.role === 'Editor'"/>
            </div>
        </div>
    </div>
    <!--    </transition>-->
</template>
<script>
    import ActionButton from '@/components/reusable/1_Buttons/ActionButton';

    export default {
        name: 'Chapter',
        components: {ActionButton},
        props: ['id', 'index', 'pages', 'title', 'timeToAccess', 'draggedEl', 'oldParent'],
        data() {
            return {
                newPagePosition: null,
                draggedChapter: false,
                draggedPage: null,
                draggableChapter: null,
            };
        },
        computed: {
            isExpanded() {
                return this.$store.getters.isChapterExpanded(this.id)
            },
            organization() {
                return this.$store.getters.getOrganizationById(this.$route.params.organizationID)
            }
        },
        methods: {
            middleClick(pageID) {
                const { organizationID, courseID } = this.$route.params;
                window.open(`${process.env.VUE_APP_DOMAIN_FRONTEND}/dashboard/organizations/${organizationID}/courses/${courseID}/chapters/${this.id}/pages/${pageID}`);
            },
            toggleChapter() {
                this.$store.commit('toggleExpandChapter', {chapterID: this.id})
            },
            setNewPagePosition(val) {
                this.newPagePosition = val;
            },
            handleDragStart(el, page, ind) {
                if (el.target) {
                    if (el.target.id.split('-')[0] === 'chapter') {
                        el.dataTransfer.setData('text/plain', 'handle');
                        this.draggedChapter = true;
                        this.$emit('dragChapter');
                    } else if (el.target.id.split('-')[0] === 'page' && page) {
                        el.dataTransfer.setData('text/plain', 'handle');
                        this.draggedPage = `page-${page._id}`;
                        this.$emit('dragPage', {page: page, ind});
                    }
                }
            },
            handleDragEnd() {
                this.draggedPage = null;
                this.draggedChapter = false;
                this.$emit('onDragEnd');
            },
            handleDragChapterEnter() {
                if (this.draggedEl) {
                    if (this.draggedEl._id !== this.id && this.draggedEl.type === 'Chapter') {
                        this.$emit('newChapterPosition');
                    } else if (this.oldParent !== this.index && this.draggedEl.type === 'Page') {
                        this.$emit('newPagePosition', 0);
                    }
                }
            },
            handleDragPageEnter(id, ind) {
                if (this.draggedEl) {
                    if (this.draggedEl._id !== id) {
                        this.$emit('newPagePosition', ind);
                    }
                }
            },
            handleMouseDown(id) {
                document.getElementById(id).setAttribute('draggable', 'true');
            },
            handleMouseUp(id) {
                document.getElementById(id).setAttribute('draggable', 'false');
            }
        },
        watch: {
            pages(newPage) {
                if (this.draggedEl) {
                    if (newPage.find((el) => el._id === this.draggedEl.id)) {
                        this.draggedPage = this.draggedEl.id;
                    }
                }

            },
            draggedEl(newPage) {
                if (!newPage) {
                    this.handleDragEnd();
                }
            },
        }
    };
</script>
