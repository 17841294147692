<template>
    <div class="auth flex flex--column flex--center">
        <div class="width--50 card b-radius--5 pad--3 flex flex--column bgColor--white">
            <div class="font--s2 font--w700 mar--bottom__2">
                Passwort vergessen
            </div>
            <label for="newPass" class="form--label">Neues Passwort</label>
            <input type="newPassRe" id="newPass"
                   class="form--input form--input__text mar--bottom__1"
                   v-model="newPass"
                   v-focus v-required
            />
            <label for="newPassRe" class="form--label">Neues Passwort Wiederholen</label>
            <input type="password" id="newPassRe"
                   class="form--input form--input__text mar--bottom__2"
                   v-model="newPassRe"
                   v-required
            />
            <AccentButton title="Neues Passwort speichern" @onClick="resetPass"/>
        </div>
    </div>
</template>
<script>
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    export default {
        name: 'ResetLoader',
        components: {AccentButton},
        data() {
            return {
                newPass: '',
                newPassRe: '',
            };
        },
        methods: {
            resetPass() {
                this.$store.dispatch('resetPassword', {
                    secret: this.$route.params.secret,
                    password: this.newPass,
                    passwordCheck: this.newPassRe,
                }).then(() => {
                    this.$router.push('/');
                    this.$store.commit('addToast', {
                        type: 'success',
                        title: 'Passwort erfolgreich geändert'
                    })
                });
            }
        },
        mounted() {
            this.$store.dispatch('checkSecretValid', {
                secret: this.$route.params.secret,
            }).catch(() => {
                this.$router.push('/');
                this.$store.commit('addToast', {
                    type: 'warning',
                    title: 'Der Link ist bereits abgelaufen'
                })
            })
        },
    }
</script>
