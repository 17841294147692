<template>
    <div class="flex flex--column flex--center__vert overflowAuto width--100">
        <div class="page--wrapper">
            <Page v-if="page._id !== '0'"
                  @openUploadModal="openUploadModal"
                  @openConfirmDeleteVideo="showConfirmDeleteVideo = true"
                  @openConfirmDeletePage="showConfirmDeletePage = true"
                  ref="Page"
            />
        </div>
        <transition name="fade">
            <modal v-if="showUploadModal" title="Video Upload" @toggle="resetModals">
                <div v-if="loading" class="width--100 flex flex--center flex--column">
                    <!--                    <loader :loading="true" color="#114B5F" :size="'2rem'"/>-->
                    <div class="width--100 pad--2">
                        <div class="progress">
                            <div class="progress--bar transition--2" :style="{ width: progress + '%' }"></div>
                        </div>
                    </div>
                    <div class="font--s2">Uploading Video: {{progress}}%</div>
                </div>
                <div v-else class="flex flex--column width--100 pad--2">
                    <h2 class="mar--bottom__1"></h2>
                    <form enctype="multipart/form-data"
                          class="form--input__file mar--bottom__2 flex flex--center flex--column relative b-radius--5 pad--2">
                        <input type="file" accept="video/*"
                               class="clickable width--100 height--100 opacity--0 absolute"
                               @change="uploadVideo" v-focus
                        />
                        <transition name="fade" mode="out-in">
                            <div class="flex flex--column flex--center">
                                <i class="ion-md-cloud-upload font--s3 lineHeight--12"/>
                                <div class="font--s16">Lade ein Video hoch</div>
                            </div>
                        </transition>
                    </form>
                </div>
            </modal>
        </transition>
        <transition name="fade">
            <ConfirmationModal v-if="showConfirmDeleteVideo"
                               @confirm="deleteVideo"
                               @toggle="resetModals" @reject="resetModals"
                               title="Möchtest du das Video wirklich löschen?"
                               content="Das Video wird unwiederruflich gelöscht und Deine Mitglieder haben keinen Zugriff mehr darauf."/>
        </transition>
        <transition name="fade">
            <ConfirmationModal v-if="showConfirmDeletePage"
                               @confirm="deletePage"
                               @toggle="resetModals" @reject="resetModals"
                               title="Möchtest du die Seite wirklich löschen?"
                               content="Die Seite wird unwiederruflich gelöscht und Deine Mitglieder haben keinen Zugriff mehr darauf."/>
        </transition>
    </div>
</template>

<script>
    import Page from './Page'
    import Modal from "../../reusable/2_Modals/Modal";
    import {handleEsc} from "../../../services/mixins";
    import ConfirmationModal from "../../reusable/2_Modals/ConfirmationModal";
    import {PageTypeEnum, VideoStatusEnum} from "@/services/enums";

    export default {
        name: 'PageLoader',
        components: {Page, Modal, ConfirmationModal},
        mixins: [handleEsc],
        data() {
            return {
                loading: false,
                progress: 0.0,
                showUploadModal: false,
                showConfirmDeleteVideo: false,
                showConfirmDeletePage: false,
                uploadSourceUri: '',

                PageTypeEnum: PageTypeEnum,
                VideoStatusEnum: VideoStatusEnum,
                interval: null,
            }
        },
        computed: {
            page() {
                return this.$store.getters.getPageById(this.$route.params.chapterID, this.$route.params.pageID) || {
                    _id: '0',
                };
            },
        },
        methods: {
            handleEsc() {
                this.resetModals()
            },
            resetModals() {
                this.showConfirmDeleteVideo = false
                this.showConfirmDeletePage = false

                if (!this.loading) {
                    this.showUploadModal = false
                } else {
                    this.$store.commit('addToast', {
                        type: 'warning',
                        title: 'Bitte warte bis das Video hochgeladen wurde.',
                    })
                }
            },
            async openUploadModal() { // create video
                this.showUploadModal = true
            },
            uploadVideo: function (event) {
                this.loading = true;
                const file = event.target.files[0];
                this.$store.dispatch('uploadVideo', {
                    file,
                    params: this.$route.params,
                    onProgress: (bytesUploaded, bytesTotal) => this.progress = (bytesUploaded / bytesTotal * 100).toFixed(0),
                    onSuccess: () => {
                        this.$store.dispatch('retrieveCourseDetails', {
                            organizationID: this.$route.params.organizationID,
                            courseID: this.$route.params.courseID
                        });
                        this.loading = false;
                        this.resetModals()
                    }
                })
            },
            async deleteVideo() {
                await this.$store.dispatch('deleteVideo', this.$route.params);
                this.resetModals()
            }
            ,
            deletePage() {
                this.resetModals();
                const {organizationID, courseID} = this.$route.params
                this.$store.dispatch('deletePage', this.$route.params)
                this.$router.replace(`/dashboard/organizations/${organizationID}/courses/${courseID}`)
            }
            ,
        },
        watch: {
            page(newVal) {
                if (newVal && newVal.pageType === PageTypeEnum.VIDEO
                    && newVal.video.status === VideoStatusEnum.PROCESSING
                    && !this.interval) {
                    this.interval = setInterval(async () => {
                        if (this.page && this.page.pageType === PageTypeEnum.VIDEO && this.page.video.status === VideoStatusEnum.DONE) {
                            clearInterval(this.interval);
                        }
                        await this.$store.dispatch('retrieveCourseDetails', {
                            organizationID: this.$route.params.organizationID,
                            courseID: this.$route.params.courseID,
                            status: VideoStatusEnum.PROCESSING,
                        });
                    }, 10000)
                }
            }
        },
        beforeDestroy() {
            if (this.interval) {
                clearInterval(this.interval);
            }
        },
        mounted() {
            this.$store.dispatch('retrieveCourses', this.$route.params.organizationID);
            this.$store.dispatch('retrieveCourseDetails', {
                organizationID: this.$route.params.organizationID,
                courseID: this.$route.params.courseID,
            });
            if (this.page && this.page.pageType === PageTypeEnum.VIDEO
                && this.page.video.status === VideoStatusEnum.PROCESSING
                && !this.interval) {
                this.interval = setInterval(async () => {
                    await this.$store.dispatch('retrieveCourseDetails', {
                        organizationID: this.$route.params.organizationID,
                        courseID: this.$route.params.courseID,
                        status: VideoStatusEnum.PROCESSING,
                    });
                    if (this.page && this.page.pageType === PageTypeEnum.VIDEO && this.page.video.status === VideoStatusEnum.DONE) {
                        clearInterval(this.interval);
                    }
                }, 10000)
            }
        }
    }
</script>
