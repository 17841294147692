<template>
    <div class="flex flex--column flex--center__vert overflowAuto">
        <div class="courses--wrapper">
            <ActionHeader back-title="Alle Organisationen" :title="organization.organization.title" @onBack="$router.push('/dashboard')">
                <div class="flex relative">
                    <div class="mar--right__1" v-if="organization.role === 'Creator' || organization.role === 'Editor'">
                        <ActionButton icon="ion-md-add" :accent="true" @onClick="toggleCreateCourseModal"/>
                    </div>
                    <div class="mar--right__1">
                        <ActionButton icon="ion-md-settings" :light="true" @onClick="$router.push('/dashboard/organizations/' + $route.params.organizationID + '/settings')"/>
                    </div>
                </div>
            </ActionHeader>
            <NoDataAction v-if="!courses.courses.length" @onButtonClick="toggleCreateCourseModal"
                          text="Du hast noch keine Kurse erstellt." button-title="Jetzt Kurs erstellen"
                          :src="noDataImage"
            />
            <div v-else class="grid itemGrid pad--2">
                <div class="itemGrid--slot flex flex--center" v-for="course in courses.courses" :key="course._id">
                    <div class="card card--move width--100 height--100 clickable itemGrid--inner grid"
                         @click="forward(`courses/${course._id}`)">
                        <div class="width--100 aspect--169 relative flex flex--center">
                            <RawImage class="absolute top--0" :link="course.thumbnail.link"/>
                        </div>
                        <div class="flex flex--column flex--space__hor pad--2">
                            <div class="font--s2 lineHeight--12 font--w700 mar--bottom__1">
                                {{ course.title }}
                            </div>
                            <p class="text--overflow__ellipse max--height__10 overflowHidden mar--bottom__1">
                                {{ course.description }}
                            </p>
                            <div class="flex--grow" />
                            <div class="flex font--s16 font--w700">
                                <i class="ion-md-people mar--right__1"/>
                                <div>
                                    {{ course.memberCount }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <Modal title="Neuer Kurs" @toggle="resetModals" v-if="showCreateCourseModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <label for="course_title" class="form--label">Name</label>
                    <input type="text" id="course_title"
                           class="form--input form--input__text"
                           v-model.trim="courseTitle" v-focus v-required
                    />
                    <label for="course_description" class="form--label">Beschreibung</label>
                    <textarea id="course_description" class="form--input form--input__textArea" rows="5"
                              v-model.trim="courseDescription"/>
                    <label for="course_img" class="form--label">Thumbnail</label>
                    <UploadThumbnail id="course_img" @file-uploaded="cacheThumb" :cached-thumb-name="cachedThumbName" aspect-ratio="16:9"/>
                    <AccentButton :full-width="true" :filled="true" title="Kurs erstellen" @onClick="createCourse"
                                  tabindex="0" ref="validate" :loading="loading"/>
                </div>
            </Modal>
        </transition>
    </div>
</template>
<script>
    import Modal from '@/components/reusable/2_Modals/Modal';
    import AccentButton from '@/components/reusable/1_Buttons/AccentButton';
    import ActionButton from '@/components/reusable/1_Buttons/ActionButton';
    import RawImage from '@/components/reusable/3_Other/RawImage';
    import {handleEsc} from "../../../services/mixins";
    import UploadThumbnail from "../../reusable/3_Other/UploadThumbnail";
    import ActionHeader from "../4_Page/ActionHeader";
    import NoDataAction from "../../reusable/NoDataAction";
    import noDataImage from '@/assets/SVG5.svg'

    export default {
        name: 'CoursesOverview',
        components: {
            UploadThumbnail, RawImage, ActionButton, AccentButton, Modal, ActionHeader, NoDataAction},
        mixins: [handleEsc],
        data() {
            return {

                organizationTitle: '', //required

                courseTitle: '', //required
                courseDescription: null,
                showCreateCourseModal: false,

                cachedThumb: null,
                cachedThumbName: null,
                noDataImage: noDataImage,
                loading: false,
            };
        },
        computed: {
            courses() {
                return this.$store.getters.getCourses;
            },
            organization() {
                return this.$store.getters.getOrganizationById(this.$route.params.organizationID)
            },
        },
        methods: {
            forward(url) {
                this.$router.push(`${this.$route.path}/${url}`);
            },
            handleEsc() {
                if (!this.loading) {
                this.resetModals()
                } else {
                    this.$store.commit('addToast', {
                        type: 'warning',
                        msg: 'Bitte warte bis die Dateien hochgeladen wurden'
                    })
                }
            },
            resetModals() {
                this.showCreateCourseModal = false;
                this.organizationTitle = '';
                this.loading = false;
                this.courseTitle = '';
                this.courseDescription = '';
            },
            toggleCreateCourseModal() {
                this.showCreateCourseModal = !this.showCreateCourseModal;
            },
            async createCourse() {
                this.loading = true;
                const id = await this.$store.dispatch('createCourse', {
                    organizationID: this.$route.params.organizationID,
                    course: {
                        title: this.courseTitle,
                        description: this.courseDescription,
                    },
                });
                if (this.cachedThumb) await this.uploadThumb(id);
                this.resetModals();
            },
            cacheThumb(fileList) {
                const formData = new FormData();
                formData.append('File', fileList[0], fileList[0].name);
                this.cachedThumb = formData;
                this.cachedThumbName = fileList[0].name;
            },
            async uploadThumb(id) {
                await this.$store.dispatch('uploadCourseThumbnail', {
                    organizationID: this.$route.params.organizationID,
                    courseID: id,
                    thumbnail: this.cachedThumb,
                });
                this.cachedThumb = null;
                this.cachedThumbName = null;
            },
        },
        async mounted() {
            /* eslint-disable */
            if (this.organization && this.organization.organization && this.organization.organization.subscription && !this.organization.organization.subscription.subscriptionId) {
                this.$router.push(`/dashboard/organizations/${this.$route.params.organizationID}/plan`);
            }
            await this.$store.dispatch('retrieveOrganizations');
            await this.$store.dispatch('retrieveCourses', this.$route.params.organizationID);

        },
    };
</script>
