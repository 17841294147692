<template>
    <div class="flex flex--column flex--center__vert overflowAuto width--100">
        <div class="course--wrapper">
            <ActionHeader back-title="Alle Kurse" :title="course.title" @onBack="$router.push(`/dashboard/organizations/${$route.params.organizationID}`)">
                <ActionButton icon="ion-md-more" :light="true" @onClick="toggleMainPopup"
                              v-click-outside="closeMainPopup"/>
            </ActionHeader>

            <div v-if="pageLoading" class="flex flex--center mar--top__4">
                <loader :loading="true" color="#114B5F" :size="'2rem'"/>
            </div>
            <CourseStructure v-else
                             @addChapter="toggleNewChapterModal"
                             @deleteChapter="toggleChapterConfirmationModal"
                             @editChapter="handleEditChapter"
                             @addPage="handleAddPage"
                             ref="CourseStructure"
            />
        </div>
        <transition name="fade">
            <Modal v-if="showNewChapterModal" @toggle="toggleNewChapterModal" title="Neues Kapitel">
                <div class="pad--2 flex flex--column flex--center__hor flex--grow width--100">
                    <label for="chapter_title_new" class="form--label">Titel</label>
                    <input type="text" id="chapter_title_new" class="form--input__text mar--bottom__2"
                           v-model.trim="chapterTitle" @keyup.enter="createChapter"
                           v-focus v-required>
                    <AccentButton title="Kapitel erstellen" full-width="true" filled="true"
                                  @onClick="createChapter"
                                  ref="validate"
                    />
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <Modal v-if="showEditChapterModal" @toggle="toggleEditChapterModal" title="Kapitel bearbeiten">
                <div class="pad--2 flex flex--column flex--center__hor width--100 height--100">
                    <label for="chapter_title_edit" class="form--label">Titel</label>
                    <input type="text" id="chapter_title_edit" class="form--input__text mar--bottom__2"
                           v-model.trim="chapterTitle" @keyup.enter="updateChapter"
                           v-focus v-required>

                    <div class="form--label">Thumbnail</div>

                    <div class="width--100 aspect--169 relative flex flex--center mar--bottom__1" v-if="chapterImg">
                      <RawImage class="absolute top--0" :link="chapterImg.link"/>
                    </div>
                   <UploadThumbnail id="chapter_img" @file-uploaded="cacheThumb" :cached-thumb-name="cachedThumbName" aspect-ratio="16:9"/>
                    <div class="form--label">Kapitelzugriff</div>
                    <div class="mar--bottom__2">
                        <ToggleButton :value="fullAccess" @toggleValue="fullAccess = !fullAccess" false="Limitiert" true="Direkt"/>
                    </div>
                    <div class="flex flex--column" v-if="fullAccess">
                        <label for="chapter_drip" class="form--label">Freigabe nach</label>
                        <div class="flex">
                            <input type="number" id="chapter_drip" class="form--input__text mar--bottom__2"
                                   min="1"
                                   max="365"
                                   v-model.trim="timeToAccess" @keyup.enter="updateChapter">
                            <div class="mar--left__1 font--s16 ">
                                Tagen
                            </div>
                        </div>
                    </div>
                    <AccentButton title="Kapitel bearbeiten" full-width="true" filled="true"
                                  @onClick="updateChapter"
                                  ref="validate" :loading="loading"
                    />
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <Modal v-if="showNewPageModal" @toggle="toggleNewPageModal" title="Neue Lektion">
                <div class="pad--2 flex flex--column flex--center__hor flex--grow width--100">
                    <label for="page_title" class="form--label">Titel</label>
                    <input type="text" id="page_title" class="form--input__text mar--bottom__2"
                           v-model.trim="pageTitle" @keyup.enter="createPage"
                           v-focus v-required>
                    <div class="form--label">Typ</div>
                    <div class="selectionGrid grid mar--bottom__2">
                        <div class="card width--100 height--100 pad--2 flex flex--column flex--center clickable"
                             :class="{'card--selected': pageType === PageTypeEnum.VIDEO}"
                             @click="pageType = PageTypeEnum.VIDEO">
                            <i class="ion-md-play-circle font--s3" />
                            <div class="font--s16 font--w700">
                                Video
                            </div>
                        </div>
                        <div class="card width--100 height--100 pad--2 flex flex--column flex--center clickable"
                             :class="{'card--selected': pageType === PageTypeEnum.ASSIGNMENT}"
                             @click="pageType = PageTypeEnum.ASSIGNMENT">
                            <i class="ion-md-list font--s3" />
                            <div class="font--s16 font--w700">
                                Quiz
                            </div>
                        </div>
<!--                        <div class="card width&#45;&#45;100 height&#45;&#45;100 pad&#45;&#45;2 flex flex&#45;&#45;column flex&#45;&#45;center clickable"-->
<!--                             :class="{'card&#45;&#45;selected': pageType === PageTypeEnum.AD}"-->
<!--                             @click="pageType = PageTypeEnum.AD">-->
<!--                            <i class="ion-md-megaphone font&#45;&#45;s3" />-->
<!--                            <div class="font&#45;&#45;s16 font&#45;&#45;w700">-->
<!--                                Werbung-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                    <AccentButton title="Lektion erstellen" full-width="true" filled="true"
                                  @onClick="createPage"
                                  ref="validate" :disable="!pageType"
                    />
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <Modal title="Kursdetails" @toggle="showDetailsModal = false" v-if="showDetailsModal">
                <div class="pad--25 flex width--100 height--100 flex--column flex--center__hor">
                    <div class="form--label">Status</div>
                    <div class="mar--bottom__2">
                        <ToggleButton :value="courseStatus" @toggleValue="courseStatus = !courseStatus" false="Öffentlich" true="Privat"/>
                    </div>
                    <label for="course_title" class="form--label">Name</label>
                    <input type="text" id="course_title"
                           class="form--input form--input__text mar--bottom__2"
                           v-model.trim="courseTitle" v-focus v-required
                    />
                    <label for="course_description" class="form--label">Beschreibung</label>
                    <textarea id="course_description" class="form--input form--input__textArea mar--bottom__2" rows="5"
                              v-model.trim="courseDescription"/>
                    <label for="course_duration" class="form--label">Dauer</label>
                    <input type="text" id="course_duration"
                           class="form--input form--input__text mar--bottom__2"
                           v-model.trim="courseDuration" v-required
                    />
                    <label for="course_instructor" class="form--label">Instruktor</label>
                    <div class="mar--bottom__2">
                        <v-select :clearable="false"
                                  :options="courses.instructors"
                                  id="course_instructor"
                                  transition="fade"
                                  v-model="courseInstructor">
                            <template v-slot:option="option">
                                {{ option.firstName }} {{ option.lastName }}
                            </template>
                            <template #selected-option="option">
                                {{ option.firstName }} {{ option.lastName }}
                            </template>
                        </v-select>
                    </div>
                    <label for="course_img" class="form--label">Thumbnail</label>
                    <div class="width--100 aspect--169 relative flex flex--center mar--bottom__1" v-if="course.thumbnail.link">
                      <RawImage class="absolute top--0" :link="course.thumbnail.link"/>
                    </div>
                    <UploadThumbnail id="course_img" @file-uploaded="cacheThumb" :cached-thumb-name="cachedThumbName" aspect-ratio="16:9"/>
                    <AccentButton :full-width="true" :filled="true" title="Kursdetails updaten" @onClick="updateCourse"
                                  tabindex="0" ref="validate" :loading="loading"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <Modal title="Kurszertifikat" @toggle="showCertificate = false" v-if="showCertificate">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <div class="mar--bottom__2">
                        <ToggleButton :value="enableCertificate"
                                      @toggleValue="enableCertificate = !enableCertificate"
                                      false="Aktiviert"
                                      true="Deaktiviert"/>
                    </div>
                    <div class="mar--bottom__2" />
                    <AccentButton :full-width="true" :filled="true" title="Speichern" @onClick="updateCourse"
                                  tabindex="0" ref="validate" :loading="loading"/>
                </div>
            </Modal>
        </transition>
      <transition name="fade">
        <ConfirmationModal title="Möchtest du den Kurs wirklich duplizieren?"
                           v-if="showDuplicateConfirmationModal"
                           @toggle="resetModals"
                           content="Alle Inhalte werden in einen neuen Kurs kopiert"
                           @confirm="duplicateCourse"
                           @reject="resetModals"/>
      </transition>
        <transition name="fade">
            <ConfirmationModal title="Möchtest du den Kurs wirklich löschen?"
                               v-if="showCourseConfirmationModal"
                               @toggle="resetModals"
                               content="Alle Inhalte werden unwiederruflich gelöscht und Mitglieder haben keinen Zugriff mehr."
                               @confirm="deleteCourse"
                               @reject="resetModals"/>
        </transition>
        <transition name="fade">
            <ConfirmationModal title="Möchtest du das Kapitel wirklich löschen?"
                               v-if="showChapterConfirmationModal"
                               @toggle="resetModals"
                               content="Alle Inhalte und Lektionen werden unwiederruflich gelöscht und Mitglieder haben keinen Zugriff mehr."
                               @confirm="deleteChapter"
                               @reject="resetModals"/>
        </transition>
        <transition name="fade">
            <div class="absolute popup--anchor" v-if="showMainPopup">
                <PopUp :posX="posX" :posY="posY">
                    <div class="popup--item pad--2 clickable font--s16" @click="toggleDetailsModal"
                         v-if="organization.role === 'Creator' || organization.role === 'Editor'">
                        Kursdetails
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="showCertificate = true"
                         v-if="organization.role === 'Creator' || organization.role === 'Editor'">
                        Kurszertifikat
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="forward('members')">
                        Kursmitglieder
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="preview"
                         v-if="organization.role === 'Creator' || organization.role === 'Editor'">
                        Kursvorschau
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="forward('welcome')"
                         v-if="organization.role === 'Creator' || organization.role === 'Editor'">
                        Startseite anpassen
                    </div>
<!--                    <div class="popup&#45;&#45;item pad&#45;&#45;2 clickable font&#45;&#45;s16 color&#45;&#45;primary"-->
<!--                         @click="toggleDuplicateConfirmationModal" v-if="organization.role === 'Creator' || organization.role === 'Editor'">-->
<!--                      Kurs duplizieren-->
<!--                    </div>-->
                    <div class="popup--item pad--2 clickable font--s16 color--danger"
                         @click="toggleCourseConfirmationModal" v-if="organization.role === 'Creator'">
                        Kurs löschen
                    </div>
                </PopUp>
            </div>
        </transition>
    </div>
</template>
<script>
    import CourseStructure from '@/components/views/3_Course/CourseStructure';
    import Modal from '@/components/reusable/2_Modals/Modal';
    import AccentButton from '@/components/reusable/1_Buttons/AccentButton';
    import ActionButton from '@/components/reusable/1_Buttons/ActionButton';
    import PopUp from '@/components/reusable/2_Modals/PopUp';
    import ConfirmationModal from '@/components/reusable/2_Modals/ConfirmationModal';
    import {handleEsc} from "../../../services/mixins";
    import UploadThumbnail from "../../reusable/3_Other/UploadThumbnail";
    import ActionHeader from "../4_Page/ActionHeader";
    import ToggleButton from '@/components/reusable/1_Buttons/ToggleButton';
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    import {PageTypeEnum} from "../../../services/enums";
    import RawImage from "@/components/reusable/3_Other/RawImage";

    export default {
        name: 'CourseLoader',
        components: {
          RawImage,
            ToggleButton,
            ConfirmationModal,
            PopUp,
            ActionButton,
            AccentButton,
            Modal,
            CourseStructure,
            UploadThumbnail,
            ActionHeader,
            vSelect
        },
        mixins: [handleEsc],
        data() {
            return {
                posX: null,
                posY: null,
                loading: false,
                pageLoading: true,
                showMainPopup: false,
                showNewChapterModal: false,
                showEditChapterModal: false,
                showNewPageModal: false,
                showDetailsModal: false,
                showCourseConfirmationModal: false,
                showChapterConfirmationModal: false,
              showDuplicateConfirmationModal: false,
                showCertificate: false,

                cachedThumb: null,
                cachedThumbName: null,

                chapterIdCache: null,
                chapterCache: null,
                courseTitle: '',
                courseDescription: null,
                courseDuration: null,
                courseStatus: true,
                courseInstructor: null,
                chapterTitle: '',
                chapterImg: null,
                fullAccess: false,
                timeToAccess: 1,
                chapterDescription: null,
                pageTitle: '',
                pageType: null,
                pageDescription: null,
                PageTypeEnum: PageTypeEnum,
                enableCertificate: false,
            };
        },
        computed: {
            course() {
              /* eslint-disable */
                return this.$store.getters.getCourseById(this.$route.params.courseID)
            },
            courses() {
                return this.$store.getters.getCourses;
            },
            organization() {
                return this.$store.getters.getOrganizationById(this.$route.params.organizationID)
            },
          chapters() {
            console.log(this.$store.getters.getCourseDetails);
            return this.$store.getters.getCourseDetails || [];
          },
          URL() {
              return this.$store.getters.getURL;
          }
        },
        methods: {
            forward(url) {
                this.$router.push(`${this.$route.path}/${url}`);
            },
            preview() {
              /* eslint-disable */
              console.log(this.organization);
              if (this.organization.organization.domainInformation.active && this.organization.organization.domainInformation.domain && this.organization.organization.domainInformation.domain !== '') {
                console.log(`https://${this.organization.organization.domainInformation.domain}/${this.$route.params.organizationID}/Course/${this.$route.params.courseID}`);
                window.open(`https://${this.organization.organization.domainInformation.domain}/${this.$route.params.organizationID}/Course/${this.$route.params.courseID}`);
              } else {
                console.log(`https://${this.organization.organization.identifier}.okourse.com/${this.$route.params.organizationID}/Course/${this.$route.params.courseID}`);
                window.open(`https://${this.organization.organization.identifier}.okourse.com/${this.$route.params.organizationID}/Course/${this.$route.params.courseID}`);
              }
            },
            resetModals() {
                this.showMainPopup = false
                this.showNewChapterModal = false
                this.showEditChapterModal = false
                this.showNewPageModal = false
                this.showDetailsModal = false
                this.showCourseConfirmationModal = false
                this.showChapterConfirmationModal = false
                this.showDuplicateConfirmationModal = false
                this.courseTitle = ''
                this.courseDescription = null
                this.courseStatus = true
                this.courseInstructor = null
                this.chapterTitle = ''
                this.chapterDescription = null
                this.pageTitle = ''
                this.pageDescription = null
                this.showCertificate = false
                this.loading = false
              this.chapterImg = null
            },
            handleEsc() {
                this.resetModals()
            },
            toggleNewChapterModal() {
                this.showNewChapterModal = !this.showNewChapterModal;
            },
            toggleEditChapterModal() {
                this.showEditChapterModal = !this.showEditChapterModal;
            },
            toggleNewPageModal() {
                this.showNewPageModal = !this.showNewPageModal;
            },
            toggleDetailsModal() {
                this.showDetailsModal = !this.showDetailsModal;
            },
            toggleCourseConfirmationModal() {
                this.showCourseConfirmationModal = !this.showCourseConfirmationModal;
            },
          toggleDuplicateConfirmationModal() {
            this.showDuplicateConfirmationModal = !this.showDuplicateConfirmationModal;
          },
            toggleChapterConfirmationModal(id) {
                this.chapterIdCache = id;
                this.showChapterConfirmationModal = !this.showChapterConfirmationModal;
            },
            toggleMainPopup(e) {
                this.posX = e.clientX;
                this.posY = e.clientY;

                this.showMainPopup = !this.showMainPopup;
            },
            closeMainPopup() {
                this.showMainPopup = false;
            },
            handleEditChapter(val) {
                this.chapterCache = val;
                this.chapterTitle = val.title;
                this.chapterDescription = val.description;
                this.chapterImg = val.thumbnail;
                this.fullAccess = !!val.timeToAccess;
                this.timeToAccess = Math.round(val.timeToAccess / 86400000);
                this.showEditChapterModal = true
            },
            handleAddPage(id) {
                this.chapterIdCache = id;
                this.showNewPageModal = true
            },
            async updateCourse() {
                this.loading = true;
                await this.$store.dispatch('updateCourse', {
                    organizationID: this.$route.params.organizationID,
                    courseID: this.$route.params.courseID,
                    course: {
                        title: this.courseTitle !== this.course.title ? this.courseTitle : undefined,
                        description: this.courseDescription !== this.course.description ? this.courseDescription : undefined,
                        publish: this.courseStatus !== this.course.publish ? this.courseStatus : undefined,
                        instructorId: this.courseInstructor ? this.courseInstructor._id : undefined,
                        duration: this.courseDuration !== this.course.duration ? this.courseDuration : undefined,
                        certificationPossible: this.enableCertificate !== this.course.certificationPossible ? this.enableCertificate : undefined,
                    },
                }).then(async () => {
                    if (this.cachedThumb) {
                        await this.uploadThumb(this.$route.params.courseID);
                    }
                });
                this.loading = false;
                this.resetModals()
            },
            deleteCourse() {
                this.$store.dispatch('deleteCourse', {
                    organizationID: this.$route.params.organizationID,
                    courseID: this.$route.params.courseID,
                }).then(() => {
                    this.$router.push('/dashboard/organizations/' + this.$route.params.organizationID);
                    this.$store.dispatch('retrieveCourses', this.$route.params.organizationID);
                });
            },
            deleteChapter() {
                this.$store.dispatch('deleteChapter', {
                    organizationID: this.$route.params.organizationID,
                    courseID: this.$route.params.courseID,
                    chapterID: this.chapterIdCache,
                }).then(() => {
                    this.chapterIdCache = null;
                    this.toggleChapterConfirmationModal();
                });
            },
          duplicateCourse() {
            this.$store.dispatch('duplicateCourse', {
              organizationID: this.$route.params.organizationID,
              courseID: this.$route.params.courseID,
            }).then(() => {
              this.$router.push('/dashboard/organizations/' + this.$route.params.organizationID);
              this.$store.dispatch('retrieveCourses', this.$route.params.organizationID);
            });
          },
            createChapter() {
                this.$store.dispatch('createChapter', {
                    organizationID: this.$route.params.organizationID,
                    courseID: this.$route.params.courseID,
                    chapter: {
                        title: this.chapterTitle,
                        description: 'Beschreibung',
                    },
                })
                this.resetModals()
            },
            async updateChapter() {
                this.loading = true
                await this.$store.dispatch('updateChapter', {
                    organizationID: this.$route.params.organizationID,
                    courseID: this.$route.params.courseID,
                    chapterID: this.chapterCache._id,
                    chapter: {
                        title: this.chapterTitle,
                        description: this.chapterDescription,
                        // fullAccess: this.fullAccess,
                        timeToAccess: this.fullAccess ? this.timeToAccess * 86400000 : 0,
                    },
                }).catch(() => {
                    this.loading = false;
                });
                if (this.loading && this.cachedThumb) {
                  await this.$store.dispatch('uploadChapterThumbnail', {
                    organizationID: this.$route.params.organizationID,
                    courseID: this.$route.params.courseID,
                    chapterID: this.chapterCache._id,
                    thumbnail: this.cachedThumb,
                  });
                  this.cachedThumb = null;
                  this.cachedThumbName = null;
                }
                this.resetModals();
            },
            async createPage() {
                const chapterID = this.chapterIdCache;
                await this.$store.dispatch('createPage', { //todo: await hier und in ahnlichen Fällen rausschmeißen? -> modal schließt sofort, nur moglich wenn keine Daten z.B. fur spatere requests benotigt werden
                    organizationID: this.$route.params.organizationID,
                    courseID: this.$route.params.courseID,
                    chapterID,
                    page: {
                        title: this.pageTitle,
                        description: this.pageDescription,
                        pageType: this.pageType
                    },
                });
                this.resetModals();
                this.$store.commit('toggleExpandChapter', {chapterID, expand: true})
            },
            cacheThumb(fileList) {
                const formData = new FormData();
                formData.append('File', fileList[0], fileList[0].name);
                this.cachedThumb = formData;
                this.cachedThumbName = fileList[0].name;
            },
            async uploadThumb(id) {
                await this.$store.dispatch('uploadCourseThumbnail', {
                    organizationID: this.$route.params.organizationID,
                    courseID: id,
                    thumbnail: this.cachedThumb,
                });
                this.cachedThumb = null;
                this.cachedThumbName = null;
            },
        },
        watch: {
            courses() {
                if (this.course) {
                    this.courseTitle = this.course.title;
                    this.courseDescription = this.course.description;
                    this.courseStatus = this.course.publish;
                    this.courseInstructor = this.course.instructor;
                    this.courseDuration = this.course.duration;
                    this.enableCertificate = this.course.certificationPossible;
                }
            }
        },
        async mounted() {
            this.$store.dispatch('retrieveOrganizations');
            this.$store.dispatch('retrieveCourses', this.$route.params.organizationID);
            await this.$store.dispatch('retrieveCourseDetails', {
                organizationID: this.$route.params.organizationID,
                courseID: this.$route.params.courseID,
            });
            this.$store.commit('toggleExpandAllChapters');
            this.pageLoading = false
        },
    };
</script>
