<template>
    <div class="flex flex--column flex--center__vert overflowAuto">
        <div class="organizations--wrapper">
            <ActionHeader back-title="Alle Organisationen" title="Zahlungsarten" @onBack="$router.push('/dashboard')">
                <div class="flex relative">
                    <ActionButton icon="ion-md-add" :accent="true" @onClick="createNew"/>
                </div>
            </ActionHeader>
            <transition name="fade" mode="out-in">
                <div class="height--total__70 width--100 flex flex--center" v-if="isLoading">
                    <loader :loading="true" color="#114B5F" :size="'1rem'"/>
                </div>
                <div class="grid itemGrid--auto pad--2" v-if="methods.length && !isLoading">
                    <div class="itemGrid--slot flex flex--center" v-for="method in methods" :key="method.id">
                        <div class="card clickable pad--3 b-radius--5 mar--2 bgColor--white"
                             :class="{'card--selected__outline': method.defaultPaymentMethod}">
                            <CreditCard :method="method" />
                            <div class="font--s2 font--w700">
                                Rechnungsadresse
                            </div>
                            <div class="font--s16">
                                {{ method.billing_details.name }}
                            </div>
                            <div class="font--s16">
                                {{ method.billing_details.address.line1 }}
                            </div>
                            <div class="font--s16">
                                {{ method.billing_details.address.line2 }}
                            </div>
                            <div class="font--s16 mar--bottom__2">
                                {{ method.billing_details.address.postal_code }} {{ method.billing_details.address.city }}
                            </div>
                            <div class="width--100 mar--bottom__1">
                                <AccentButton :title="method.defaultPaymentMethod ? 'Als Standard ausgewählt' : 'Als Standard auswählen'"
                                              :disable="method.defaultPaymentMethod"
                                              @onClick="method.defaultPaymentMethod ? null : selectDefault(method.id)"/>
                            </div>
                            <AccentButton danger="true" title="Zahlungsart löschen" @onClick="deleteMethod(method.id)"/>
                        </div>
                    </div>
                </div>
                <NoDataAction v-if="!methods.length  && !isLoading" @onButtonClick="createNew"
                              text="Du hast noch keine Zahlungsarten erstellt." button-title="Zahlungsart erstellen"
                />
            </transition>
        </div>
    </div>
</template>
<script>
    import NoDataAction from "@/components/reusable/NoDataAction";
    import ActionHeader from "@/components/views/4_Page/ActionHeader";
    import ActionButton from "@/components/reusable/1_Buttons/ActionButton";
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    import CreditCard from "@/components/reusable/3_Other/CreditCard";
    export default {
        name: 'OrganizationPaymentMethods',
        components: {CreditCard, AccentButton, ActionButton, ActionHeader, NoDataAction},
        data() {
            return {
                isLoading: false,
            };
        },
        computed: {
            methods() {
                return this.$store.getters.getMethods;
            },
        },
        methods: {
            forward(url) {
                this.$router.push(url)
            },
            async selectDefault(id) {
                this.isLoading = true;
                await this.$store.dispatch('setDefaultMethod', {
                    paymentMethod: id,
                });
                this.isLoading = false;
            },
            async deleteMethod(id) {
                this.isLoading = true;
                await this.$store.dispatch('deleteMethod', {
                    paymentMethod: id,
                });
                this.isLoading = false;
            },
            async createNew() {
                // eslint-disable-next-line
                this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
                const session = await this.$store.dispatch('retrieveSetupSession', {
                    cancelURL: `${process.env.VUE_APP_DOMAIN_FRONTEND}/dashboard/methods`,
                    successURL: `${process.env.VUE_APP_DOMAIN_FRONTEND}/dashboard/methods`,
                });
                if (session) {
                    this.stripe.redirectToCheckout({
                        sessionId: session,
                    });
                }
            },
        },
        async mounted() {
            this.isLoading = true;
            await this.$store.dispatch('retrieveMethods');
            this.isLoading = false;
        },
    };
</script>
