<template>
    <div class="landing--grid content relative">
        <div class="flex flex--center pad--top__2">
            <img src="../../../assets/illustrations/Contact1.png" class="width--80 transition"
                 :style="{ transform: position1 }"/>
        </div>
        <div class="pad--2 flex flex--column flex--center__hor info">
            <div class="font--s5 font--w700 lineHeight--12 mar--bottom__4 color--primaryDark">
                <span class="color--secondary">Noch fragen?</span> Schreib uns eine Nachricht.
            </div>
            <div class="flex width--50">
                <div class="width--100">
                    <AccentButton title="Jetzt kontaktieren" secondary="true"  @onClick="showContact = true"/>
                </div>
            </div>
        </div>
        <div class="absolute top--1 bubble bubble--medium bubble--left bubble--slow" />
        <div class="absolute bottom--0 bubble bubble--mediumLarge bubble--right bubble--slow2" />
        <transition name="fade" mode="out-in">
            <Modal title="Was können wir für dich tun?" v-if="showContact" @toggle="showContact = false">
                <div class="pad--3 flex flex--column flex--center__hor width--100">
                    <label for="email" class="form--label">
                        Deine Email
                    </label>
                    <input id="email" type="text" class="form--input form--input__text mar--bottom__1" v-model="email"/>
                    <label for="message" class="form--label">
                        Nachricht
                    </label>
                    <textarea id="message" class="form--input form--input__textArea mar--bottom__4" v-model="message"/>
                    <AccentButton title="Nachricht abschicken" @onClick="sendMail" :loading="loading"/>
                </div>
            </Modal>
        </transition>
    </div>
</template>
<script>
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    import Modal from "@/components/reusable/2_Modals/Modal";
    export default {
        name: 'Contact',
        components: {Modal, AccentButton},
        data() {
            return {
                showContact: false,
                subject: null,
                message: null,
                email: null,
                loading: false,
                position1: `translateY(0)`,
            };
        },
        methods: {
            sendMail() {
                if (!this.message || !this.email || !this.email.includes('@')) {
                    this.$store.commit('addToast', {
                        type: 'warning',
                        title: 'Bitte fülle alle Felder aus'
                    });
                    return;
                }
                this.loading = true;
                let success = true;
                this.$store.dispatch('sendMail', {
                    email: this.email,
                    body: this.message,
                    subject: 'Contact Message',
                }).catch(() => {
                    success = false;
                }).then(() => {
                    if (success) {
                        this.$store.commit('addToast', {
                            type: 'success',
                            title: 'Vielen Dank! Wir werden uns in Kürze bei dir melden.'
                        });
                        this.showContact = false;
                        this.message = null;
                        this.email = null;
                    }
                    this.loading = false;
                })
            },
            updateScroll() {
                this.position1 = `translateY(-${window.scrollY * 0.4}px)`;
            },
        },
        mounted() {
            window.addEventListener('scroll', this.updateScroll)
        },
        destroy() {
            window.removeEventListener('scroll', this.updateScroll)
        },
    };
</script>
