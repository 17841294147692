<template>
    <div class="flex flex--column flex--center">
        <div class="font--w700 font--s25">
            Bestellübersicht
        </div>
        <div class="card mar--2 b-radius--5 flex flex--column pad--3 width--100 bgColor--white">
            <div class="flex flex--column width--100 font--s16 mar--bottom__2">
                <div class="font--w700 font--s2 mar--bottom__1">
                    Produkt
                </div>
                <div class="">
                    {{ plan.nickname }} - OKourse Abonnement
                </div>
                <div class="">
                    {{ plan.type === 'year' ? (plan.amount / 100) / 12 : (plan.amount / 100)}}€ monatlich
                </div>
                <div class="mar--bottom__1">
                    {{ plan.type === 'year' ? 'Jährliche' : 'Monatliche' }} Abrechnung
                </div>
                <div class="font--w700">
                    Gesamtbetrag:
                </div>
                <div class="font--w700">
                    <span class="font--s25">{{ validCoupon ? validCoupon.amount_off ? (plan.amount - validCoupon.amount_off) / 100 : validCoupon.percent_off ? (plan.amount * ((100 - validCoupon.percent_off) / 100)) / 100 : plan.amount / 100 : plan.amount / 100 }} €</span> inkl. Mwst.
                </div>
            </div>
<!--            <div class="width&#45;&#45;100 border&#45;&#45;bottom__light mar&#45;&#45;bottom__1" />-->
            <div class="font--w700 font--s2 mar--bottom__1">
                Gutscheincode
            </div>
            <div class="flex" v-if="!validCoupon">
                <input type="text" class="form--input form--input__text mar--bottom__1" v-model="coupon"/>
                <div class="mar--right__1" />
                <AccentButton title="Einlösen" :disable="!coupon || isLoading" @onClick="checkCoupon"/>
            </div>
            <div class="flex flex--center__vert flex--space__hor font--s16 border--light b-radius--5 pad--1 mar--bottom__1" v-if="validCoupon">
                <div class="flex flex--center__vert">
                    <div class="font--w700  mar--right__1">
                        {{ coupon }}
                    </div>
                    <div class="">
                        {{ validCoupon.amount_off ? `${validCoupon.amount_off}€` : `${validCoupon.percent_off}%`}} Rabatt
                        {{ validCoupon.duration === 'once' ? 'einmalig' : validCoupon.duration === 'repeating' ? `für die ersten ${validCoupon.duration_in_months} Monate` : null}}
                    </div>
                </div>
                <ActionButton icon="ion-md-close" @onClick="validCoupon = null"/>
            </div>
<!--            <div class="width&#45;&#45;100 border&#45;&#45;bottom__light mar&#45;&#45;bottom__2" />-->
            <div class="flex flex--column width--100 font--s16">
                <div class="font--w700 font--s2 mar--bottom__1">
                    Zahlungsart
                </div>
                <div class="">
                    {{ method.card.brand.toUpperCase() }} - endet auf {{ method.card.last4 }}
                </div>
                <div class="">
                    {{ method.billing_details.name }}
                </div>
                <div class="">
                    {{ method.billing_details.address.line1 }}
                </div>
                <div class="">
                    {{ method.billing_details.address.line2 }}
                </div>
                <div class="">
                    {{ method.billing_details.address.postal_code }} {{ method.billing_details.address.city }}
                </div>
            </div>
        </div>
        <div class="flex flex--center flex--center__vert width--100 mar--bottom__2">
            <div class="auth--stay b-radius--5 mar--right__1 clickable flex flex--center"
                 @click.self="accept = !accept"
                 :class="{'border--dark': accept, 'border--light': !accept}">
                <transition name="fade">
                    <i class="ion-md-checkmark font--s2" v-if="accept"/>
                </transition>
            </div>
            <div class="font--s16 clickable" @click="accept = !accept">
                Ich akzeptiere die <span class="font--w700" @click="open('agb')">Allgemeinen Geschäftsbedingungen</span> und die <span class="font--w700"  @click="open('data')">Dateschutzerklärung</span>
            </div>
        </div>
        <div class="width--100 mar--bottom__2">
            <IconButton title="Jetzt sicher bezahlen" filled="true" icon="ion-md-lock" @onClick="checkout" :loading="isLoading" :disable="!accept"/>
        </div>
    </div>
</template>
<script>
    import IconButton from "@/components/reusable/1_Buttons/IconButton";
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    import ActionButton from "@/components/reusable/1_Buttons/ActionButton";
    export default {
        name: 'Checkout',
        components: {ActionButton, AccentButton, IconButton},
        data() {
            return {
                coupon: null,
                validCoupon: null,
                isLoading: false,
                accept: false,
            };
        },
        computed: {
            plans() {
                return this.$store.getters.getPlans;
            },
            plan() {
                if (this.plans) {
                    return this.plans.find((el) => el.id === this.$route.params.planID);
                }
                return {};
            },
            methods() {
                return this.$store.getters.getMethods;
            },
            method() {
                if (this.methods) {
                    return this.methods.find((el) => el.id === this.$route.params.paymentID);
                }
                return {};
            },
        },
        methods: {
            open(url) {
                if (url === 'agb') {
                    window.open(process.env.VUE_APP_DOMAIN_FRONTEND + '/agb');
                } else {
                    window.open(process.env.VUE_APP_DOMAIN_FRONTEND + '/data');
                }
            },
            checkCoupon() {
                if (this.coupon && this.coupon !== '') {
                    this.isLoading = true;
                    this.$store.dispatch('retrieveValidCoupon', {
                        coupon: this.coupon,
                    }).then((coupon) => {
                        this.validCoupon = coupon;
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 600);
                    }).catch(() => {
                        this.coupon = null;
                        this.validCoupon = null;
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 600);
                    });
                }
            },
            async checkout() {
                this.isLoading = true;
                this.$store.dispatch('createSubscription', {
                    organizationID: this.$route.params.organizationID,
                    data: {
                        coupon: this.coupon ? this.coupon : undefined,
                        planId: this.plan.id,
                        paymentMethod: this.$route.params.paymentID,
                    }
                }).then(async () => {
                    await this.$store.dispatch('retrieveOrganizations');
                    this.$router.push(`/dashboard/organizations/${this.$route.params.organizationID}/plan/success`);
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 600);
                }).catch(() => {
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 600);
                });
            },
        },
    };
</script>
