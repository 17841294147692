<template>
    <div class="pad--5 height--total__50 flex flex--column flex--center">
        <i class="ion-md-checkmark-circle-outline font--s10 color--primaryLight mar--bottom__2" />
        <div class="font--w700 font--s3 mar--bottom__2">
            Vielen Dank für deinen Einkauf!
        </div>
        <div class="font--s16 font--center mar--bottom__2">
            Wir wünschen dir viel Erfolg mit OKourse.
        </div>
        <AccentButton title="Zu meinen Organisationen" @onClick="$router.push(`/dashboard`)" />
    </div>
</template>
<script>
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    export default {
        name: 'PaymentSuccess',
        components: {AccentButton},
    };
</script>
