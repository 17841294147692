<template>
    <header class="headerLight fixed flex flex--center">
        <div class="headerLight--wrapper relative flex flex--center__vert flex--space__hor">
            <router-link to="/"
                         exact class="noDec">
                <img class="height--2 clickable" src="../../../assets/SVG3.svg"/>
            </router-link>
            <div class="flex flex--center__vert">
                <nav class="flex--center__vert mar--right__2 headerLight--mobile__links">
                    <a href="https://okourse.app/"
                       target="_blank"
                       class="headerLight--link noDec">
                        App
                    </a>
                    <router-link to="/comparison"
                                 exact
                                 @click.native="linkNavigation"
                                 active-class="headerLight--link__active"
                                 class="headerLight--link">
                        Plattformvergleich
                    </router-link>
                    <router-link to="/information"
                                 exact
                                 @click.native="linkNavigation"
                                 active-class="headerLight--link__active"
                                 class="headerLight--link">
                        Preise
                    </router-link>
                    <router-link to="/contact"
                                 exact
                                 @click.native="linkNavigation"
                                 active-class="headerLight--link__active"
                                 class="headerLight--link">
                        Kontakt
                    </router-link>
                </nav>
                <AccentButton title="Los Geht's" secondary="true" @onClick="$router.push('/dashboard')"/>
                <button class="burgerMenu burgerMenu--spin flex--center noDec headerLight--mobile__burger" type="button" :class="{'is-active': mobileHeader}" @click="mobileHeader = !mobileHeader">
                      <span class="burgerMenu-box">
                        <span class="burgerMenu-inner" />
                      </span>
                </button>
            </div>
        </div>
        <transition name="fade">
            <nav class="absolute flex flex--column headerLight--mobile__menu pad--3" v-if="mobileHeader">
                <a href="https://okourse.app/"
                   target="_blank"
                   class="headerLight--link noDec">
                    App
                </a>
                <router-link to="/comparison"
                             exact
                             @click.native="linkNavigation"
                             active-class="headerLight--link__active"
                             class="headerLight--link">
                    Plattformvergleich
                </router-link>
                <router-link to="/information"
                             exact
                             @click.native="linkNavigation"
                             active-class="headerLight--link__active"
                             class="headerLight--link flex">
                    Preise
                </router-link>
                <router-link to="/contact"
                             exact
                             @click.native="linkNavigation"
                             active-class="headerLight--link__active"
                             class="headerLight--link flex">
                    Kontakt
                </router-link>
            </nav>
        </transition>
    </header>
</template>
<script>
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    export default {
        name: 'LandingHeader',
        components: {AccentButton},
        data() {
            return {
                posX: null,
                posY: null,

                mobileHeader: false,

                showMainPopup: false,
            };
        },
        methods: {
            logout() {
                this.$router.push('/login');
            },
            linkNavigation() {
                document.getElementById('main').scrollTop = 0;
                this.mobileHeader = false;
            },
        },
    };
</script>
