/* eslint-disable no-console */
import axios from 'axios'
import tus from "tus-js-client";
import router from '@/router'
import store from '@/store'
import {delay} from "../services/helpers";

let BASE_URL = process.env.VUE_APP_DOMAIN_BACKEND;
let API = BASE_URL + '/editors';

const VIMEO_OEMBED_API = 'https://vimeo.com/api/oembed.json?url=';

const ORGS = '/organizations/';
const COURSES = '/courses/';
const CHAPTERS = '/chapters/';
const PAGES = '/pages/';

const orgs = orgId => ORGS + orgId;
const courses = (orgId, courseId) => orgs(orgId) + COURSES + courseId;
const chapters = (orgId, courseId, chapterId) => courses(orgId, courseId) + CHAPTERS + chapterId;
const pages = (orgId, courseId, chapterId, pageId) => chapters(orgId, courseId, chapterId) + PAGES + pageId;
const pagesFromRoute = p => pages(p.organizationID, p.courseID, p.chapterID, p.pageID)

const videoAxios = axios.create();

axios.defaults.withCredentials = true;
axios.defaults.timeout = 30000;

// global error handling
// Vercel check

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let errorMsg;
    if (error && error.response) {
        errorMsg = store.getters.getErrorMsg(error.response.status, error.response.data.msg);
    }
    if (errorMsg) {
        store.commit('addToast', {
            type: 'error',
            title: errorMsg
        })
    } else {
        store.commit('addToast', {
            type: 'error',
            title: 'Es ist ein Fehler aufgetreten.'
        })
    }
    return Promise.reject(error);
});


export default {

    // ORGANIZATION ROUTES --------------------------------------------------------------------------------------------

    async retrieveOrganizations({commit}) {
        try {
            const res = await axios.get(API + ORGS);
            commit('setOrganizations', res.data);
            commit('setAuthenticated');
        } catch (e) {

            // eslint-disable-next-line
            console.log(e.response.data.msg);
            
            if (e.response.status >= 401 && e.response.status < 500) {
                router.replace('/login')
            }
        }
    },

    async createOrganization({dispatch}, organization) {
        const res = await axios.post(API + ORGS, organization);
        dispatch('retrieveOrganizations');
        return res.data. _id;
    },

    async updateOrganization({dispatch}, {organization, organizationID}) {
        const res = await axios.put(API + orgs(organizationID), organization);
        dispatch('retrieveOrganizations');
        return res.data._id;
    },

    async deleteOrganization({dispatch}, organizationID) {
        await axios.delete(API + orgs(organizationID));
        dispatch('retrieveOrganizations');
    },

    async uploadOrganizationThumbnail({dispatch}, {organizationID, thumbnail}) {
        await axios.post(API + orgs(organizationID) + '/thumbnail', thumbnail);
        dispatch('retrieveOrganizations');
    },

    // EDITOR ROUTES -------------------------------------------------------------------------------------------------


    async inviteEditor({dispatch}, {organizationID, editor}) {
        await axios.post(API + orgs(organizationID) + '/editors', editor);
        dispatch('retrieveEditors', organizationID);
    },

    async retrieveEditors({commit}, organizationID) {
        const res = await axios.get(API + orgs(organizationID) + '/editors');
        commit('setEditors', res.data);
    },

    async retrieveOwnEditor({commit}) {
        const res = await axios.get(API + '/me');
        commit('setUser', res.data);
    },

    async updateOwnEditor({dispatch}, data) {
        await axios.put(API + '/me', data);
        dispatch('retrieveOwnEditor');
    },

    async updateEditorRole({dispatch}, {organizationID, editorID, role}) {
        await axios.put(API + orgs(organizationID) + '/editors/' + editorID, role);
        dispatch('retrieveEditors', organizationID);
    },

    async removeEditor({dispatch}, {organizationID, editorID}) {
        await axios.delete(API + orgs(organizationID) + '/editors/' + editorID);
        dispatch('retrieveEditors', organizationID);
    },

    // INSTRUCTOR ROUTES ---------------------------------------------------------------------------------------------

    async createInstructor({dispatch}, {organizationID, instructor}) {
        await axios.post(API + orgs(organizationID) + '/instructors', instructor);
        dispatch('retrieveCourses', organizationID);
    },

    async updateInstructor({dispatch}, {organizationID, instructorID, instructor}) {
        // eslint-disable-next-line no-unused-vars
        await axios.put(API + orgs(organizationID) + '/instructors/' + instructorID, instructor).catch((error) => {
        });
        dispatch('retrieveCourses', organizationID);
    },

    async removeInstructor({dispatch}, {organizationID, instructorID}) {
        await axios.delete(API + orgs(organizationID) + '/instructors/' + instructorID);
        dispatch('retrieveCourses', organizationID);
    },

    // MEMBER ROUTES -------------------------------------------------------------------------------------------------

    async createMember({dispatch}, {organizationID, member}) {
        await axios.post(API + orgs(organizationID) + '/members', member);
        dispatch('retrieveMembers', {
            organizationID,
            range: {
                start: 0,
                end: 50,
            }
        });
        dispatch('retrieveCourses', organizationID);
    },

    async deleteMember({dispatch}, {organizationID, memberID}) {
        await axios.delete(API + orgs(organizationID) + '/members/' + memberID);
        dispatch('retrieveMembers', {
            organizationID,
            range: {
                start: 0,
                end: 50,
            }
        });
        dispatch('retrieveCourses', organizationID);
    },

    async retrieveDetailedMember({commit}, {organizationID, memberID}) {
        const res = await axios.get(API + orgs(organizationID) + '/members/' + memberID);
        commit('setDetailedMember', res.data);
    },

    async retrieveMembers({commit}, {organizationID, range}) {
        const res = await axios.post(API + orgs(organizationID) + '/members/fetch', range);
        commit('setMembers', res.data.data);
        commit('setMemberCount', res.data.total);
    },

    async searchMembers({commit}, {organizationID, data}) {
        const res = await axios.post(API + orgs(organizationID) + '/members/search', data);
        commit('setMembers', res.data.data);
        commit('setMemberCount', res.data.total);
    },

    async retrieveCourseMembers({commit}, {organizationID, courseID, range}) {
        const res = await axios.post(API + courses(organizationID, courseID) + '/members/fetch', range);
        commit('setMembers', res.data.data);
        commit('setMemberCount', res.data.total);
    },

    async searchCourseMembers({commit}, {organizationID, courseID,  data}) {
        const res = await axios.post(API + courses(organizationID, courseID) + '/members/search', data);
        commit('setMembers', res.data.data);
        commit('setMemberCount', res.data.total);
    },

    async removeMemberCourse({dispatch}, {organizationID, memberID, courseID}) {
        await axios.delete(API + orgs(organizationID) + '/members/' + memberID + '/courses/' + courseID);
        dispatch('retrieveMembers', {
            organizationID,
            range: {
                start: 0,
                end: 50,
            }
        });
    },

    async addMemberCourse({dispatch}, {organizationID, memberID, courseID, course}) {
        await axios.post(API + orgs(organizationID) + '/members/' + memberID + '/courses/' + courseID, course);
        dispatch('retrieveMembers', {
            organizationID,
            range: {
                start: 0,
                end: 50,
            }
        });
    },

    async updateMemberCourse({dispatch}, {organizationID, memberID, courseID, course}) {
        await axios.put(API + orgs(organizationID) + '/members/' + memberID + '/courses/' + courseID, course);
        dispatch('retrieveMembers', {
            organizationID,
            range: {
                start: 0,
                end: 50,
            }
        });
    },

    async updateMember({dispatch}, {organizationID, memberID, data}) {
        await axios.put(API + orgs(organizationID) + '/members/' + memberID, data);
        dispatch('retrieveMembers', {
            organizationID,
            range: {
                start: 0,
                end: 50,
            }
        });
    },

    async importMembers({dispatch}, {organizationID, courseID, data}) {
        await axios.post(API + courses(organizationID, courseID) + '/import', data);
        dispatch('retrieveMembers', {
            organizationID,
            range: {
                start: 0,
                end: 50,
            }
        });
    },

    // COURSE ROUTES -------------------------------------------------------------------------------------------------

    async retrieveCourses({commit}, organizationID) {
        const res = await axios.get(API + ORGS + organizationID + COURSES);
        commit('setCourses', res.data);
    },

    async retrieveCourseDetails({commit}, {organizationID, courseID, status}) {
        const res = await axios.get(API + courses(organizationID, courseID));
        console.log(res.data);
        if (!status || (status && res.data && res.data.video && res.data.video.status !== status)) {
            commit('setCourseDetails', res.data);
        }
    },

    async createCourse({dispatch}, {organizationID, course}) {
        const res = await axios.post(API + ORGS + organizationID + COURSES, course);
        dispatch('retrieveCourses', organizationID);
        return res.data._id;
    },

    async updateCourse({dispatch}, {organizationID, courseID, course}) {
        await axios.put(API + courses(organizationID, courseID), course);
        dispatch('retrieveCourses', organizationID);
    },

    async duplicateCourse({dispatch}, {organizationID, courseID}) {
        await axios.post(API + courses(organizationID, courseID) + '/duplicate', {});
        dispatch('retrieveCourses', organizationID);
    },

    async deleteCourse(_, {organizationID, courseID}) {
        await axios.delete(API + courses(organizationID, courseID));
    },

    async uploadCourseThumbnail({dispatch}, {organizationID, courseID, thumbnail}) {
        await axios.post(API + courses(organizationID, courseID) + '/thumbnail', thumbnail);
        dispatch('retrieveCourses', organizationID);
    },

    async uploadChapterThumbnail({dispatch}, {organizationID, courseID, chapterID, thumbnail}) {
        await axios.post(API + chapters(organizationID, courseID, chapterID) + '/thumbnail', thumbnail);
        dispatch('retrieveCourseDetails', {organizationID, courseID});
    },

    async uploadCourseHeader({dispatch}, {organizationID, courseID, header}) {
        await axios.post(API + courses(organizationID, courseID) + '/header', header);
        dispatch('retrieveCourses', organizationID);
    },

    async deleteCourseHeader({dispatch}, {organizationID, courseID}) {
        await axios.delete(API + courses(organizationID, courseID) + '/header');
        dispatch('retrieveCourses', organizationID);
    },

    async uploadCourseAd({dispatch}, {organizationID, courseID, ad}) {
        await axios.post(API + courses(organizationID, courseID) + '/elements', ad);
        dispatch('retrieveCourses', organizationID);
    },

    async deleteCourseAd({dispatch}, {organizationID, courseID, adID}) {
        await axios.delete(API + courses(organizationID, courseID) + '/elements/' + adID);
        dispatch('retrieveCourses', organizationID);
    },

    // API-TOKEN ROUTES -----------------------------------------------------------------------------------------------

    async retrieveApiTokens({commit}, organizationID) {
        const res = await axios.get(API + orgs(organizationID) + '/apiTokens');
        commit('setApiTokens', res.data);
    },

    async generateApiToken({dispatch}, organizationID) {
        await axios.post(API + orgs(organizationID) + '/apiTokens');
        dispatch('retrieveApiTokens', organizationID);
    },

    async removeApiToken({dispatch}, {organizationID, tokenID}) {
        await axios.delete(API + orgs(organizationID) + '/apiTokens/' + tokenID);
        dispatch('retrieveApiTokens', organizationID);
    },

    // CHAPTER ROUTES -------------------------------------------------------------------------------------------------

    async createChapter({dispatch}, {organizationID, courseID, chapter}) {
        await axios.post(API + courses(organizationID, courseID) + CHAPTERS, chapter);
        dispatch('retrieveCourseDetails', {organizationID, courseID});
    },

    async updateChapter({dispatch}, {organizationID, courseID, chapterID, chapter}) {
        await axios.put(API + chapters(organizationID, courseID, chapterID), chapter);
        dispatch('retrieveCourseDetails', {organizationID, courseID});
    },

    async deleteChapter({dispatch}, {organizationID, courseID, chapterID}) {
        await axios.delete(API + chapters(organizationID, courseID, chapterID));
        dispatch('retrieveCourseDetails', {organizationID, courseID});
    },

    async updateChapterOrder({dispatch}, {organizationID, courseID, chapters}) {
        await axios.put(API + courses(organizationID, courseID) + '/move', chapters)
        dispatch('retrieveCourseDetails', {organizationID, courseID});
    },

    // THEME ROUTES ---------------------------------------------------------------------------------------------------

    async retrieveTheme({commit}, organizationID) {
        const res = await axios.get(BASE_URL + orgs(organizationID) + '/themes');
        commit('setTheme', res.data.theme);
    },

    async updateTheme({dispatch}, {organizationID, theme}) {
        await axios.put(API + orgs(organizationID) + '/themes', theme);
        dispatch('retrieveTheme', organizationID);
    },

    async uploadLogo({dispatch}, {organizationID, logo}) {
        await axios.post(API + orgs(organizationID) + '/themes/header', logo);
        dispatch('retrieveTheme', organizationID);
    },

    async removeLogo({dispatch}, organizationID) {
        await axios.delete(API + orgs(organizationID) + '/themes/header');
        dispatch('retrieveTheme', organizationID);
    },

    async uploadFavicon({dispatch}, {organizationID, logo}) {
        await axios.post(API + orgs(organizationID) + '/themes/favicon', logo);
        dispatch('retrieveTheme', organizationID);
    },

    async removeFavicon({dispatch}, organizationID) {
        await axios.delete(API + orgs(organizationID) + '/themes/favicon');
        dispatch('retrieveTheme', organizationID);
    },

    // AUTHENTICATION ROUTES ------------------------------------------------------------------------------------------

    async login({ commit, getters }, credentials) {
        let success = true;
        const res = await axios.post(API + '/login', credentials, { withCredentials: true }).catch((error) => {
            // eslint-disable-next-line
            console.log(error.response);
            success = false;
            return error.response;
        });
        if (success) {
            commit('setToken', res.headers['x-auth-token']);
            if (credentials.rememberLogin) {
                localStorage.setItem('x-auth-token', res.headers['x-auth-token']);
                sessionStorage.removeItem('x-auth-token')
            } else {
                sessionStorage.setItem('x-auth-token', res.headers['x-auth-token'])
                localStorage.removeItem('x-auth-token')
            }
            axios.defaults.headers.common['x-auth-token'] = getters.getToken;
        }
        return res;
    },

    async logout({commit}) {
        await axios.post(BASE_URL + '/logout/editor', {}, { withCredentials: true }).catch((error) => {
            // eslint-disable-next-line
            console.log(error.response.data.msg);
        });
        commit('setToken', null);
        sessionStorage.removeItem('x-auth-token')
        localStorage.removeItem('x-auth-token')
        axios.defaults.headers.common['x-auth-token'] = null;
    },

    async register({dispatch}, credentials) {
        let success = true;
        await axios.post(API, credentials).catch(() => {
            success = false;
        });
        if (success) {
            await dispatch('login', {
                email: credentials.email,
                password: credentials.password,
                rememberLogin: true,
            });
        }
    },

    async refreshToken({ getters, commit }) {
        console.log(getters.getToken);
        axios.defaults.headers.common['x-auth-token'] = getters.getToken;
        if (!getters.getToken) throw Error;
        const res = await axios.post(API + `/refresh-token`, {}, { withCredentials: true }).catch((error) => {
            console.log(error.response.data.msg);
        });
        if ( res) {
            commit('setToken', res.headers['x-auth-token']);
            axios.defaults.headers.common['x-auth-token'] = getters.getToken;
            if (localStorage.getItem('x-auth-token')) {
                localStorage.setItem('x-auth-token', res.headers['x-auth-token']);
                sessionStorage.removeItem('x-auth-token')
            } else {
                sessionStorage.setItem('x-auth-token', res.headers['x-auth-token'])
                localStorage.removeItem('x-auth-token')
            }
            axios.defaults.headers.common['x-auth-token'] = getters.getToken;
        } else {
            // commit('setJWT', null);
        }
    },

    async forgotPassword(_, email) {
        await axios.post(API + `/password/forgot`, email);
    },
    async resetPassword(_, data) {
        await axios.post(API + `/password/reset`, data);
    },
    async checkSecretValid(_, secret) {
        await axios.post(API + `/password/valid`, secret);
    },
    async forgotMemberPassword(_, {organizationID, email}) {
        await axios.post(BASE_URL + '/members/organizations/' + organizationID + `/password/forgot`, {email});
    },

    // PAGES ROUTES ------------------------------------------------------------------------------------------

    async createPage({dispatch}, {organizationID, courseID, chapterID, page}) {
        await axios.post(API + chapters(organizationID, courseID, chapterID) + PAGES, page)
        dispatch('retrieveCourseDetails', {organizationID, courseID})
    },
    async deletePage(_, params) {
        await axios.delete(API + pagesFromRoute(params))
    },
    async updatePage({dispatch}, {params, page}) {
        await axios.put(API + pagesFromRoute(params), page)
        const {organizationID, courseID} = params
        dispatch('retrieveCourseDetails', {organizationID, courseID})
    },

    async uploadDownload(_, {params, formData}) {
        try {
            await axios.post(API + pagesFromRoute(params) + '/files', formData)
            return true
        } catch (e) {
            return false
        }
    },
    async deleteDownload({commit}, {params, id}) {
        try {
            await axios.delete(API + pagesFromRoute(params) + `/files/${id}`)
        } catch (e) {
            commit('addToast', {
                type: 'error',
                title: `Es gab einen Fehler beim Löschen des Downloads. Bitte versuche es erneut`,
            })
        }
    },

    // OFFER ROUTES -----------------------------------------------------------------------------------------

    async retrieveOffers({ commit }, organizationID) {
        const res = await axios.get(API + orgs(organizationID) + '/offers');
        console.log(res.data);
        commit('setOffers', res.data);
    },

    async createOffer({ dispatch }, {organizationID, data}) {
        await axios.post(API + orgs(organizationID) + '/offers', data);
        dispatch('retrieveOffers', organizationID);
    },

    async editOffer({ dispatch }, {organizationID, offerID, data}) {
        await axios.put(API + orgs(organizationID) + '/offers/' + offerID, data);
        dispatch('retrieveOffers', organizationID);
    },

    async deleteOffer({ dispatch }, {organizationID, offerID}) {
        await axios.delete(API + orgs(organizationID) + '/offers/' + offerID);
        dispatch('retrieveOffers', organizationID);
    },

    async addMemberOffer(_, {organizationID, memberID, data}) {
        await axios.post(`${API}${orgs(organizationID)}/members/${memberID}/offers`, data);
    },

    async updateMemberOffer(_, {organizationID, memberID, offerID, data}) {
        await axios.put(`${API}${orgs(organizationID)}/members/${memberID}/offers/${offerID}`, data);
    },

    async deleteMemberOffer(_, {organizationID, memberID, offerID}) {
        await axios.delete(`${API}${orgs(organizationID)}/members/${memberID}/offers/${offerID}`);
    },

    // PAYMENT ROUTES ---------------------------------------------------------------------------------------

    async createSubscription(_, {organizationID, data}) {
        const res = await axios.post(API + orgs(organizationID) + '/subscription', data);
        return res.data.sessionId;
    },

    async getCustomerPortal({ commit }, {organizationID, data}) {
        const res = await axios.post(API + orgs(organizationID) + '/customer-portal', data);
        commit('setCustomerPortal', res.data.url)
    },

    async fetchBillingAddress({ commit }, {organizationID}) {
        const res = await axios.get(API + orgs(organizationID) + '/billing-address');
        console.log(res);
        if (res.data) {
            commit('setBillingAddress', res.data);
        } else {
            commit('setBillingAddress', {});
        }
        // dispatch('retrieveSubscriptions');
    },

    async updateBillingAddress(_, {organizationID, data}) {
        await axios.put(API + orgs(organizationID) + '/billing-address', data);
        // dispatch('retrieveSubscriptions');
    },

    async upgradeSubscription({ dispatch }, {organizationID, data}) {
        await axios.put(API + orgs(organizationID) + '/subscription', data);
        dispatch('retrieveSubscriptions');
    },

    async retrievePlans({commit}) {
        const res = await axios.get(BASE_URL + '/plans');
        commit('setPlans', res.data);
    },

    async retrieveSetupSession(_, data) {
        const res = await axios.post(API + '/payments/setup', data);
        return res.data.session;
    },

    async retrieveValidCoupon(_, data) {
        const res = await axios.post(API + '/payments/coupon', data);
        return res.data;
    },

    async retrieveMethods({commit}) {
        const res = await axios.get(API + '/paymentmethods');
        commit('setMethods', res.data);
    },

    async setDefaultMethod({ dispatch }, data) {
        await axios.post(API + '/paymentmethods/default', data);
        dispatch('retrieveMethods');
    },

    async deleteMethod({ dispatch }, data) {
        await axios.delete(API + '/paymentmethods', { data });
        dispatch('retrieveMethods');
    },

    async retrieveSubscriptions({commit}) {
        const res = await axios.get(API + '/subscriptions');
        commit('setSubscriptions', res.data);
    },

    async deleteSubscription({ dispatch }, { organizationID, data}) {
        await axios.post(API + orgs(organizationID) + '/subscription/cancel', data);
        dispatch('retrieveSubscriptions');
    },

    async changeMethodOfSubscription({ dispatch }, data) {
        await axios.put(API + '/subscriptions', data);
        dispatch('retrieveSubscriptions');
    },

    async retrieveInvoices({commit}) {
        const res = await axios.get(API + '/invoices');
        commit('setInvoices', res.data);
    },

    // CONTACT ROUTES ----------------------------------------------------------------------------------------
    async sendMail(_, data) {
        await axios.post(BASE_URL + '/email', data);
    },

    async sendAuthMail(_, data) {
        await axios.post(API + '/email', data);
    },

    // VIDEO ROUTES ------------------------------------------------------------------------------------------

    uploadVideo({commit, getters}, {file, params, onProgress, onSuccess}) {
        const upload = new tus.Upload(file, {
            endpoint: 'https://upload.okourse.com'+ '/editors' + pagesFromRoute(params) + '/video',
            retryDelays: [0, 3000, 5000, 10000, 20000],
            removeFingerprintOnSuccess: true,
            metadata: {
                filename: file.name,
                filetype: file.type,
            },
            headers: {
                'x-auth-token': getters.getToken,
            },
            withCredentials: true,
            // eslint-disable-next-line no-unused-vars
            onError: error => {
                commit('addToast', {
                    type: 'error',
                    title: `Es gab einen Fehler beim Hochladen des Videos. Bitte versuche es erneut`,
                })
            },
            onProgress,
            onSuccess
        })
        upload.start()
    },

    async retrieveVideoHtml(_, videoUrl) {
        const res = await videoAxios.get(VIMEO_OEMBED_API + videoUrl)
        return res.data.html
    },

    async retrieveVideoDetails(_, videoId) {
        const res = await axios.post(API + '/video-details', { videoId });
        return res.data;
    },

    async deleteVideo({dispatch}, params) {
        await axios.delete(API + pagesFromRoute(params) + '/deleteVideo')
        dispatch('retrieveCourseDetails', {
            organizationID: params.organizationID,
            courseID: params.courseID,
        })
    },

    async updateVideoThumbnail({commit, dispatch}, {params, formData}) {
        await axios.post(API + pagesFromRoute(params) + '/thumbnail', formData)
        await delay(15000)
        await dispatch('retrieveCourseDetails', {
            organizationID: params.organizationID,
            courseID: params.courseID,
        })
        commit('addToast', {
            type: 'success',
            title: `Thumbnail wurde erfolgreich hochgeladen`,
        })
    },

    // WEBHOOK ROUTES ---------------------------------------------------------------------------------------------

    async retrieveEndpoints({commit}, organizationID) {
        const res = await axios.get(API + orgs(organizationID) + '/endpoints');
        console.log(res.data);
        commit('setWebhooks', res.data);
    },

    async retrieveLogs({commit}, { organizationID, endpointID, start = 0, end = 10 }) {
        const res = await axios.get(API + orgs(organizationID) + '/endpoints/' + endpointID + `?start=${start}&end=${end}`);
        console.log(res.data);
        commit('setLogs', res.data);
    },

    async createEndpoint({dispatch}, { organizationID, data }) {
        const res = await axios.post(API + orgs(organizationID) + '/endpoints', data);
        console.log(res.data);
        dispatch('retrieveEndpoints', organizationID);
    },

    async deleteEndpoint({dispatch}, { organizationID, endpointID }) {
        const res = await axios.delete(API + orgs(organizationID) + '/endpoints/' + endpointID);
        console.log(res.data);
        dispatch('retrieveEndpoints', organizationID);
    },

    async resendEvent({dispatch}, { organizationID, endpointID, eventID }) {
        const res = await axios.post(API + orgs(organizationID) + '/events/' + eventID);
        console.log(res.data);
        dispatch('retrieveLogs', { organizationID, endpointID });
    },

    async testEndpoint({commit}, { organizationID, endpointID }) {
        const res = await axios.post(API + orgs(organizationID) + '/endpoints/' + endpointID + '/test', {});
        console.log(res.data);
        commit('addToast', {
            type: res.data.statusCode === 200 ? 'success' : 'warning',
            title: res.data.statusCode === 200 ? 'Erfolgreich entgegengenommen' : 'Etwas ist schiefgelaufen',
        });
    },

    async retrieveEventTypes({commit}) {
        const res = await axios.get(API + '/endpoints/eventtypes');
        console.log(res.data);
        commit('setEventTypes', res.data);
    },


    // ERROR CODES ROUTES ------------------------------------------------------------------------------------------

    async retrieveErrorCodes({commit}) {
        const {data} = await axios.post(BASE_URL + '/error', {
            "language": "de" // todo dynamic base on user language selection
        });
        commit('setErrorCodes', data)
    },

    // CERTIFICATE ROUTES ----------------------------------------------------------------------------------------

    // async retrieveCertificate({commit}, {organizationID, courseID, memberID}) {
    //     const res = await axios.get(API + courses(organizationID, courseID) + '/members/' + memberID + '/certificate');
    //     commit('setCertificate', res.data);
    // },

    async removeCertificate(_, {organizationID, courseID, memberID}) {
        await axios.delete(API + courses(organizationID, courseID) + '/members/' + memberID + '/certificate');
    },

    async retrieveCertificateDetails({commit}, certificateID) {
        const res = await axios.get(BASE_URL + '/certifications/' + certificateID + '/info');
        commit('setCertificateDetails', res.data);
    },
}
