export const focus = {
    inserted: function (el) {
        el.focus()
    }
}

export const required = {
    inserted(el, binding, vnode) {
        if (!el.value && !el.hasAttribute('required')) {
            el.setAttribute('required', '')
            if (vnode.context.$refs.validate)
            vnode.context.$refs.validate.incValidateCounter()
        }
    },
    update(el, binding, vnode) {
        if (el.value.length !== 0 && el.hasAttribute('required')) {
            el.removeAttribute('required')
            vnode.context.$refs.validate.decValidateCounter()
        } else if (!el.value && !el.hasAttribute('required')) {
            el.setAttribute('required', '')
            vnode.context.$refs.validate.incValidateCounter()
        }
    },
    unbind(el, binding, vnode) {
        if (vnode.context.$refs.validate)
        vnode.context.$refs.validate.decValidateCounter()
    }
}