export default {
    getToken: state => state.token,
    getURL: state => state.URL,
    getUser: state => state.user,
    getDetailedMember: state => state.detailedMember,
    getCustomerPortal: state => state.customerPortal,
    getOrganizations: state => state.organizations,
    getMethods: state => state.methods,
    getSubscriptions: state => state.subscriptions,
    getOrganizationById: state => orgId => state.organizations.find(el => el.organization._id === orgId) || {organization: {}},
    getCourses: state => state.courses,
    getCourseById: state => courseId => state.courses.courses.find(el => el._id === courseId) || {
        headerImage: {},
    },
    getCourseDetails: state => state.courseDetails,
    getMembers: state => state.members,
    getMemberCount: state => state.memberCount,
    getEditors: state => state.editors,
    getToasts: state => state.toasts,
    getApiTokens: state => state.apiTokens,
    getTheme: state => state.theme,
    getPlans: state => state.plans,
    getInvoices: state => state.invoices,
    getOffers: state => state.offers,
    getBillingAddress: state => state.billingAddress,
    getWebhooks: state => state.webhooks,
    getEventTypes: state => state.eventTypes,
    getLogs: state => state.logs,

    getErrorMsg: state => (statusCode, errorCode) => state.errorCodes[statusCode].find(el => el.code === errorCode).msg,

    getChapterById: state => chapterId => {
        return state.courseDetails.find(el => el._id === chapterId) || {
            _id: '0',
            pages: [
                {
                    pageType: 'Video',
                }
            ]
        };
    },

    getPageById: (state, getters) => (chapterId, pageId) => {
        const chapter = getters.getChapterById(chapterId);
        return chapter.pages ? chapter.pages.find(el => el._id === pageId) : {
            _id: '0',
            pageType: 'Video',
        };
    },

    isChapterExpanded: state => chapterId => state.expandedChapters.includes(chapterId),
    isAnyChapterExpanded: state =>  state.expandedChapters.length > 0,
    getCertificate: state => state.certificate,
    getCertificateDetails: state => state.certificateDetails,
}
