<template>
    <div class="flex flex--column flex--center__vert overflowAuto">
        <div class="courses--wrapper">
            <ActionHeader :back-title="organization.organization.title" title="Einstellungen" @onBack="$router.push('/dashboard/organizations/' + $route.params.organizationID)" />
            <div class="grid courses--settings__grid width--100 mar--bottom__4">
                <div class="courses--settings__title font--s2 font--w700 color--greyDark border--bottom__light">
                    Allgemein
                </div>
                <div class="courses--settings__item flex pad--2 clickable b-radius--5"  @click="toggleDetailsModal"
                    v-if="organization.role === 'Creator' || organization.role === 'Editor'">
                    <div class="courses--settings__icon b-radius--5 bgColor--grey flex flex--center mar--right__1">
                        <i class="ion-md-options font--s5 color--grey" />
                    </div>
                    <div class="flex flex--column">
                        <div class="font--s2 font--w700">
                            Organisationsdetails
                        </div>
                        <p>
                            Passe die Details wie den Namen und das Thumbnail deines Unternehmens ein.
                        </p>
                    </div>
                </div>
                <div class="courses--settings__item flex pad--2 clickable b-radius--5"  @click="$router.push('/dashboard/organizations/' + $route.params.organizationID + '/offers')"
                     v-if="organization.role === 'Creator' || organization.role === 'Editor'">
                    <div class="courses--settings__icon b-radius--5 bgColor--grey flex flex--center mar--right__1">
                        <i class="ion-md-apps font--s5 color--grey" />
                    </div>
                    <div class="flex flex--column">
                        <div class="font--s2 font--w700">
                            Angebote
                        </div>
                        <p>
                            Erstelle Angebote für deine Organisation.
                        </p>
                    </div>
                </div>
                <div class="courses--settings__item flex pad--2 clickable b-radius--5" @click="forward('theme')"
                     v-if="organization.role === 'Creator' || organization.role === 'Editor'">
                    <div class="courses--settings__icon b-radius--5 bgColor--grey flex flex--center mar--right__1">
                        <i class="ion-md-color-palette font--s5 color--grey" />
                    </div>
                    <div class="flex flex--column">
                        <div class="font--s2 font--w700">
                            Organisationsdesign
                        </div>
                        <p>
                            Passe das Design von OKourse deinem Unternehmensdesign an.
                        </p>
                    </div>
                </div>
                <div class="courses--settings__item flex pad--2 clickable b-radius--5" @click="forward('editors')"
                     v-if="organization.role === 'Creator' || organization.role === 'Editor'">
                    <div class="courses--settings__icon b-radius--5 bgColor--grey flex flex--center mar--right__1">
                        <i class="ion-md-create font--s5 color--grey" />
                    </div>
                    <div class="flex flex--column">
                        <div class="font--s2 font--w700">
                            Organisationsmitglieder
                        </div>
                        <p>
                            Verwalte die Mitglieder deiner Organisation und ihre Rollen.
                        </p>
                    </div>
                </div>
                <div class="courses--settings__item flex pad--2 clickable b-radius--5" @click="forward('instructors')"
                     v-if="organization.role === 'Creator' || organization.role === 'Editor'">
                    <div class="courses--settings__icon b-radius--5 bgColor--grey flex flex--center mar--right__1">
                        <i class="ion-md-school font--s5 color--grey" />
                    </div>
                    <div class="flex flex--column">
                        <div class="font--s2 font--w700">
                            Instruktoren
                        </div>
                        <p>
                            Verwalte die Instruktoren deiner Organisation.
                        </p>
                    </div>
                </div>
                <div class="courses--settings__item flex pad--2 clickable b-radius--5" @click="forward('members')">
                    <div class="courses--settings__icon b-radius--5 bgColor--grey flex flex--center mar--right__1">
                        <i class="ion-md-people font--s5 color--grey" />
                    </div>
                    <div class="flex flex--column">
                        <div class="font--s2 font--w700">
                            Alle Kursmitglieder
                        </div>
                        <p>
                            Verwalte alle Kursmitglieder deiner Organisation.
                        </p>
                    </div>
                </div>
                <div class="courses--settings__item flex pad--2 clickable b-radius--5" @click="showEmailModal = true">
                    <div class="courses--settings__icon b-radius--5 bgColor--grey flex flex--center mar--right__1">
                        <i class="ion-md-mail font--s5 color--grey" />
                    </div>
                    <div class="flex flex--column">
                        <div class="font--s2 font--w700">
                          E-Mail Einstellungen
                        </div>
                        <p>
                            Stelle ein von welchem Absender Emails versendet werden sollen und was die Kontaktadresse ist.
                        </p>
                    </div>
                </div>
                <div class="courses--settings__item flex pad--2 clickable b-radius--5" @click="forwardToCustomerPortal" v-if="organization.role === 'Creator' || organization.role === 'Editor'">
                    <div class="courses--settings__icon b-radius--5 bgColor--grey flex flex--center mar--right__1">
                        <i class="ion-md-wallet font--s5 color--grey" />
                    </div>
                    <div class="flex flex--column">
                        <div class="font--s2 font--w700">
                            Zahlungsinformationen
                        </div>
                        <p>
                            Hier findest du dein aktuelles Abo, Rechnungen sowie Zahlungsinformationen.
                        </p>
                    </div>
                </div>
                <div class="courses--settings__title font--s2 font--w700 color--greyDark border--bottom__light">
                    Entwickler
                </div>
                <div class="courses--settings__item flex pad--2 clickable b-radius--5" @click="toggleAPIModal">
                    <div class="courses--settings__icon b-radius--5 bgColor--grey flex flex--center mar--right__1">
                        <i class="ion-ios-key font--s5 color--grey" />
                    </div>
                    <div class="flex flex--column">
                        <div class="font--s2 font--w700">
                            API-Schlüssel
                        </div>
                        <p>
                            Verwalte deine API Zugänge und erstelle neue Zugangsdaten.
                        </p>
                    </div>
                </div>
                <div class="courses--settings__item flex pad--2 clickable b-radius--5" @click="forward('webhooks')"
                     v-if="organization.role === 'Creator' || organization.role === 'Editor'">
                    <div class="courses--settings__icon b-radius--5 bgColor--grey flex flex--center mar--right__1">
                        <i class="ion-ios-log-in font--s5 color--grey" />
                    </div>
                    <div class="flex flex--column">
                        <div class="font--s2 font--w700">
                            Webhooks
                        </div>
                        <p>
                            Stell ein welche Webhook-Schnittstellen verwendet werden sollen.
                        </p>
                    </div>
                </div>
                <div class="courses--settings__item flex pad--2 clickable b-radius--5" @click="showIntegrationModal = true"
                     v-if="organization.role === 'Creator' || organization.role === 'Editor'">
                    <div class="courses--settings__icon b-radius--5 bgColor--grey flex flex--center mar--right__1">
                        <i class="ion-md-git-network font--s5 color--grey" />
                    </div>
                    <div class="flex flex--column">
                        <div class="font--s2 font--w700">
                            Integrationen
                        </div>
                        <p>
                            Verwalte die Integrationen deiner Organisation.
                        </p>
                    </div>
                </div>
                <div class="courses--settings__item flex pad--2 clickable b-radius--5" @click="showDomainModal = true"
                     v-if="organization.role === 'Creator' || organization.role === 'Editor'">
                    <div class="courses--settings__icon b-radius--5 bgColor--grey flex flex--center mar--right__1">
                        <i class="ion-md-search font--s5 color--grey" />
                    </div>
                    <div class="flex flex--column">
                        <div class="font--s2 font--w700">
                            Domain
                        </div>
                        <p>
                            Wähle die Domain unter der das OKourse Interface angezeigt werden soll.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <Modal title="Organisationsdetails" @toggle="resetModals" v-if="showDetailsModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <label for="organization_title" class="form--label">Name</label>
                    <input type="text" id="organization_title"
                           class="form--input form--input__text mar--bottom__1"
                           v-model.trim="organizationTitle" v-focus v-required
                    />
                    <label for="organization_description" class="form--label">Beschreibung</label>
                    <textarea id="organization_description" class="form--input form--input__textArea" rows="5"
                              v-model.trim="organizationDescription"/>
                    <label for="organization_img" class="form--label">Thumbnail</label>
                    <UploadThumbnail id="organization_img" @file-uploaded="cacheThumb"
                                     :cached-thumb-name="cachedThumbName" aspect-ratio="16:9" />
                    <AccentButton :full-width="true" :filled="true" title="Änderungen Speichern"
                                  @onClick="updateOrganization" ref="validate" tabindex="0" :loading="loading"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <Modal title="E-Mail Einstellungen" @toggle="resetModals" v-if="showEmailModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <label for="organization_email" class="form--label">Kontakt-Email</label>
                    <input type="email" id="organization_email"
                           class="form--input form--input__text mar--bottom__1"
                           placeholder="test@example.de"
                           v-model.trim="$v.email.$model"
                    />
                    <label for="organization_emailprefix" class="form--label">Name des Absenders</label>
                    <input type="email" id="organization_emailprefix"
                           class="form--input form--input__text mar--bottom__1"
                           :placeholder="this.organization.organization.title"
                           v-model.trim="emailPrefix"
                    />
                    <AccentButton :full-width="true" :filled="true" title="Änderungen Speichern"
                                  @onClick="updateOrganization" ref="validate" tabindex="0" :loading="loading"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <Modal title="Domain Einstellungen" @toggle="resetModals" v-if="showDomainModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <p class="font--s16">
                      Setze folgenden Eintrag in deinen DNS Einstellungen bevor du eine Domain speicherst:
                    </p>
                    <div class="pad--1 bgColor--greyLight mar--top__1 mar--bottom__1">
                      <div class="grid grid--col__3">
                        <h3>Type</h3>
                        <h3>Name</h3>
                        <h3>Value</h3>
                      </div>
                      <div class="grid grid--col__3">
                        <p class="font--s16">CNAME</p>
                        <p class="font--s16">-</p>
                        <p class="font--s16">cname.vercel-dns.com</p>
                      </div>
                    </div>
                    <label for="organization_domain" class="form--label">Domain</label>
                    <input type="text" id="organization_domain"
                           class="form--input form--input__text"
                           placeholder="z.B. example.com ..."
                           v-model.trim="$v.domain.$model"
                    />
                    <div class="font--s14 color--grey mar--bottom__1">
                        {{ domainActive }}
                    </div>
                    <AccentButton :full-width="true" :filled="true" title="Änderungen Speichern"
                                  @onClick="updateOrganization" ref="validate" tabindex="0" :loading="loading"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <Modal title="API Einstellungen" @toggle="resetModals" v-if="showAPIModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <div class="pad--1 font--s16 width--100 border--bottom__light">
                        API-Keys
                    </div>
                    <div class="flex flex--column width--100 mar--bottom__2">
                        <div class="flex flex--center pad--2 font--s16"  v-if="!tokens.length">
                            Du hast nch keine API-Keys
                        </div>
                        <div class="flex flex--space__hor pad--1 border--bottom__light clickable flex--center__vert" v-for="token in tokens" :key="token._id">
                            <div class="flex flex--column mar--right__1">
                                <div class="font--s16 mar--bottom__1">
                                    API-Token:
                                </div>
                                <div class="font--s16 mar--bottom__1">
                                    API-Secret:
                                </div>
                                <div class="font--s16">
                                    Letzte Nutzung:
                                </div>
                            </div>
                            <div class="flex flex--column mar--right__1">
                                <div class="font--s16 font--w700 mar--bottom__1">
                                    {{ token.tokenId }}
                                </div>
                                <div class="font--s16 mar--bottom__1 transition" :class="{'blur': !revealSecret.includes(token._id)}">
                                    {{ token.tokenSecret }}
                                </div>
                                <div class="font--s14">
                                    {{ token.lastDateUsed ? token.lastDateUsed : 'Noch nie' }}
                                </div>
                            </div>
                            <div class="flex flex--column">
                                <div class="mar--bottom__1">
                                    <ActionButton :light="true" icon="ion-md-eye" @onClick="toggleSecret(token._id)"/>
                                </div>
                                <ActionButton :light="true" icon="ion-md-trash" @onClick="removeApiToken(token._id)"/>
                            </div>
                        </div>
                    </div>
                    <AccentButton :full-width="true" :filled="true" title="Neuen API-Key generieren" @onClick="generateApiToken"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <Modal title="Webhook Einstellungen" @toggle="resetModals" v-if="showWebhookModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <div class="form--label">Aktivieren</div>
                    <ToggleButton :value="webHooks.activated !== 'false'" @toggleValue="toggleWebhook" false="Aktiviert" true="Deaktiviert"/>
                    <label for="webhook_link" class="form--label">Link</label>
                    <input type="text" id="webhook_link"
                           class="form--input form--input__text"
                           v-model.trim="webHooks.usersWebHook"
                    />
                    <label for="webhook_secret" class="form--label">Schlüssel</label>
                    <div class="mar--bottom__2">
                        <input type="text" id="webhook_secret"
                               class="form--input form--input__text"
                               v-model.trim="webHooks.headerToken"
                        />
                    </div>
                    <AccentButton :full-width="true" :filled="true" title="Änderungen Speichern"
                                  @onClick="updateOrganization" :loading="loading"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <Modal title="Integrationen" @toggle="resetModals" v-if="showIntegrationModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor">
                    <div class="form--label border--bottom__light mar--bottom__1">
                        General
                    </div>
                    <div class="mar--bottom__1 font--s14 font--w700 b-radius--5 pad--1 bgColor--grey">
                       {{ `${URL}/integrations/general/${$route.params.organizationID}`}}
                    </div>
                    <p class="mar--bottom__2">
                        hinterlege diesen Link bei deinem Zahlungsanbieter und verwende das generelle Übertragungsformat von OKourse.
                    </p>
                    <div class="form--label border--bottom__light mar--bottom__1">
                        DigiStore24
                    </div>
                    <div class="mar--bottom__1 font--s14 font--w700 b-radius--5 pad--1 bgColor--grey">
                        {{ `${URL}/integrations/digistore24/${$route.params.organizationID}`}}
                    </div>
                    <p class="mar--bottom__1">
                        hinterlege diesen Link bei DigiStore um Kurse bei OKourse freischalten zu lassen.
                    </p>
                    <div class="mar--bottom__1 font--s14 font--w700 b-radius--5 pad--1 bgColor--grey">
                        {{ `${organization.organization.digistoreIntegration.token}`}}
                    </div>
                </div>
            </Modal>
        </transition>
    </div>
</template>
<script>
    import ActionHeader from "@/components/views/4_Page/ActionHeader";
    import noDataImage from "@/assets/SVG5.svg";
    import _ from "lodash";
    import {email} from "vuelidate/lib/validators";
    import Modal from "@/components/reusable/2_Modals/Modal";
    import ActionButton from "@/components/reusable/1_Buttons/ActionButton";
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    import ToggleButton from "@/components/reusable/1_Buttons/ToggleButton";
    import UploadThumbnail from "@/components/reusable/3_Other/UploadThumbnail";

    const domainValid = (val) => {
        let validHostnameRegex = new RegExp("^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]).)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$");
        return validHostnameRegex.test(val);
    };

    export default {
        name: 'CoursesSettings',
        components: {UploadThumbnail, ToggleButton, AccentButton, ActionButton, Modal, ActionHeader},
        validations: {
            domain: {
                domainValid,
            },
            email: {
                email,
            },
        },
        data() {
            return {
                posX: null,
                posY: null,

                showDetailsModal: false,
                showAPIModal: false,
                showWebhookModal: false,
                showMainPopup: false,
                showIntegrationModal: false,
                showEmailModal: false,
                showDomainModal: false,

                revealSecret: [],

                organizationTitle: '', //required
                organizationDescription: null,
                domain: null,
                email: null,
                emailPrefix: null,
                wistiaCredentials: null,
                courseTitle: '', //required
                courseDescription: null,
                webHooks: {
                    activated: false,
                    headerToken: null,
                    usersWebHook: null,
                },

                cachedThumb: null,
                cachedThumbName: null,
                noDataImage: noDataImage,
                loading: false,
            };
        },
        computed: {
            courses() {
                return this.$store.getters.getCourses;
            },
            organization() {
                return this.$store.getters.getOrganizationById(this.$route.params.organizationID)
            },
            tokens() {
                return this.$store.getters.getApiTokens;
            },
            customerPortal() {
                return this.$store.getters.getCustomerPortal;
            },
            domainActive() {
                if (this.organization && this.organization.organization) {
                    if (this.organization.organization.domainInformation.domain && this.organization.organization.domainInformation.domain !== '') {
                        if (this.organization.organization.domainInformation.active) {
                            return 'Die Domain ist aktiv.';
                        }
                        return 'Die Domain wird noch verarbeitet.';
                    }
                }
                return '';
            },
            URL() {
                return this.$store.getters.getURL;
            }
        },
        methods: {
            forward(url) {
                this.$router.push(`/dashboard/organizations/${this.$route.params.organizationID}/${url}`);
            },
            handleEsc() {
                if (!this.loading) {
                    this.resetModals()
                } else {
                    this.$store.commit('addToast', {
                        type: 'warning',
                        msg: 'Bitte warte bis die Dateien hochgeladen wurden'
                    })
                }
            },
            resetModals() {
                this.showCreateCourseModal = false;
                this.organizationTitle = '';
                this.organizationDescription = null;
                this.showDetailsModal = false;
                this.showAPIModal = false;
                this.showMainPopup = false;
                this.showWebhookModal = false;
                this.loading = false;
                this.courseTitle = '';
                this.courseDescription = '';
                this.showIntegrationModal = false;
                this.showEmailModal = false;
                this.showDomainModal = false;
            },
            toggleDetailsModal() {
                this.organizationTitle = this.organization.organization.title;
                this.organizationDescription = this.organization.organization.description;
                this.showDetailsModal = !this.showDetailsModal;
            },
            toggleAPIModal() {
                this.showAPIModal = !this.showAPIModal;
            },
            toggleWebHookModal() {
                this.showWebhookModal = !this.showWebhookModal;
            },
            toggleMainPopup(e) {
                this.posX = e.clientX;
                this.posY = e.clientY;

                this.showMainPopup = !this.showMainPopup;
            },
            closeMainPopup() {
                this.showMainPopup = false;
            },
            async createCourse() {
                this.loading = true;
                const id = await this.$store.dispatch('createCourse', {
                    organizationID: this.$route.params.organizationID,
                    course: {
                        title: this.courseTitle,
                        description: this.courseDescription,
                    },
                });
                if (this.cachedThumb) await this.uploadThumb(id);
                this.resetModals();
            },
            cacheThumb(fileList) {
                const formData = new FormData();
                formData.append('File', fileList[0], fileList[0].name);
                this.cachedThumb = formData;
                this.cachedThumbName = fileList[0].name;
            },
            async uploadThumb(id) {
                await this.$store.dispatch('uploadCourseThumbnail', {
                    organizationID: this.$route.params.organizationID,
                    courseID: id,
                    thumbnail: this.cachedThumb,
                });
                this.cachedThumb = null;
                this.cachedThumbName = null;
            },
            async uploadOrganizationThumbnail(id) {
                await this.$store.dispatch('uploadOrganizationThumbnail', {
                    organizationID: id,
                    thumbnail: this.cachedThumb,
                });
                this.cachedThumb = null;
                this.cachedThumbName = null;
            },
            async updateOrganization() {
                if (this.domain !== '' && this.$v.domain.$invalid) {
                    this.$store.commit('addToast', {
                        type: 'warning',
                        title: 'Ungültige Domain (kein http:// oder https://)'
                    });
                    return;
                }
                if (this.email !== '' && this.$v.email.$invalid) {
                    this.$store.commit('addToast', {
                        type: 'warning',
                        title: 'Ungültige Email-Adresse'
                    });
                    return;
                }
                this.loading = true;
                await this.$store.dispatch('updateOrganization', {
                    organizationID: this.$route.params.organizationID,
                    organization: {
                        title: this.organizationTitle && this.organizationTitle !== this.organization.organization.title ? this.organizationTitle : undefined,
                        description: this.organizationDescription && this.organizationDescription !== this.organization.organization.description ? this.organizationDescription : undefined,
                        webHooks: this.webHooks && this.webHooks !== this.organization.organization.webHooks ? this.webHooks : undefined,
                        domain: this.domain ? this.domain : '',
                        replyEmail: this.email ? this.email : '',
                        emailPrefix: this.emailPrefix && this.emailPrefix !== this.organization.organization.emailPrefix ? this.emailPrefix : undefined,
                    }
                });
                if (this.cachedThumb) this.uploadOrganizationThumbnail(this.$route.params.organizationID);
                this.resetModals();
            },
            generateApiToken() {
                this.$store.dispatch('generateApiToken', this.$route.params.organizationID);
            },
            removeApiToken(val) {
                this.$store.dispatch('removeApiToken', {
                    organizationID: this.$route.params.organizationID,
                    tokenID: val,
                });
            },
            toggleSecret(val) {
                if (this.revealSecret.includes(val)) {
                    this.revealSecret = this.revealSecret.filter(el => el !== val);
                } else {
                    this.revealSecret.push(val);
                }
            },
            toggleWebhook() {
                this.webHooks.activated = this.webHooks.activated === 'true' ? 'false' : 'true'
            },
            async getCustomerPortal() {
                await this.$store.dispatch('getCustomerPortal', {
                    organizationID: this.$route.params.organizationID,
                    data: {
                        urlParams: {
                            return: this.$route.path.slice(1)
                        }
                    }
                })
            },
            async forwardToCustomerPortal() {
                if (!this.customerPortal) {
                    await this.getCustomerPortal()
                }
                window.location.href = this.customerPortal
            }
        },
        watch: {
            organization(newPage) {
                this.organizationTitle = _.cloneDeep(newPage.organization.title);
                this.organizationDescription = _.cloneDeep(newPage.organization.description);
                this.webHooks = _.cloneDeep(newPage.organization.webHooks);
                this.domain = _.cloneDeep(newPage.organization.domainInformation.domain);
                this.email = _.cloneDeep(newPage.organization.replyEmail);
                this.emailPrefix = _.cloneDeep(newPage.organization.emailPrefix);
            },
        },
        async mounted() {
            await this.$store.dispatch('retrieveOrganizations');
            if (!this.organization.organization.subscription.subscriptionId) {
                this.$router.push(`/dashboard/organizations/${this.$route.params.organizationID}/plan`);
            }
            if (this.organization.role === 'Creator') {
              this.getCustomerPortal()
            }
            await this.$store.dispatch('retrieveCourses', this.$route.params.organizationID);

        },
    }
</script>
