export default {
    isAuthenticated: false,
    token: localStorage.getItem('x-auth-token') && localStorage.getItem('x-auth-token') != 'null'  ?  localStorage.getItem('x-auth-token') : sessionStorage.getItem('x-auth-token'),
    URL: process.env.VUE_APP_DOMAIN_BACKEND,
    customerPortal: null,
    user: {},
    detailedMember: {},
    organizations: [],
    methods: [],
    subscriptions: {
        active: [],
        pendingDue: [],
    },
    billingAddress: {},
    courses: { courses: [], instructors: []},
    courseDetails: [],
    members: [],
    memberCount: 0,
    editors: [],
    apiTokens: [],
    toasts: [],
    expandedChapters: [],
    theme: null,
    errorCodes: null,
    plans: [],
    invoices: [],
    certificate: null,
    certificateDetails: null,
    offers: [],
    webhooks: [],
    eventTypes: [],
    logs: [],
}
