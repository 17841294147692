<template>
    <transition name="fade" mode="out-in">
        <img v-if="link" class="card--img" :src="`${URL}/${link}/${auth ? token : ''}`"/>
        <img v-else class="width--50 height--100" src="@/assets/SVG7.svg" />
    </transition>
</template>
<script>
    export default {
        name: 'RawImage',
        props: ['link', 'auth'],
        data() {
            return {
                imgData: null,
            };
        },
        computed: {
            URL() {
                return this.$store.getters.getURL;
            },
            token() {
                return this.$store.getters.getToken;
            }
        },
    };
</script>
