<template>
    <div class="flex flex--column flex--center__vert">
        <div class="flex flex--column organizations--wrapper">
            <ActionHeader back-title="Alle Organisationen" :title="organization.organization.title" @onBack="$router.push('/dashboard')">
                <div class="flex relative">
                    <ActionButton icon="ion-md-more" light="true" @onClick="toggleMainPopup" v-click-outside="closeMainPopup"
                                  v-if="organization.role === 'Creator'"/>
                </div>
            </ActionHeader>
            <transition name="fade" mode="out-in" v-if="organization.role === 'Creator'">
                <router-view v-if="me" key="content"/>
                <div v-else class="absolutes" key="loader">
                    <loader :loading="true" color="#fff" :size="'10px'"/>
                </div>
            </transition>
        </div>
        <transition name="fade">
            <div class="absolute popup--anchor" v-if="showMainPopup">
                <PopUp :posX="posX" :posY="posY">
                    <div class="popup--item pad--2 clickable font--s16 color--danger"
                         @click="showConfirmationModal = true">
                        Organisation löschen
                    </div>
                </PopUp>
            </div>
        </transition>
        <transition name="fade">
            <ConfirmationModal title="Möchten sie die Organisation wirklich löschen?"
                               v-if="showConfirmationModal"
                               @toggle="showConfirmationModal = false"
                               content="Alle Kurse und Lektionen werden unwiederruflich gelöscht und Mitglieder haben keinen Zugriff mehr."
                               @confirm="deleteOrganization"
                               @reject="showConfirmationModal = false"/>
        </transition>
    </div>
</template>
<script>
    import ActionHeader from "@/components/views/4_Page/ActionHeader";
    import ActionButton from "@/components/reusable/1_Buttons/ActionButton";
    import PopUp from "@/components/reusable/2_Modals/PopUp";
    import ConfirmationModal from "@/components/reusable/2_Modals/ConfirmationModal";
    export default {
        name: 'SelectPlan',
        components: {ConfirmationModal, PopUp, ActionButton, ActionHeader},
        data() {
            return {
                showMainPopup: false,
                showConfirmationModal: false,
                posX: null,
                posY: null,
                yearly: true,
                selected: null,
            };
        },
        computed: {
            plans() {
                return this.$store.getters.getPlans;
            },
            filteredPlans() {
                if (this.plans) {
                    return this.plans.filter((el) => this.yearly ? el.type === 'year' : el.type === 'month');
                }
                return [];
            },
            organization() {
                return this.$store.getters.getOrganizationById(this.$route.params.organizationID)
            },
            me() {
                return this.$store.getters.getUser;
            },
        },
        methods: {
            toggleMainPopup(e) {
                this.posX = e.clientX;
                this.posY = e.clientY;

                this.showMainPopup = !this.showMainPopup;
            },
            closeMainPopup() {
                this.showMainPopup = false;
            },
            async deleteOrganization() {
                await this.$store.dispatch('deleteOrganization', this.$route.params.organizationID)
                    .then(() => {
                        this.$router.push('/dashboard');
                    });
            },
        },
        mounted() {
            this.$store.dispatch('retrievePlans');
            this.$store.dispatch('retrieveOwnEditor');
            this.$store.dispatch('retrieveOrganizations');
            // this.$store.dispatch('retrieveMethods');
        },
    };
</script>
