<template>
    <div class="flex flex--column flex--center__vert overflowAuto">
        <div class="organizations--wrapper">
            <ActionHeader back-title="Alle Organisationen" title="Rechnungen" @onBack="$router.push('/dashboard')" />
            <transition name="fade" mode="out-in">
                <div class="height--total__70 width--100 flex flex--center" v-if="isLoading">
                    <loader :loading="true" color="#114B5F" :size="'1rem'"/>
                </div>
                <div class="flex flex--column" v-if="!isLoading && invoices.length">
                    <div class="relative width--100 pad--3 border--bottom__light flex flex--column" v-for="invoice in invoices" :key="invoice._id">
                        <div class="grid grid--col__2">
                            <div class="font--s16 flex flex--column lineHeight--1 mar--right__2">
                                <div class="font--s14 color--grey">
                                    Betrag
                                </div>
                                <div class="font--w700 font--s2">
                                    {{ invoice.total / 100 }}€
                                </div>
                            </div>
                            <div class="font--s16 flex flex--column lineHeight--1 mar--right__2">
                                <div class="font--s14 color--grey">
                                    Datum
                                </div>
                                <div class="font--w700 font--s2">
                                    {{ moment(invoice.createdAt * 1000).format('DD.MM.YYYY') }}
                                </div>
                            </div>
                            <div class="font--s16 flex flex--end__hor flex--center__vert lineHeight--1 mar--right__2">
                                <ActionButton icon="ion-md-link" light="true" @onClick="openLink(invoice.invoiceUrl)"/>
                                <div class="mar--right__1" />
                                <ActionButton icon="ion-md-download" accent="true" @onClick="openLink(invoice.invoicePdf)"/>
                            </div>
                        </div>
                    </div>
                </div>
                <NoDataAction v-if="!invoices.length  && !isLoading" @onButtonClick="this.$('/dashboard')"
                              text="Du hast noch keine Rechnungen." button-title="Zu meinen Organisationen"
                />
            </transition>
        </div>
    </div>
</template>
<script>
    import ActionHeader from "@/components/views/4_Page/ActionHeader";
    import NoDataAction from "@/components/reusable/NoDataAction";
    import ActionButton from "@/components/reusable/1_Buttons/ActionButton";
    export default {
        name: 'Invoices',
        components: {ActionButton, NoDataAction, ActionHeader},
        data() {
            return {
                isLoading: false,
            };
        },
        computed: {
            subscriptions() {
                return this.$store.getters.getSubscriptions;
            },
            organizations() {
                return this.$store.getters.getOrganizations();
            },
            methods() {
                return this.$store.getters.getMethods;
            },

            plans() {
                return this.$store.getters.getPlans;
            },
            filteredPlans() {
                if (this.plans) {
                    return this.plans.filter((el) => this.yearly ? el.type === 'year' : el.type === 'month');
                }
                return [];
            },
            invoices() {
                return this.$store.getters.getInvoices;
            }
        },
        methods: {
            forward(url) {
                this.$router.push(url)
            },
            organization(id) {
                return this.$store.getters.getOrganizationById(id);
            },
            toggleMainPopup({ e }) {
                this.posX = e.clientX;
                this.posY = e.clientY;

                setTimeout(() => {
                    this.showMainPopup = true;
                }, 0)
            },
            closeMainPopup() {
                this.showMainPopup = false;
            },
            openLink(url) {
               window.open(url);
            },
        },
        async mounted() {
            this.isLoading = true;
            await this.$store.dispatch('retrieveInvoices');
            this.isLoading = false;
        },
    };
</script>