<template>
    <div class="flex flex--column flex--center__vert pad--top__10">
        <div class="landing--grid relative content--inner">
            <div class="flex flex--center relative width--100 height--100">
                <div class="illustration--wrapper">
                    <div class="illustration--inner">
                        <div class="illustration--content">
                            <img :style="{ transform: position3 }"
                                 class="illustration--info__1-1 transition"
                                 src="../../../assets/illustrations/Info1-1.png"
                            />
                            <img :style="{ transform: position2 }"
                                 class="illustration--info__1-2 transition"
                                 src="../../../assets/illustrations/Info1-2.png"
                            />

                            <img :style="{ transform: position1 }"
                                 class="illustration--info__1-3 transition"
                                 src="../../../assets/illustrations/Info1-3.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="pad--2 flex flex--column flex--center__hor info">
                <div class="font--s5 font--w700 lineHeight--12 mar--bottom__4 color--primaryDark">
                    Die <span class="color--secondary">Schnittstelle</span> zu deinem Wissen.
                </div>
                <div class="font--s16 mar--bottom__4">
                    Deine Passion - unsere Plattform: Mache Dir keine Gedanken mehr um die Verwaltung deiner Kurse und
                    konzentriere dich auf das, was dir am wichtigsten ist.
                </div>
                <div class="flex width--50">
                    <div class="width--100">
                        <AccentButton @onClick="showDemo = true" secondary="true"
                                      title="Testzugang sichern"/>
                    </div>
                </div>
            </div>
            <img class="absolute top--1 bubble bubble--medium bubble--right bubble--slow opacity--5"
                 src="../../../assets/illustrations/Bubble5.png"/>
            <div class="absolute bottom--0 bubble bubble--mediumLarge bubble--left bubble--slow2"/>
        </div>
        <div class="landing--grid relative content--inner">
            <div class="flex flex--center relative width--100 height--100 landing--grid__mobile">
                <div class="illustration--wrapper">
                    <div class="illustration--inner">
                        <div class="illustration--content">
                            <img :style="{ transform: position3 }"
                                 class="illustration--info__2-1 transition"
                                 src="../../../assets/illustrations/Info2-1.png"
                            />
                            <img :style="{ transform: position2 }"
                                 class="illustration--info__2-2 transition"
                                 src="../../../assets/illustrations/Info2-2.png"
                            />
                            <img :style="{ transform: position2 }"
                                 class="illustration--info__2-3 transition"
                                 src="../../../assets/illustrations/Info2-3.png"
                            />
                            <img :style="{ transform: position4 }"
                                 class="illustration--info__2-4 transition"
                                 src="../../../assets/illustrations/Info2-4.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="pad--2 flex flex--column flex--center__hor info">
                <div class="font--s5 font--w700 lineHeight--12 mar--bottom__4 color--primaryDark">
                    Unser Fokus: <span class="color--secondary">deine Onlinekurse </span>
                </div>
                <div class="font--s16 mar--bottom__4">
                    Unser Fokus ist so simpel wie klar: deine Onlinekurse. Damit du dich voll und ganz auf deine
                    Kursinhalte
                    konzentrieren kannst, halten wir die Nutzung so einfach wie möglich, geben dir keine Inhalts- oder
                    Nutzungslimitierungen vor und gewähren die vollen Zugang zu unseren Schnittstellen.
                </div>
                <div class="flex width--50">
                    <div class="width--100">
                        <AccentButton @onClick="showDemo = true" secondary="true" title="Kostenlos testen"/>
                    </div>
                </div>
            </div>
            <div class="flex flex--center relative width--100 height--100 landing--grid__desktop">
                <div class="illustration--wrapper">
                    <div class="illustration--inner">
                        <div class="illustration--content">
                            <img :style="{ transform: position3 }"
                                 class="illustration--info__2-1 transition"
                                 src="../../../assets/illustrations/Info2-1.png"
                            />
                            <img :style="{ transform: position2 }"
                                 class="illustration--info__2-2 transition"
                                 src="../../../assets/illustrations/Info2-2.png"
                            />
                            <img :style="{ transform: position2 }"
                                 class="illustration--info__2-3 transition"
                                 src="../../../assets/illustrations/Info2-3.png"
                            />
                            <img :style="{ transform: position4 }"
                                 class="illustration--info__2-4 transition"
                                 src="../../../assets/illustrations/Info2-4.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="absolute bottom--1 bubble bubble--mediumLarge bubble--right bubble--slow"/>
        </div>
        <div class="landing--grid relative content--inner">
            <div class="flex flex--center relative width--100 height--100">
                <div class="illustration--wrapper">
                    <div class="illustration--inner">
                        <div class="illustration--content">
                            <img :style="{ transform: position3 }"
                                 class="illustration--info__3-1 transition"
                                 src="../../../assets/illustrations/Info3-1.png"
                            />
                            <img :style="{ transform: position4 }"
                                 class="illustration--info__3-2 transition"
                                 src="../../../assets/illustrations/Info3-2.png"
                            />
                            <img :style="{ transform: position5 }"
                                 class="illustration--info__3-3 transition"
                                 src="../../../assets/illustrations/Info3-3.png"
                            />
                            <img :style="{ transform: position2 }"
                                 class="illustration--info__3-4 transition"
                                 src="../../../assets/illustrations/Info3-4.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="pad--2 flex flex--column flex--center__hor info">
                <div class="font--s5 font--w700 lineHeight--12 mar--bottom__4 color--primaryDark">
                    Passe die <span class="color--secondary">Nutzererfahrung</span> deinen Wünschen an.
                </div>
                <div class="font--s16 mar--bottom__4">
                    Passe das Design deiner Kurse deiner eigenen Marke an. Logos, Farben und Texte sind frei von dir
                    wählbar. Wenn du eine komplett individuelle Nutzererfahrung bieten möchtest, hast du jederzeit die
                    Möglichkeit deine eigene App oder Website anzuschließen.
                </div>
                <div class="flex width--50">
                    <div class="width--100">
                        <AccentButton @onClick="$router.push('/dashboard')" secondary="true" title="Los Geht's"/>
                    </div>
                </div>
            </div>
            <img class="absolute top--2 bubble bubble--medium bubble--left bubble--slow2 opacity--5"
                 src="../../../assets/illustrations/Bubble1.png"/>
        </div>
        <div class="testimonial flex flex--column flex--center">
            <div class="content--inner grid testimonial--grid">
                <div class="pad--2 flex flex--center">
                    <div class="flex flex--column flex--center__vert card card--move pad--2 b-radius--5 bgColor--white width--100 height--100 font--s16 relative">
                        <div class="flex flex--center overflowHidden mar--bottom__2">
                            <img class="width--100" src="../../../assets/icons/tap.png"/>
                        </div>
                        <h2 class="mar--bottom__1">
                            Simpel und Intuitiv
                        </h2>
                        <div class="mar--bottom__1">
                            Unser Fokus ist die Verwaltung deiner Onlinekurse. Daher verzichten bewusst auf unnötig
                            komplexe oder unausgereifte Zusatzfunktionen. Wir helten die Bedienung so einfach wie
                            möglich, damit du ohne technische Expertise oder Einarbeitung direkt loslegen kannst.
                        </div>
                    </div>
                </div>
                <div class="pad--2 flex flex--center">
                    <div class="flex flex--column flex--center__vert card card--move pad--2 b-radius--5 bgColor--white width--100 height--100 font--s16 relative">
                        <div class="flex flex--center overflowHidden mar--bottom__2">
                            <img class="width--100" src="../../../assets/icons/infinity.png"/>
                        </div>
                        <h2 class="mar--bottom__1">
                            Unbegrenzte Inhalte
                        </h2>
                        <div class="mar--bottom__1">
                            Damit du dich in deinen Kursen vollständig entfalten kannst, bieten wir dir beliebig viele
                            Nutzer und unbegrenzten Speicherplatz. Dir stehen verschiedene Formate zur Verfügung und du
                            kannst die Kursen nach deinem Geschmack designen.
                        </div>
                    </div>
                </div>
                <div class="pad--2 flex flex--center">
                    <div class="flex flex--column flex--center__vert card card--move pad--2 b-radius--5 bgColor--white width--100 height--100 font--s16 relative">
                        <div class="flex flex--center overflowHidden mar--bottom__2">
                            <img class="width--100" src="../../../assets/icons/layers.png"/>
                        </div>
                        <h2 class="mar--bottom__1">
                            API Schnittstelle
                        </h2>
                        <div class="mar--bottom__1">
                            Über unsere Schnittstellen bieten wir dir Zugriff auf wichtige Kursinformationen,
                            Videostreams und Kursfortschritte, damit du diese z.B. in deine Webseite oder App einbinden
                            kannst
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="landing--grid relative content--inner">
            <div class="flex flex--center relative width--100 height--100">
                <div class="illustration--wrapper">
                    <div class="illustration--inner">
                        <div class="illustration--content">
                            <img :style="{ transform: position7 }"
                                 class="illustration--home__1-1 transition"
                                 src="../../../assets/illustrations/Main1-1.png"
                            />
                            <img :style="{ transform:position6 }"
                                 class="illustration--home__1-2 transition"
                                 src="../../../assets/illustrations/Main1-2.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="pad--2 flex flex--column flex--center__hor home relative">
                <div class="font--s5 font--w700 lineHeight--12 mar--bottom__4 color--primaryDark">
                    Die neue Heimat für deine <span class="color--secondary">Online Kurse</span>
                </div>
                <div class="font--s16 mar--bottom__4">
                    Neben herkömmlichen Funktionen wie der Verwaltung und Pflege deiner Onlinekurse bietet OKourse als
                    einzige Plattform eine Anbindung per API Schnittstelle. Du willst wissen, welche Vorteile OKourse im
                    Vergleich zu anderen Lösungen bietet? Dann schau Dir unseren Vergleich an!
                </div>
                <div class="flex width--50">
                    <div class="width--100">
                        <AccentButton @onClick="$router.push('/comparison')" secondary="true" title="Zum Vergleich"/>
                    </div>
                </div>
            </div>
            <div class="absolute top--0 bubble bubble--large bubble--left bubble--slow"/>
            <img class="absolute bottom--2 bubble bubble--medium bubble--right bubble--slow2 opacity--5"
                 src="../../../assets/illustrations/Bubble4.png"/>
        </div>
        <div class="landing--grid relative content--inner">
            <div class="flex--center landing--grid__mobile relative width--100 height--100">
                <div class="illustration--wrapper">
                    <div class="illustration--inner">
                        <div class="illustration--content">
                            <img :style="{ transform: position2 }"
                                 class="illustration--home__2 transition"
                                 src="../../../assets/illustrations/Main2.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="pad--2 flex flex--column flex--center__hor info">
                <div class="font--s5 font--w700 lineHeight--12 mar--bottom__4 color--primaryDark">
                    <span class="color--secondary">Unbegrenzte </span> Kursinhalte und -teilnehmer
                </div>
                <div class="font--s16 mar--bottom__4">
                    Bei OKourse hast du unbegrenzt Platz um dich bei der Gestaltung deiner Online-Kurse frei auszuleben.
                    Auch bei der Darstellung deiner Kurse machen wir dir kaum Vorgaben und bieten dir sogar die
                    Möglichkeit die Kurse in deine eigene Applikation zu integrieren.
                </div>
                <div class="flex width--50">
                    <div class="width--100">
                        <AccentButton @onClick="showDemo = true" secondary="true" title="Kostenlos testen"/>
                    </div>
                </div>
            </div>
            <div class="flex--center landing--grid__desktop relative width--100 height--100">
                <div class="illustration--wrapper">
                    <div class="illustration--inner">
                        <div class="illustration--content">
                            <img :style="{ transform: position6 }"
                                 class="illustration--home__2 transition"
                                 src="../../../assets/illustrations/Main2.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="absolute bottom--1 bubble bubble--mediumLarge bubble--right bubble--slow"/>
        </div>
<!--        <div class="testimonial flex flex&#45;&#45;column flex&#45;&#45;center">-->
<!--            <div class="mar&#45;&#45;bottom__8 font&#45;&#45;w700 font&#45;&#45;s3">-->
<!--                Was denken unsere Kunden?-->
<!--            </div>-->
<!--            <div class="content&#45;&#45;inner grid testimonial&#45;&#45;grid">-->
<!--                <div class="pad&#45;&#45;2 flex flex&#45;&#45;center">-->
<!--                    <div class="flex flex&#45;&#45;column flex&#45;&#45;center__vert flex&#45;&#45;space__hor card card&#45;&#45;move pad&#45;&#45;2 b-radius&#45;&#45;5 bgColor&#45;&#45;white width&#45;&#45;100 height&#45;&#45;100 font&#45;&#45;s16 relative pad&#45;&#45;top__8">-->
<!--                        <div class="absolute circle bgColor&#45;&#45;grey testimonial&#45;&#45;img flex flex&#45;&#45;center overflowHidden">-->
<!--                            <img class="width&#45;&#45;100" src="../../../assets/testimonials/Testimonial-1.jpg"/>-->
<!--                        </div>-->
<!--                        <div class="mar&#45;&#45;bottom__1">-->
<!--                            "Als ich meine eigene Kurs-App entwickeln lassen wollte, ging das mit meinem bisherigen-->
<!--                            Kurs-Hoster nicht. Dann habe ich OKourse gefunden, was ja genau darauf ausgelegt ist und-->
<!--                            seitdem bin ich echt super happy wie alles läuft."-->
<!--                        </div>-->
<!--                        <div class="width&#45;&#45;100 font&#45;&#45;right font&#45;&#45;w700">-->
<!--                            - Jonas N.-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="pad&#45;&#45;2 flex flex&#45;&#45;center">-->
<!--                    <div class="flex flex&#45;&#45;column flex&#45;&#45;center__vert flex&#45;&#45;space__hor card card&#45;&#45;move pad&#45;&#45;2 b-radius&#45;&#45;5 bgColor&#45;&#45;white width&#45;&#45;100 height&#45;&#45;100 font&#45;&#45;s16 relative pad&#45;&#45;top__8">-->
<!--                        <div class="absolute circle bgColor&#45;&#45;grey testimonial&#45;&#45;img flex flex&#45;&#45;center overflowHidden">-->
<!--                            <img class="width&#45;&#45;100" src="../../../assets/testimonials/Testimonial-2.jpg"/>-->
<!--                        </div>-->
<!--                        <div class="mar&#45;&#45;bottom__1">-->
<!--                            "Da meine Videos auch mal mehrere Stunden lang sein können, war ich echt froh, dass OKourse-->
<!--                            den Upload nicht limitiert. Echt nice!"-->
<!--                        </div>-->
<!--                        <div class="width&#45;&#45;100 font&#45;&#45;right font&#45;&#45;w700">-->
<!--                            - Caroline J.-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="pad&#45;&#45;2 flex flex&#45;&#45;center">-->
<!--                    <div class="flex flex&#45;&#45;column flex&#45;&#45;center__vert flex&#45;&#45;space__hor card card&#45;&#45;move pad&#45;&#45;2 b-radius&#45;&#45;5 bgColor&#45;&#45;white width&#45;&#45;100 height&#45;&#45;100 font&#45;&#45;s16 relative pad&#45;&#45;top__8">-->
<!--                        <div class="absolute circle bgColor&#45;&#45;grey testimonial&#45;&#45;img flex flex&#45;&#45;center overflowHidden">-->
<!--                            <img class="width&#45;&#45;100" src="../../../assets/testimonials/Testimonial-3.jpg"/>-->
<!--                        </div>-->
<!--                        <div class="mar&#45;&#45;bottom__1">-->
<!--                            "Ehrlicherweise waren mir 99€ im Monat erst etwas zu viel. Durch die Einbindung in meine-->
<!--                            eigene App hat sich das allerdings schon zehnmal rentiert. Kann ich unter dem Strich nur-->
<!--                            jedem weiterempfehlen!"-->
<!--                        </div>-->
<!--                        <div class="width&#45;&#45;100 font&#45;&#45;right font&#45;&#45;w700">-->
<!--                            - Frank S.-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="landing--grid relative content--inner">
            <div class="flex flex--center relative width--100 height--100">
                <div class="illustration--wrapper">
                    <div class="illustration--inner">
                        <div class="illustration--content">
                            <img class="illustration--home__3 transition"
                                 src="../../../assets/icons/Doguniversity-Case.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="pad--2 flex flex--column flex--center__hor info">
                <div class="font--s5 font--w700 lineHeight--12 mar--bottom__4 color--primaryDark">
                    Du willst mehr zum Thema <span class="color--secondary">App </span> erfahren?
                </div>
                <div class="font--s16 mar--bottom__4">
                    Mit OKourse können deine Inhalte per Schnittstelle ganz einfach in bspw. deine App integriert
                    werden.

                    Eine native App bietet viele Vorteile wie z.B. Offline-Funktion oder Push-Benachrichtigungen.

                    Du willst wissen, was eine App kostet oder für dein Projekt in Frage kommt? Lass dich von unseren
                    erfahrenen Entwicklern beraten!
                </div>
                <div class="flex width--50">
                    <div class="width--100">
                        <AccentButton @onClick="showMeeting = true" secondary="true" title="Beratungstermin buchen"/>
                    </div>
                </div>
            </div>
            <div class="absolute bottom--1 bubble bubble--mediumLarge bubble--right bubble--slow"/>
        </div>

        <transition name="fade" mode="out-in">
            <Modal title="Demozugang" v-if="showDemo" @toggle="showDemo = false">
                <div class="pad--3 flex flex--column flex--center__hor width--100">
                    <label for="email_demo" class="form--label">
                        Deine E-Mail
                    </label>
                    <input id="email_demo" type="text" class="form--input form--input__text mar--bottom__2" v-model="email"/>
                    <AccentButton title="Zugang anfordern" @onClick="sendMail('Demozugang')" :loading="loading"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade" mode="out-in">
            <Modal title="Beratungsgespräch" v-if="showMeeting" @toggle="showMeeting = false">
                <div class="pad--3 flex flex--column flex--center__hor width--100">
                    <label for="email" class="form--label">
                        Deine Email
                    </label>
                    <input id="email" type="text" class="form--input form--input__text mar--bottom__1" v-model="email"/>
                    <label for="message" class="form--label">
                        Nachricht
                    </label>
                    <textarea id="message" class="form--input form--input__textArea mar--bottom__4" v-model="message"/>
                    <AccentButton title="Termin anfordern" @onClick="sendMail(false)" :loading="loading"/>
                </div>
            </Modal>
        </transition>
    </div>
</template>
<script>
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    import Modal from "@/components/reusable/2_Modals/Modal";

    export default {
        name: 'Information',
        components: {Modal, AccentButton},

        data() {
            return {
                showDemo: false,
                showMeeting: false,

                position1: `translateY(0)`,
                position2: `translateY(0)`,
                position3: `translateY(0)`,
                position4: `translateY(0)`,
                position5: `translateY(0)`,
                position6: `translateY(0)`,
                position7: `translateY(0)`,

                expanded: null,
                loading: false,

                email: null,
                message: null,
            }
        },
        mounted() {
            window.addEventListener('scroll', this.updateScroll)
        },
        methods: {
            toggle(id) {
                if (this.expanded === id) {
                    this.expanded = null;
                } else if (this.expanded !== id) {
                    this.expanded = id;
                }
            },
            updateScroll() {
                const offY = window.scrollY;
                const bottomOffY = offY < 2500 ? 0 : (offY - 2500);
                this.position1 = `translateY(-${offY * 0.24}px)`;
                this.position2 = `translateY(-${offY * 0.1}px)`;
                this.position3 = `translateY(-${offY * 0.03}px)`;
                this.position4 = `translateY(-${offY * 0.06}px)`;
                this.position5 = `translateY(-${offY * 0.1}px)`;
                this.position6 = `translateY(-${bottomOffY * 0.1}px)`;
                this.position7 = `translateY(-${bottomOffY * 0.15}px)`;
            },
            sendMail(msg) {
                if (!this.email || !this.email.includes('@')) {
                    this.$store.commit('addToast', {
                        type: 'warning',
                        title: 'Bitte fülle alle Felder aus'
                    });
                    return;
                }
                this.loading = true;
                let success = true;
                this.$store.dispatch('sendMail', {
                    email: this.email,
                    body: msg ? `${msg} für ${this.email}` : `Von ${this.email}: ${this.message}`,
                    subject: 'Demo or Consulting Request',
                }).catch(() => {
                    success = false;
                }).then(() => {
                    if (success) {
                        this.$store.commit('addToast', {
                            type: 'success',
                            title: 'Vielen Dank! Wir werden uns in Kürze bei dir melden.'
                        });
                        this.showDemo = false;
                        this.showMeeting = false;
                        this.email = null;
                        this.message = null;
                    }
                    this.loading = false;
                })
            },
        },
        destroy() {
            window.removeEventListener('scroll', this.updateScroll)
        },
    };
</script>
