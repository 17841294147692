<template>
    <div class="flex flex--column content pad--top__10">
        <div class="fle flex--center width--100 mar--2">
            <div class="flex flex--center font--s3 font--w700">
                Unbegrenzte Möglichkeiten
            </div>
        </div>
        <div class="flex mar--bottom__4">
            <div class="font--s16">
                Damit du dir keine Gedanken über deine Kursplattform machen musst und dein Team und du sich auf hochwertige Inhalte konzentrieren können, limitieren wir weder die Anzahl an Nutzern noch die Speicherkapazität deiner Videos / Inhalte. Der Preis richtet sich alleine nach der Anzahl deiner Kurse.
            </div>
        </div>
        <div class="pricing grid mar--bottom__8 card bgColor--white b-radius--5 pad--2">
            <div class="bgColor--greyLight pad--2 b-radius--5">
                <div class="flex flex--column flex--space__hor height--100">
                    <div class="flex flex--column">
                        <h1>Free</h1>
                        <div class="height--01 bgColor--primaryDark width--2"></div>
                        <div class="flex flex--column pad--top__2 mar--bottom__2">
                            <div class="flex flex--start__vert mar--bottom__1">
                                <i class="ion-ios-checkmark font--s3 color--primaryDark mar--right__1 lineHeight--12" />
                                <div class="font--s16 lineHeight--12 mar--top__1">Lass dich in Organisationen einladen und arbeite an Online-Kursen</div>
                            </div>
                            <div class="flex flex--start__vert mar--bottom__1">
                                <i class="ion-ios-checkmark font--s3 color--primaryDark mar--right__1 lineHeight--12" />
                                <div class="font--s16 lineHeight--12 mar--top__1">Verwalte und erstelle Kursinhalte anderer Organisationen</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex--center__hor flex--end__vert lineHeight--1 border--top__medium pad--top__2">
                        <div class="font--s5 font--w500">0€</div>
                    </div>
                </div>
            </div>
            <div class="bgColor--primaryLightest pad--2 b-radius--5">
                <div class="flex flex--column flex--space__hor height--100">
                    <div class="flex flex--column">
                        <h1>Starter</h1>
                        <div class="height--01 bgColor--primaryDark width--2"></div>
                        <div class="flex flex--column pad--top__2 mar--bottom__2">
                            <div class="flex flex--start__vert mar--bottom__1">
                                <i class="ion-ios-checkmark font--s3 color--primaryDark mar--right__1 lineHeight--12" />
                                <div class="font--s16 lineHeight--12 mar--top__1">Erstelle deine eigene Organisation</div>
                            </div>
                            <div class="flex flex--start__vert mar--bottom__1">
                                <i class="ion-ios-checkmark font--s3 color--primaryDark mar--right__1 lineHeight--12" />
                                <div class="font--s16 lineHeight--12 mar--top__1">Lade unbegrenzt viele Inhalte in deinen Kursen hoch</div>
                            </div>
                            <div class="flex flex--start__vert mar--bottom__1">
                                <i class="ion-ios-checkmark font--s3 color--primaryDark mar--right__1 lineHeight--12" />
                                <div class="font--s16 lineHeight--12 mar--top__1">Erstelle <b>1</b> Online-Kurs</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex--center__hor flex--end__vert lineHeight--1 border--top__medium pad--top__2">
                        <div class="font--s5 font--w500">19€</div>
                        <div class="font--s14">/Monat</div>
                    </div>
                </div>
            </div>
            <div class="bgColor--greyLight pad--2 b-radius--5">
                <div class="flex flex--column flex--space__hor height--100">
                    <div class="flex flex--column">
                        <h1>Creator</h1>
                        <div class="height--01 bgColor--primaryDark width--2"></div>
                        <div class="flex flex--column pad--top__2">
                            <div class="flex flex--start__vert mar--bottom__1">
                                <i class="ion-ios-checkmark font--s3 color--primaryDark mar--right__1 lineHeight--12" />
                                <div class="font--s16 lineHeight--12 mar--top__1">Genieße alle Vorteile vom Starter-Paket</div>
                            </div>
                            <div class="flex flex--start__vert mar--bottom__1">
                                <i class="ion-ios-checkmark font--s3 color--primaryDark mar--right__1 lineHeight--12" />
                                <div class="font--s16 lineHeight--12 mar--top__1">Erstelle bis zu <b>10</b> Online-Kurse</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex--center__hor flex--end__vert lineHeight--1 border--top__medium pad--top__2">
                        <div class="font--s5 font--w500">79€</div>
                        <div class="font--s14">/Monat</div>
                    </div>
                </div>
            </div>
            <div class="bgColor--greyLight pad--2 b-radius--5">
                <div class="flex flex--column flex--space__hor height--100">
                    <div class="flex flex--column">
                        <h1>Business</h1>
                        <div class="height--01 bgColor--primaryDark width--2"></div>
                        <div class="flex flex--column pad--top__2">
                            <div class="flex flex--start__vert mar--bottom__1">
                                <i class="ion-ios-checkmark font--s3 color--primaryDark mar--right__1 lineHeight--12" />
                                <div class="font--s16 lineHeight--12 mar--top__1">Genieße alle Vorteile vom Creator-Paket</div>
                            </div>
                            <div class="flex flex--start__vert mar--bottom__1">
                                <i class="ion-ios-checkmark font--s3 color--primaryDark mar--right__1 lineHeight--12" />
                                <div class="font--s16 lineHeight--12 mar--top__1">Erstelle bis zu <b>25</b> Online-Kurse</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex--center__hor flex--end__vert lineHeight--1 border--top__medium pad--top__2">
                        <div class="font--s5 font--w500">129€</div>
                        <div class="font--s14">/Monat</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="fle flex--center width--100 mar--2">
            <div class="flex flex--center font--s3 font--w700">
                Eure Fragen. Unsere Antworten.
            </div>
        </div>
        <div class="flex flex--column mar--bottom__4 width--100">
            <div class="faq--item flex flex--column overflowHidden mar--bottom__05">
                <div class="flex flex--center__vert flex--space__hor pad--2 bgColor--primaryLight color--white clickable" @click="toggle(1)">
                    <div class="font--w700 font--s16">
                        Welche Vorteile bietet mir OKourse?
                    </div>
                    <i class="ion-md-add font--s3" v-if="expanded !== 1"/>
                    <i class="ion-md-remove font--s3" v-if="expanded === 1"/>
                </div>
                <div class="bgColor--primaryLightest pad--2 font--s14" v-if="expanded === 1">
                    Mit OKourse kannst du Online-Kurse erstellen und in unterschiedliche Applikationen einbinden. Der große Vorteil von OKourse gegenüber anderen Kurs-Hostern ist, dass wir bei OKourse alle Schnittstellen öffentlich zur Verfügung stellen. Dadurch sind Kurse nicht nur auf unserer Plattform verfügbar sondern du kannst sie auch in deine eigene Website oder App integrieren. Du bist dadurch nicht wie bei anderen Kurs-Hostern an die vorgegebene User-Experience und Plattform gebunden sodern kannst deine eigene Applikation frei gestalten.
                </div>
            </div>
            <div class="faq--item flex flex--column overflowHidden mar--bottom__05">
                <div class="flex flex--center__vert flex--space__hor pad--2 bgColor--primaryLight color--white clickable" @click="toggle(2)">
                    <div class="font--w700 font--s16">
                        Kann ich wirklich unbegrenzt Kursinhalte hochladen?
                    </div>
                    <i class="ion-md-add font--s3" v-if="expanded !== 2" />
                    <i class="ion-md-remove font--s3" v-if="expanded === 2"/>
                </div>
                <div class="bgColor--primaryLightest pad--2 font--s14" v-if="expanded === 2">
                    Ab dem Starter-Paket hast du tatsächlich ein unbegrenztes Kontingent an Kursinhalten. Lediglich die Zahl der Kurse an sich ist abhängig von deinem ausgewählten Paket limitiert. Nicht nur die Kursinhalte sondern auch der traffic und die Nutzerzahlen in deinen Kursen sind unlimitiert. Dadurch wollen wir dir und deiner community ein starkes Wachstum ermöglichen.
                </div>
            </div>
            <div class="faq--item flex flex--column overflowHidden mar--bottom__05">
                <div class="flex flex--center__vert flex--space__hor pad--2 bgColor--primaryLight color--white clickable" @click="toggle(3)">
                    <div class="font--w700 font--s16">
                        Brauche ich eine eigene Website um auf OKourse Kurse zu erstellen?
                    </div>
                    <i class="ion-md-add font--s3" v-if="expanded !== 3" />
                    <i class="ion-md-remove font--s3" v-if="expanded === 3"/>
                </div>
                <div class="bgColor--primaryLightest pad--2 font--s14" v-if="expanded === 3">
                    Nein. OKourse bietet neben den öffentlichen Schnittstellen auch standardmäßig ein eigenes Kurs-Interface an, über welches deine Kursteilnehmer auf die Kursinhalte zugreifen können. Das Interface kannst du an dein Organisations-Design anpassen. Sofern du aber tiefgreifende Änderungen in der Gestaltung vornehmen willst, empfehlen wir dir deine eigene Applikation als Frontend zu verwenden.
                </div>
            </div>
            <div class="faq--item flex flex--column overflowHidden mar--bottom__05">
                <div class="flex flex--center__vert flex--space__hor pad--2 bgColor--primaryLight color--white clickable" @click="toggle(4)">
                    <div class="font--w700 font--s16">
                        Kann ich über OKourse auch meine Käufe abwickeln?
                    </div>
                    <i class="ion-md-add font--s3" v-if="expanded !== 4" />
                    <i class="ion-md-remove font--s3" v-if="expanded === 4"/>
                </div>
                <div class="bgColor--primaryLightest pad--2 font--s14" v-if="expanded === 4">
                    Nein. Wir wollen dir die Freiheit lassen deinen präferierten Zahlungsdienstleister zu verwenden ohne dir etwas aufzuzwingen. OKourse bietet Schnittstellen zu verschiedenen Zahlungsdienstleistern, wodurch wir mitbekommen wenn deine Kunden Kurse kaufen. OKourse selbst, bietet allerdings keine Möglichkeit Zahlungen direkt über die Plattform abzuwickeln.
                </div>
            </div>
            <div class="faq--item flex flex--column overflowHidden mar--bottom__05">
                <div class="flex flex--center__vert flex--space__hor pad--2 bgColor--primaryLight color--white clickable" @click="toggle(5)">
                    <div class="font--w700 font--s16">
                        Kann ich mehrere Organisationen mit einem Account verwalten?
                    </div>
                    <i class="ion-md-add font--s3" v-if="expanded !== 5" />
                    <i class="ion-md-remove font--s3" v-if="expanded === 5"/>
                </div>
                <div class="bgColor--primaryLightest pad--2 font--s14" v-if="expanded === 5">
                    Ja. Mit deinem OKourse Account kannst du verschiedene Organisationen erstellen oder bei diesen als Mitarbeiter bei der Verwaltung der Online-Kurse helfen. Ein Paket kaufst du dabei immer für eine spezielle Organisation wodurch die entsprechenden Funktionen auch nur in dieser verfügbar sind.
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Home',
        data() {
            return {
                position1: `translateY(0)`,
                position2: `translateY(0)`,
                position3: `translateY(0)`,
                position4: `translateY(0)`,
                expanded: null,
            }
        },
        mounted() {
            window.addEventListener('scroll', this.updateScroll)
        },
        methods: {
            toggle(id) {
                if (this.expanded === id) {
                    this.expanded = null;
                } else if (this.expanded !== id) {
                    this.expanded = id;
                }
            },
            updateScroll() {
                this.position1 = `translateY(-${window.scrollY * 0.4}px)`;
                this.position2 = `translateY(-${window.scrollY * 0.2}px)`;
                this.position3 = `translateY(-${window.scrollY * 0.05}px)`;
                this.position3 = `translateY(-${window.scrollY * 0.1}px)`;
            },
        },
        destroy() {
            window.removeEventListener('scroll', this.updateScroll)
        },
    };
</script>
