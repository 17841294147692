<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
<!--    <transition name="fade" mode="out-in">-->
    <!--      <Modal title="Cookie Einstellungen" v-if="allowed == null && route !== 'data'">-->
    <!--        <div class="pad&#45;&#45;3 flex flex&#45;&#45;column flex&#45;&#45;center__hor width&#45;&#45;100">-->
    <!--          <div class="width&#45;&#45;max__50vw font&#45;&#45;s16 mar&#45;&#45;bottom__2">-->
    <!--            Diese Webseite speichert sogenannte Cookies um wichtige Funktionen (bspw. login) zu ermöglichen.-->
    <!--            Darüber hinaus können Cookies für personalisiertes Marketing verwendet werden. Du kannst die hier-->
    <!--            ausgewählten Einstellungen jederzeit ändern.-->
    <!--          </div>-->
    <!--          <router-link to="/data" class="noDec font&#45;&#45;s16 font&#45;&#45;w700 color&#45;&#45;primary mar&#45;&#45;bottom__2 clickable">-->
    <!--            Datenschutzerklärung & Cookie-Richtlinien-->
    <!--          </router-link>-->
    <!--          <div class="flex flex&#45;&#45;center__vert mar&#45;&#45;bottom__2 clickable">-->
    <!--            <div class="checkbox circle border&#45;&#45;light clickable mar&#45;&#45;right__1 flex flex&#45;&#45;center"-->
    <!--                 :class="{'checkbox&#45;&#45;filled': required}">-->
    <!--              <i class="ion-ios-checkmark color&#45;&#45;white font&#45;&#45;s2 lineHeight&#45;&#45;1" v-if="required"/>-->
    <!--            </div>-->
    <!--            <div class="font&#45;&#45;s2">-->
    <!--              Notwendige Cookies-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="flex flex&#45;&#45;center__vert mar&#45;&#45;bottom__2 clickable" @click="marketing = !marketing">-->
    <!--            <div class="checkbox circle border&#45;&#45;light clickable mar&#45;&#45;right__1 flex flex&#45;&#45;center"-->
    <!--                 :class="{'checkbox&#45;&#45;filled': marketing}">-->
    <!--              <i class="ion-ios-checkmark color&#45;&#45;white font&#45;&#45;s2 lineHeight&#45;&#45;1" v-if="marketing"/>-->
    <!--            </div>-->
    <!--            <div class="font&#45;&#45;s2">-->
    <!--              Marketing Cookies-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <AccentButton title="Einstellungen speichern" @onClick="storeCookies"/>-->
    <!--        </div>-->
    <!--      </Modal>-->
    <!--    </transition>-->
    <transition-group
            name="fade"
            v-if="toasts.length"
            class="toast--container fixed bottom--2 right--2 flex flex--column flex--end__hor">
      <toast v-for="toast in toasts"
             :key="toast.id"
             :toastID="toast.id"
             :type="toast.type"
             :title="toast.title"/>
    </transition-group>
  </div>
</template>

<script>
  export default {
  name: 'app',
  data() {
    return {
      allowed: false,
      required: true,
      marketing: false,
      route: '',
    };
  },
  computed: {
    toasts() {
      return this.$store.getters.getToasts;
    },
  },
  watch: {
    $route(newVal) {
      this.route = newVal.path.split('/')[1];
    }
  },
  methods: {
    storeCookies() {
      localStorage.setItem('OKourseCookies', `${this.marketing}`);
      this.allowed = localStorage.getItem('OKourseCookies');
    },
  },
  mounted() {
    // this.allowed = localStorage.getItem('OKourseCookies');
    this.route = this.$route.path.split('/')[1];
  },
}
</script>

<style lang="scss">
  @import "style/main";
</style>
