<template>
    <div class="flex flex--column flex--center__vert overflowAuto">
        <div class="courses--wrapper">
            <ActionHeader back-title="Einstellungen" title="Webhooks" @onBack="$router.push('/dashboard/organizations/' + $route.params.organizationID + '/settings')">
                <ActionButton icon="ion-md-add" accent="true" @onClick="showNewEndpoint = true" />
            </ActionHeader>
            <div class="pad--2">
                <div class="members--list flex flex--column width--100 b-radius--5 overflowHidden" v-if="webhooks.length">
                    <div class="members--member border--bottom__light clickable"
                         v-for="endpoint in webhooks" :key="endpoint._id" @click="$router.push('webhooks/' + endpoint._id)">
                        <div class="flex flex--center__vert pad--2" >
                            <div class="font--s14">
                                {{ endpoint.url }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="height--100 width--100 flex flex--center mar--top__2" v-if="!webhooks.length">
                    <div class="flex flex--column flex--center width--50">
                        <img src="../../../assets/SVG6.svg" class="width--50 mar--bottom__2"/>
                        <div class="font--s2 mar--bottom__2">
                            Du hast noch keine Webhooks.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <Modal title="Webhook Endpunkt hinzufügen" @toggle="showNewEndpoint = false" v-if="showNewEndpoint">
                <div class="pad--25 flex flex--column flex--grow height--100 flex--column flex--center overflowAuto">
                    <div class="flex flex--column width--100">
                        <label for="endpoint_url" class="form--label">Endpunkt-URL</label>
                        <input type="text" id="endpoint_url" class="form--input__text mar--bottom__2"
                               v-model.trim="endpointURL" v-focus>
                        <label for="endpoint_description" class="form--label">Beschreibung</label>
                        <textarea id="endpoint_description" class="form--input form--input__textArea mar--bottom__2" rows="5"
                                  v-model.trim="endpointDescription"/>

                        <label for="endpoint_event" class="form--label">Events</label>
                        <div class="flex flex--center__vert width--100 mar--bottom__2">
                            <v-select :clearable="true"
                                      :multiple="true"
                                      id="endpoint_event"
                                      :options="eventTypes"
                                      label="title"
                                      transition="fade"
                                      class="width--100 flex--grow pad--right__1"
                                      v-model="endpointEvents"/>
                            <ActionButton accent="true" icon="ion-md-add" />
                        </div>
<!--                        <div class="mar&#45;&#45;bottom__2">-->
<!--                            <p>-->
<!--                                Du hast noch keine Events hinzugefügt.-->
<!--                            </p>-->
<!--                        </div>-->
                        <AccentButton title="Endpunkt hinzufügen" @onClick="createEndpoint" />
                    </div>
                </div>
            </Modal>
        </transition>
    </div>
</template>
<script>
    import ActionHeader from "@/components/views/4_Page/ActionHeader";
    import ActionButton from "@/components/reusable/1_Buttons/ActionButton";
    import Modal from "@/components/reusable/2_Modals/Modal";
    import vSelect from 'vue-select'
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    export default {
        name: 'Webhooks',
        components: {AccentButton, Modal, ActionButton, ActionHeader, vSelect},
        data() {
            return {
                endpointURL: '',
                endpointDescription: '',
                endpointEvents: [],
                showNewEndpoint: false,
            }
        },
        computed: {
            webhooks() {
                return this.$store.getters.getWebhooks;
            },
            eventTypes() {
                return this.$store.getters.getEventTypes;
            },
        },
        methods: {
            createEndpoint() {
                if(this.endpointURL && this.endpointEvents) {
                    this.$store.dispatch('createEndpoint', {
                        organizationID: this.$route.params.organizationID,
                        data: {
                            url: this.endpointURL,
                            description: this.endpointDescription,
                            eventTypes: this.endpointEvents,
                        }
                    });
                    this.showNewEndpoint = false;
                }
            },
        },
        mounted() {
            this.$store.dispatch('retrieveEndpoints', this.$route.params.organizationID);
            this.$store.dispatch('retrieveEventTypes');
        }
    }
</script>
