<template>
    <div class="flex flex--column flex--center__vert overflowAuto">
        <div class="courses--wrapper">
            <ActionHeader back-title="Einstellungen" title="Angebote" @onBack="$router.push('/dashboard/organizations/' + $route.params.organizationID + '/settings')" >
                <ActionButton icon="ion-md-add" accent="true" @onClick="toggleCreateOffer" />
            </ActionHeader>
            <NoDataAction v-if="false" @onButtonClick="null"
                          text="Du hast noch keine Angebote erstellt." button-title="Jetzt Angebot erstellen"
            />
            <div class="offers flex flex--column width--100 height--100" v-if="true">
                <div v-for="offer in offers" :key="offer._id" class="offers--item pad--2 bgColor--white transition grid">
                    <div class="font--s14 font--w700 flex flex--center__vert">
                        {{ offer.title }}
                    </div>
                    <div class="font--s14  flex flex--center__vert">
                        {{ offer.courses.length }} Kurs{{ offer.courses.length === 1 ? '' : 'e'}}
                    </div>
                    <ActionButton icon="ion-md-create" light="true" @onClick="toggleEditOffer(offer)"/>
                    <ActionButton icon="ion-md-trash" light="true" class="color--danger" @onClick="toggleDelete(offer)" v-if="organization.role === 'Creator'"/>
                </div>
            </div>
        </div>
        <transition name="fade">
            <Modal title="Angebot erstellen" @toggle="showCreateOfferModal = false" v-if="showCreateOfferModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor height--100">
                    <label for="create_offer_title" class="form--label">Titel</label>
                    <input type="text" id="create_offer_title"
                           class="form--input form--input__text"
                           v-model.trim="offer.title"
                    />
                    <label for="create_offer_desc" class="form--label">Beschreibung</label>
                    <textarea id="create_offer_desc" class="form--input form--input__textArea"
                              v-model.trim="offer.description"/>
                    <div class="form--label">Kurse</div>
                    <div class="flex flex--center__vert width--100 mar--bottom__1">
                        <div class="flex--grow">
                            <v-select :clearable="false"
                                      :options="courses"
                                      label="title"
                                      transition="fade"
                                      v-model="newCourse"/>
                        </div>
                        <div class="mar--right__1" />
                        <ActionButton icon="ion-md-add" :accent="newCourse" :light="!newCourse" @onClick="newCourse ? addCourse() : null"/>
                    </div>
                    <div class="flex flex--column mar--bottom__2">
                        <div v-for="course in offer.courses" :key="course" class="pad--1 font--s14 offers--item__list flex flex--center__vert flex--space__hor">
                            <div>{{ courseById(course).title }}</div>
                            <ActionButton icon="ion-md-trash" @onClick="removeCourse(course)" />
                        </div>
                    </div>
                    <AccentButton :full-width="true" :filled="true" :loading="isLoading" title="Angebot erstellen" class="mar--top__2" @onClick="createOffer"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <Modal title="Angebot bearbeiten" @toggle="showEditOfferModal = false" v-if="showEditOfferModal">
                <div class="pad--25 flex flex--grow flex--column flex--center__hor height--100">
                    <label for="offer_title" class="form--label">Titel</label>
                    <input type="text" id="offer_title"
                           class="form--input form--input__text"
                           v-model.trim="offer.title"
                    />
                    <label for="offer_desc" class="form--label">Beschreibung</label>
                    <textarea id="offer_desc" class="form--input form--input__textArea"
                              v-model.trim="offer.description"/>
                    <div class="flex flex--space__hor flex--center__vert mar--top__1 clickable" @click="fullAccess = !fullAccess">
                        <div class="form--label">Voller Kurszugriff</div>
                        <div class="checkbox circle border--light clickable mar--right__1 flex flex--center"
                             :class="{'checkbox--filled': fullAccess}">
                            <i class="ion-ios-checkmark color--white font--s2 lineHeight--1" v-if="fullAccess"/>
                        </div>
                    </div>

                  <div class="flex flex--space__hor">
                    <div class="form--label">Zugriffsdauer</div>
                    <div class="flex flex--center__vert">
                      <ActionButton icon="ion-md-remove" @onClick="removeAccessRestriction" />
                      <h3 class="pad--1">{{ restrictAccess ? `${restrictAccess} Tage` : 'Unbegrenzt'}}</h3>
                      <ActionButton icon="ion-md-add" @onClick="addAccessRestriction" />
                    </div>
                  </div>
                    <div class="form--label">Kurse</div>
                    <div class="flex flex--center__vert width--100 mar--bottom__1">
                        <div class="flex--grow">
                            <v-select :clearable="false"
                                      :options="courses"
                                      label="title"
                                      transition="fade"
                                      v-model="newCourse"/>
                        </div>
                        <div class="mar--right__1" />
                        <ActionButton icon="ion-md-add" :accent="newCourse" :light="!newCourse" @onClick="newCourse ? addCourse() : null"/>
                    </div>
                    <div class="flex flex--column mar--bottom__2">
                        <div v-for="course in offer.courses" :key="course" class="pad--1 font--s14 offers--item__list flex flex--center__vert flex--space__hor">
                            <div>{{ courseById(course).title }}</div>
                            <ActionButton icon="ion-md-trash" @onClick="removeCourse(course)" />
                        </div>
                    </div>
                    <label for="offer_key" class="form--label">Externe Produktschlüssel</label>
                    <div class="flex flex--center__vert width--100 mar--bottom__1">
                        <div class="flex--grow">
                            <input type="text" id="offer_key"
                                   class="form--input form--input__text"
                                   v-model.trim="newKey"
                            />
                        </div>
                        <div class="mar--right__1" />
                        <ActionButton icon="ion-md-add" :accent="newKey" :light="!newKey" @onClick="newKey ? addKey() : null"/>
                    </div>
                    <div class="flex flex--column">
                        <div v-for="key in validIDs" :key="key" class="pad--1 font--s14 offers--item__list flex flex--center__vert flex--space__hor">
                            <div>{{ key }}</div>
                            <ActionButton icon="ion-md-trash" @onClick="removeKey(key)" v-if="organization.role === 'Creator'"/>
                        </div>
                    </div>
                    <AccentButton :full-width="true" :filled="true" :loading="isLoading" title="Änderungen Speichern" class="mar--top__2" @onClick="editOffer"/>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <ConfirmationModal title="Möchtest du das Angebot wirklich löschen?"
                               @reject="showDeleteOffer = false"
                               @confirm="removeOffer"
                               v-if="showDeleteOffer"
                               content="Durch das Löschen werden keine Kurse mehr freigeschaltet die mit diesem Angebot verbunden waren" />
        </transition>
    </div>
</template>
<script>
    import ActionHeader from "@/components/views/4_Page/ActionHeader";
    import ActionButton from "@/components/reusable/1_Buttons/ActionButton";
    import NoDataAction from "@/components/reusable/NoDataAction";
    import Modal from "@/components/reusable/2_Modals/Modal";
    import AccentButton from "@/components/reusable/1_Buttons/AccentButton";
    import vSelect from 'vue-select'
    import _ from "lodash";
    import ConfirmationModal from "@/components/reusable/2_Modals/ConfirmationModal";
    export default {
        name: 'Offers',
        components: {ConfirmationModal, AccentButton, Modal, NoDataAction, ActionButton, ActionHeader, vSelect},
        data() {
            return {
                showEditOfferModal: false,
                showCreateOfferModal: false,
                showDeleteOffer: false,
                offer: {},
                newCourse: null,
                newKey: null,
                deleteOffer: null,
                isLoading: false,
                fullAccess: false,
              restrictAccess: 0,
            };
        },
        computed: {
            offers() {
                /* eslint-disable */
                console.log(this.$store.getters.getOffers);
                return this.$store.getters.getOffers;
            },
            courses() {
                return _.cloneDeep(this.$store.getters.getCourses.courses);
            },
            validIDs() {
                if (!this.offer.externalIds) {
                    return [];
                }
                return this.offer.externalIds.filter((id) => id !== this.offer._id);
            },
          organization() {
            return this.$store.getters.getOrganizationById(this.$route.params.organizationID)
          }
        },
        methods: {
          addAccessRestriction() {
            this.restrictAccess++;
          },
          removeAccessRestriction() {
            if (this.restrictAccess > 0) {
              this.restrictAccess--;
            }
          },
          toggleCreateOffer() {
            this.offer = {}
            this.newKey = null;
            this.newCourse = null;
            this.fullAccess = false;
            this.restrictAccess = false;
            this.showCreateOfferModal = true;
          },
            toggleEditOffer(offer) {
                this.offer = _.cloneDeep(offer);
                this.newKey = null;
                this.newCourse = null;
                this.showEditOfferModal = true;
                this.fullAccess = offer.fullAccess;
                this.restrictAccess = offer.restrictAccess.active ? offer.restrictAccess.days : 0;
            },
            addCourse() {
                if (!this.offer.courses) {
                    this.offer.courses = []
                }
                if (this.offer.courses.includes(this.newCourse._id)) {
                    this.$store.commit('addToast', {
                        type: 'warning',
                        title: 'Kurs bereits im Angebot enthalten.',
                    });
                    this.newCourse = null;
                    return;
                }
                this.offer.courses.push(this.newCourse._id);
                this.newCourse = null;
            },
            removeCourse(id) {
                this.offer.courses = this.offer.courses.filter((course) => course !== id);
            },
            addKey() {
                this.offer.externalIds.push(this.newKey);
                this.newKey = null;
            },
            removeKey(id) {
                this.offer.externalIds = this.offer.externalIds.filter((key) => key !== id);
            },
            courseById(id) {
                return this.$store.getters.getCourseById(id);
            },
            async editOffer() {
              if (!this.offer.courses || !this.offer.courses.length) {
                this.$store.commit('addToast', {
                  type: 'warning',
                  title: 'Füge einen Kurs hinzu'
                })
                return;
              }
                this.isLoading = true;
                await this.$store.dispatch('editOffer', {
                    organizationID: this.$route.params.organizationID,
                    offerID: this.offer._id,
                    data: {
                        title: this.offer.title,
                        description: this.offer.description,
                        courses: this.offer.courses,
                        externalIds: this.offer.externalIds,
                        fullAccess: this.fullAccess,
                      restrictAccess: {
                        active: this.restrictAccess > 0,
                        days: this.restrictAccess,
                      },
                    }
                }).catch(() => {
                    this.isLoading = false;
                });
                this.isLoading = false;
                this.showEditOfferModal = false;
                this.newKey = null;
                this.newCourse = null;
            },
            async createOffer() {
              if (!this.offer.courses || !this.offer.courses.length) {
                this.$store.commit('addToast', {
                  type: 'warning',
                  title: 'Füge einen Kurs hinzu'
                })
                return;
              }
                this.isLoading = true;
                await this.$store.dispatch('createOffer', {
                    organizationID: this.$route.params.organizationID,
                    data: {
                        title: this.offer.title,
                        description: this.offer.description,
                        courses: this.offer.courses,
                    }
                }).catch(() => {
                    this.isLoading = false;
                });
                this.isLoading = false;
                this.showCreateOfferModal = false;
                this.newCourse = null;
            },
            toggleDelete(offer) {
              this.deleteOffer = offer;
              this.showDeleteOffer = true;
            },
            async removeOffer() {
                this.$store.dispatch('deleteOffer', {
                    organizationID: this.$route.params.organizationID,
                    offerID: this.deleteOffer._id,
                });
                this.deleteOffer = null;
                this.showDeleteOffer = false;
            }
        },
        mounted() {
            this.$store.dispatch('retrieveCourses', this.$route.params.organizationID);
            this.$store.dispatch('retrieveOffers', this.$route.params.organizationID);
        }
    }
</script>
